import React from 'react';
import PropTypes from 'prop-types';
import './style.css';
import app from 'new-ui/app';
import MissingCriticalQuestions from 'new-ui/Components/MissingCritical';
import { useTranslation } from 'utils/modifiedTranslation';
import { useHistory } from 'react-router-dom';
import { getDrugCommercialName } from 'new-ui/functions/getDrugCommercialName';
import { ROUTES, PAGES } from 'new-ui/constants';
import PublicationIcon from 'new-ui/assets/icons/publication.svg'; // Import a publication icon
import TypeLabel from '../TypeLabel';
import { LearnMoreButton } from '../LearnMoreButton/LearnMoreButton';
import SponsoredLabel from '../SponsoredLabel';
import SettingsLabel from '../SettingsLabel';
import { NewlyMatchedLabel } from '../NewlyMatchedLabel/NewlyMatchedLabel';
import InterventionalTypes from '../InterventionalTypes';
import AdministrationTypes from '../AdministrationTypes';
import Phases from '../Phases';
import FacilityLabel from '../FacilityLabel';
import SponsorInfo from '../SponsorInfo';

const getDetilsRoute = (type, item, isSearchPage) => {
  const isTreatment = type === TYPE.TREATMENT;

  if (isSearchPage) {
    return `${ROUTES[!isTreatment ? PAGES.PUBLIC_TRIAL : PAGES.PUBLIC_TREATMENT]}/${item.nct_number}`;
  }
  return `${ROUTES[!isTreatment ? PAGES.TRIAL : PAGES.TREATMENT]}/${item.nct_number}`;
};

const TYPE = {
  TRIAL: 'trial',
  TREATMENT: 'treatment',
};

const getItemType = (item) => (item.trialType === 'drug' ? 'treatment' : 'trial');

const BaseItem = ({ item, publicationCount, user }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const type = getItemType(item);
  const isSearchPage = app.isCurrentPage(PAGES.SEARCH);
  const onBaseItemButtonClick = () => {
    const route = getDetilsRoute(type, item, isSearchPage);
    history.push(route);
  };

  return (
    <div className={`base-item ${!user && 'public-item'}`}>
      <div className="base-header">
        {item.isSponsored && <SponsoredLabel />}
        {item.isNew && <NewlyMatchedLabel />}
        <div className="item-box">
          <MissingCriticalQuestions item={item} user={user} />
        </div>
      </div>
      <div className="base-body">
        <div className="info">
          {item.isSponsored && <SponsorInfo item={item} />}
          <h3 className="title">
            {type === TYPE.TRIAL && item.title}
            {type === TYPE.TREATMENT && (
              <div className="treatment-title">
                {item.drug_commercial_name && <div className="drug-name">{getDrugCommercialName(item.drug_commercial_name)}</div>}
                {item.generic_name && (
                  <div className="generic-name">
                    <span className="label">
                      {t('list_item.title.generic_name')}
                      :
                      {' '}
                    </span>
                    {' '}
                    {getDrugCommercialName(item.generic_name)}
                  </div>
                )}
              </div>
            )}
          </h3>
        </div>
        <InterventionalTypes options={item.groups} />
        <Phases options={item._phases.filter((phase) => phase)} />
        <AdministrationTypes options={item.treatment_administration_type} />
        {type === TYPE.TREATMENT && <SettingsLabel options={item.treatment_settings} />}
        {type === TYPE.TRIAL && <FacilityLabel item={item} />}
      </div>
      <div className="base-footer">
        <TypeLabel item={item} />
        <div className="buttons-container">
          { publicationCount === 0 || !publicationCount ? (
            <></>
          ) : (
            <button
              className="publication-button"
              onClick={onBaseItemButtonClick}
              data-count={Math.min(publicationCount, 10)}
            >
              <img src={PublicationIcon} alt="Publications" />
              {Math.min(publicationCount, 10)}
              {t('list_item.button.publication')}
              {publicationCount !== 1 ? 's' : ''}
            </button>
          )}
          <LearnMoreButton onClick={item.action ? () => {
            item.action(item);
          } : onBaseItemButtonClick}
          />
        </div>
      </div>
    </div>
  );
};

BaseItem.propTypes = {
  user: PropTypes.object.isRequired,
};

export default BaseItem;

import { MATCH_FLOW, MATCH_FLOW_LOCAL_KEY } from '../../../components/Matches/constants';

const getPossibleMatchFlows = () => Object.values(MATCH_FLOW);

const setMatchFlow = flow => {
  if (getPossibleMatchFlows().includes(flow)) {
    localStorage.setItem(MATCH_FLOW_LOCAL_KEY, flow);
  }
};

const getMatchFlow = () => {
  return localStorage.getItem(MATCH_FLOW_LOCAL_KEY);
};

export const MatchFlow = {
  setMatchFlow,
  getMatchFlow,
};

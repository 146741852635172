import api from 'new-ui/api';

export default async function getObjectChanges(oldObj, newObj) {
  // Make the function async
  const changes = {};

  // Helper function to format date as DD-MM-YYYY
  function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  }

  const dateOfUpdate = formatDate(new Date()); // Get current date in DD-MM-YYYY format

  function compare(oldVal, newVal, path = '') {
    // Skip if new value is null or undefined
    if (newVal === null || newVal === undefined) return;

    // Handle undefined cases (one of the values doesn't exist)
    if (oldVal === undefined && newVal === undefined) return;
    if (oldVal === undefined) {
      changes[path] = { old: undefined, new: newVal, date_of_update: dateOfUpdate };
      return;
    }

    // Check type equality
    if (typeof oldVal !== typeof newVal) {
      changes[path] = { old: oldVal, new: newVal, date_of_update: dateOfUpdate };
      return;
    }

    // Handle null cases
    if (oldVal === null || newVal === null) {
      if (oldVal !== newVal) {
        changes[path] = { old: oldVal, new: newVal, date_of_update: dateOfUpdate };
      }
      return;
    }

    // Handle primitive types
    if (typeof oldVal !== 'object') {
      if (oldVal !== newVal) {
        changes[path] = { old: oldVal, new: newVal, date_of_update: dateOfUpdate };
      }
      return;
    }

    // Handle arrays
    if (Array.isArray(oldVal) || Array.isArray(newVal)) {
      if (!Array.isArray(oldVal) || !Array.isArray(newVal)) {
        changes[path] = { old: oldVal, new: newVal, date_of_update: dateOfUpdate };
        return;
      }

      if (oldVal.length !== newVal.length) {
        changes[path] = { old: oldVal, new: newVal, date_of_update: dateOfUpdate };
        return;
      }

      for (let i = 0; i < oldVal.length; i++) {
        compare(oldVal[i], newVal[i], path ? `${path}[${i}]` : `[${i}]`);
      }
      return;
    }

    // Handle objects
    const allKeys = new Set([...Object.keys(oldVal), ...Object.keys(newVal)]);
    for (const key of allKeys) {
      const currentPath = path ? `${path}.${key}` : key;
      if (!(key in oldVal)) {
        compare(undefined, newVal[key], currentPath);
      } else if (!(key in newObj)) {
        // Skip if new value is null or undefined
        if (newVal[key] !== null && newVal[key] !== undefined) {
          compare(oldVal[key], newVal[key], currentPath);
        }
      } else {
        compare(oldVal[key], newVal[key], currentPath);
      }
    }
  }

  compare(oldObj, newObj);

  // Await the getConfiguration call
  const existsChanges = await api.getConfiguration();
  const existingConfig = (existsChanges?.data?.userChanges) || {};

  const keys = Object.keys(changes);
  for (const key of keys) {
    const value = changes[key];
    existingConfig[key] = value;
  }
  await api.setConfiguration({ userChanges: existingConfig });
  return changes; // Return the initial changes
}

import React, {
  useEffect, useMemo, useState, useCallback, useRef,
} from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';
import { SUCCESS } from 'utils/constants/queryStatuses';
import { useAsyncAction } from 'utils/hooks/useAsyncAction';
import { useLocationSearchParameters } from 'utils/hooks/useLocationSearchParameters';
import { useUpdatePageHeader } from 'new-ui/Components/PageHeader/PageHeaderContext';
import {
  addCurrentUserPendingDocuments,
  updateCurrentUser,
} from 'modules/currentUser/actions';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import { useHCPUser } from 'modules/hcp/useHCPUser';
import { startSignup } from 'modules/hcp/actions/signup';
import { saveHCPPatient } from 'modules/hcp/actions/saveHCPPatient';
import { getIsHCPPatient } from 'modules/hcp/helpers';
import { useTranslation } from 'utils/modifiedTranslation';
import { savePatientPendingDocuments } from 'modules/hcp/actions/savePatientPendingDocuments';
import { renderEligibilityForm } from './eligibilityForm';
import { MatchedItemHeaderContent } from './MatchedItemHeaderContent';
import { getMatchedItemByUser, getEligibilityStatus } from './helpers';
import { PATIENT_ELIGIBILITY_FORM_SEARCH_PARAM, ELIGIBILITY_STATUSES } from './constants';

const consultWithUsPopup = () => {
  app.setPopup(POPUP_TYPES.CONSULT_WITH_US);
};

// matchedItem - trial or treatment
export const useMatchedItemEligibilityCheck = ({ trialType, nctNumber }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const closeEligibilityFormRef = useRef(null);

  const [eligibilityFormWasOpened, setEligibilityFormWasOpened] = useState(false);

  const { [PATIENT_ELIGIBILITY_FORM_SEARCH_PARAM]: openEligibilitySearchParameter } = useLocationSearchParameters();

  const {
    currentUser: user,
  } = useSelector((state) => ({
    currentUser: state.currentUser.data,
  }));

  const { setHeaderContent } = useUpdatePageHeader();
  const {
    isHCPUser,
  } = useHCPUser();

  const getMatchedItemFetchParams = useMemo(() => ({
    user, trialType, nctNumber,
  }), [user, trialType, nctNumber]);

  const { status: userMatchedItemFetchingStatus, data: userMatchedItem } = useAsyncAction({
    isActive: !!(user && nctNumber),
    action: getMatchedItemByUser,
    parameters: getMatchedItemFetchParams,
    dataKey: nctNumber,
  });

  const isRegisteredPatient = getIsHCPPatient(user);

  const eligibilityStatus = userMatchedItemFetchingStatus === SUCCESS ? getEligibilityStatus(userMatchedItem) : null;

  const shouldOpenEligibilityForm = !!openEligibilitySearchParameter && !!user
    && !eligibilityFormWasOpened
    && userMatchedItemFetchingStatus === SUCCESS && eligibilityStatus === ELIGIBILITY_STATUSES.NOT_ELIGIBLE;

  const handleEligibilityFormSave = useCallback((nextUser) => {
    if (!nextUser) {
      return;
    }
    batch(async () => {
      dispatch(updateCurrentUser(nextUser, true));

      if (!isRegisteredPatient) {
        return;
      }

      try {
        await dispatch(saveHCPPatient());
      } catch (ex) {
        console.error('saveHCPPatient ex', ex);
      }
    });
  }, [dispatch, isRegisteredPatient]);

  const handleEligibilityFormUploadedFilesChange = useCallback((files, { jobId, source }) => {
    if (!files?.length) {
      return;
    }
    batch(async () => {
      dispatch(addCurrentUserPendingDocuments([{ files, metadata: { jobId, source } }]));

      if (!isRegisteredPatient) {
        return;
      }

      dispatch(savePatientPendingDocuments())
        .catch((ex) => {
          console.error('savePatientPendingDocuments ex', ex);
        });
    });
  }, [dispatch, isRegisteredPatient]);

  const talkToUs = useCallback(() => {
    if (isHCPUser) {
      consultWithUsPopup();
    } else {
      dispatch(startSignup({
        onHCPUserSuccessAction: () => () => consultWithUsPopup(),
      }));
    }
  }, [dispatch, isHCPUser]);

  const checkEligibility = useCallback(() => {
    setEligibilityFormWasOpened(true);
    closeEligibilityFormRef.current = renderEligibilityForm({
      item: userMatchedItem,
      user,
      onSave: handleEligibilityFormSave,
      onUploadedFilesChange: handleEligibilityFormUploadedFilesChange,
      t,
    });
  }, [userMatchedItem, user, handleEligibilityFormSave, handleEligibilityFormUploadedFilesChange, t]);

  useEffect(() => {
    // it is assumed that for public trial/treatment user is empty
    if (!nctNumber || !user) {
      return () => {};
    }

    if (shouldOpenEligibilityForm) {
      setEligibilityFormWasOpened(true);
      closeEligibilityFormRef.current = renderEligibilityForm({
        item: userMatchedItem,
        user,
        onSave: handleEligibilityFormSave,
        onUploadedFilesChange: handleEligibilityFormUploadedFilesChange,
        t,
      });
    }

    setHeaderContent(
      <MatchedItemHeaderContent
        checkEligibility={checkEligibility}
        talkToUs={talkToUs}
      />,
    );

    return () => {
      setHeaderContent(null);
    };
  }, [
    checkEligibility,
    user,
    handleEligibilityFormSave,
    handleEligibilityFormUploadedFilesChange,
    isHCPUser,
    setHeaderContent,
    shouldOpenEligibilityForm,
    talkToUs,
    userMatchedItem,
    nctNumber,
    t,
  ]);

  useEffect(() => () => {
    const { current: closeEligibilityForm } = closeEligibilityFormRef;
    if (closeEligibilityForm) {
      closeEligibilityForm();
    }
  }, []);

  return eligibilityFormWasOpened ? eligibilityStatus : null;
};

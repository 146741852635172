import matchesConstants from './constants';

export const initialState = {
  isFetching: false,
  error: null,
  trial_groups: [],
  trial_group_descriptions: {},
  trial_groups_colors: {},
  result_trials: [],
  result: null,
};

export function matches(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case matchesConstants.GET_MATCHES_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case matchesConstants.GET_MATCHES_SUCCESS:
      return {
        ...state,
        ...payload,
        result_trials: [...payload.result],
        isFetching: false,
        error: null
      };
    case matchesConstants.GET_MATCHES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    case matchesConstants.CLEAR_MATCHES:
      return {
        ...initialState,
      };
    default:
      return state;
  }
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { USER_TYPES, PLATFORM, USER_SOURCE } from 'utils/constants/user';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { getInitialConditionProfileByIndication, signup } from 'new-ui/functions';
import RESPONSE_ERROR_CONSTANTS from 'utils/constants/responseError';
import { AddPatientForm } from './AddPatientForm';

const { USER_EXISTS: USER_EXISTS_ERROR_CODE } = RESPONSE_ERROR_CONSTANTS;

const ERROR_TYPES = {
  INDICATION_REQUIRED: 'INDICATION_REQUIRED',
  NAME_REQUIRED: 'NAME_REQUIRED',
};

const GA = {
  category: 'HCP',
  events: {
    error: 'Could not signup',
  },
};

export const RegisterUser = (props) => {
  const {
    onSignup,
    onBeforeSignup,
    subtitle,
  } = props;

  const [error, setError] = useState(null);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();

  const { t } = useTranslation();

  const ERRORS = {
    [ERROR_TYPES.NAME_REQUIRED]: t('general.name_required'),
    [ERROR_TYPES.INDICATION_REQUIRED]: t('general.indication_required'),
  };

  const getErrorMessage = (ex) => {
    const { response: { data: { code } = {} } = {} } = ex;
    if (code === USER_EXISTS_ERROR_CODE) {
      return t('add_patient.error.email_duplication');
    }
    return 'Signup Error';
  };

  const handleFormSubmit = async (formData) => {
    const { indication: { value: indication } = {}, email, name } = formData ?? {};

    if (!indication) {
      return setError(ERRORS[ERROR_TYPES.INDICATION_REQUIRED]);
    }
    if (!name) {
      return setError(ERRORS[ERROR_TYPES.NAME_REQUIRED]);
    }

    app.setShowPreloader(true);
    const condition_profile = await getInitialConditionProfileByIndication(indication);
    const patient = {
      condition_profile,
      info: {
        family_name: name,
        email,
        user_type: USER_TYPES.PATIENT,
        platform: PLATFORM.HCP,
      },
      personal: {
        condition: indication,
      },
    };

    if (onBeforeSignup) {
      await onBeforeSignup(patient);
    }

    signup({
      email,
      indication,
      recaptchaToken: await getRecaptchaTokenAsync(),
      condition_profile: patient.condition_profile,
      info: patient.info,
      onsignup: async (user) => {
        app.intercom.updateUserAttributes({
          email,
          condition: indication,
          user_type: USER_TYPES.PATIENT,
          user_source: USER_SOURCE.HCP,
          shadow_patient: true,
        }, user.info.user_wix_id);

        onSignup(user, setError);
      },
      onerror: (e) => {
        const errorMsg = getErrorMessage(e);
        app.setShowPreloader(false);
        setError(errorMsg);
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.error, { name: 'caregiver_patient_signup_error' });
      },
    });
  };

  return (
    <AddPatientForm
      emailRequired
      onSubmit={handleFormSubmit}
      recaptcha={recaptcha}
      error={error}
      title={t('add_user.title')}
      subtitle={subtitle}
      indicationTitle={t('add_user.indication_input_title')}
      buttonTitle={t('add_user.button_title')}
      nameTitle={t('add_user.name_input_title')}
      emailTitle={t('add_user.email_input_title')}
    />
  );
};

RegisterUser.propTypes = {
  onSignup: PropTypes.func.isRequired,
  onBeforeSignup: PropTypes.func.isRequired,
  subtitle: PropTypes.string,
};

export const REDUX_ACTIONS = {
  DIALOG_DATA_SET: 'DIALOG_DATA_SET',
  DIALOG_DATA_CLEAR: 'DIALOG_DATA_CLEAR',
  DIALOG_TOGGLE: 'DIALOG_TOGGLE',
};

export const OPENED_VIA = {
  REDUX_ACTION: 'REDUX_ACTION',
  URL: 'URL',
};

export const THEME = {
  STANDARD: 'STANDARD',
  INFO: 'INFO',
  INFO_SMALL: 'INFO_SMALL',
  SPONSORED: 'SPONSORED',
};

export const SIDEBAR_DIALOGS = [
];

export const DIALOG_NAMES = {
};

import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { push } from 'connected-react-router';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import { PAGES, POPUP_TYPES, ROUTES } from 'new-ui/constants';
import { useHCPUser } from 'modules/hcp/useHCPUser';
import { patientSignupPopup } from 'modules/hcp/actions/patientSignup';
import { hcpUserSignupPopup } from 'modules/hcp/actions/hcpUserSignup';
import { assembleRoutePath } from 'functions/assembleRoutePath';
import { PATIENT_ELIGIBILITY_FORM_SEARCH_PARAM } from 'components/MatchItemEligibilityCheck/constants';
import { TRIAL_TYPES } from 'utils/constants/trial';

const consultWithUs = () => {
  app.setPopup(POPUP_TYPES.CONSULT_WITH_US);
};

export const MissingCriticalHCPButton = ({ hasMissingCritical, item }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const showConsultWithUsRef = useRef(false);

  const {
    isAuthenticated,
    isHCPUser,
    isPatientWithoutId,
  } = useHCPUser();

  const { nct_number: nctNumber, trialType } = item;

  useEffect(() => {
    if (!isAuthenticated || !showConsultWithUsRef.current || !isHCPUser) {
      return;
    }
    showConsultWithUsRef.current = false;
    consultWithUs();
  }, [isAuthenticated, isHCPUser]);

  const onEligibilityClick = () => {
    if (isPatientWithoutId) {
      dispatch(patientSignupPopup());
      return;
    }

    if (!hasMissingCritical) {
      if (isAuthenticated) {
        consultWithUs();
      } else {
        showConsultWithUsRef.current = true;
        dispatch(hcpUserSignupPopup());
      }
      return;
    }

    const isTreatment = trialType === TRIAL_TYPES.TREATMENT;
    dispatch(push(assembleRoutePath({
      pathname: `${ROUTES[isTreatment ? PAGES.PUBLIC_TREATMENT : PAGES.PUBLIC_TRIAL]}/${nctNumber}`,
      searchParameters: { [PATIENT_ELIGIBILITY_FORM_SEARCH_PARAM]: '1' },
    })));
  };

  return (
    <div
      className="missing-questions missing-questions-ready"
      onClick={onEligibilityClick}
    >
      <div className="missing-questions-text-subtitle">
        {t(`hcp.${hasMissingCritical ? 'item_missed_questions' : 'item_no_missed_questions'}`)}
      </div>
    </div>
  );
};

MissingCriticalHCPButton.propTypes = {
  hasMissingCritical: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    isSponsored: PropTypes.bool,
    trialType: PropTypes.string,
    nct_number: PropTypes.string.isRequired,
  }).isRequired,
};

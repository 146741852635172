import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './MissingCritical.css';
import app from 'new-ui/app';
import { getUserConditionProfile, getUserCondition } from 'modules/user/utils';
import { useIsMounted } from 'utils/hooks/useIsMounted';
import useAuth from 'utils/hooks/useAuth';
import { PAGES } from 'new-ui/constants';
import { filterQuestionsByCondition } from 'new-ui/functions';
import { MissingCriticalButton } from './MissingCriticalButton';
import { MissingCriticalHCPButton } from './MissingCriticalHCPButton';

const getIsMissingCritical = async ({ questionsIds, conditionProfile, condition }) => {
  const filteredQuestions = await filterQuestionsByCondition({
    questionsIds,
    conditionProfile,
    condition,
  });
  return !!filteredQuestions.length;
};

const MissingCriticalQuestions = ({
  item,
  user,
}) => {
  const isAuthenticated = useAuth();
  const isHcp = app.isCurrentPage(PAGES.SEARCH);
  const isMounted = useIsMounted();

  // let hasMissingCritical = item?.missed_questions?.length ? true : false;
  const questionsIds = item?.missed_questions || [];
  const [hasMissingCritical, setMissingCritical] = useState(!!questionsIds.length);

  const userConditionProfile = getUserConditionProfile(user);
  // for search page user condition can be undefined
  const condition = getUserCondition(user) ?? item.cancer_type;

  useEffect(() => {
    if (!condition || !questionsIds.length) {
      return;
    }

    getIsMissingCritical({
      questionsIds, conditionProfile: userConditionProfile, condition,
    }).then((isMissingCritical) => {
      if (!isMounted()) {
        return;
      }
      setMissingCritical(isMissingCritical);
    });
  }, [condition, questionsIds, userConditionProfile, isMounted]);

  if (questionsIds.length && !condition) {
    return null;
  }

  if (isHcp) {
    return (
      <MissingCriticalHCPButton
        hasMissingCritical={hasMissingCritical}
        item={item}
      />
    );
  }

  if (!isAuthenticated) {
    // for not search page a user must be authenticated
    return null;
  }

  return (
    <MissingCriticalButton
      hasMissingCritical={hasMissingCritical}
      item={item}
    />
  );
};

MissingCriticalQuestions.propTypes = {
  user: PropTypes.object.isRequired,
  item: PropTypes.object.isRequired,
};

export default MissingCriticalQuestions;

import React from 'react';
import { render } from 'react-dom';
import app from 'new-ui/app';
import Button from 'new-ui/Components/Button';
import {
  POPUP_TYPES, THEMES,
} from 'new-ui/constants';
import { DOM, getOverlay } from 'new-ui/functions';
import ArrowLeft from 'new-ui/assets/icons/arrow_left_2.svg';
import { SearchForm } from 'new-ui/Components/SearchForm/SearchForm';
import { AiAssistant } from 'new-ui/Search/AiAssistant';
import { GA_HCP } from 'new-ui/Search/GA';
import {
  mergeUser, getUserCondition, getUserConditionProfile,
} from 'modules/user/utils';
import { getGeneralDiseaseSubType } from 'modules/user/conditionProfileHelpers';
import { allCancerTypes } from 'utils/constants/cancerTypes';
import { mergeExtractedProfile } from 'modules/hcp/helpers';
import styles from './eligibilityForm.module.css';

const { GENERAL: GENERAL_DISEASE } = allCancerTypes;

// TODO rework with using createPortal

const renderSearchForm = ({
  $overlay,
  $existingIntake,
  onUserChange,
  user,
  item,
}) => {
  if ($existingIntake) {
    $existingIntake.remove();
  }
  const $intake = DOM(`.${styles.intakeHolder}`, {});
  $overlay.append($intake);

  render(
    <>
      <SearchForm
        indicationChangeDisabled={!!getUserCondition(user)}
        user={user}
        onAccordionRender={() => {
          const allQuestions = document.querySelectorAll('.intake-question');
          allQuestions.forEach(($q) => {
            const qid = $q.getAttribute('qid');
            if (!item?.missed_questions?.length || !item?.missed_questions?.includes(qid)) {
              return;
            }
            const isAnswered = Boolean($q.getAttribute('data-answered') === 'true');
            if (!isAnswered) {
              $q.classList.add('not-eligible');
            }
          });
        }}
        onChange={(nextUser) => {
          onUserChange(nextUser);
        }}
        onLoad={(nextUser) => {
          onUserChange(nextUser);
        }}
      />
    </>,
    $intake,
  );
  return $intake;
};

export const renderEligibilityForm = ({
  item, user, onSave, onUploadedFilesChange, t,
}) => {
  let $overlay = getOverlay();
  $overlay.classList.add(styles.popupOverlay);
  const $header = DOM(`.${styles.popupHeader}`, {});
  $overlay.append($header);

  let isRendered = true;

  const close = () => {
    if (!isRendered) {
      return;
    }
    isRendered = false;
    if ($overlay?.remove) {
      $overlay.remove();
      $overlay = null;
    }
  };

  let formUser = { ...user };

  const onUserChange = (nextUser) => {
    formUser = nextUser;

    // auto save
    onSave(formUser);
  };

  render(
    <>
      <div className={styles.popupHeaderStartGroup}>
        <Button
          theme={THEMES.GREY}
          icon={ArrowLeft}
          title={t('general.back')}
          action={() => {
            app.history.goBack();
            close();
          }}
        />
      </div>
      <div className={styles.popupHeaderEndGroup}>
        <Button
          title={t('general.save')}
          theme={THEMES.ORANGE_FULL}
          action={() => {
            close();
          }}
        />
      </div>
    </>, $header,
  );

  const $ai = DOM(`.${styles.aiAssistant}`, {});
  $overlay.append($ai);

  let $existingIntake;

  const upload = () => {
    const condition = getUserCondition(formUser);
    const generalDiseaseSubType = condition === GENERAL_DISEASE
      ? getGeneralDiseaseSubType(getUserConditionProfile(formUser))
      : null;

    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_FILES_CLICKED);
    app.setPopup(POPUP_TYPES.CURRENT_USER_UPLOAD, {
      theme: 'middle',
      preventOverlayClose: true,
      condition,
      generalDiseaseSubType,
      onUploadedFilesChange,
      onSuccess: async (nextUser) => {
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_SUCCESS);

        app.setShowPreloader(true);
        try {
          formUser = await mergeExtractedProfile({ currentProfile: formUser, extractedProfile: nextUser });
          // eslint-disable-next-line no-empty
        } catch {}

        onSave(formUser);

        app.setShowPreloader(false);

        $existingIntake = renderSearchForm({
          $overlay,
          $existingIntake,
          onUserChange,
          user: formUser,
          item,
        });
      },
    });
  };

  const NGS = () => {
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_CLICKED);
    app.setPopup(POPUP_TYPES.CURRENT_USER_NGS, {
      theme: 'middle',
      preventOverlayClose: true,
      condition: getUserCondition(formUser),
      onUploadedFilesChange,
      onSuccess: async (biomarkers) => {
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_SUCCESS, {
          biomarkers,
        });
        app.setPopup(false);

        // merge on the server is not accessible because the server does not know about the changes made in the form
        formUser = mergeUser(
          formUser,
          { condition_profile: { biomarkers } },
          { mergeBiomarkers: true },
        );

        onSave(formUser);

        $existingIntake = renderSearchForm({
          $overlay,
          $existingIntake,
          onUserChange,
          user: formUser,
          item,
        });
      },
    });
  };

  render(
    <AiAssistant uploadAction={upload} ngsAction={NGS} />, $ai,
  );

  $existingIntake = renderSearchForm({
    $overlay,
    $existingIntake,
    onUserChange,
    user,
    item,
  });

  document.body.appendChild($overlay);
  $overlay.show();

  return close;
};

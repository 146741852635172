export const SORT_URL_SEARCH_PARAM = 'sort';

export const SORT_ORDER = {
  MOST_MATCHED: 'most_matched',
  RECENT: 'recent',
};

export const URL_QUERY_PARAM_BY_SORT_ORDER = {
  [SORT_ORDER.MOST_MATCHED]: null,
  [SORT_ORDER.RECENT]: 'recent',
};

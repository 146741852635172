import React from 'react';
import { useHistory } from 'react-router-dom';
import { PAGES, URI } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import { ReactComponent as ProfileIcon } from 'new-ui/assets/icons/profile_icon.svg';
import styles from './enhancePrecisionButton.module.css';

export const EnhancePrecisionButton = () => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <div>
      <div
        className={styles.root}
        onClick={() => {
          history.push(`${URI[PAGES.INTAKE]}?unanswered=1`);
        }}
      >
        <div className={styles.statusCircle} />
        <span className={styles.label}>
          {t('navbar.enhanced_result_precision')}
        </span>
        <ProfileIcon />
      </div>
    </div>
  );
};

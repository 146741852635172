import { deduplicateList } from 'functions/deduplicateList';
import authenticationConstants from 'components/Authentication/constants';
import {
  REPLACE_CURRENT_USER,
  RESET_CURRENT_USER,
  SET_CURRENT_USER_DIRTY,
  ADD_CURRENT_USER_PENDING_DOCUMENTS,
  RESET_CURRENT_USER_PENDING_DOCUMENTS,
  UPDATE_CURRENT_USER,
} from './constants';

const { LOGOUT } = authenticationConstants;

const initialState = {
  data: null,
  // means that there is a difference between the data in the storage and on the server, e.g.
  dirty: false,
  pendingDocuments: [],
};

// can be a not registered user (e.g. from search page)
// can be a patient from patient list of a caregiver
export function currentUser(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case REPLACE_CURRENT_USER:
      return { ...initialState, data: payload };
    case UPDATE_CURRENT_USER: {
      const { dirty: currentDirty } = state;
      // preserve dirty status
      const { user, dirty = currentDirty } = payload;

      return { ...state, data: user, dirty };
    }
    case SET_CURRENT_USER_DIRTY:
      return { ...state, dirty: payload };
    case ADD_CURRENT_USER_PENDING_DOCUMENTS:
      return {
        ...state,
        pendingDocuments: deduplicateList([
          ...state.pendingDocuments,
          ...payload,
        ]),
      };
    case RESET_CURRENT_USER_PENDING_DOCUMENTS:
      return { ...state, pendingDocuments: [] };
    case RESET_CURRENT_USER:
    case LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}

import { useState, useEffect } from 'react';
import app from 'new-ui/app';
import { getTotalArticles } from './api';
import { arrangeArticles, getArticles, getLabels } from './utils';

export const useFetchArticles = (user) => {
  const LIMIT = 4;

  const [articles, setArticles] = useState([]);
  const [totalArticles, setTotalArticles] = useState([]);
  const [labels, setLabels] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [offset, setOffset] = useState(0);
  const [isLoaded, setLoaded] = useState(false);

  const loadMoreArticles = () => {
    const response = getArticles(totalArticles, offset, LIMIT);
    setArticles((prev) => [...prev, ...response.data]);
    setOffset((prevOffset) => prevOffset + LIMIT);
    setHasMore(response.hasMore);
  };

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const arrangedArticles = arrangeArticles(await getTotalArticles(user.condition));
        setTotalArticles(arrangedArticles);
        const labelsRes = getLabels();
        setLabels(labelsRes);
        const articlesRes = getArticles(arrangedArticles);
        setArticles(articlesRes.data);
        setHasMore(articlesRes.hasMore);
        setOffset((prevOffset) => prevOffset + LIMIT);
        setLoaded(true);
      } catch (error) {
        console.error('Error fetching articles:', error);
      } finally {
        app.setShowPreloader(false);
      }
    };

    app.setShowPreloader(true);
    fetchArticles();
  }, [user]);

  return {
    articles,
    totalArticles,
    labels,
    hasMore,
    loadMoreArticles,
    isLoaded,
  };
};

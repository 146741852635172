/* eslint-disable operator-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable no-prototype-builtins */
/* eslint-disable arrow-parens */
import app from 'new-ui/app';
import { CHAT_ROLES } from 'new-ui/constants';
import { formatTextToUseNewLineBeforeNumbers, updateChatHistory } from '../Chat/functions';
import { sendMessageReturnUser } from './api';

/* eslint-disable no-console */

export function getDaysSinceRegistration(registeredAt) {
  // Parse the registration date (UTC)
  const registeredDate = new Date(registeredAt);
  const registeredUTCMidnight = Date.UTC(
    registeredDate.getUTCFullYear(),
    registeredDate.getUTCMonth(),
    registeredDate.getUTCDate()
  );

  // Get today's date in UTC midnight
  const today = new Date();
  const todayUTCMidnight = Date.UTC(
    today.getUTCFullYear(),
    today.getUTCMonth(),
    today.getUTCDate()
  );

  // Calculate difference in days
  const diffMs = todayUTCMidnight - registeredUTCMidnight;
  const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
  app.setConfiguration({ DaysSinceRegister: diffDays });
  // Return 0 for future dates
  return Math.max(0, diffDays);
}

function addDateSinceUpdate(obj) {
  const today = new Date();

  function calculateDaysSince(dateStr) {
    const [day, month, year] = dateStr.split('-').map(Number);
    const updateDate = new Date(year, month - 1, day); // Month is 0-indexed in Date
    const diffInMs = today.getTime() - updateDate.getTime();
    const diffInDays = Math.floor(diffInMs / (1000 * 60 * 60 * 24));
    return diffInDays;
  }

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const entry = obj[key];

      // Check if the entry has the necessary structure
      if (entry && typeof entry === 'object' && entry.hasOwnProperty('date_of_update')) {
        try {
          const daysSince = calculateDaysSince(entry.date_of_update);
          entry.days_since_update = daysSince;
        } catch (error) {
          // Handle invalid date strings gracefully
          console.error(`Error calculating days since update for key "${key}":`, error);
          entry.days_since_update = null; // Or some other indicator of an error, like -1
        }
      }
    }
  }

  return obj;
}

function updateOriginalJson(originalJson, modifiedJson) {
  let changesMade = false;

  for (const key in modifiedJson) {
    if (
      modifiedJson.hasOwnProperty(key) &&
      originalJson.hasOwnProperty(key) &&
      JSON.stringify(originalJson[key]) !== JSON.stringify(modifiedJson[key])
    ) {
      originalJson[key] = modifiedJson[key];
      changesMade = true;
    }
  }

  return changesMade;
}

const getUserSegment = () => {
  let segment = app?.coniguration?.data?.userIntent?.user_type;
  if (!segment) {
    segment = 'treatment';
  } else {
    segment = segment.toLowerCase(); // Convert segment to lowercase
  }
  return segment;
};

export const formmatedlData = (
  data,
  trials,
  userHaveSponseredTrialList,
  userSponsoredTrials,
  socTreatmentsCategories,
  socTreatments,
  newSession
) => {
  try {
    const toReturn = data;
    delete toReturn?.personal?.temp_id;
    delete toReturn?.personal?.is_done;
    delete toReturn?.personal?.answered_no_on_has_treatment_date;
    delete toReturn?.personal?.user_role;
    delete toReturn?.personal?.trialsCountBeforeExtraFilter;
    delete toReturn?.personal?.documents_data;
    delete toReturn?.personal?.providerCode;
    delete toReturn?.personal?.providerConsent;
    delete toReturn?.personal?.looking_for;
    delete toReturn?.condition_profile?.temp_id;
    delete toReturn?.info?.country;

    const userChangesWithDates = addDateSinceUpdate(app?.coniguration?.data?.userChanges || {});
    delete userChangesWithDates['condition_profile.qstack'];
    delete userChangesWithDates['personal.reached_required_question'];

    toReturn.info.Fulladdress = toReturn?.info?.country?.label;
    toReturn.info.addressLat = toReturn?.info?.country?.location?.lat;
    toReturn.info.addressLng = toReturn?.info?.country?.location?.lng;
    toReturn.socTreatments = app?.treatments?.slice(0, 15) || []; // Use an empty array if undefined
    toReturn.userNctList = trials;
    toReturn.numberOfTrialList = trials?.length ?? 0;
    toReturn.numberOfApprovedDrugs = app.treatments?.length ?? 0;
    toReturn.userSponsoredTrials = userSponsoredTrials;
    toReturn.userHaveSponseredTrialList = userHaveSponseredTrialList;
    toReturn.socTreatmentsCategories = socTreatmentsCategories;
    toReturn.DaysSinceRegister = app?.coniguration?.data?.DaysSinceRegister ?? 0;
    toReturn.userSegment = getUserSegment();
    toReturn.userChangesWithDates = userChangesWithDates;
    toReturn.userTreatments = app?.user?.condition_profile?.treatments;
    toReturn.newSession = newSession;
    return toReturn;
  } catch (error) {
    console.log('🚀 ~ formmatedlData ~ error:', error);
    return {};
  }
};

export const handleSendMessageByReturnUser = async (
  prompt,
  payload,
  setChatHistory,
  chatHistory,
  dontIncludeInHistory
) => {
  if (prompt && !dontIncludeInHistory) {
    updateChatHistory(setChatHistory, chatHistory, ` ${formatTextToUseNewLineBeforeNumbers(prompt)}`, CHAT_ROLES.ME);
  }
  let response = {};
  response = await sendMessageReturnUser(prompt, payload);
  updateChatHistory(
    setChatHistory,
    chatHistory,
    ` ${formatTextToUseNewLineBeforeNumbers(response.msg)}`,
    CHAT_ROLES.TINA
  );

  if (response.dataToUpdate) {
    updateOriginalJson(app.user.condition_profile, response.dataToUpdate);
    if (response.triggerProfileUpload === '1') {
      console.log('app.user.condition_profile:', app.user.condition_profile);
      await app.updateUser();
    }
  }

  if (response.triggerTrialListUpdate === '1') {
    await app.updateUser();

    response = await sendMessageReturnUser('Give me list of updated trials', payload);
    updateChatHistory(
      setChatHistory,
      chatHistory,
      ` ${formatTextToUseNewLineBeforeNumbers(response.msg)}`,
      CHAT_ROLES.TINA
    );
  }

  return response;
};

import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import { QUESTION_TYPES } from 'new-ui/Components/QuestionRenderer';
import HCPSelect from 'new-ui/Components/HCP/Select';
import { adjustTranslationKey } from '../helpers';
import { CheckEligibility } from './CheckEligibility';
import { questionPropTypes } from './questionPropTypes';

export const BoxSelectQuestion = ({ q, user, onselect }) => {
  const { t } = useTranslation();
  let isMulti = false;

  if (q.type === QUESTION_TYPES.MULTIPLE_OPTION_SELECT) {
    isMulti = true;
  }
  const options = q?.options
    ? q.options.map((o) => {
      o.title = t(adjustTranslationKey(o.label));
      return o;
    })
    : [];

  return (
    <div className="hcp-input">
      <div className="hcp-input-title">
        {t(adjustTranslationKey(q.title))}
      </div>
      <CheckEligibility />
      <HCPSelect
        q={q}
        user={user}
        onselect={onselect}
        initValue={user?.condition_profile[q.id]}
        placeholder={t(adjustTranslationKey(q.title))}
        options={options}
        isMulti={isMulti}
        useTags={false}
      />
    </div>
  );
};

BoxSelectQuestion.propTypes = questionPropTypes;

// All the names are synced with this document:
// https://docs.google.com/document/d/1qat7pGpBG9cExT8_hLuxHVbwVGX-M-m_a33nx7XipVc/edit?usp=sharing

import clickDownloadAndShareOnMinisite from 'functions/analytics/functions/clickDownloadAndShareOnMinisite';
import clickNextOnAQuestion from 'functions/analytics/functions/clickNextOnAQuestion';
import downloadPdfFromPopup from 'functions/analytics/functions/downloadPdfFromPopup';
import userIsMovedToTheNextSection from 'functions/analytics/functions/userIsMovedToTheNextSection';
import quickSignUpBtnViewed from 'functions/analytics/functions/quickSignUpBtnViewed';
import quickSignUpLoginPageViewed from 'functions/analytics/functions/quickSignUpLoginPageViewed';
import quickSignUpLoginSucceed from 'functions/analytics/functions/quickSignUpLoginSucceed';
import quickSignUpStarted from 'functions/analytics/functions/quickSignUpStarted';
import quickSignUpSucceed from 'functions/analytics/functions/quickSignUpSucceed';
import { sendFbEventToConversionApi } from 'functions/analytics/functions/sendFbEventToConversionApi';

export default {
  quickSignUpSucceed,
  quickSignUpStarted,
  quickSignUpLoginSucceed,
  quickSignUpLoginPageViewed,
  quickSignUpBtnViewed,
  clickDownloadAndShareOnMinisite,
  clickNextOnAQuestion,
  downloadPdfFromPopup,
  userIsMovedToTheNextSection,
  sendFbEventToConversionApi,
};

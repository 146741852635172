import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import app from 'new-ui/app';
import { getUserCondition, getUserWixId } from 'modules/user/utils';
import { saveHCPPatient } from 'modules/hcp/actions/saveHCPPatient';
import { updateCurrentUser } from 'modules/currentUser/actions';
import './FinanceIntake.css';
import { useTranslation } from 'utils/modifiedTranslation';
import Intake from 'new-ui/Components/Intake';
import { INTAKE_ABOUT_QUESTION, INTAKE_LOCATION_QUESTION, INTAKE_SECTIONS } from 'new-ui/Components/Intake/questions';
import SupportIcon from 'new-ui/assets/icons/support_white.svg';
import {
  DEFAULT_INDICATION, PAGES, ROUTES,
} from 'new-ui/constants';
import LottieAnimationLoading from 'new-ui/assets/lottie/intake_loading.json';
import { CancerFieldsByIndication } from 'new-ui/api/data/cancerTypes';
import {
  createEmptyUser,
  // getInitialConditionProfileByIndication,
  getLottie,
  getOverlay,
} from 'new-ui/functions';
import { useUpdatePageHeader } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { HeaderGoToResults } from 'new-ui/Components/HeaderGoToResults/HeaderGoToResults';
import { hcpApi } from 'modules/hcp/api';
import { getIsHCPPatient } from 'modules/hcp/helpers';
import { useAsyncAction } from 'utils/hooks/useAsyncAction';
import { PENDING, PRISTINE, SUCCESS } from 'utils/constants/queryStatuses';
import {
  createFinanceUser, getFinanceProfile, setFinanceUser,
} from '../Finance/api';
import { FINANCE_QUESTIONS } from './financeQuestions';
import { getFinanceParams } from '../Finance/functions';
import { getCategoryLabels } from '../Finance/utils';

const INTAKE_FINANCE_SECTIONS = {
  ABOUT_YOUR_CANCER: INTAKE_SECTIONS.ABOUT_YOUR_CANCER,
  ABOUT_YOU: INTAKE_SECTIONS.ABOUT_YOU,
  GENERAL_INFO: 'general',
};

const FINANCE_FIELDS = [
  'helpReceivedBefore',
  'hasSsn',
  'numOfHouseholdMembers',
  'income',
  'insurance',
];

const FINANCE_INTAKE_MODES = {
  EMPTY_USER: 'EMPTY_USER',
  PATIENT: 'PATIENT',
  USER: 'USER',
};

let $loading;
let isLoading;
let updateNum = 0;

let user;
let questions;
let mode;

export const getEmptyFinanceProfile = () => ({
  helpReceivedBefore: null,
  hasSsn: null,
  numOfHouseholdMembers: null,
  income: null,
  insurance: null,
});

const FinanceIntake = () => {
  const dispatch = useDispatch();
  const params = app.getParams();
  const { setHeaderRightSlot } = useUpdatePageHeader();

  const [financeUser, setFinanceUserClient] = useState(null);
  const [questionsData, setQuestionsData] = useState(null);
  const [isHcp] = useState(!!params.get('hcp'));
  const [financeParams, setFinanceParams] = useState(null);

  const {
    currentUser,
  } = useSelector((state) => ({
    currentUser: state.currentUser.data,
  }));

  const { status: financeMatchFetchStatus, data: financeMatchFetchResult } = useAsyncAction({
    action: hcpApi.financeMatch,
    parameters: financeParams,
    isActive: !!financeParams,
  });

  const [error] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (isHcp && !getUserCondition(currentUser)) {
      return () => {};
    }
    app.intercom.hidden = true;

    const fetch = async () => {
      await app.getSession();

      let _financeProfile = getEmptyFinanceProfile();

      /*
        3 modes:
        1. public - no app.user or patient - user is created
        2. user - user is app user
        3. patient - user is patient
      */

      user = await createEmptyUser(DEFAULT_INDICATION, undefined, {
        user_type: 'patient',
      });
      questions = await app.getQuestions(DEFAULT_INDICATION, true);
      mode = FINANCE_INTAKE_MODES.EMPTY_USER;

      if (isHcp && currentUser) {
        user = currentUser;
        mode = FINANCE_INTAKE_MODES.PATIENT;
        questions = await app.getQuestions(user.personal.condition, true);
        try {
          const patientUserWixId = getUserWixId(currentUser);
          if (patientUserWixId) {
            _financeProfile = await hcpApi.getFinancePatient(patientUserWixId);
          }
        } catch (e) {
          // no patient finance profile, create a new patient
        }
      } else if (app.user) {
        try {
          _financeProfile = await getFinanceProfile();
          console.log('finance get', _financeProfile);
          app.financeProfile = _financeProfile;
        } catch (e) {
          console.log('finance user does not exist. creating a new one');
          try {
            const selectedCategories = getCategoryLabels();
            await createFinanceUser(getFinanceParams(app.user, {}, selectedCategories));
          } catch (e) {
            console.log('finance user cannot be created');
          }
        }

        user = app.user;
        questions = app.questions;
        mode = FINANCE_INTAKE_MODES.USER;
      } else {
        // window.location = URI[PAGES.LOGIN];
      }

      Object.keys(_financeProfile).forEach((a) => {
        if (_financeProfile[a] === false) _financeProfile[a] = 'no';
        if (_financeProfile[a] === true) _financeProfile[a] = 'yes';
      });

      const _financeUser = user;

      _financeUser.condition_profile = Object.assign(user.condition_profile, _financeProfile);

      setFinanceParams(getFinanceParams(user, _financeProfile));

      const _questionsData = {
        [INTAKE_FINANCE_SECTIONS.ABOUT_YOUR_CANCER]: [
          FINANCE_QUESTIONS.HAS_TREATMENT,
        ],
        [INTAKE_FINANCE_SECTIONS.ABOUT_YOU]: [
          INTAKE_ABOUT_QUESTION,
          INTAKE_LOCATION_QUESTION,
        ],
        [INTAKE_FINANCE_SECTIONS.GENERAL_INFO]: [
          FINANCE_QUESTIONS.FINANCIAL_HELP,
          FINANCE_QUESTIONS.HAS_SSN,
          FINANCE_QUESTIONS.HOUSEHOLD_MEMBERS_NUM,
          FINANCE_QUESTIONS.INCOME,
          FINANCE_QUESTIONS.INSURANCE,
        ],
      };

      // for e.g. for general disease there is not CancerFieldsByIndication
      const stageField = CancerFieldsByIndication[user.personal.condition]?.patientStage;

      if (stageField) {
        const stageQuestion = questions.find((a) => [stageField].includes(a.id));

        if (stageQuestion) _questionsData[INTAKE_FINANCE_SECTIONS.ABOUT_YOUR_CANCER].unshift(stageQuestion);
      }

      console.log('data for finance intake', _questionsData, _financeUser);

      setQuestionsData(_questionsData);
      setFinanceUserClient(_financeUser);
    };

    fetch();

    return () => {
      app.intercom.hidden = false;
    };
    // eslint-disable-next-line
  }, []);

  const gotoResultsAction = () => {
    if (isLoading) {
      $loading = getOverlay();
      const $lottie = getLottie(LottieAnimationLoading);
      $loading.append($lottie);
      document.body.appendChild($loading);
      $loading.show();
    } else gotoResults();
  };

  const goToResultsRef = useRef(gotoResultsAction);
  goToResultsRef.current = gotoResultsAction;

  useEffect(() => {
    setHeaderRightSlot(
      <HeaderGoToResults
        goToResultsRef={goToResultsRef}
        resultsCount={(financeMatchFetchStatus === SUCCESS && financeMatchFetchResult?.length) || 0}
        isLoading={[PENDING, PRISTINE].includes(financeMatchFetchStatus)}
        resultsTitle={t('finance_intake.programs')}
        resultsIconSrc={SupportIcon}
      />,
    );
  }, [financeMatchFetchStatus, financeMatchFetchResult, goToResultsRef, setHeaderRightSlot, t]);

  useEffect(() => () => {
    setHeaderRightSlot(null);
  }, [setHeaderRightSlot]);

  const gotoResults = () => {
    app.history.push(ROUTES[isHcp ? PAGES.PUBLIC_FINANCE : PAGES.FINANCE]);
  };

  if (isHcp && !getUserCondition(currentUser)) {
    return <Redirect to={ROUTES[PAGES.SEARCH]} />;
  }

  const update = async (accordion, item, q, showUnanswered) => {
    // console.log('financeIntake::update', item);
    item.$item.classList.add('intake-collapsed');
    setTimeout(() => {
      // comment
      item.hide();
      showUnanswered(q.id);
      accordion.render();
      item.$item.classList.remove('intake-collapsed');
    }, 1000);
    console.log('finance intake save', financeUser, user);

    user.condition_profile.race = financeUser.condition_profile.race || null;

    Object.keys(financeUser.condition_profile).forEach((f) => {
      if (!FINANCE_FIELDS.includes(f)) user.condition_profile[f] = financeUser.condition_profile[f];
    });
    const currentUpdateNum = updateNum + 1;
    updateNum++;
    isLoading = true;

    let financeParams;

    if (mode === FINANCE_INTAKE_MODES.USER) {
      const financeProfile = await getFinanceProfile();
      const aidTypes = financeProfile?.aidTypes?.split(',');
      financeParams = getFinanceParams(user, { ...financeUser.condition_profile }, aidTypes);

      await setFinanceUser({ ...financeParams });

      await app.updateUser(user);
      app.user = user;
    } else if (mode === FINANCE_INTAKE_MODES.EMPTY_USER) {
      const aidTypes = [];
      financeParams = getFinanceParams(user, { ...financeUser.condition_profile }, aidTypes);
    } else if (mode === FINANCE_INTAKE_MODES.PATIENT) {
      // save finance profile and user from intake for later use in add_patient
      const aidTypes = [];
      financeParams = getFinanceParams(user, { ...financeUser.condition_profile }, aidTypes);

      if (getIsHCPPatient(currentUser)) {
        try {
          // actually currentUser is already mutated
          dispatch(updateCurrentUser(user, true));
          await dispatch(saveHCPPatient());
          const updateFinancePatient = await hcpApi.updateFinancePatient({ ...user }, { ...financeParams });
          if (!updateFinancePatient?.success) console.log('intake finance:: update error:: finance user not updated');
        } catch (e) {
          console.log('HCP:: cannot update patient');
        }
      }
    } else {
      // error
    }

    localStorage.setItem('finance_params', JSON.stringify(financeParams));
    localStorage.setItem('finance_user', JSON.stringify(user));

    if (currentUpdateNum !== updateNum) return; // execute only if last update
    isLoading = false;

    setFinanceParams(financeParams);
  };

  return financeUser ? (
    <div className="finance-intake">
      <div className="finance-intake-wrapper">
        <div className="finance-intake-title">{t('finance_intake.title')}</div>
        <div className="finance-intake-body">
          <Intake
            user={financeUser}
            questionsData={questionsData}
            customOnSelect={update}
            preventNotSure
          />
        </div>
      </div>
      <div className="back-to-results">
        <div
          className="back-to-results-button"
          onClick={gotoResults}
        >
          <div className="back-to-results-button-icon">
            <img src={SupportIcon} alt="icon" />
          </div>
          <div className="back-to-results-button-text">
            {t('finance_intake.back_button')}
          </div>
        </div>
      </div>
    </div>
  ) : error ? (
    <div className="finance-intake-wrapper">
      <div className="finance-intake-error">
        { error }
      </div>
    </div>
  ) : (
    <div className="intake-finance-wait">
      Good things come to those who wait...
      <span aria-label="img" aria-labelledby="img" role="img">😊</span>
    </div>
  );
};

export default FinanceIntake;

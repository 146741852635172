import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import InfoNote from 'new-ui/Components/InfoNote';
import Button from '../Button';
import { QUESTION_TYPES } from '../QuestionRenderer';
import SwitchBox from '../SwitchBox';
import HCPSelect from '../HCP/Select';
import { useTranslation } from 'utils/modifiedTranslation';
import { treatmentForIndicationQuestionIds } from 'new-ui/api/data/cancerTypes';
import { adjustTranslationKey } from './helpers';

const GA_EVENTS = {
  CATEGORY: 'intake_treatment_question',
  ADD_TREATMENT: 'treatment_intake_add_treatment',
  SHOW_TREATMENTS: 'treatment_intake_show_treatments',
  SWITCHBOX: 'treatment_intake_switch_box_clicked',
  DRUG_ADDED: 'treatment_intake_combination_added',
  COMBINATION_ADDED: 'treatment_intake_drug_added',
  STOP_REASON: 'treatment_intake_stop_reason_clicked',
};

const renderSearchAccordion = () => {
  if (window._accordions?.search?.render) window._accordions.search.render();
};

export const BoxTreatmentsQuestion = (props)=>{
    const {
      user,
      q,
      onselect,
    } = props;
    const { t } = useTranslation();

    app.sendGoogleAnalyticsEvent(GA_EVENTS.CATEGORY, GA_EVENTS.SHOW_TREATMENTS);

    const treatmentFor = treatmentForIndicationQuestionIds[user.personal.condition];

    const [showTreatments, setShowTreatments] = useState(user?.condition_profile[treatmentFor] !== 'no');
    const [treatments, setTreatments] = useState(user?.condition_profile?.treatments || []);

    useEffect(()=>{
      if (!treatments.length) addTreatment();
      // eslint-disable-next-line
    }, []);

    const renderTreatmentSubQuestion = ({ subQuestion, treatment, treatmentIndex, user }) => {
      return (
        <div key={`${treatmentIndex}_${subQuestion.id}`} className="box-question-treatment-section">
          <div className="box-question-treatment-title">
            {t(adjustTranslationKey(subQuestion.title))}
          </div>
          <div className="box-question-treatment-select">
            <HCPSelect
              user={user}
              showAutofill={false}
              options={subQuestion.options?.map((b)=>{
                return {
                  ...b,
                  label: t(b.label),
                };
              })}
              renderOnSelect={false}
              initValue={
                treatments[treatmentIndex][subQuestion.id]
              }
              onselect={(newValue)=>{
                // console.log(newValue);
                if(newValue) app.sendGoogleAnalyticsEvent(GA_EVENTS.CATEGORY, `treatment_intake_${subQuestion.id}_added`);

                treatment[subQuestion.id] = newValue;
                user.condition_profile.treatments = treatments;
                onselect(treatments);
                // window._accordion.render();
              }}
            />
          </div>
        </div>
      );
    };

    const HCP_TREATMENT_DISABLED_QUESTIONS = [
      'drugs_received',
    ];

    const renderButton = ()=>{
      return (
        <Button
          title={t('general.add')}
          action={addTreatment}
        />
      );
    };

    const TreatmentQuestion = (treatment, idx, user)=>{
      const drugsReceived = q.subQuestions.find((sq)=>sq.id === 'drugs_received');
      if (!drugsReceived) return;

      const getSubQuestions = ()=>{
        return q.subQuestions.filter((sq)=>{
          if (sq.type !== QUESTION_TYPES.SINGLE_OPTION_SELECT) return false;
          if (sq.isShown === false) {
            // DEV-3814 Questions for internal usage should not be shown
            return false;
          }
          if (HCP_TREATMENT_DISABLED_QUESTIONS.includes(sq.id)) return false;
          return true;
        });
      };

      return (
        <React.Fragment key={`treatment-${idx}`}>
          <div key={idx} className="box-question-treatment">
            <div className="box-question-treatment-background">
              <div className="box-question-treatment-section">
                <div className="box-question-treatment-title">
                  {t('general.treatment')}
                  {' '}
                  #
                  {idx + 1}
                  {' '}
                  <span>*</span>
                </div>
                <HCPSelect
                  user={user}
                  isMulti
                  useTags
                  renderOnSelect={false}
                  initValue={
                    treatments[idx].drugs_received
                  }
                  options={drugsReceived.options.map((o)=>{
                    return {
                      label: t(o.label),
                      value: o.value,
                    };
                  })}
                  onselect={(v)=>{
                    treatments[idx].drugs_received = v;
                    user.condition_profile.treatments = treatments;
                    app.sendGoogleAnalyticsEvent(GA_EVENTS.CATEGORY, GA_EVENTS.DRUG_ADDED);
                    if(v?.length > 1) app.sendGoogleAnalyticsEvent(GA_EVENTS.CATEGORY, GA_EVENTS.COMBINATION_ADDED);
                    setTreatments([...treatments]);
                    onselect(treatments);
                  }}
                />
                {(treatments[idx]?.drugs_received?.length > 1) && <InfoNote
                  info={t('intake.note_description')}
                />}
              </div>
              {
            treatments[idx].drugs_received?.length
              ? getSubQuestions().map((subQuestion) => (
                renderTreatmentSubQuestion({
                  subQuestion, treatment, treatmentIndex: idx, user,
                })
              ))
              : null
              }
              {
                treatments[idx].treatment_stop_reason ? null : (
                  <div
                    className="still-on-treatment"
                    onClick={()=>{
                      treatments[idx].treatment_stop_reason = 'treatment_ongoing';
                      app.sendGoogleAnalyticsEvent(GA_EVENTS.CATEGORY, GA_EVENTS.STOP_REASON);
                      setTreatments([...treatments]);
                      renderSearchAccordion();
                    }}
                  >
                    <div className="still-on-treatment-box" />
                    {' '}
                    Still on this treatment
                  </div>
                )
              }
            </div>
          </div>
        </React.Fragment>
      );
    };

    const newTreatment = ()=>{
      return {
        drugs_received: null,
        treatment_admin: null,
        treatment_end_date: null,
        treatment_stop_reason: null,
      };
    };

    const addTreatment = ()=>{
      app.sendGoogleAnalyticsEvent(GA_EVENTS.CATEGORY, GA_EVENTS.ADD_TREATMENT);
      const _newTreatment = newTreatment();
      treatments.push(_newTreatment);
      const newTreatments = [...treatments];
      setTreatments(newTreatments);
      // onselect(newTreatments);
    };

    const shouldShowAdd = ()=>{
      return user?.condition_profile[treatmentFor] !== 'no';
    };

    return (
      <>
        <div className="box-question">
          <div className="box-question-title">{t('hcp.treatments_title')}</div>
          <div className="box-question-received">
            <SwitchBox
              text1={t('general.didnt_receive')}
              text2={t('general.received')}
              initValue={user?.condition_profile[treatmentFor] !== 'no'}
              onChange={(value)=>{
                if (!user?.personal?.condition) return;
                user.condition_profile[treatmentFor] = value ? 'yes' : 'no';
                user.condition_profile.treatments = treatments;
                app.sendGoogleAnalyticsEvent(GA_EVENTS.CATEGORY, GA_EVENTS.SWITCHBOX);
                setShowTreatments(value);
                renderSearchAccordion();
                onselect(treatments);
              }}
            />
          </div>
          {
            showTreatments ? (
              <>
                {
                  treatments.length ? (
                    <div className="box-question-treatments">
                      {
                        treatments.map((treatment, idx)=>{
                          return (
                            TreatmentQuestion(treatment, idx, user)
                          );
                        })
                      }
                    </div>
                  ) : null
                }
              </>
            ) : null
          }
          {
            shouldShowAdd() ? (
              <div className="add-more">
                { renderButton() }
              </div>
            ) : null
          }
        </div>
      </>
    );
  };

import React from 'react';
import './style.css';
import { TREATMENT_GROUPS } from 'new-ui/constants';
import { interventional_types } from 'new-ui/TreatmentOptions/options';

const generateTypesMap = (options) => {
  return options.reduce((acc, { label, color }) => {
    acc[label.toLowerCase()] = color;
    return acc;
  }, {});
};

const itemsMap = generateTypesMap(interventional_types);

const getBackgroundColor = (option) => {
  return itemsMap[option.toLowerCase()] || 'white';
};

function sortArrayAlphabetically(arr) {
  return arr.sort();
}

const sortOptions = (options) => {
  const majorCategories = options.filter((item) => TREATMENT_GROUPS.includes(item));
  const otherCategories = options.filter((item) => !TREATMENT_GROUPS.includes(item));

  const sortedMajorCategories = sortArrayAlphabetically(majorCategories);
  const sortedOtherCategories = sortArrayAlphabetically(otherCategories);
  return [...sortedMajorCategories, ...sortedOtherCategories];
};

const InterventionalTypes = ({ options }) => {
  return (
    <ul className="interventional-list">
      {sortOptions(options).map((option) => (
        <li key={option} style={{ backgroundColor: getBackgroundColor(option) }}>
          {option}
        </li>
      ))}
    </ul>
  );
};

export default InterventionalTypes;

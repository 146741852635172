import React from 'react';
import PropTypes from 'prop-types';
import pick from 'lodash/pick';
import { uploadFile } from 'new-ui/Pages/Upload/api';
import { allCancerTypes } from 'utils/constants/cancerTypes';
import { UploadForm } from './UploadForm';

const { GENERAL: GENERAL_DISEASE } = allCancerTypes;

export const CurrentUserUploadPopup = ({
  onSuccess,
  onBeforeIntake,
  condition,
  generalDiseaseSubType,
  onUploadedFilesChange,
}) => {
  const handleUpload = ({
    files,
    getRecaptchaTokenAsync,
    onUpload,
    onError,
  }) => {
    uploadFile({
      files,
      getRecaptchaTokenAsync,
      onUpload,
      onError,
      condition: condition === GENERAL_DISEASE ? generalDiseaseSubType : condition,
      noUserProfileMerge: true,
    });
  };

  const handleUploadedFiles = (files, { jobId }) => onUploadedFilesChange(
    // to get rid of file blob
    files.map((file) => pick(file, ['name', 'storageName'])),
    { jobId, source: 'upload' },
  );

  return (
    <UploadForm
      condition={condition}
      onSuccess={onSuccess}
      onBeforeIntake={onBeforeIntake}
      uploadFiles={handleUpload}
      onUploadedFilesChange={handleUploadedFiles}
    />
  );
};

CurrentUserUploadPopup.propTypes = {
  onSuccess: PropTypes.func,
  condition: PropTypes.string,
  generalDiseaseSubType: PropTypes.string,
  onBeforeIntake: PropTypes.func,
  onUploadedFilesChange: PropTypes.func.isRequired,
};

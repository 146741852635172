import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';

export const createUserFinance = ({
  redirectTo,
  subtitle,
} = {}) => {
  const onBeforeSignup = () => {
    app.setShowPreloader(true);
  };
  const onSignup = () => {
    app.setShowPreloader(false);
    if (redirectTo) {
      window.location = redirectTo;
    }
    app.setPopup(false);
  };

  app.setPopup(POPUP_TYPES.REGISTER_USER, {
    theme: 'middle',
    onBeforeSignup,
    onSignup,
    subtitle,
  });
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'utils/modifiedTranslation';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { addPatient } from 'modules/hcp/actions/addPatient';
import RESPONSE_ERROR_CONSTANTS from 'utils/constants/responseError';
import { AddPatientForm } from './AddPatientForm';

const { USER_EXISTS: USER_EXISTS_ERROR_CODE } = RESPONSE_ERROR_CONSTANTS;

const ERROR_TYPES = {
  INDICATION_REQUIRED: 'INDICATION_REQUIRED',
  NAME_REQUIRED: 'NAME_REQUIRED',
};

export const AddHCPPatient = ({
  onSuccess,
  indication,
  isFirstPatient,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();

  const { t } = useTranslation();

  const ERRORS = {
    [ERROR_TYPES.NAME_REQUIRED]: t('general.name_required'),
    [ERROR_TYPES.INDICATION_REQUIRED]: t('general.indication_required'),
  };

  // eslint-disable-next-line consistent-return
  const handleFormSubmit = async (formData) => {
    const {
      indication: { value: indicationValue } = {},
      name,
      email,
    } = formData ?? {};

    if (!indicationValue) {
      return setError(ERRORS[ERROR_TYPES.INDICATION_REQUIRED]);
    }
    if (!name) {
      return setError(ERRORS[ERROR_TYPES.NAME_REQUIRED]);
    }

    const recaptchaToken = await getRecaptchaTokenAsync();

    dispatch(addPatient({
      email,
      name,
      condition: indicationValue,
      onError: (ex) => {
        const { response: { data: { code } = {} } = {} } = ex ?? {};
        let errorMessage = t('add_patient.error.something_went_wrong');
        if (code === USER_EXISTS_ERROR_CODE) {
          errorMessage = t('add_patient.error.email_duplication');
        }
        setError(errorMessage);
      },
      recaptchaToken,
      onSuccess,
    }));
  };

  return (
    <AddPatientForm
      indication={indication}
      onSubmit={handleFormSubmit}
      recaptcha={recaptcha}
      error={error}
      title={isFirstPatient ? t('add_patient.add_first') : t('general.add_patient')}
      indicationTitle={t('add_patient.indication_input_title')}
      buttonTitle={t('add_patient.add')}
      nameTitle={t('add_patient.name_input_title')}
      emailTitle={t('add_patient.email_input_title')}
    />
  );
};

AddHCPPatient.propTypes = {
  indication: PropTypes.shape({
    value: PropTypes.string,
  }),
  onSuccess: PropTypes.func.isRequired,
  isFirstPatient: PropTypes.bool.isRequired,
};

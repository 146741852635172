import React from 'react';
import PropTypes from 'prop-types';

export const CombinedProviders = ({ children, providers }) => (
  providers.reduceRight((acc, { provider: Provider, value }) => (
    <Provider value={value}>{acc}</Provider>
  ), children)
);

CombinedProviders.propTypes = {
  children: PropTypes.node.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape({
    provider: PropTypes.elementType.isRequired,
    value: PropTypes.any,
  })).isRequired,
};

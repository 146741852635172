import { push } from 'connected-react-router';
import { checkIsAuthenticated } from 'functions/checkIsAuthenticated';
import { replaceCurrentUser } from 'modules/currentUser/actions';
import app from 'new-ui/app';
import { PAGES, POPUP_TYPES, ROUTES } from 'new-ui/constants';
import {
  getUserProfileId,
} from 'modules/user/utils';
import { getGeneralDiseaseSubType } from 'modules/user/conditionProfileHelpers';
import { allCancerTypes } from 'utils/constants/cancerTypes';
import { saveUserDocuments } from '../helpers';

const { GENERAL: GENERAL_DISEASE } = allCancerTypes;

export const patientSignupPopup = ({ onSuccessAction } = {}) => (dispatch, getState) => {
  const {
    profile,
    currentUser: { data: currentUser },
    authentication,
  } = getState();

  if (checkIsAuthenticated({ authentication, profile })) {
    // invariant
    console.error('patientSignup profile');
    return;
  }

  if (!currentUser) {
    // invariant
    console.error('patientSignup !currentUser');
    return;
  }

  const {
    personal: { condition, condition_name: conditionName } = {},
    condition_profile: conditionProfile,
  } = currentUser;

  const indicationValue = condition || conditionName || '';

  const indication = indicationValue ? {
    value: indicationValue,
    title: conditionName ?? '',
    type: condition === GENERAL_DISEASE ? getGeneralDiseaseSubType(conditionProfile) : null,
  } : undefined

  app.setPopup(POPUP_TYPES.SIGNUP_HCP_PATIENT, {
    theme: 'middle',
    indication,
    profile: currentUser,
    onSignup: async (nextUser) => {
      app.user = nextUser;

      const { currentUser: { pendingDocuments } } = getState();

      dispatch(replaceCurrentUser(nextUser));

      const profileId = getUserProfileId(nextUser);

      if (pendingDocuments?.length && profileId) {
        app.setShowPreloader(true);
        await saveUserDocuments(profileId, pendingDocuments);
        app.setShowPreloader(false);
      }

      app.setPopup(false);

      dispatch(
        onSuccessAction ? onSuccessAction() : push(ROUTES[PAGES.INTAKE]),
      );
    },
  });
};

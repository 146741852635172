import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import app from 'new-ui/app';
import { useParams } from 'react-router-dom';
import { getTrialByNCT } from 'new-ui/api';
import PointsToDiscussWithOncologist from 'new-ui/Widgets/PointsToDiscuss';
import PatientsLikeYou from 'new-ui/Widgets/PatientsLikeYou';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import InfoNote from 'new-ui/Components/InfoNote';
import { useTranslation } from 'react-i18next';
import BackButton from 'new-ui/Components/BackButton';
import { useSetHeaderSettings } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { VERSIONS } from 'new-ui/Components/PageHeader/constants';
import { PAGES, ROUTES } from 'new-ui/constants';
import { TRIAL_TYPES } from 'utils/constants/trial';
import {
  useMatchedItemEligibilityCheck,
} from 'components/MatchItemEligibilityCheck/useMatchedItemEligibilityCheck';
import { MatchItemEligibilityStatus } from 'components/MatchItemEligibilityStatus/MatchItemEligibilityStatus';
import TreatmentItem from './Item';

export const GA_HCP = {
  category: 'HCP',
  events: {
    SOC_VIEWED: 'hcp soc viewed',
  },
};

const isEmpty = (obj) => Object.keys(obj).length === 0;

const PublicTreatment = () => {
  const { t } = useTranslation();
  const { nctNumber: rawNctNumber } = useParams();
  const history = useHistory();

  const nctNumber = decodeURIComponent(rawNctNumber);

  const [treatment, setTreatment] = useState([...(app.treatments || [])].find((i) => i.nct_number === nctNumber));
  const {
    currentUser,
  } = useSelector((state) => ({
    currentUser: state.currentUser.data,
  }));

  useSetHeaderSettings({
    version: VERSIONS.HCP,
  });

  const isSearchPage = history.location.pathname.includes('treatment');
  app.treatment = treatment; // debugging purposes

  useEffect(() => {
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.SOC_VIEWED);
    document.body.classList.add('public-treatment');
  }, []);

  const eligibilityStatus = useMatchedItemEligibilityCheck({ trialType: TRIAL_TYPES.TREATMENT, nctNumber });

  useEffect(() => {
    const fetch = async () => {
      const usersCountry = app.user?.info?.country?.location;
      const r = await getTrialByNCT({ nctNumber, usersCountry });

      if (isEmpty(r) || (r.statusCode && r.statusCode !== 200)) {
        history.push(ROUTES[PAGES.SEARCH]);
      }

      setTreatment(r);
    };
    if (!treatment || !treatment?.points_to_discuss_with_your_oncologist) fetch();
  }, [history, nctNumber, treatment]);

  const getInfoBoxTranslation = (treatment) => {
    const categoryKey = treatment?.treatment_category_key?.[0] || null;
    const trans = `match.list.treatment.category.description.${categoryKey}.${treatment.condition}`;
    const translated = t(trans);
    if (trans === translated) return '';
    return translated;
  };

  return (
    treatment ? (
      <div className={isSearchPage ? 'treatment isSearchPage' : 'treatment '}>
        {
          eligibilityStatus ? <MatchItemEligibilityStatus status={eligibilityStatus} /> : null
        }

        <div className="wrapper">
          <br />
          <BackButton />
          <TreatmentItem
            isFull
            item={treatment}
            type="list"
            friendlyTitle={treatment.title ?? null}
            user={currentUser ?? app.user}
          />

          {getInfoBoxTranslation(treatment) && (
            <InfoNote
              info={getInfoBoxTranslation(treatment)}
              theme="theme_2"
            />
          )}

          <div className="height-20" />
          <div className="height-20" />
          <PatientsLikeYou treatment={treatment} />
          {
            treatment.points_to_discuss_with_your_oncologist ? <PointsToDiscussWithOncologist isPublic treatment={treatment} /> : null
          }

          {/* {renderActions('mobile')} */}
          <div className="height-20" />
          <div className="height-20" />
        </div>
        {/* {renderActions('desktop')} */}
      </div>
    ) : null
  );
};

export default PublicTreatment;

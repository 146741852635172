/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import app from 'new-ui/app';
import editTreatmentIcon from 'new-ui/assets/icons/edit_treatment.svg';
import previousTreatmentsIcon from 'new-ui/assets/icons/previous_treatments.svg';
import { getDrugsTranslatedLabel } from 'new-ui/functions/getDrugsTranslatedLabel';
import { indicationChooser } from 'components/Questionnaire/functions';
import { metastaticId } from 'components/Questionnaire/components/TreatmentQuestion/constants';
import './ProfileTreatment.css';
import { profileTreatmentSectionEvents, GA_CATEGORY_NAME } from 'new-ui/Components/GoogleAnalytics/profileSectionEvents';
import { getUserConditionProfile, getUserCondition } from 'modules/user/utils';
import { filterQuestionsByCondition } from 'new-ui/functions';
import { treatmentForIndicationQuestionIds } from 'components/Questionnaire/constants/treatmentForIndicationQuestionIds';
import { LastTreatment } from './LastTreatment';
import { EditTreatment } from './EditTreatment';

const {
  addTreatmentButtonClicked,
  profileTabWithoutTakingStateOpened,
  profileTabWithoutOutcomeOpened,
  treatmentQuestionPresented,
  treatmentEditClicked,
} = profileTreatmentSectionEvents;

export const ProfileTreatments = ({
  gotoQuestionnaire,
  setShowTreatmentsAlert,
  showTreatmentsAlert,
  setDrawerContent,
  showSurveyBtn,
  profile,
  treatments,
  treatment_for_advanced_metastatic_disease,
  lastTreatment,
  previousTreatments,
  lastTreatmentDrug,
}) => {
  const { t } = useTranslation();

  const [
    {
      treatmentStopReasonOptions = [], treatmentAdminOptions = [],
    },
    setOptions,
  ] = useState({});
  const [shouldMetastaticQuestionBeShown, setShouldMetastaticQuestionBeShown] = useState(false);
  const { condition } = app?.user?.personal || {};

  // GA effects
  useEffect(() => {
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      treatmentQuestionPresented.eventName,
      treatmentQuestionPresented.getMetaData(),
    );
  }, []);

  // GA effects
  useEffect(() => {
    if (!lastTreatment?.taking_state) {
      app.sendGoogleAnalyticsEvent(
        GA_CATEGORY_NAME,
        profileTabWithoutTakingStateOpened.eventName,
        profileTabWithoutTakingStateOpened.getMetaData(),
      );
    }
    if (!lastTreatment?.treatment_stop_reason) {
      app.sendGoogleAnalyticsEvent(
        GA_CATEGORY_NAME,
        profileTabWithoutOutcomeOpened.eventName,
        profileTabWithoutOutcomeOpened.getMetaData(),
      );
    }
  }, [lastTreatment]);

  useEffect(() => {
    (async () => {
      const allQuestions = await indicationChooser(condition);
      const treatmentQuestion = allQuestions?.find((q) => q.id === 'treatments');
      const metastaticOptions = allQuestions?.find((q) => q.id === metastaticId)?.options || [];

      if (treatmentQuestion) {
        // for commented new treatment design
        // return treatmentQuestion.subQuestionsOptions?.treatment_stop_reason || [];
        const treatmentStopReasonOptions = treatmentQuestion.subQuestions.find(
          (q) => q.id === 'treatment_stop_reason',
        )?.options?.filter((opt) => opt !== 'treatment_ongoing') || [];
        const treatmentAdminOptions = treatmentQuestion.subQuestions.find((q) => q.id === 'treatment_admin')?.options || [];
        const stageIds = treatmentQuestion.stageIds || [];
        let usersStage = null;
        stageIds.forEach((stage) => {
          if (profile[stage]) {
            usersStage = profile[stage];
          }
        });
        const filteredMetastaticOptions = await filterQuestionsByCondition({
          questionsIds: [metastaticId],
          condition: getUserCondition(app?.user),
          conditionProfile: getUserConditionProfile(app?.user),
        });
        setShouldMetastaticQuestionBeShown(filteredMetastaticOptions.length > 0
        && metastaticOptions.length > 0
        && (usersStage == null || usersStage >= 3));
        setOptions({
          treatmentStopReasonOptions,
          treatmentAdminOptions,
        });
      }
    })();
  }, [condition, profile]);

  useEffect(() => {
    if (treatments.length === 0) {
      setShowTreatmentsAlert(true);
    } else {
      treatments.forEach((treat, i) => {
        treat.id = i + 1;
      });
    }
  }, [treatments, setShowTreatmentsAlert]);

  const addTreatmentHandle = async () => {
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      addTreatmentButtonClicked.eventName,
      addTreatmentButtonClicked.getMetaData(),
    );
    const treatmentForIndicationQuestionId = treatmentForIndicationQuestionIds[app.user.personal.condition];
    const treatmentForIndicationQuestion = app.user.condition_profile[treatmentForIndicationQuestionId];
    if (treatmentForIndicationQuestion === 'no') {
      await app.answerQuestion({ [treatmentForIndicationQuestionId]: 'yes' });
    }
    gotoQuestionnaire('treatments');
  };

  const editTreatment = (treatment = {}) => {
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      treatmentEditClicked.eventName,
      treatmentEditClicked.getMetaData(),
    );
    const drawerContent = (
      <EditTreatment
        treatmentStopReasonOptions={treatmentStopReasonOptions}
        treatmentAdminOptions={treatmentAdminOptions}
        setDrawerContent={setDrawerContent}
        treatments={treatments}
        treatment={treatment}
        metastaticValue={treatment_for_advanced_metastatic_disease}
        shouldMetastaticQuestionBeShown={shouldMetastaticQuestionBeShown}
      />
    );
    setDrawerContent(drawerContent);
  };

  return (
    <section className="profileTreatments">
      {/* currentTreatmentSection */}
      <div className="currentTreatmentSection">
        <div className="section-title-wrapper">
          {showTreatmentsAlert && <span>1</span>}
          <h3 className="currentTreatmentTitle">{t('questionnaire.profile_treatments.current_treatment_title')}</h3>
        </div>
        {showTreatmentsAlert && treatments.length > 0 && (
          <button type="button" onClick={addTreatmentHandle}>
            {t('questionnaire.profile_treatments.add_treatment_btn')}
          </button>
        )}
      </div>

      {/* addNewTreatmentSection */}
      {(!showTreatmentsAlert || treatments.length === 0) && (
        <div className="addNewTreatmentSection">
          <p className="addNewTreatmentTitle">
            {treatments.length === 0
              ? t('questionnaire.profile_treatments.add_treatment_title_without_treatments')
              : t('questionnaire.profile_treatments.add_treatment_title_with_treatments')}
          </p>
          <button type="button" onClick={addTreatmentHandle} className="mainOrangeButton">
            <p className="addNewTreatmentButtonTitle">
              {treatments.length === 0
                ? t('questionnaire.profile_treatments.add_treatment_btn_title_without_treatments')
                : t('questionnaire.profile_treatments.add_treatment_btn_title_with_treatments')}
            </p>
            <span>+</span>
          </button>
        </div>
      )}

      {/* lastTreatmentSection */}
      {lastTreatment && (
        <LastTreatment
          previousTreatments={previousTreatments}
          lastTreatment={lastTreatment}
          lastTreatmentDrug={lastTreatmentDrug}
          getDrugsTranslatedLabel={getDrugsTranslatedLabel}
          setShowTreatmentsAlert={setShowTreatmentsAlert}
          showTreatmentsAlert={showTreatmentsAlert}
          editTreatment={editTreatment}
          treatmentStopReasonOptions={treatmentStopReasonOptions}
          treatmentAdminOptions={treatmentAdminOptions}
          setDrawerContent={setDrawerContent}
          conditionName={profile?.personal?.condition_name}
          showSurveyBtn={showSurveyBtn}
        />
      )}

      {/* previousTreatmentSection */}
      {previousTreatments.length > 0 && (
        <div className="previousTreatmentSection">
          <div className="previousTreatmentHeader">
            <img alt="last treatment icon" src={previousTreatmentsIcon} />
            <span>{t('questionnaire.profile_treatments.previous_treatment_header_title')}</span>
          </div>
          <div className="previousTreatmentBody">
            {previousTreatments.map((treatment, i) => (
              <div key={i} className="previousTreatmentWrapper">
                <p>{getDrugsTranslatedLabel(t, treatment.drugs_received)}</p>
                <img onClick={() => editTreatment(treatment)} src={editTreatmentIcon} alt="edit treatment icon" />
              </div>
            ))}
          </div>
        </div>
      )}
    </section>
  );
};

import { cancerTypes } from 'utils/constants/cancerTypes';
import GLOBAL_ENUMS from '../Questionnaire/constants/globalEnums';
import { Profile as Melanoma_2_ID } from '../Questionnaire/cancers/melanoma_2/MelanomaProfile_2.enum';
import { MelanomaProfile as Melanoma_ID } from '../Questionnaire/cancers/melanoma/MelanomaProfile.enum';
import { Profile as Crc_ID } from '../Questionnaire/cancers/crc/CrcProfile.enum';
import { Profile as BreastCancer2_ID } from '../Questionnaire/cancers/breast_2/Breast2Profile.enum';
import { Profile as Mds_ID } from '../Questionnaire/cancers/mds/MdsProfile.enum';
import { Profile as Bladder_ID } from '../Questionnaire/cancers/bladder/BladderProfile.enum';
import { Profile as Mpn_ID } from '../Questionnaire/cancers/mpn/MpnProfile.enum';
import { Profile as Uterine_ID } from '../Questionnaire/cancers/uterine/UterineProfile.enum';
import { Profile as Brain_ID } from '../Questionnaire/cancers/brain/BrainProfile.enum';
import { Profile as Mm_ID } from '../Questionnaire/cancers/mm/MmProfile.enum';
import { Profile as Lung_ID } from '../Questionnaire/cancers/lung/LungProfile.enum';
import { Profile as Nhl_ID } from '../Questionnaire/cancers/nhl/NhlProfile.enum';
import { Profile as Crc2_ID } from '../Questionnaire/cancers/crc_2/Crc2Profile.enum';
import { Profile as Prostate_ID } from '../Questionnaire/cancers/prostate/ProstateProfile.enum';
import { Profile as Aml_ID } from '../Questionnaire/cancers/aml/AmlProfile.enum';
import { Profile as Hnscc_ID } from '../Questionnaire/cancers/hnscc/HnsccProfile.enum';
import { Profile as Bladder2_ID } from '../Questionnaire/cancers/bladder_2/Bladder2Profile.enum';
import { Profile as Cll_ID } from '../Questionnaire/cancers/cll/CllProfile.enum';
import { Profile as GiTract_ID } from '../Questionnaire/cancers/gi_tract/GiTractProfile.enum';
import { Profile as Ovarian_ID } from '../Questionnaire/cancers/ovarian/OvarianProfile.enum';
import { Profile as Hcc_ID } from '../Questionnaire/cancers/hcc/HccProfile.enum';

const mappingKeys = {
  cancerType: 'cancer_type',
  diseaseStatus: 'disease_status',
  patientStage: 'patient_stage',
  biomarkers: 'biomarkers',
  riskGroup: 'risk_group',
  cancerSubtype: 'cancer_subtype',
  treatment: 'treatment',
  radiation: 'radiation',
  surgery: 'surgery',
  drugs: 'drugs',
  stopReasons: 'stop_reasons',
};

export const staticLabels = {
  [mappingKeys.radiation]: 'treat_finder.static_labels.radiation',
  [mappingKeys.surgery]: 'treat_finder.static_labels.surgery',
};

export const treatmentFinderAction = {
  GET_TREATMENT_FINDER_REQUEST: 'GET_TREATMENT_FINDER_REQUEST',
  GET_TREATMENT_FINDER_SUCCESS: 'GET_TREATMENT_FINDER_SUCCESS',
  GET_TREATMENT_FINDER_FAILURE: 'GET_TREATMENT_FINDER_FAILURE',
};

export const mappingByCancerType = {
  [cancerTypes.MELANOMA_2]: {
    [mappingKeys.cancerType]: [Melanoma_ID.initial_location, Melanoma_2_ID.melanoma_subtype],
    [mappingKeys.diseaseStatus]: [GLOBAL_ENUMS.looking_for, Melanoma_2_ID.recurrence],
    [mappingKeys.patientStage]: [Melanoma_ID.diagnosis_stage, Melanoma_2_ID.current_stage],
    [mappingKeys.biomarkers]: [GLOBAL_ENUMS.biomarkers, GLOBAL_ENUMS.biomarkers],
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [
        Melanoma_ID.immunotheraphy_and_traget_drugs,
        Melanoma_ID.chemotherapy_treatment,
        Melanoma_2_ID.drugs_received,
      ],
      [mappingKeys.stopReasons]: [
        Melanoma_ID.immunotherapy_stop_reason,
        Melanoma_ID.chemotherapy_stop_reason,
        Melanoma_2_ID.treatment_stop_reason,
      ],
    },
    [mappingKeys.radiation]: [Melanoma_ID.radiation_treatment, Melanoma_2_ID.radiation_treatment],
  },
  [cancerTypes.CRC]: {
    [mappingKeys.cancerType]: Crc_ID.type_of_crc,
    [mappingKeys.diseaseStatus]: GLOBAL_ENUMS.looking_for,
    [mappingKeys.patientStage]: Crc_ID.diagnosis_stage,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [
        Crc_ID.immunotheraphy_types,
        Crc_ID.target_drug_categories,
        Crc_ID.chemotherapy_treatment_administration_advanced_disease_types,
      ],
      [mappingKeys.stopReasons]: [
        Crc_ID.immunotherapy_treatment_stop_reason,
        Crc_ID.target_drug_treatment_stop_reason,
        Crc_ID.chemotherapy_treatment_administration_advanced_disease_stop_reas,
      ],
    },
    [mappingKeys.surgery]: Crc_ID.undergo_surgery,
    [mappingKeys.radiation]: Crc_ID.received_radiation_treatment,
  },
  [cancerTypes.BREAST_2]: {
    [mappingKeys.cancerType]: BreastCancer2_ID.type_of_bc,
    [mappingKeys.cancerSubtype]: BreastCancer2_ID.molecular_subtype,
    [mappingKeys.diseaseStatus]: BreastCancer2_ID.disease_status,
    [mappingKeys.patientStage]: BreastCancer2_ID.patient_stage,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [BreastCancer2_ID.drugs_received],
      [mappingKeys.stopReasons]: [BreastCancer2_ID.treatment_stop_reason],
    },
    [mappingKeys.surgery]: BreastCancer2_ID.procedures,
    [mappingKeys.radiation]: BreastCancer2_ID.procedures,
  },
  [cancerTypes.MDS]: {
    [mappingKeys.cancerType]: Mds_ID.type_of_mds,
    [mappingKeys.diseaseStatus]: GLOBAL_ENUMS.looking_for,
    [mappingKeys.riskGroup]: Mds_ID.risk_group,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Mds_ID.treatment_for_low_risk_mds],
      [mappingKeys.stopReasons]: [Mds_ID.treatment_for_low_risk_mds_treatment_outcome],
    },
  },
  [cancerTypes.BLADDER]: {
    [mappingKeys.cancerType]: Bladder_ID.type_of_bladder_cancer,
    [mappingKeys.diseaseStatus]: Bladder_ID.disease_status,
    [mappingKeys.patientStage]: Bladder_ID.patient_stage,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Bladder_ID.treatment_type],
      [mappingKeys.stopReasons]: [
        Bladder_ID.chemotherapy_treatment_stop_reason,
        Bladder_ID.intravesical_bcg_treatment_stop_reason,
        Bladder_ID.checkpoint_inhibitor_treatment_stop_reason,
        Bladder_ID.erdafitinib_treatment_stop_reason,
      ],
    },
    [mappingKeys.radiation]: Bladder_ID.undergo_radiation,
    [mappingKeys.surgery]: Bladder_ID.undergo_surgery,
  },
  [cancerTypes.MPN]: {
    [mappingKeys.cancerType]: Mpn_ID.type_of_mpn,
    [mappingKeys.diseaseStatus]: Mpn_ID.disease_status,
    [mappingKeys.risk_group]: Mpn_ID.risk_group,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.riskGroup]: Mpn_ID.risk_group,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Mpn_ID.drugs_received],
      [mappingKeys.stopReasons]: [Mpn_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Mpn_ID.splenic_radiation,
  },
  [cancerTypes.UTERINE]: {
    [mappingKeys.cancerType]: Uterine_ID.type_of_uterine_cancer,
    [mappingKeys.diseaseStatus]: Uterine_ID.disease_status,
    [mappingKeys.patientStage]: Uterine_ID.current_stage,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Uterine_ID.drugs_received],
      [mappingKeys.stopReasons]: [Uterine_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Uterine_ID.procedures,
    [mappingKeys.surgery]: Uterine_ID.procedures,
  },
  [cancerTypes.BRAIN]: {
    [mappingKeys.cancerType]: Brain_ID.brain_cancer_type,
    [mappingKeys.diseaseStatus]: Brain_ID.disease_status,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Brain_ID.drugs_received],
      [mappingKeys.stopReasons]: [Brain_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Brain_ID.brain_radiation,
    [mappingKeys.surgery]: Brain_ID.tumor_surgery,
  },
  [cancerTypes.MM]: {
    [mappingKeys.cancerType]: Mm_ID.type_of_mm,
    [mappingKeys.diseaseStatus]: Mm_ID.disease_status,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Mm_ID.drugs_received],
      [mappingKeys.stopReasons]: [Mm_ID.treatment_stop_reason],
    },
  },
  [cancerTypes.LUNG]: {
    [mappingKeys.cancerType]: Lung_ID.type_of_lung_cancer,
    [mappingKeys.cancerSubtype]: Lung_ID.type_of_nsclc,
    [mappingKeys.diseaseStatus]: Lung_ID.disease_status,
    [mappingKeys.patientStage]: Lung_ID.current_stage,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Lung_ID.drugs_received],
      [mappingKeys.stopReasons]: [Lung_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Lung_ID.procedures,
    [mappingKeys.surgery]: Lung_ID.procedures,
  },
  [cancerTypes.NHL]: {
    [mappingKeys.cancerType]: Nhl_ID.type_of_lymphoma,
    [mappingKeys.cancerSubtype]: [
      Nhl_ID.type_of_bcell_lymphoma,
      Nhl_ID.type_of_marginal_cell_lymphoma,
      Nhl_ID.type_of_dlbcl,
    ],
    [mappingKeys.diseaseStatus]: Nhl_ID.disease_status,
    [mappingKeys.patientStage]: Nhl_ID.current_stage,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Nhl_ID.drugs_received],
      [mappingKeys.stopReasons]: [Nhl_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Nhl_ID.procedures,
    [mappingKeys.surgery]: Nhl_ID.procedures,
  },
  [cancerTypes.CRC_2]: {
    [mappingKeys.cancerType]: Crc2_ID.type_of_crc,
    [mappingKeys.cancerSubtype]: [Crc2_ID.type_of_familial_cancer],
    [mappingKeys.diseaseStatus]: Crc2_ID.disease_status,
    [mappingKeys.patientStage]: Crc2_ID.current_stage,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Crc2_ID.drugs_received],
      [mappingKeys.stopReasons]: [Crc2_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Crc2_ID.procedures,
    [mappingKeys.surgery]: Crc2_ID.procedures,
  },
  [cancerTypes.PROSTATE]: {
    [mappingKeys.cancerType]: Prostate_ID.type_of_pc,
    [mappingKeys.diseaseStatus]: Prostate_ID.disease_status,
    [mappingKeys.patientStage]: Prostate_ID.current_stage,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Prostate_ID.drugs_received],
      [mappingKeys.stopReasons]: [Prostate_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Prostate_ID.procedures,
    [mappingKeys.surgery]: Prostate_ID.procedures,
  },
  [cancerTypes.AML]: {
    [mappingKeys.cancerType]: Aml_ID.type_of_aml,
    [mappingKeys.diseaseStatus]: Aml_ID.disease_status,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Aml_ID.drugs_received],
      [mappingKeys.stopReasons]: [Aml_ID.treatment_stop_reason],
    },
  },
  [cancerTypes.HNSCC]: {
    [mappingKeys.cancerType]: Hnscc_ID.type_of_aml,
    [mappingKeys.diseaseStatus]: Hnscc_ID.disease_status,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Hnscc_ID.drugs_received],
      [mappingKeys.stopReasons]: [Hnscc_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Hnscc_ID.procedures,
    [mappingKeys.surgery]: Hnscc_ID.procedures,
  },
  [cancerTypes.GI_TRACT]: {
    [mappingKeys.cancerType]: GiTract_ID.type_of_gi,
    [mappingKeys.diseaseStatus]: GiTract_ID.disease_status,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [GiTract_ID.drugs_received],
      [mappingKeys.stopReasons]: [GiTract_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: GiTract_ID.procedures,
    [mappingKeys.surgery]: GiTract_ID.procedures,
  },
  [cancerTypes.OVARIAN]: {
    [mappingKeys.cancerType]: Ovarian_ID.type_of_ovarian_cancer,
    [mappingKeys.diseaseStatus]: Ovarian_ID.disease_status,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Ovarian_ID.drugs_received],
      [mappingKeys.stopReasons]: [Ovarian_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Ovarian_ID.procedures,
    [mappingKeys.surgery]: Ovarian_ID.procedures,
  },
  [cancerTypes.HCC]: {
    [mappingKeys.cancerType]: Hcc_ID.type_of_liver,
    [mappingKeys.diseaseStatus]: Hcc_ID.disease_status,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Hcc_ID.drugs_received],
      [mappingKeys.stopReasons]: [Hcc_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Hcc_ID.procedures,
    [mappingKeys.surgery]: Hcc_ID.procedures,
  },
  [cancerTypes.BLADDER_2]: {
    [mappingKeys.cancerType]: Bladder2_ID.type_of_bladder_cancer,
    [mappingKeys.diseaseStatus]: Bladder2_ID.disease_status,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Bladder2_ID.drugs_received],
      [mappingKeys.stopReasons]: [Bladder2_ID.treatment_stop_reason],
    },
    [mappingKeys.radiation]: Bladder2_ID.procedures,
    [mappingKeys.surgery]: Bladder2_ID.procedures,
  },
  [cancerTypes.CLL]: {
    [mappingKeys.cancerType]: Cll_ID.type_of_disease,
    [mappingKeys.diseaseStatus]: Cll_ID.disease_status,
    [mappingKeys.biomarkers]: GLOBAL_ENUMS.biomarkers,
    [mappingKeys.treatment]: {
      [mappingKeys.drugs]: [Cll_ID.drugs_received],
      [mappingKeys.stopReasons]: [Cll_ID.treatment_stop_reason],
    },
  },
};

export default {
  colorPalette: [
    '#FF8A65',
    '#FFD54F',
    '#FFEE58',
    '#DCE775',
    '#DCE775',
    '#AED581',
    '#81C784',
    '#4DD0E1',
    '#64B5F6',
    '#7986CB',
    '#9575CD',
    '#F06292',
  ],
};

import { replaceCurrentUser } from 'modules/currentUser/actions';
import { checkIsAuthenticated } from 'functions/checkIsAuthenticated';
import { getIsHCPUser, createMockUser } from '../helpers';

// currentUser is not a source of knowledge of userType for sign up
export const ensureCurrentUser = () => async (dispatch, getState) => {
  const {
    profile,
    currentUser: { data: currentUser },
    authentication,
  } = getState();

  if (currentUser) {
    return;
  }

  if (!checkIsAuthenticated({ authentication, profile })) {
    // registration flow of any kind user
    const user = await createMockUser();
    dispatch(replaceCurrentUser(user));
    return;
  }

  if (getIsHCPUser(profile)) {
    if (profile?.patients?.length) {
      dispatch(replaceCurrentUser({ ...profile.patients[0] }));
    } else {
      const user = await createMockUser();
      dispatch(replaceCurrentUser(user));
    }
    return;
  }
  // should be invariant
  // authenticated patient should be redirected
  dispatch(replaceCurrentUser({}));
};

import { batch } from 'react-redux';
import { push } from 'connected-react-router';
import { replaceCurrentUser } from 'modules/currentUser/actions';
import { currentUserLossWarningPopup } from './currentUserLossWarningPopup';
import { saveCurrentUserAndContinue } from './saveCurrentUserAndContinue';

const getContinueWithoutSavingAction = ({ redirectPath, user }) => () => (dispatch) => {
  batch(() => {
    if (redirectPath) {
      dispatch(push(redirectPath));
    }
    dispatch(replaceCurrentUser(user));
  });
};

export const setPatient = ({ popupTitle, redirectPath, user }) => (dispatch) => {
  const continueAction = getContinueWithoutSavingAction({ redirectPath, user });
  dispatch(currentUserLossWarningPopup({
    popupTitle,
    continueWithoutSavingAction: continueAction,
    saveAndContinueAction: () => (actualDispatch) => {
      actualDispatch(saveCurrentUserAndContinue({
        continueAction,
      }));
    },
  }));
};

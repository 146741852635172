import { useSelector, shallowEqual } from 'react-redux';
import useAuth from 'utils/hooks/useAuth';
import { useHCPUserType } from './useHCPUserType';
import { getIsHCPUser, getIsHCPUserType, getIsPatientUser } from './helpers';

export const useHCPUser = () => {
  const isAuthenticated = useAuth();
  const {
    profile,
  } = useSelector((state) => ({
    profile: state.profile,
  }), shallowEqual);

  const userType = useHCPUserType();

  const isHCPUserType = !userType || getIsHCPUserType(userType);

  return {
    userType,
    isHCPUserWithoutId: !isAuthenticated && !!isHCPUserType,
    isPatientWithoutId: !isAuthenticated && !isHCPUserType,
    isAuthenticated,
    isHCPUser: isAuthenticated && getIsHCPUser(profile),
    isPatient: isAuthenticated && getIsPatientUser(profile),
  };
};

import { checkIsAuthenticated } from 'functions/checkIsAuthenticated';
import { getIsHCPPatient } from '../helpers';
import { addPatientPopup } from './addPatient';
import { startSignup } from './signup';
import { saveHCPPatient } from './saveHCPPatient';

export const saveCurrentUserAndContinue = ({ continueAction }) => async (dispatch, getState) => {
  const {
    currentUser: { data: currentUser },
    profile,
    authentication,
  } = getState();

  if (checkIsAuthenticated({ authentication, profile })) {
    if (getIsHCPPatient(currentUser)) {
      try {
        await dispatch(saveHCPPatient());
      } catch (ex) {
        console.error('saveHCPPatient ex', ex);
        return;
      }

      dispatch(continueAction());
    } else {
      dispatch(addPatientPopup({ onSuccessAction: continueAction }));
    }
  } else {
    dispatch(startSignup());
  }
};

import React from 'react';
import './style.css';
import { default as Btn } from 'new-ui/Components/Button';
import ArrowCircleIcon from 'new-ui/assets/icons/arrow-circle-right_v2.svg';
import { useTranslation } from 'utils/modifiedTranslation';

export const LearnMoreButton = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <div className="learn-more-btn">
      <Btn
        className="learn-more"
        title={t('list_item.button.learn_more')}
        action={onClick}
      />
      <img className="arrow-circle" src={ArrowCircleIcon} alt="icon" />
    </div>
  );
};

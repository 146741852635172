import React from 'react';
import { useHistory } from 'react-router-dom';
import cx from 'classnames';
import Button from 'new-ui/Components/Button';
import iconBackSrc from 'new-ui/assets/Icon-back.svg';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import styles from './treatmentHeaderContent.module.css';

export const TreatmentHeaderContent = () => {
  const history = useHistory();
  const matches = window.innerWidth <= 800;

  return (
    <>
      <div>
        <Button
          className={styles.backButton}
          color="#213332CC"
          backgroundColor="#F2F2F7"
          icon={iconBackSrc}
          title={matches ? 'results' : 'Back to results'}
          action={history.goBack}
        />
      </div>
      <div className="treatment-header">
        <Button
          action={() => app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR)}
          className={styles.button}
          color="#213332"
          backgroundColor="#FFFFFF"
          title={matches ? 'Share' : 'Share with your doctor'}
        />
        <Button
          action={() => app.setPopup(POPUP_TYPES.MEDICAL_PROFILE)}
          className={cx(styles.button, styles.updateProfileButton)}
          title={matches ? 'Update profile' : 'Update your medical info'}
        />
      </div>
    </>
  );
};

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ReactComponent as ClockIcon } from 'new-ui/Pages/Articles/assets/icons/light_clock.svg';
import { ReactComponent as MegaphoneIcon } from 'new-ui/Pages/Articles/assets/icons/cheering_megaphone.svg';
import { useTranslation } from 'utils/modifiedTranslation';
import styles from './card.module.css';
import { LazyImage } from '../LazyImage/LazyImage';

export const Card = forwardRef(({ item, selectedTags, onClick }, ref) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root} ref={ref} onClick={onClick}>
      <div className={styles.imageContainer}>
        <div className={styles.imageWrapper}>
          <LazyImage src={item.imageUrl} alt={item.title} />
        </div>
        <div className={cx(styles.tag, { [styles.selectedTag]: selectedTags.includes(item.key) })}>
          {item.tag}
        </div>
      </div>
      <h3 className={styles.title}>
        {item.title}
      </h3>
      <div className={styles.duration}>
        <div className={styles.key}>
          <ClockIcon />
          {t('articles.card.duration')}
        </div>
        <span className={styles.value}>
          {`${item.durationMinutes} ${t('articles.card.minutes')}`}
        </span>
      </div>
      <div className={styles.urgency}>
        <div className={styles.key}>
          <MegaphoneIcon />
          {t('articles.card.urgency')}
        </div>
        <span className={styles.value}>
          {item.urgency}
        </span>
      </div>
      <div className={styles.description}>
        {item.briefContent}
      </div>
    </div>
  );
});

Card.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    urgency: PropTypes.string.isRequired,
    tag: PropTypes.string.isRequired,
    key: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    sourceUrl: PropTypes.string.isRequired,
    briefContent: PropTypes.string.isRequired,
    durationMinutes: PropTypes.number.isRequired,
  }).isRequired,
  onClick: PropTypes.func,
  selectedTags: PropTypes.array.isRequired,
};

/**
 *
 * @param files {Array<File>}
 * @param presignedUrls {Array<{ key: string, url: string }>}
 * @returns {Array<{ url: string, file: File }>}
 */
export const getUploadFiles = (files, presignedUrls) => {
  const uploadFiles = [];

  for (const file of files) {
    const presignedUrl = presignedUrls.find(({ key }) => key.includes(file.name));

    if (!presignedUrl) {
      throw new Error(`No presigned url found for file ${file.name}`);
    }

    uploadFiles.push({
      key: presignedUrl.key,
      url: presignedUrl.url,
      file,
    });
  }

  return uploadFiles;
};

/**
 * @param ms number
 * @returns {Promise<unknown>}
 */
async function wait(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

/**
 * @param fn () => Promise<T>
 * @param fnCondition (result: T) => boolean
 * @param intervalMs number
 * @returns {Promise<T>}
 */
export async function poll(fn, fnCondition, intervalMs) {
  const waitAndCall = async () => {
    await wait(intervalMs);
    return await fn();
  };

  let result = await waitAndCall();

  while (fnCondition(result)) {
    result = await waitAndCall();
  }

  return result;
}

/**
 * @param response {{ error: string | null, files: {name: string, error: string | null }[] }}
 * @param t {function}
 * @return {string[]}
 */
export const getErrorMessages = (response, t) => {
  if (!response?.error) {
    return [];
  }

  const parts = [t(response.error)];

  for (const file of response.files ?? []) {
    if (file.error) {
      parts.push(`${file.name} - ${t(file.error)}`);
    }
  }

  return parts;
};

import {
  REPLACE_CURRENT_USER,
  RESET_CURRENT_USER,
  SET_CURRENT_USER_DIRTY,
  ADD_CURRENT_USER_PENDING_DOCUMENTS,
  RESET_CURRENT_USER_PENDING_DOCUMENTS,
  UPDATE_CURRENT_USER,
} from './constants';

export const replaceCurrentUser = (user) => ({
  type: REPLACE_CURRENT_USER, payload: user,
});

export const updateCurrentUser = (user, dirty) => ({
  type: UPDATE_CURRENT_USER, payload: { user, dirty },
});

export const setCurrentUserDirty = (dirty) => ({
  type: SET_CURRENT_USER_DIRTY, payload: dirty,
});

export const addCurrentUserPendingDocuments = (payload) => ({
  type: ADD_CURRENT_USER_PENDING_DOCUMENTS, payload,
});

export const resetCurrentUserPendingDocuments = (payload) => ({
  type: RESET_CURRENT_USER_PENDING_DOCUMENTS, payload,
});

export const resetCurrentUser = () => ({
  type: RESET_CURRENT_USER,
});

import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { uploadFile } from 'new-ui/Pages/Upload/api';
import { addUserDocuments } from 'modules/userDocuments/api';
import { getUserProfileId } from 'modules/user/utils';
import { allCancerTypes } from 'utils/constants/cancerTypes';
import { geOnJobStatusChange } from 'new-ui/Pages/Upload/uploadHelpers';
import { getGeneralDiseaseSubType } from 'modules/user/conditionProfileHelpers';
import { UploadForm } from './UploadForm';

const { GENERAL: GENERAL_DISEASE } = allCancerTypes;

// is intended for (not) authenticated main user (not hcp patient)
export const UploadPopup = ({
  onSuccess,
  onBeforeIntake,
  condition,
}) => {
  const dispatch = useDispatch();

  const {
    profile,
  } = useSelector((state) => ({
    profile: state.profile,
  }), shallowEqual);

  const profileId = getUserProfileId(profile);

  const onJobStatusChange = geOnJobStatusChange({ dispatch, keepQstack: true });

  const handleUpload = ({
    files,
    getRecaptchaTokenAsync,
    onUpload,
    onError,
  }) => {
    uploadFile({
      files,
      getRecaptchaTokenAsync,
      onUpload,
      onError,
      onJobStatusChange,
      condition: condition === GENERAL_DISEASE ? getGeneralDiseaseSubType(profile) : condition,
    });
  };

  const handleUploadedFiles = async (files, { jobId }) => {
    if (!profileId) {
      return;
    }
    try {
      await addUserDocuments({
        jobId,
        files,
        source: 'upload',
        profileId,
      });
    } catch (ex) {
      console.error('addUserDocuments ex', ex);
    }
  };

  return (
    <UploadForm
      condition={condition}
      onSuccess={onSuccess}
      onBeforeIntake={onBeforeIntake}
      uploadFiles={handleUpload}
      onUploadedFilesChange={handleUploadedFiles}
    />
  );
};

UploadPopup.propTypes = {
  onSuccess: PropTypes.func,
  condition: PropTypes.string,
  onBeforeIntake: PropTypes.func,
};

import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import app from 'new-ui/app';
import { OTHER_INDICATION } from 'new-ui/constants';

const apiUrl = configs.beApiUrl;

export const fetchPublicationCounts = async () => {
  const indication = app?.user?.info.condition || app?.user?.personal.condition || OTHER_INDICATION;
  if (!indication) {
    throw new Error('Indication is required to fetch publication counts.');
  }

  try {
    const response = await fetch(
      `${apiUrl}/publication/getAllPublicationData/${encodeURIComponent(indication)}`,
      {
        method: 'GET',
        headers: await authHeaders(),
      },
    );

    if (!response.ok) {
      const message = `HTTP error! status: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();

    const counts = {};
    data.forEach((publication) => {
      const { nctNumber } = publication;
      if (nctNumber) { // Make sure nctNumber exists
        counts[nctNumber] = (counts[nctNumber] || 0) + 1;
      }
    });

    return counts; // Return the object: { [nctNumber]: count, ... }
  } catch (error) {
    console.error('Error fetching publication counts:', error);
    throw error; 
  }
};

import { getUserProfileId } from 'modules/user/utils';
import { resetCurrentUserPendingDocuments } from 'modules/currentUser/actions';
import { saveUserDocuments } from '../helpers';

export const savePatientPendingDocuments = () => async (dispatch, getState) => {
  const { currentUser: { data: currentUser, pendingDocuments } } = getState();

  const profileId = getUserProfileId(currentUser);

  if (!pendingDocuments.length) {
    return;
  }

  await saveUserDocuments(profileId, pendingDocuments);

  const { currentUser: { data: actualCurrentUser } } = getState();

  if (profileId !== getUserProfileId(actualCurrentUser)) {
    return;
  }

  dispatch(resetCurrentUserPendingDocuments());
};

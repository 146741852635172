import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'utils/modifiedTranslation';
import {
  getLoggedUser,
} from 'new-ui/functions';
import app from 'new-ui/app';
import noTitleLogoSrc from 'new-ui/assets/logo_no_title.svg';
import logoSrc from 'new-ui/assets/leal_logo_round.svg';
import { ReactComponent as UserIcon } from 'new-ui/assets/icons/user_button.svg';
import { ReactComponent as BurgerIcon } from 'new-ui/assets/icons/burger_menu.svg';
import { ReactComponent as CloseIcon } from 'new-ui/assets/icons/close_menu.svg';
import NotePadIcon from 'new-ui/assets/icons/note.svg';

import {
  POPUP_TYPES,
  PAGES,
  URI,
  ROUTES,
} from 'new-ui/constants';
import LanguageMenu from 'new-ui/Components/LanguageMenu';
import { ROUTE_NAME, ROUTE_PATH } from 'components/AppRoutes/constants';
import AiAssistant, { AI_WIDGET_THEMES } from 'new-ui/Components/AiAssistent';
import { HeaderTabs } from './HeaderTabs';
import { usePageHeader } from './PageHeaderContext';
import { VERSIONS } from './constants';
import { LoginButton } from './LoginButton';
import styles from './pageHeader.module.css';

const {
  DEFAULT: DEFAULT_VERSION,
  WHITE: WHITE_VERSION,
  HCP: HCP_VERSION,
} = VERSIONS;

const getHeaderVersionClassName = (version) => ({
  [DEFAULT_VERSION]: styles.defaultHeader,
  [WHITE_VERSION]: styles.whiteHeader,
  [HCP_VERSION]: styles.hcpHeader,
}[version]);

const adjustVersion = (rawVersion) => (
  rawVersion && Object.values(VERSIONS).includes(rawVersion)
    ? rawVersion
    : VERSIONS.DEFAULT
);

const renderLogo = ({ onLogoClick }) => (
  <>
    <img
      className={styles.logo}
      src={logoSrc}
      alt="Leal Health Logo"
      onClick={onLogoClick}
    />
    <img
      className={styles.logoNoTitle}
      src={noTitleLogoSrc}
      alt="Leal Health Logo"
      onClick={onLogoClick}
    />
  </>
);

const renderCustomLogo = (customLogoSrc, { onLogoClick }) => {
  try {
    return (
      <img
        src={customLogoSrc}
        onClick={onLogoClick}
        alt="logo"
        style={{ maxWidth: 180 }}
        className={styles.customLogo}
      />
    );
  } catch (e) {
  }
};

const isDashboardPage = () => (
  app.isCurrentPage(PAGES.RESULTS) || app.isCurrentPage(PAGES.FINANCE) || app.isCurrentPage(PAGES.CHAT)
);

// TODO move click handler logic to page components
const getLogoClickHandler = ({ history, t, customLogoClickHandler }) => () => {
  if (customLogoClickHandler?.() === false) {
    return;
  }
  if (isDashboardPage()) {
    history.push(ROUTE_PATH[ROUTE_NAME.FEED]);
    return;
  }
  // isUploadPage
  if (history.location.pathname === ROUTES.UPLOAD) {
    window.location = URI[PAGES.INTAKE];
  } else if (!app.isPublic) {
    history.push(ROUTE_PATH[ROUTE_NAME.RESULTS]);
  } else if (app.isCurrentPage(PAGES.UPLOAD)) {
    history.push('/');
  }
};

export const PageHeader = ({ isProviderMode }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    onLogoClick,
    headerContent, // complete replacement of the header tag content
    headerSettings,
    customLogoScr, // getProvidersAssetUrl(app.provider?.logoFileName)
    headerRightSlot,
  } = usePageHeader();

  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 1024 && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobileMenuOpen]);

  const {
    isLogoShown,
    isProfileButtonShown,
    isUploadButtonShown,
    isNavMenuShown,
    version: rawVersion,
    isLanguageMenuShown,
    isLoginButtonShown,
    isBurgerMenuShown,
    tabIds,
  } = headerSettings;

  const version = adjustVersion(rawVersion);

  const logoClickHandler = getLogoClickHandler({ history, t, customLogoClickHandler: onLogoClick });

  return (
    <div className={cx(styles.headerWrapper, { [styles.mobileMenuOpen]: isMobileMenuOpen })}>
      <header
        className={cx(
          styles.header,
          getHeaderVersionClassName(version),
          isProviderMode ? styles.providerHeader : styles.noProviderHeader,
        )}
      >
        {headerContent || (
          <>
            {/* leftSlot */}
            {(!isProviderMode && isLogoShown && (
              <div className={styles.logoContainer}>
                {
                  (customLogoScr && renderCustomLogo(customLogoScr, { onLogoClick: logoClickHandler }))
                  ?? renderLogo({ onLogoClick: logoClickHandler })
                }
              </div>
            )) || <div />}

            {/* middleSlot */}
            {isNavMenuShown && tabIds.length
              ? (
                <HeaderTabs
                  version={version}
                  tabIds={tabIds}
                  isMobileMenuOpen={isMobileMenuOpen}
                  onMenuItemClick={() => setIsMobileMenuOpen(false)}
                />
              ) : null}

            {/* rightSlot */}
            {headerRightSlot || ((isProfileButtonShown || isUploadButtonShown) && (
              <div className={styles.buttonsWrapper}>
                {isUploadButtonShown && (
                  <div className={styles.uploadButton}>
                    <AiAssistant
                      theme={AI_WIDGET_THEMES.DARK}
                      customUploadIcon={NotePadIcon}
                      customUploadLabel={isMobileMenuOpen ? t('ai_profile.upload_button') : ' '}
                    />
                  </div>
                )}
                {isProfileButtonShown && (
                  <div className={styles.profileButton} onClick={() => { app.setPopup(POPUP_TYPES.MEDICAL_PROFILE); }}>
                    <UserIcon />
                    <span className={styles.profileLabel}>{t('general.navbar.profile')}</span>
                  </div>
                )}
              </div>
            )) || ((isLanguageMenuShown || isLoginButtonShown) && (
              <div className={styles.rightCornerSlot}>
                {isLanguageMenuShown && <LanguageMenu />}
                {isLoginButtonShown && !getLoggedUser() && <LoginButton />}
              </div>
            )) || <div />}

            {(getLoggedUser() && isBurgerMenuShown) && (
              <div className={styles.burgerMenuWrapper}>

                {!app.isCurrentPage(PAGES.CHAT) && (
                  <div
                    className={styles.helpButton}
                    onClick={() => {
                      app.history.push(URI[PAGES.CHAT]);
                    }}
                  >
                    {t('general.help_with_tina')}
                  </div>
                )}

                <div className={styles.burger} onClick={() => setIsMobileMenuOpen((prevState) => !prevState)}>
                  {isMobileMenuOpen ? <CloseIcon /> : <BurgerIcon />}
                </div>
              </div>
            )}

          </>
        )}
      </header>
    </div>
  );
};

PageHeader.propTypes = {
  isProviderMode: PropTypes.bool.isRequired,
};

import React, { useCallback, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import configs from 'configs';

const useStyles = makeStyles(() => ({
  root: {
    position: 'fixed',
    zIndex: 1,
    visibility: 'hidden',
  },
}));

const useRecaptcha = () => {
  const recaptchaRef = useRef();
  const classes = useStyles();

  const recaptcha = configs.recaptchaSiteKey ? (
    <ReCAPTCHA
      className={classes.root}
      ref={recaptchaRef}
      size="invisible"
      sitekey={configs.recaptchaSiteKey}
    />
  ) : null;

  const getRecaptchaTokenAsync = useCallback(async () => {
    const token = await recaptchaRef.current?.executeAsync();
    // eslint-disable-next-line no-unused-expressions
    recaptchaRef.current?.reset();
    return token;
  }, []);

  return {
    recaptcha,
    getRecaptchaTokenAsync,
  };
};

export default useRecaptcha;

import React from 'react';
import './Questionnaire.css';

import { POPUP_TYPES } from 'new-ui/constants';
import app from 'new-ui/app';

import globalEnums from 'components/Questionnaire/constants/globalEnums';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';
import { authenticationActions } from 'components/Authentication/actions';
import { CancerFieldsByIndication } from 'new-ui/api/data/cancerTypes';
import { setFieldValue } from 'components/Questionnaire/actions';
import { setUserIntent } from '../UserIntent';

app.editQuestion = (question, onsave) => {
  if (!app.setPopup) throw new Error('setPopup not ready!');

  if(question === 'treatments') return app.setPopup(POPUP_TYPES.EDIT_QUESTION, { question, onsave: (user)=>{
  }});

  app.setPopup(POPUP_TYPES.QUESTIONNAIRE, { question });

  setTimeout(() => {
    const $q = document.querySelector('iframe.questionnaire');
    if ($q) {
      $q.contentWindow.addEventListener('DOMContentLoaded', () => {
        $q.contentDocument.body.classList.add('edit-mode');
        if (document.body.clientWidth >= 800) $q.contentDocument.body.classList.add('desktop-edit-mode');
        $q.contentWindow.app.user = app.user;
        $q.contentWindow.setData = (updatedData) => {
          if (onsave) onsave(updatedData);
        };
      }, true);
    }
  }, 1);
};

const setValueToUserProfilersQuestion = (question) => {
  const [questionKey, value] = question;
  let questionId = questionKey;
  if (questionKey === 'current_stage') {
    questionId = CancerFieldsByIndication[app.user.personal.condition]?.patientStage || 'patient_stage';
  }
  app.store.dispatch(
    setFieldValue({
      id: questionId,
      value,
    }),
  );
  app.user.condition_profile[questionId] = value;
};

app.answerQuestion = async (questions) => {
  const oldUser = JSON.parse(JSON.stringify(app.user));
  if (questions.user_intent) return setUserIntent(questions.user_intent);
  for (const question in questions) {
    setValueToUserProfilersQuestion([question, questions[question]]);
  }
  await app.store.dispatch(authenticationActions.updateUser(app.user, true, oldUser));
  await app.update({ rematch: true });
};

const Questionnaire = (props) => {
  const {
    question = globalEnums.disease_status,
  } = props;
  const src = getQuestionnaireRoute({ questionId: `${question}?inline=1` });
  return (
    <div className="questionnaire">
      {question ? <iframe className="questionnaire" title="questionnaire" src={src} /> : null}
    </div>
  );
};

export default Questionnaire;

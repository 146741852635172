// api.js
import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import app from 'new-ui/app';

const apiUrl = configs.beApiUrl;

const extractLastStringFromUrl = () => {
  try {
    const url = window.location.href;
    if (!url) {
      return '';
    }
    const parts = url.split('/');
    const lastPart = parts[parts.length - 1];
    if (!lastPart) {
      return '';
    }
    const decodedLastPart = decodeURIComponent(lastPart);
    const trimmedLastPart = decodedLastPart.split('-')[0];
    return trimmedLastPart;
  } catch (error) {
    console.error('Error extracting last string from URL:', error);
    return '';
  }
};

const fetchPublicationData = async () => {
  const nctNumber = extractLastStringFromUrl();
  const indication = app.user.info.condition;

  try {
    const response = await fetch(
      `${apiUrl}/publication/getByNctADisease/${encodeURIComponent(nctNumber)}/${encodeURIComponent(indication)}`,
      {
        method: 'GET',
        headers: await authHeaders(),
      },
    );

    if (!response.ok) {
      const message = `HTTP error! status: ${response.status}`;
      throw new Error(message);
    }

    const data = await response.json();
    const { publications } = data;
    const sortedPublications = [...publications].sort((a, b) => new Date(b.articleDate) - new Date(a.articleDate));
    data.publications = sortedPublications;
    return data;
  } catch (error) {
    console.error('Error fetching publication data:', error);
    throw error; // Re-throw the error to be caught by the component
  }
};

export default fetchPublicationData;

import app from 'new-ui/app';
import { PAGES, ROUTES } from 'new-ui/constants';
import { preserveQueryParam } from 'functions/preserveQueryParam';
import { SORT_ORDER, URL_QUERY_PARAM_BY_SORT_ORDER, SORT_URL_SEARCH_PARAM } from '../constants';

export const getSortOptions = ({ t, sortOrder }) => {
  const formAction = (targetSortOrder) => {
    const urlQueryParam = URL_QUERY_PARAM_BY_SORT_ORDER[targetSortOrder];
    return () => {
      const url = preserveQueryParam(
        ROUTES[PAGES.RESULTS],
        {
          ...(urlQueryParam ? { extra: [[SORT_URL_SEARCH_PARAM, urlQueryParam]] } : { omit: [SORT_URL_SEARCH_PARAM] }),

        },
      );
      app.history.push(url);
    };
  };

  const options = [
    {
      title: t('general.most_matched'),
      action: formAction(SORT_ORDER.MOST_MATCHED),
      isSelected: !sortOrder || sortOrder === URL_QUERY_PARAM_BY_SORT_ORDER[SORT_ORDER.MOST_MATCHED],
    },
    {
      title: t('general.most_recent'),
      action: formAction(SORT_ORDER.RECENT),
      isSelected: !sortOrder || sortOrder === URL_QUERY_PARAM_BY_SORT_ORDER[SORT_ORDER.RECENT],
    },
  ];
  return options;
};

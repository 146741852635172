import React, {
  useEffect, useRef, useState,
} from 'react';
import { useMediaQuery } from 'react-responsive';
import './Intake.css';
import app from 'new-ui/app';
// import { useTranslation } from 'utils/modifiedTranslation';

import Intake from 'new-ui/Components/Intake';
import {
  MATCH_FLOWS, PAGES, POPUP_TYPES, ROUTES,
} from 'new-ui/constants';
import ButtonIcon from 'new-ui/assets/icons/intake_icon.svg';
import BackIcon from 'new-ui/assets/icons/profile_icon.svg';
import { SECONDARY_THEME } from 'new-ui/Components/Intake';
import { ReactComponent as ProfileIcon } from 'new-ui/assets/icons/profile_icon.svg';
import LottieAnimation from 'new-ui/assets/lottie/check.json';
import LottieAnimationLoading from 'new-ui/assets/lottie/intake_loading.json';
import LottieAnimationNewResults from 'new-ui/assets/lottie/new_results.json';
import { GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import { getLottie, getOverlay, waitFor, removeDashboardSkeleton } from 'new-ui/functions';
import { authenticationActions } from 'components/Authentication/actions';
import { useTranslation } from 'utils/modifiedTranslation';
import { PENDING, PRISTINE, SUCCESS } from 'utils/constants/queryStatuses';
import { useIsMounted } from 'utils/hooks/useIsMounted';
import mediaQueriesConstants from 'utils/constants/mediaQueries';
import configs from 'configs';
import { getCleanedTreatments } from 'new-ui/Components/QuestionRenderer/renderers/TreatmentQuestion';
import { HeaderGoToResults } from 'new-ui/Components/HeaderGoToResults/HeaderGoToResults';
import axios from 'axios';
import { useUpdatePageHeader, useSetHeaderSettings } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { VERSIONS } from 'new-ui/Components/PageHeader/constants';

window.__LottieAnimation = LottieAnimation;

let initResults = null;
let isLoading = false;
let oldUser = null;
// const gotoResultsFlag = false;

let $loading;

let updateNum = 0;
let userIp = null;

const IntakePage = () => {
  const {
    setHeaderRightSlot,
  } = useUpdatePageHeader();
  // eslint-disable-next-line no-unused-vars
  const [locationError, setLocationError] = useState(null);
  const [user, setUser] = useState(null);
  const [foundIpInUS, setFoundIpInUS] = useState(null);
  const [matchedTrialsCount, setMatchedTrialsCount] = useState({ status: PRISTINE, data: app.trials?.length ?? 0 });
  const isMounted = useIsMounted();
  const { t } = useTranslation();

  const isWidthLess800 = useMediaQuery(mediaQueriesConstants.maxWidth800);

  useSetHeaderSettings({
    version: VERSIONS.WHITE,
    isLogoShown: !isWidthLess800,
  });

  const fetchUserIp = async () => {
    try {
      const response = await fetch('https://api.ipify.org?format=json');
      const data = await response.json();
      userIp = (data.ip);
      if (data.ip) {
        const ipLocation = await fetchIpLocation(data.ip, foundIpInUS);
      }
    } catch (error) {
      console.error('Error fetching IP address:', error);
    }
  };

  const fetchIpLocation = async (ip) => {
    try {
      const response = await axios.get(`https://ipapi.co/${ip}/json/`);
      if (response.data.latitude && response.data.longitude) {
        const {
          latitude,
          longitude,
          city,
          region,
          country_name,
        } = response.data;

        if (country_name) {
          setFoundIpInUS(true);
          const locationToUpdate = {
            location: {
              approximateLat: latitude,
              approximateLng: longitude,
              lat: latitude,
              lng: longitude,
            },
            name: null,
            label: `${city}, ${region}, ${country_name}`, // You can customize the label
            state: region,
          };

          const updatedUser = {
            ...app.user,
            info: {
              ...app.user.info,
              country: locationToUpdate,
            },
          };


          app.setConfiguration({ hiddenLocation: true });
          await app.setUser(updatedUser);
          app.user.info.country = locationToUpdate;
          await app.store.dispatch(authenticationActions.updateUser(updatedUser, true, app.user));
          return locationToUpdate; // Optionally return the location
        }
        return { // Return the full data even if not US
          latitude,
          longitude,
          city,
          region,
          country_name,
        };
      }
      console.warn('Could not retrieve accurate IP geolocation (Axios).');
    } catch (error) {
      console.error('Error fetching IP geolocation (Axios):', error);
    }
  };

  const { personal: { customerRef } = {} } = app.user || {};

  useEffect(() => {
    app.intakeVisited = true;
    initResults = null;
    $loading = null;

    app.sendGoogleAnalyticsEvent(GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS.INTAKE_PAGE_VIEW);

    // Function to fetch the user's IP address
    if (!app?.user?.info?.country?.label) {
      fetchUserIp(); // Call the function to get the IP address
      if (userIp) {
        fetchIpLocation(userIp);
      }
    }

    const hideLoader = () => {
      app.setShowPreloader(false);
      removeDashboardSkeleton();
    };
    
    const fetch = async () => {
      app.setShowPreloader(true);
      if (app.signupTrials) {
        app.trials = [...app.signupTrials];

        renderResults(app.trials?.length ?? 0);
        initResults = [...app.trials];
        hideLoader();
        delete app.signupTrials;
        if (app.user) return setUser(app.user);
      }
      app.getTrials(MATCH_FLOWS.BASIC, ()=>{
        renderResults(app.trials?.length ?? 0);
        initResults = [...app.trials];
        hideLoader();
        if (app.user) return setUser(app.user);
      });
    };

    fetch();

    app.intercom.hidden = true;

    return ()=>{
      app.intercom.hidden = false;
    };
    // eslint-disable-next-line
  }, []);

  const requestLocation = async () => {
    let updatedUser = {};
    if (navigator.geolocation) {
      try {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const reverseGeocodeLocation = await reverseGeocode(position.coords.latitude, position.coords.longitude);
            if (position) {
              const locationToUpdate = {
                location:
                {
                  approximateLat: position.coords.latitude,
                  approximateLng: position.coords.longitude,
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
                name: null,
                label: reverseGeocodeLocation.address,
                state: reverseGeocodeLocation.country,
              };
              updatedUser = {
                ...app.user,
                info: {
                  ...app.user.info,
                  country: locationToUpdate,
                },
              };

              await app.setUser(updatedUser);

              app.user.info.country = locationToUpdate;
              await app.store.dispatch(authenticationActions.updateUser(updatedUser, true, app.user));
              await app.setConfiguration({ hiddenLocation: false });
            }
          },
          (err) => {
            console.error('Error getting location:', err);
            setLocationError(err.message);
          },
        );
      } catch (error) {
      }
    } else {
      setLocationError('Geolocation is not supported by this browser.');
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    const apiKey = configs.geosearchapi;
    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status.code === 200) {
        // Successful response from OpenCage
        const result = data.results[0];
        return {
          address: result.formatted, // Get the formatted address
          country: result.components.country, // Extract country
          state: result.components.state || null, // Extract state (if available)
          // ... other components you need (city, postcode, etc.)
        };
      }

      // eslint-disable-next-line no-console
      console.log(`OpenCage Geocoding request failed with code: ${data.status.code}`);
      return {
        address: null,
        country: null,
        state: null,
      };
    } catch (error) {
      console.error('Error fetching geocoding data:', error);
      return {
        address: null,
        country: null,
        state: null,
      };
    }
  };

  const gotoResultsAction = ()=>{
    if (isLoading) {
      $loading = getOverlay();
      const $lottie = getLottie(LottieAnimationLoading);
      $loading.append($lottie);
      document.body.appendChild($loading);
      $loading.show();
    } else gotoResults();
  };

  const goToResultsRef = useRef(gotoResultsAction);
  goToResultsRef.current = gotoResultsAction;

  useEffect(() => {
    if (customerRef) {
      return;
    }

    const { status: trialsCountFetchStatus, data: trialsCount } = matchedTrialsCount;

    setHeaderRightSlot(
      <HeaderGoToResults
        goToResultsRef={goToResultsRef}
        resultsCount={(trialsCountFetchStatus === SUCCESS && trialsCount) || 0}
        isLoading={[PENDING, PRISTINE].includes(trialsCountFetchStatus)}
        resultsTitle="Personalized Results"
        resultsIconSrc={ButtonIcon}
      />,
    );
  }, [goToResultsRef, setHeaderRightSlot, customerRef, matchedTrialsCount]);

  useEffect(() => () => {
    setHeaderRightSlot(null);
  }, [setHeaderRightSlot]);

  const gotoResults = ()=>{
    // window.location = `${ROUTES[PAGES.RESULTS]}?rematch=1&results=1`;
    // return;
    if (isLoading) {
      waitFor(()=>{
        return isLoading === false;
      }, ()=>{
        window.location = `${ROUTES[PAGES.RESULTS]}?rematch=1`;
      }, ()=>{
        window.location = `${ROUTES[PAGES.RESULTS]}?rematch=1`;
      });
      return;
    }
    app.history.push(`${ROUTES[PAGES.RESULTS]}`);
    const doGoto = ()=>{
      // console.log('here', initResults.map((a)=>a._id), app.trials.map((a)=>a._id));
      console.log('initResults', initResults);
      try {
        if (JSON.stringify(initResults.map((a)=>a._id)) === JSON.stringify(app.trials.map((a)=>a._id))) return;
      } catch (e) {
        console.log('here');
      }
      initResults = null;
      waitFor(()=>{
        const $list = document.querySelector('.widget.result-list');
        if ($list) return true;
      }, ()=>{
      // console.log('show new results');
        const $list = document.querySelector('.widget.result-list');
        const $newResults = getOverlay();
        $newResults.classList.add('new-results');
        const $lottie = getLottie(LottieAnimationNewResults);
        $newResults.append($lottie);
        $list.appendChild($newResults);
        $newResults.show();
        setTimeout(()=>{
          $newResults.remove();
        }, 3000);
      });
    };
    const results = app.numResults(true);
    if (!results || !initResults) {
      // console.log('no results', app.fetchedTrials, results, initResults);
      waitFor(()=>{
        // console.log('here 5', initResults, app.numResults(true));
        return initResults;
      }, ()=>{
        doGoto();
      }, ()=>{
        // console.log('timeout');
        window.location = `${ROUTES[PAGES.RESULTS]}`;
      });
      return;
    }
    doGoto();
  };

  const renderResults = (trialsCount) => {
    setMatchedTrialsCount({ status: PENDING, data: 0 });
    setTimeout(() => {
      if (!isMounted()) {
        return;
      }
      setMatchedTrialsCount({ status: SUCCESS, data: trialsCount });
    }, 0);

    if ($loading) {
      $loading.remove();
      $loading = null;
      gotoResults();
    }
  };

  const update = async (user)=>{
    const _user = window.structuredClone(user);
    _user.condition_profile.treatments = getCleanedTreatments(user.condition_profile.treatments);

    const currentUpdateNum = updateNum + 1;
    updateNum++;
    isLoading = true;
    const newUser = await app.store.dispatch(authenticationActions.updateUser(_user, true, oldUser));
    app.user = newUser;
    oldUser = JSON.parse(JSON.stringify(app.user));
    await app.getTrials(MATCH_FLOWS.BASIC);
    if (currentUpdateNum !== updateNum) return; // execute only if last update
    isLoading = false;

    renderResults(app.trials?.length ?? 0);
  };

  const params = new URLSearchParams(window.location.search);

  const openProfile = async ()=>{
    if ((!app.user.info.country?.label || !app.user.info.country?.location) || app?.coniguration?.data?.hiddenLocation) {
      await requestLocation();
      app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
    }
    app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
  };

  let qid = params.get('qid');

  let customQuestions;
  const intakeQuestion = params.get('intake_question');
  if (intakeQuestion) {
    customQuestions = [intakeQuestion];
    qid = intakeQuestion;
  }

  return (
    <div className="intake-page">
      { user ? (
        <>
          <div className="intake-wrapper">
            <div className="intake-page-title">
              {t('intake.page_title')}
            </div>
            <Intake
              theme={SECONDARY_THEME}
              user={user}
              onUpdate={update}
              gotoResultsAction={gotoResultsAction}
              part={params.get('section')}
              qid={qid}
              customQuestions={customQuestions}
              showOnlyUnanswered={params.get('unanswered')}
            />
            <div className="back-to-results">
              <div
                className="back-to-results-button"
                onClick={openProfile}
              >
                <div className="back-to-results-button-icon">
                  <ProfileIcon className="back-to-results-button-icon-itself" />
                </div>
                <div className="back-to-results-button-text">
                  {t('intake.goto_profile')}
                </div>
              </div>
              {!customerRef && (
                <div
                  className="back-to-results-button back-to-results-button-results"
                  onClick={gotoResults}
                >
                  <div className="back-to-results-button-icon">
                    <img src={ButtonIcon} alt="" />
                  </div>
                  <div className="back-to-results-button-text">
                    {t('intake.goto_results')}
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      ) : null }
    </div>
  );
};

export default IntakePage;

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'utils/modifiedTranslation';
import styles from './label.module.css';

export const Label = ({
  item, onChange,
  selectedItems,
}) => {
  const { t } = useTranslation();
  return (
    <label className={cx(styles.root, { [styles.checked]: selectedItems.includes(item.key) })} htmlFor={item.key}>
      <span className={styles.title}>{t(item.title)}</span>
      <input
        id={item.key}
        type="checkbox"
        checked={selectedItems.includes(item.key)}
        onChange={onChange}
        className={styles.checkbox}
      />
    </label>
  );
};

Label.propTypes = {
  item: PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    checked: PropTypes.bool,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  selectedItems: PropTypes.array.isRequired,
};

import { upsertUserPatient } from 'components/Authentication/actions';
import { setCurrentUserDirty } from 'modules/currentUser/actions';
import { prepareAndSaveHCPPatient } from '../helpers';
import { savePatientPendingDocuments } from './savePatientPendingDocuments';

export const saveHCPPatient = () => async (dispatch, getState) => {
  const { currentUser: { data: currentUser, pendingDocuments } } = getState();

  if (pendingDocuments.length) {
    await dispatch(savePatientPendingDocuments());
  }

  const { success } = await prepareAndSaveHCPPatient(currentUser);
  if (!success) {
    throw new Error('prepareAndSaveHCPPatient is not successful');
  }
  dispatch(upsertUserPatient(currentUser));

  const { currentUser: { dirty } } = getState();
  if (dirty) {
    dispatch(setCurrentUserDirty(false));
  }
};

/* eslint-disable comma-dangle */
import { authHeaders } from 'functions/authHeader';
import { GA_CHAT_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import app from 'new-ui/app';
import configs from '../../../configs';

const apiUrl = configs.beApiUrl;

/**
 * Sends a message to the chat API and returns the response.
 *
 * @param {string} prompt - The prompt for the message
 * @param {string[]} userTrialList - The list of user's Nct
 * @param {string} userWixId - The user's Wix ID
 * @param {string[]} treatmentCategories - The categories of treatment
 * @param {boolean} missingCritical - Indicates if there are missing critical details
 * @param {string} indication - The indication for the message
 * @param {string} userChatMode - The user's chat mode
 * @return {Promise<Object>} The response from the chat API
 */

export const textConnection = async () => {
  try {
    app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_USER_SEND_MESSAGE_TO_CHAT, 'user send msg to chat');
    const response = await fetch(`${apiUrl}/flowise/test`, {
      method: 'GET',
      headers: await authHeaders(),
    });

    if (!response.ok) {
      throw new Error(`Error sending message: ${response.statusText}`);
    }

    const ans = await response.json();
    return ans;
  } catch (error) {
    console.error('Error sending message:', error);
  }
};

export const sendMessageReturnUser = async (prompt, data) => {
  const payload = JSON.stringify({
    question: prompt,
    overrideConfig: {
      vars: {
        payload: { ...data },
      },
    },
  });

  try {
    app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_USER_SEND_MESSAGE_TO_CHAT, 'user send msg to chat');
    const response = await fetch(`${apiUrl}/chatReturnUser/returnUserMsg`, {
      method: 'POST',
      headers: await authHeaders(),
      body: payload,
    });

    if (!response.ok) {
      throw new Error(`Error sending message: ${response.statusText}`);
    }

    const ans = await response.json();
    return ans;
  } catch (error) {
    console.error('Error sending message:', error);
  }
};
/**
 * Updates the chat score for a user.
 *
 * @param {string} userWixId - The Wix ID of the user.
 * @param {number} score - The new score for the user.
 * @return {Promise<void>} A promise that resolves when the score is updated.
 */

export const updateChatScore = async (userWixId, score) => {
  const data = {
    userWixId,
    score,
  };
  await fetch(`${apiUrl}/chat/updateChatScore`, {
    method: 'POST',
    headers: await authHeaders(),
    body: JSON.stringify(data),
  });
  app.sendGoogleAnalyticsEvent(GA_CHAT_EVENTS.PROFILE_CHAT_USER_UPDATE_SCORING, 'user update score in profile chat');
};

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import './NGSPopup.css';
import app from 'new-ui/app';
import { getBiomarkerTitle } from 'new-ui/functions';
import { getUserConditionProfile } from 'modules/user/utils';
import FilesUploader from 'new-ui/Components/FilesUploader';
import { UPLOADER_STATES } from 'new-ui/Components/FilesUploader/FileUploaderConstants';
import Button from 'new-ui/Components/Button';
import LottieAnimation from 'new-ui/assets/lottie/NGS.json';
import { INTERCOM_EVENTS } from 'new-ui/constants';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { JOB_STATUS } from 'components/MedicalFiles/constants';

const GA = {
  category: 'NGS_POPUP',
  events: {
    pageview: 'page_view',
    button: 'button_click',
    general: 'general',
    form: 'form_filled',
  },
};

const getNextBiomarkersDefault = (extractedBiomarkers) => extractedBiomarkers;

export const NGSForm = ({
  onSuccess,
  title,
  generatingText,
  uploadText,
  uploadFiles,
  getNextBiomarkers = getNextBiomarkersDefault,
  onUploadedFilesChange,
}) => {
  const [uploaderState, setUploaderState] = useState(UPLOADER_STATES.SELECT);
  const [job, setJob] = useState(null);
  const [biomarkers, setBiomarkers] = useState([]);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const uploaderRef = useRef({});

  const { current: uploader } = uploaderRef;

  const header = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return null;
      case UPLOADER_STATES.MULTI:
        return <div className="files-uploader-preview-title">{uploadText}</div>;
      case UPLOADER_STATES.UPLOADING:
        return (
          <>
            <div className="ngs-title">{ title }</div>
            <div className="ngs-analyze">
              <Lottie animationData={LottieAnimation} loop />
            </div>
            <div className="height-10" />
          </>
        );
      case UPLOADER_STATES.ERROR:
        return (
          <div className="ngs-upload-error">
            Something went wrong with uploading your NGS files, please try again or contact support
          </div>
        );
      case UPLOADER_STATES.SUCCESS:
        return <div className="ngs-title">{ generatingText }</div>;
    }
  };

  const back = () => {
    uploader.setFiles([...uploader.files.filter((a) => !a.error)]);
    uploader.setState(UPLOADER_STATES.MULTI);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, {
      name: 'back_to_file_select_due_to_error',
    });
  };

  const errorFooter = () => (
    <div>
      <Button title="Upload another file" action={back} />
    </div>
  );

  const successFooter = () => (
    <div className="success-footer">
      {job?.profile?.condition_profile?.biomarkers?.length ? (
        <div className="popup-ngs-analyzer-results-container">
          <div className="popup-ngs-analyzer-results-subtitle">
            We identified the following biomarkers:
          </div>
          {job.profile.condition_profile.biomarkers.map((biomarker) => (
            <div className="ngs-analyze-files-biomarker" key={biomarker}>
              <div className="ngs-analyze-files-biomarker-name">{getBiomarkerTitle(biomarker)}</div>
            </div>
          ))}
        </div>
      ) : null}
      <Button
        title="Apply biomarkers and search"
        action={() => {
          onSuccess(biomarkers);
        }}
      />
    </div>
  );

  const isErrored = (files = uploader.files) => {
    const erroredFiles = files.filter((a) => a.error);
    return erroredFiles.length === files.length;
  };

  const footer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return null;
      case UPLOADER_STATES.UPLOADING:
        return null;
      case UPLOADER_STATES.ERROR:
        return errorFooter();
      case UPLOADER_STATES.SUCCESS:
        return isErrored() ? errorFooter() : successFooter();
    }
  };

  const filerenderer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
      case UPLOADER_STATES.UPLOADING:
      case UPLOADER_STATES.SUCCESS:
      case UPLOADER_STATES.ERROR:
        return null;
    }
  };

  const uploadError = () => {
    uploader.setState(UPLOADER_STATES.ERROR);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'upload_error' });
  };

  const upload = async () => {
    const files = [...uploader.files.filter(({ error }) => !error)];
    uploader.setState(UPLOADER_STATES.UPLOADING);

    uploadFiles({
      files: [...uploader.files],
      getRecaptchaTokenAsync,
      onUpload: (result) => {
        if (result?.status === JOB_STATUS.ERROR) {
          uploadError();
          return;
        }
        if (result?.status !== JOB_STATUS.COMPLETED) {
          // invariant
          return;
        }

        const { profile, files: uploadedFiles, id: jobId } = result;

        files.forEach((file) => {
          const f = uploadedFiles.find(({ name }) => name === file.name);

          if (!f) {
            console.log('error: no matching file in analyzer result set', file, uploadedFiles);
            return;
          }

          file.storageName = f?.storageName ?? '';
          file.biomarkers = f?.profile?.condition_profile?.biomarkers ?? [];

          if (f?.error) {
            file.error = f.error;
            app.intercom.sendEvent(INTERCOM_EVENTS.NGS_POPUP.FILE_NOT_READABLE);
          } else if (!file.biomarkers || !file.biomarkers.length) {
            file.error = "The NGS file doesn't contain any biomarkers";
            app.intercom.sendEvent(INTERCOM_EVENTS.NGS_POPUP.NO_BIOMARKERS);
          }
        });

        const { biomarkers: extractedBiomarkers = [] } = getUserConditionProfile(profile);

        setJob(result);
        setBiomarkers(getNextBiomarkers(extractedBiomarkers));
        uploader.setFiles(files);
        uploader.setState(UPLOADER_STATES.SUCCESS);

        onUploadedFilesChange(
          files.filter(({ error }) => !error),
          { jobId },
        );

        app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'upload_success' });
      },
      onError: uploadError,
    });
  };

  return (
    <div className="ngs-popup">
      {recaptcha}
      <FilesUploader
        isMulti
        onstatechange={setUploaderState}
        header={header()}
        footer={footer()}
        filerenderer={filerenderer}
        uploader={uploader}
        onfilesselected={() => {
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'file_selected' });
        }}
        onselect={() => {
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.button, { name: 'files_selection_next_button' });
          return upload();
        }}
        onFileError={() => {
          app.intercom.sendEvent(INTERCOM_EVENTS.NGS.WRONG_FORMAT);
        }}
      />
    </div>
  );
};

NGSForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  onUploadedFilesChange: PropTypes.func.isRequired,
  getNextBiomarkers: PropTypes.func,
  title: PropTypes.string.isRequired,
  generatingText: PropTypes.string.isRequired,
  uploadText: PropTypes.string.isRequired,
};

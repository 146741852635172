import configs from 'configs';
import request from 'functions/api/request';
import { getBasicHeaders, formAuthHeaders } from 'functions/authHeader';

const { beApiUrl: apiUrl } = configs;

export const getTargetUserProfile = async ({ accessToken, lang }) => {
  const requestOptions = {
    url: `${apiUrl}/user/profile`,
    qs: {
      lang: lang || null,
    },
    headers: {
      ...getBasicHeaders(),
      ...formAuthHeaders({ accessToken }),
    },
    json: true,
  };

  let response = null;
  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting target user profile', ex);
    throw ex;
  }

  return response;
};

import React from 'react';
import MuiButton from '@material-ui/core/Button';
import { useTranslation } from 'utils/modifiedTranslation';
import { PAGES, URI } from 'new-ui/constants';

export const LoginButton = () => {
  const { t } = useTranslation();

  return (
    <MuiButton
      onClick={() => {
        window.location = URI[PAGES.LOGIN];
      }}
    >
      {t('general.login')}
    </MuiButton>
  );
};

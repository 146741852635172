import request from 'functions/api/request';
import configs from 'configs';
import { authHeaders } from 'functions';
import { getUploadFiles } from './utils';

/**
 * @param files {Array<File>}
 * @param recaptchaToken {string}
 * @returns {Promise<Array<{ key: string, url: string }>>}
 */
export const getPresignUrls = async (files, recaptchaToken) => request.post({
  url: `${configs.beApiUrl}/medicalFiles/signUrls`,
  data: {
    files: files.map(({ name, type, size }) => ({ name, type, size })),
    recaptchaToken,
  },
});

/**
 * @param files {Array<File>}
 * @param recaptchaToken {string}
 * @returns {Promise<Array<string>>}
 */
export const uploadFiles = async (files, recaptchaToken) => {
  const presignedUrls = await getPresignUrls(files, recaptchaToken);
  const filesToUpload = getUploadFiles(files, presignedUrls);

  await Promise.all(filesToUpload.map(({ url, file }) => request.put({
    url,
    data: file,
  })));

  return presignedUrls.map(({ key }) => key);
};

/**
 * @param {{ email: string, phone: string }} formData
 * @param {string} recaptchaToken
 */
export const getMedicalFilesFromEmail = async (formData, recaptchaToken) => {
  const { email, phone } = formData;

  await request.post({
    url: `${configs.beApiUrl}/medicalFiles/fromMail`,
    data: {
      email,
      phone: phone || undefined,
      recaptchaToken,
    },
  });
};

/**
 * @param {Array.<string>} fileKeys
 * @param {"full_profile" | "biomarkers"} extractionStrategy
 * @param {string} recaptchaToken
 * @param {string | undefined} indication
 * @returns {Promise<string>}
 */
export const createFileProcessingJob = async (fileKeys, extractionStrategy, recaptchaToken, indication) => {
  const headers = await authHeaders();

  const response = await request.post({
    url: `${configs.beApiUrl}/medicalFiles/createJob`,
    data: {
      fileKeys,
      extractionStrategy,
      indication: indication ?? undefined,
      recaptchaToken,
    },
    headers,
    json: true,
  });

  return response.id;
};

/**
 * @param id string
 * @returns {Promise<{ id: string, indication: string, prfoile: object, status: string, error: string }>}
 */
export const getUserProfile = async (jobId, { noUserProfileMerge } = {}) => {
  const headers = await authHeaders();

  return request.get({
    headers,
    url: `${configs.beApiUrl}/medicalFiles/userProfile/${jobId}`,
    json: true,
    qs: {
      ...(noUserProfileMerge ? { noUserProfileMerge: '1' } : {}),
    },
  });
};

/**
 * @param items {{ jobId: string,  docStorageKey: string, docStatus: string, failureReason?: string  }[]}
 * @return {Promise<*|null>}
 */
export const addDocLogs = async (items) => {
  const headers = await authHeaders();

  try {
    await request.post({
      headers,
      url: `${configs.beApiUrl}/medicalFiles/log`,
      data: { items },
      json: true,
    });
  } catch (e) {
    console.error(e);
  }
};

export const mergeUserProfiles = async ({ oldProfile, newProfile }) => {
  const headers = await authHeaders();

  return request.post({
    headers,
    url: `${configs.beApiUrl}/medicalFiles/mergeProfiles`,
    json: true,
    data: { oldProfile, newProfile },
  });
};

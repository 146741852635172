import React from 'react';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import SaveSearchPopup from 'new-ui/Search/SaveSearch';
import { getIsMockUser } from '../helpers';

export const currentUserLossWarningPopup = ({
  popupTitle, saveAndContinueAction, continueWithoutSavingAction,
}) => (dispatch, getState) => {
  const {
    currentUser: { data: currentUser, dirty: isCurrentUserDirty, pendingDocuments },
  } = getState();

  if (!pendingDocuments.length && (getIsMockUser(currentUser) || !isCurrentUserDirty)) {
    dispatch(continueWithoutSavingAction());
    return;
  }

  app.setPopup(POPUP_TYPES.GENERAL, {
    theme: 'middle',
    title: popupTitle,
    content: (
      <SaveSearchPopup
        handleContinueWithoutSaving={() => {
          app.setPopup(false);
          dispatch(continueWithoutSavingAction());
        }}
        handleSaveAndContinue={() => {
          app.setPopup(false);
          dispatch(saveAndContinueAction());
        }}
      />
    ),
  });
};

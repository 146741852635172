import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Lottie from 'lottie-react';
import { useMinDurationTrue } from 'utils/hooks/useMinDurationTrue';
import LottieAnimation from 'new-ui/assets/lottie/check.json';
import styles from './headerGoToResults.module.css';

const MIN_PERIOD = 2000;

export const HeaderGoToResults = ({
  goToResultsRef, resultsCount, isLoading, resultsTitle, resultsIconSrc,
}) => {
  // show loader immediately
  // hide after it has been shown for at least MIN_PERIOD
  const isLoaderShown = useMinDurationTrue({ value: isLoading, timeout: MIN_PERIOD });

  return (
    <div
      className={styles.root}
      onClick={goToResultsRef.current}
    >
      <div className={cx(styles.resultsCount, { [styles.zeroOpacity]: isLoaderShown })}>
        {resultsCount}
      </div>
      <div className={cx(styles.loader, { [styles.hidden]: !isLoaderShown })}>
        {isLoaderShown && <Lottie animationData={LottieAnimation} loop />}
      </div>
      <span className={styles.resultsTitle}>
        {resultsTitle}
      </span>
      <div className={styles.resultsIconWrapper}>
        <img className={styles.resultsIcon} src={resultsIconSrc} alt="img" />
      </div>
    </div>
  );
};

HeaderGoToResults.propTypes = {
  goToResultsRef: PropTypes.exact({
    current: PropTypes.func.isRequired,
  }).isRequired,
  resultsCount: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  resultsTitle: PropTypes.string.isRequired,
  resultsIconSrc: PropTypes.string.isRequired,
};

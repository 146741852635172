import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import fileIcon from '../../assets/icons/file.svg';
import trashIcon from '../../assets/icons/trash.svg';
import CheckIcon from '../../assets/icons/check.svg';
import { LOADING_MAX_PERCENT, UPLOADER_STATES } from './FileUploaderConstants';
import { useTranslation } from '../../../utils/modifiedTranslation';

export const UploaderFile = (props) => {
  const {
    state, body, file, onremove, isUploadPopup,
  } = props;

  const { t } = useTranslation();

  const $bar = useRef(null);
  const $percent = useRef(null);

  const hasProgressBar = () => [
    UPLOADER_STATES.UPLOADING,
    UPLOADER_STATES.SUCCESS,
    UPLOADER_STATES.ERROR,
    isUploadPopup && UPLOADER_STATES.MULTI,
  ].includes(state);

  const timeout = useRef();

  useEffect(() => () => {
    clearTimeout(timeout.current);
  }, []);

  useEffect(() => {
    const doProgress = () => {
      timeout.current = setTimeout(() => {
        const currentPercent = Number($percent.current.innerHTML.replace('%', ''));
        if (currentPercent >= LOADING_MAX_PERCENT) return;
        const add = Math.floor(Math.random() * 7);
        let p = currentPercent + add;
        if (p >= LOADING_MAX_PERCENT) p = LOADING_MAX_PERCENT;
        $bar.current.style.width = `${p}%`;
        $percent.current.innerHTML = `${p}%`;
        doProgress();
      }, Math.random() * 850);
    };

    switch (state) {
      default:
        break;
      case UPLOADER_STATES.UPLOADING:
        $bar.current.style.width = '0%';
        $bar.current.style.transition = 'width 0.2s ease-out';
        $percent.current.innerHTML = '0%';
        doProgress();
        break;
      case UPLOADER_STATES.ERROR:
        $percent.current.innerHTML = '100%';
        $bar.current.style.width = '100%';
        break;
      case UPLOADER_STATES.SUCCESS:
        $percent.current.innerHTML = '100%';
        $bar.current.style.width = '100%';
        break;
      case UPLOADER_STATES.MULTI:
        $percent.current.innerHTML = '100%';
        $bar.current.style.width = '100%';
        break;
    }
  }, [state]);

  return (
    <div className={`files-uploader-preview ${file.error ? 'files-uploader-preview-error' : ''}`}>
      <div className="files-uploader-preview-header">
        <div>
          <div className="files-uploader-preview-icon">
            <img alt="delete" src={fileIcon} />
          </div>
          <div className="files-uploader-preview-text">
            {file.name}
          </div>
        </div>
        <div>
          {onremove ? (
            <div
              className="files-uploader-preview-remove"
              onClick={() => {
                onremove(file);
              }}
            >
              <img alt="delete" src={trashIcon} />
            </div>
          ) : null}
        </div>
        {
          (state === UPLOADER_STATES.SUCCESS && !file.error)
            ? <div className="ngs-analyze-files-header-status"><img alt="icon" src={CheckIcon} /></div>
            : null
        }
      </div>
      {
        hasProgressBar() ? (
          <div className="files-uploader-progress">
            <div className="files-uploader-progress-bar" ref={$bar} />
            <div className="files-uploader-progress-percent" ref={$percent}>0%</div>
          </div>
        ) : null
      }
      {file.error ? (
        <div className="files-uploader-preview-error-message">
          {t(file.error)}
        </div>
      ) : null}
      {body}
    </div>
  );
};

UploaderFile.propTypes = {
  state: PropTypes.any,
  body: PropTypes.any,
  file: PropTypes.any,
  onremove: PropTypes.any,
  isUploadPopup: PropTypes.bool,
};

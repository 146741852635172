import React from 'react';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import { useSetHeaderSettings } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { TAB_IDS } from 'new-ui/Components/PageHeader/constants';
import './Treatment.css';
import AiAssistant from 'new-ui/Components/AiAssistent';
import { isNewlyDiagnosedUser } from 'modules/user/utils';
import { Widgets } from './Widgets';
import { CustomerRefUserWidgets } from './CustomerRefUserWidgets';
import { FEED_GA } from './constants';

const FeedPage = () => {
  const { t } = useTranslation();

  useSetHeaderSettings({
    isProfileButtonShown: true,
    isBurgerMenuShown: true,
    isUploadButtonShown: true,
    tabIds: [
      TAB_IDS.FEED,
      TAB_IDS.TREATMENT_OPTIONS,
      TAB_IDS.SUPPORT_PROGRAMS,
      TAB_IDS.SYMPTOM_CARE,
      ...(isNewlyDiagnosedUser(app?.user) ? [TAB_IDS.ARTICLES] : []),
      TAB_IDS.SPEAK_WITH_TINA,
    ],
  });

  const welcome = () => `${t('feed.title.main')}${app.user.info.family_name ? `, ${app.user.info.family_name}!` : ''}`;

  const { personal: { customerRef } = {} } = app.user || {};

  return (
    <div className="feed-page">
      <h2 className="welcome welcome-mobile">{welcome()}</h2>
      <div className="feed-page-container">
        <h2 className="welcome">{welcome()}</h2>
        {customerRef ? (
          <CustomerRefUserWidgets />
        ) : (
          <Widgets />
        )}
      </div>
      <div className="feed-sidebar">
        <AiAssistant
          onUploadClick={() => {
            app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.fileUploadClicked);
          }}
          onEditClick={() => {
            app.sendGoogleAnalyticsEvent(FEED_GA.category, FEED_GA.events.answerManuallyClicked);
          }}
        />
      </div>
    </div>
  );
};

export default FeedPage;

import { getUserCondition } from 'modules/user/utils';
import { adjustUserForServer } from 'modules/hcp/helpers';
import { hcpApi } from 'modules/hcp/api';
import { TRIAL_TYPES } from 'utils/constants/trial';
import { ELIGIBILITY_STATUSES } from './constants';

export const getMatchedItemByUser = async ({ user, trialType, nctNumber }) => {
  let toFetch = user;
  if (getUserCondition(user)) {
    toFetch = await adjustUserForServer(user);
  }
  const matches = await hcpApi.getMatchesByUser(toFetch);
  if (trialType === TRIAL_TYPES.TREATMENT) {
    return matches.treatments.find(({ nct_number: treatmentNctNumber }) => treatmentNctNumber === nctNumber);
  }
  return matches.trials.find(({ nct_number: trialNctNumber }) => trialNctNumber === nctNumber);
};

export const getEligibilityStatus = (item) => {
  if (!item) return ELIGIBILITY_STATUSES.GONE;

  if (!item.missed_questions) {
    // should be invariant
    console.error('item.missed_questions is not defined');
  }

  return item.missed_questions?.length ? ELIGIBILITY_STATUSES.NOT_ELIGIBLE : ELIGIBILITY_STATUSES.ELIGIBLE;
};

import { INDICATIONS } from 'new-ui/constants';
import app from 'new-ui/app';
import { HCP_QUESTIONS, LOCATION_QUESTION, MOCK_QUESTIONS } from './constants';

const { GENERAL: GENERAL_CONDITION } = INDICATIONS;

export const getIntakeQuestions = async (indication) => {
  let questions = [...MOCK_QUESTIONS];
  if (indication) {
    const allQuestions = await app.getQuestions(indication, true);
    questions = allQuestions.filter(({ id: questionId }) => HCP_QUESTIONS[indication].includes(questionId));
  }
  questions.push(LOCATION_QUESTION);
  return questions;
};

export const getIntakeCustomData = ({ user, allQuestions }) => {
  let indication = user?.personal?.condition;
  if (indication === GENERAL_CONDITION) indication = null;
  return {
    sections: ['general', 'other'],
    user,
    questions: [],
    allQuestions: allQuestions.map((question) => {
      // eslint-disable-next-line no-param-reassign
      question.preventTitle = true;
      // eslint-disable-next-line no-param-reassign
      question.className = 'hcp-question';
      if (question.id === 'treatments') {
        // eslint-disable-next-line no-param-reassign
        question.show = true;
      }
      return question;
    }),
    questionsData: {
      general: allQuestions.map(({ id }) => id),
      other: [],
    },
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import { THEMES } from 'new-ui/constants';
import LinkButton from 'new-ui/Components/LinkButton';
import { ReactComponent as LoginIcon } from 'new-ui/assets/icons/login-button.svg';
import { ReactComponent as UserIcon } from 'new-ui/assets/icons/user2.svg';
import styles from './authButtonHeaderRightSlot.module.css';

export const AuthButtonHeaderRightSlot = ({ goToLogin }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <Button
        className={cx(styles.button, 'mobile-only')}
        titleClassName={styles.buttonLabel}
        theme={THEMES.GREY}
        title={t('general.login')}
        action={goToLogin}
        customIcon={<LoginIcon className={styles.buttonIcon} />}
      />
      <Button
        className={cx(styles.button, 'desktop-only')}
        theme={THEMES.GREY}
        title={t('general.login')}
        action={goToLogin}
        customIcon={<UserIcon className={styles.buttonIcon} />}
      />
      <LinkButton
        title={t('general.register')}
        action={() => {
          window.location = '/?newprofile=true';
        }}
        className={styles.linkButton}
      />
    </div>
  );
};

AuthButtonHeaderRightSlot.propTypes = {
  goToLogin: PropTypes.func.isRequired,
};

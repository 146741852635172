import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const CustomCheckbox = ({
  checked, onChange, onClick, label,
}) => (
  <label className="custom-checkbox-v2" onClick={onClick}>
    <input
      type="checkbox"
      checked={checked}
      onChange={onChange}
    />
    <span className="custom-checkbox-box" />
    {label && <span className="custom-checkbox-label">{label}</span>}
  </label>
);

CustomCheckbox.propTypes = {
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
};

export default CustomCheckbox;

import { treatmentFinderAction } from './constants';

const initialState = {
  isFetching: false,
  error: null,
  condition_profile: null,
  same_treatments: [],
  different_treatments: [],
  trials: [],
  treatment_groups: [],
  treatment_groups_description: {},
  treatment_groups_color: {},
};

export const treatmentFinder = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case treatmentFinderAction.GET_TREATMENT_FINDER_REQUEST:
      return {
        ...state,
        isFetching: true,
        error: null
      };
    case treatmentFinderAction.GET_TREATMENT_FINDER_SUCCESS:
      return {
        ...state,
        ...payload,
        isFetching: false,
        error: null
      };
    case treatmentFinderAction.GET_TREATMENT_FINDER_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: payload
      };
    default:
      return state;
  }
};

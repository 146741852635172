import {
  addDocLogs,
  createFileProcessingJob, getUserProfile, uploadFiles,
} from '../../components/MedicalFiles/api';
import { EXTRACTION_STRATEGY, JOB_STATUS } from '../../components/MedicalFiles/constants';
import { poll } from '../../components/MedicalFiles/utils';

export const uploadNGS = async ({
  files = [],
  getRecaptchaTokenAsync,
  onUpload,
  onError,
  extractionStrategy = EXTRACTION_STRATEGY.BIOMARKERS,
  indication,
  noUserProfileMerge,
}) => {
  try {
    const fileKeys = await uploadFiles(files.filter(({ error }) => !error), await getRecaptchaTokenAsync());
    const id = await createFileProcessingJob(
      fileKeys,
      extractionStrategy,
      await getRecaptchaTokenAsync(),
      indication,
    );

    await addDocLogs(
      files.map((it) => ({
        jobId: id, docName: it.name, docStatus: it?.error ? 'error' : 'success', failureReason: it.error,
      })),
    );

    const profile = await poll(
      () => getUserProfile(id, { noUserProfileMerge }),
      (job) => [JOB_STATUS.IN_PROGRESS, JOB_STATUS.UPDATED].includes(job.status),
      5000,
    );

    if (onUpload) {
      return onUpload(profile);
    }

    return profile;
  } catch (e) {
    console.log('upload file error', e);
    if (onError) {
      onError(e);
    }
  }
};

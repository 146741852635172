import React from 'react';
import PropTypes from 'prop-types';
import { refPropType } from 'utils/propTypes/refPropType';
import glassIcon from 'new-ui/assets/icons/glass-white.svg';
import UploadIcon from 'new-ui/Pages/Upload/assets/upload-files.svg';
import { ReactComponent as StarImg } from 'new-ui/assets/icons/star.svg';
import AnalyzeIcon from 'new-ui/assets/icons/analyze.svg';
import { DOM, getOverlay } from 'new-ui/functions';
import Button from 'new-ui/Components/Button';

export const SearchUploadNav = ({
  onFullProfileUpload, onBiomarkersUpload, t, searchFormContainerRef,
}) => {
  const openMobileForm = () => {
    const $overlay = getOverlay();
    const $form = document.querySelector('.hcp-form');
    document.body.appendChild($overlay);
    $overlay.classList.add('hcp-mobile-search');
    const $header = DOM('.search-form-mobile-header', {
      '/.search-form-mobile-header-title': {},
      '/.search-form-mobile-header-close': {
        innerHTML: 'X',
        onclick: () => {
          if (searchFormContainerRef.current) {
            searchFormContainerRef.current.appendChild($form);
          }
          $overlay.remove();
        },
      },
    });
    $overlay.append($header);
    $overlay.append($form);
  };

  return (
    <div className="upload-nav">
      <div className="info">
        <div className="result-list-mobile-header-options mobile-only">
          <div
            className="result-list-mobile-header-glass"
            onClick={() => {
              openMobileForm();
            }}
          >
            <img alt="" src={glassIcon} />
          </div>
        </div>
        <StarImg />
        <div className="desktop-only">{t('general.ai.assistant')}</div>
        <div className="buttons">
          <Button
            action={onFullProfileUpload}
            backgroundColor="#C77DFF"
            title={t('hcp.bar_upload_files')}
            icon={UploadIcon}
            className="upload-files desktop-only"
            width="210"
          />
          <Button
            action={onFullProfileUpload}
            backgroundColor="#C77DFF"
            title={t('hcp.bar_upload_files_mobile')}
            className="upload-files mobile-only"
            width="210"
          />
          <div className="or desktop-only">{t('general.or')}</div>
          <Button
            backgroundColor="#C77DFF"
            title={t('hcp.bar_ngs_mobile')}
            className="upload-files desktop-only"
            icon={AnalyzeIcon}
            width="210"
            action={onBiomarkersUpload}
          />
          <Button
            backgroundColor="#C77DFF"
            title={t('hcp.bar_ngs_mobile')}
            className="upload-files mobile-only"
            width="210"
            action={onBiomarkersUpload}
          />
        </div>
      </div>
    </div>
  );
};

SearchUploadNav.propTypes = {
  onBiomarkersUpload: PropTypes.func.isRequired,
  onFullProfileUpload: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  searchFormContainerRef: refPropType.isRequired,
};

import React, { useState } from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import { getNumberType } from 'new-ui/functions';
import { adjustTranslationKey } from '../helpers';
import { CheckEligibility } from './CheckEligibility';
import { questionPropTypes } from './questionPropTypes';

export const BoxNumberQuestion = ({ q, user, onselect }) => {
  const [value] = useState(q.getQuestionValue ? q.getQuestionValue(user) : (user?.condition_profile[q.id] || ''));
  const { t } = useTranslation();
  const numberType = getNumberType(q.numberType);
  return (
    <div className="hcp-input">
      <div className="hcp-input-title space-between">
        <div>
          {t(adjustTranslationKey(q.title))}
        </div>
        <div className="hcp-number-unit">{numberType.unit}</div>
      </div>
      <CheckEligibility />
      <input
        className="hcp-number-input"
        type="number"
        step={numberType.step}
        defaultValue={value}
        onChange={(ev) => {
          onselect(ev.target.value);
        }}
      />
    </div>
  );
};

BoxNumberQuestion.propTypes = questionPropTypes;

/* eslint-disable import/no-named-as-default */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './Patients.css';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import { useUpdatePageHeader, useSetHeaderSettings, useSetOnLogoClick } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { TAB_IDS, VERSIONS } from 'new-ui/Components/PageHeader/constants';
import { useHCPAccessControl } from 'modules/hcp/useHCPAccessControl';
import Preloader from 'new-ui/Components/Preloader';
import { startNewCurrentUser } from 'modules/hcp/actions/newCurrentUserCreate';
import { PAGES, ROUTES } from 'new-ui/constants';
import PatientsHeader from './Header';
import PatientsFooter from './footer';
import PatientsList from './Patientlist';
import { supplementPatients } from './api';
import { HeaderRightSlot } from './HeaderRightSlot/HeaderRightSlot';

const Patients = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { setHeaderRightSlot } = useUpdatePageHeader();
  const [patients, setPatients] = useState(null);
  const [indications, setIndications] = useState(null);
  const [indicationsMap, setIndicationsMap] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useHCPAccessControl();

  const {
    profile: { patients: rawPatients } = {},
  } = useSelector((state) => ({
    profile: state.profile,
  }));

  useSetHeaderSettings({
    version: VERSIONS.HCP,
    tabIds: [TAB_IDS.TREATMENT_OPTIONS],
  });

  useSetOnLogoClick(
    useCallback(() => {
      dispatch(startNewCurrentUser({
        popupTitle: t('save_profile.title'),
        redirectPath: ROUTES[PAGES.SEARCH],
      }));
      return false;
    }, [dispatch, t]),
  );

  useEffect(() => {
    setHeaderRightSlot(<HeaderRightSlot />);
    return () => {
      setHeaderRightSlot(null);
    };
  }, [setHeaderRightSlot]);

  useEffect(() => {
    app.intercom.hidden = true;
    setIsLoading(true);
    const fetch = async () => {
      let supplementedPatients = [];
      if (rawPatients?.length) {
        supplementedPatients = await supplementPatients(rawPatients);
      }

      let indications = await app.getIndications();
      indications = indications.map((i) => ({
        title: i.title.indexOf('disease_name') !== -1 ? t(i.title) : i.title,
        value: i.value,
      }));
      const indicationsMap = indications.reduce((acc, item) => {
        acc[item.value] = item;
        return acc;
      }, {});
      setIndications(indications);
      setIndicationsMap(indicationsMap);
      setPatients(supplementedPatients);
      setIsLoading(false);
    };
    fetch();
    // eslint-disable-next-line
  }, [rawPatients]);

  const handleSearchChange = (value) => {
    setSearchValue(value);
  };

  const filteredPatients = patients ? patients.filter((patient) => (
    !patient.info.family_name
    || patient.info.family_name.toLowerCase().includes(searchValue.toLowerCase())
  )) : [];

  return (
    <div className="patients-page">
      {isLoading ? <Preloader /> : (
        <>
          <PatientsHeader patient_count={filteredPatients?.length} onSearchPatient={handleSearchChange} />
          {patients && <PatientsList patients={filteredPatients} indications={indicationsMap} />}
          <PatientsFooter indications={indications} />
        </>
      )}
    </div>
  );
};

export default Patients;

import React, {
  useEffect,
} from 'react';
import './Treatment.css';
import { useSelector } from 'react-redux';
import { useTranslation } from 'utils/modifiedTranslation';
import { useUpdatePageHeader } from 'new-ui/Components/PageHeader/PageHeaderContext';

import PointsToDiscussWithOncologist from 'new-ui/Widgets/PointsToDiscuss';
import PatientsLikeYou from 'new-ui/Widgets/PatientsLikeYou';
import {
  ROUTES,
  PAGES,
  COLORS,
  POPUP_TYPES,
} from 'new-ui/constants';
import { fetchSponsoredTreatment } from 'new-ui/Treatment/api';
import { useParams, useHistory } from 'react-router-dom';
import app from 'new-ui/app';
import LinkButton from 'new-ui/Components/LinkButton';
import { urlToNct } from 'new-ui/functions';
import TreatmentItem from './Item';
import PublicationSection from './PublicationSection/PublicationSection';
import { TreatmentHeaderContent } from './TreatmentHeaderContent/TreatmentHeaderContent';

const Treatment = () => {
  const { nctNumber } = useParams();
  const { t } = useTranslation();
  // const [sponsoredTemplate, setSponsoredTemplate] = useState({});
  const history = useHistory();
  const { setHeaderContent } = useUpdatePageHeader();

  const {
    lang,
    currentUser,
  } = useSelector((state) => ({
    lang: state?.main?.lang,
    currentUser: state.currentUser.data,
  }));

  const treatment = [...(app.treatments || [])].find((i) => i.nct_number === urlToNct(nctNumber));
  app.treatment = treatment; // debugging purposes

  useEffect(() => {
    setHeaderContent(<TreatmentHeaderContent />);
    return () => {
      setHeaderContent(null);
    };
  }, [setHeaderContent]);

  useEffect(() => {
    if (!treatment) return;
    const getSponsoredTemplate = async () => {
      const treatmentData = await fetchSponsoredTreatment(treatment.nct, treatment.nct_number);

      app._lastFetchedTreatmentData = {
        treatmentData,
      };
    };

    getSponsoredTemplate();
  }, [lang, nctNumber, treatment]);

  if (!treatment) {
    history.push(ROUTES[PAGES.RESULTS]);
    return null;
  }

  const treatmentFooter = () => {
    const links = treatment.link_to_package_insert?.split('\n');
    return (links && links.length
      ? (
        <div className={`treatment-footer ${links.length !== 1 ? 'multi' : ''}`}>
          {
            links.length === 1 ? (
              <LinkButton color={COLORS.primaryButton} title={links[0]} href={links[0]} />
            ) : (
              <LinkButton
                color={COLORS.primaryButton}
                title={t('match.list.item.action.drug_info')}
                action={() => {
                  app.setPopup(POPUP_TYPES.GENERAL, {
                    title: t('match.list.item.action.more_info'),
                    content: (
                      <div className="multi-links">
                        {links.map((link) => <div><LinkButton color={COLORS.primaryButton} title={link} href={link} /></div>)}
                      </div>
                    ),
                  });
                }}
              />
            )
          }
        </div>
      ) : null
    );
  };

  return (
    <div className="treatment">
      <div className="wrapper">
        <TreatmentItem
          isFull
          item={treatment}
          footer={treatmentFooter}
          type="list"
          friendlyTitle={treatment.title ?? null}
          user={currentUser ?? app.user}
          // title={(sponsoredTemplate?.HeaderTitle && sponsoredTemplate?.HeaderTitle[0]) || null}
          // logoSrc={sponsoredTemplate?.Logo}
        />
        <div className="height-20" />
        {/* <div className="height-20" /> */}
        <div className="height-20" />
        <PatientsLikeYou treatment={treatment} />
        <div className="padding-x-y">
          {treatmentFooter()}
        </div>

        {
          treatment.points_to_discuss_with_your_oncologist
            ? <PointsToDiscussWithOncologist treatment={treatment} /> : null
        }
        <div>
          <PublicationSection />
        </div>

        {/* {renderActions('mobile')} */}
        {/* <div className="height-20" /> */}
        {/* <TrialDetails trial={trial} content={sponsoredTemplate.Description ? sponsoredTemplate.Description : null} /> */}
        {/* {renderActions('desktop')} */}
      </div>
    </div>
  );
};

export default Treatment;

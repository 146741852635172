import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import styles from './newlyMatchedLabel.module.css';

export const NewlyMatchedLabel = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      {t('list_item.label.new')}
    </div>
  );
};

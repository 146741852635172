import { useEffect } from 'react';
import { push } from 'connected-react-router';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { PAGES, ROUTES } from 'new-ui/constants';
import useAuth from 'utils/hooks/useAuth';
import { getIsPatientUser } from './helpers';

export const useHCPAccessControl = ({
  redirectPath,
} = {}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useAuth();
  const {
    profile,
  } = useSelector((state) => ({
    profile: state.profile,
  }), shallowEqual);

  useEffect(() => {
    if (isAuthenticated && profile && getIsPatientUser(profile)) {
      dispatch(push(redirectPath ?? ROUTES[PAGES.INTAKE]));
    }
  }, [dispatch, isAuthenticated, profile, redirectPath]);
};

import React from 'react';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import { uploadNGS } from 'new-ui/NGS/api';
import { NGSForm } from './NGSForm';

export const CurrentUserNGSPopup = ({
  onSuccess,
  condition,
  onUploadedFilesChange,
}) => {
  const { t } = useTranslation();

  const uploadFiles = ({
    files,
    getRecaptchaTokenAsync,
    onUpload,
    onError,
  }) => {
    uploadNGS({
      files,
      getRecaptchaTokenAsync,
      onUpload,
      onError,
      indication: condition,
      noUserProfileMerge: true,
    });
  };

  const handleUploadedFiles = (files, { jobId }) => onUploadedFilesChange(
    // to get rid of file blob
    files.map((file) => pick(file, ['name', 'storageName'])),
    { jobId, source: 'ngs' },
  );

  return (
    <NGSForm
      title={t('ngs.extracting_biomarkers')}
      generatingText={t('ngs.generating_report')}
      uploadText={t('ngs.upload_text')}
      uploadFiles={uploadFiles}
      onSuccess={onSuccess}
      onUploadedFilesChange={handleUploadedFiles}
    />
  );
};

CurrentUserNGSPopup.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  condition: PropTypes.string,
  onUploadedFilesChange: PropTypes.func.isRequired,
};

import React from 'react';
import cx from 'classnames';
import './Button.css';

import { COLORS } from 'new-ui/constants';
import PropTypes from 'prop-types';

import ArrowCircle from 'new-ui/assets/icons/arrow-circle.svg';

const Button = (props) => {
  const {
    backgroundColor,
    color,
    title,
    action,
    width,
    className,
    titleClassName,
    disabled,
    icon,
    svg,
    theme,
    forwardIconArrow = false,
    endIcon,
    maxWidth,
    customIcon,
  } = props;
  let {
    forwardIcon,
  } = props;
  if (forwardIconArrow) forwardIcon = ArrowCircle;
  const style = {
    backgroundColor: backgroundColor || COLORS.primaryButton,
    color: color || COLORS.primaryButtonText,
    width: width || '100%',
  };
  if (maxWidth) style.maxWidth = maxWidth;
  return (
    <button
      type="button"
      className={`button ${className || ''} ${icon && 'icon-button'} ${theme ? `${theme}-theme` : ''} ${forwardIconArrow ? 'icon-arrow' : ''}`}
      style={style}
      onClick={action}
      disabled={disabled}
    >
      {icon ? <img src={icon} alt="icon" /> : null}
      {customIcon || null}
      {svg || null}
      <span className={cx('button-text', titleClassName)}>
        {title}
      </span>
      {forwardIcon ? <img className="forward-icon" src={forwardIcon} alt="icon" /> : null}
      {endIcon ? <img src={endIcon} alt="icon" /> : null}
    </button>
  );
};

Button.propTypes = {
  backgroundColor: PropTypes.string,
  color: PropTypes.string,
  title: PropTypes.string,
  action: PropTypes.func,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  customIcon: PropTypes.any,
  icon: PropTypes.any,
  svg: PropTypes.any,
  forwardIcon: PropTypes.any,
  forwardIconArrow: PropTypes.any,
  theme: PropTypes.string,
  endIcon: PropTypes.string,
};

export default Button;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import { getItemType } from 'new-ui/functions';

export const MissingCriticalButton = ({ hasMissingCritical, item }) => {
  const { t } = useTranslation();

  const { isSponsored } = item;

  const onClick = () => {
    if (!hasMissingCritical) {
      if (isSponsored) {
        app.setPopup(POPUP_TYPES.CONSULT_WITH_US);
        return;
      }
      app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR, { type: getItemType(item) });
      return;
    }
    app.setPopup(POPUP_TYPES.MISSING_CRITICAL_INFORMATION, {
      trial: item,
    });
  };

  return (
    <div
      role="none"
      title={t(`trial.${hasMissingCritical ? 'missing_critical_information' : `${isSponsored ? 'speak_with_your_oncologist' : 'speak_with_us'}`}`)}
      className={`missing-questions missing-questions-${hasMissingCritical ? 'pending' : 'ready'}`}
      onClick={onClick}
    >
      <div className="missing-questions-text">
        <div className="missing-questions-text-subtitle">
          {t(`trial.${hasMissingCritical ? 'missing_critical_information' : `${isSponsored ? 'speak_with_your_oncologist' : 'speak_with_us'}`}`)}
        </div>
      </div>
    </div>
  );
};

MissingCriticalButton.propTypes = {
  hasMissingCritical: PropTypes.bool.isRequired,
  item: PropTypes.shape({
    isSponsored: PropTypes.bool,
  }).isRequired,
};

import React, { useState } from 'react';
import { Switch, useLocation } from 'react-router-dom';
import { PageHeader } from 'new-ui/Components/PageHeader/PageHeader';
import { PageHeaderProvider } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { CombinedProviders } from 'components/CombinedProviders/CombinedProviders';
import app from 'new-ui/app';

import PrivateRoute from 'new-ui/Components/PrivateRoute';
import PublicRoute from 'new-ui/Components/PublicRoute';
import { URI, PAGES, POPUP_TYPES } from 'new-ui/constants';

import { getLoggedUser, isPublicRoute } from 'new-ui/functions';

import DashboardFooter from 'new-ui/Footer';
import DashboardPopups from 'new-ui/Popups';
import Alert from 'new-ui/Components/Alert';

import ScrollToTop from 'new-ui/Components/ScrollToTop';
import Preloader from 'new-ui/Components/Preloader';

import Search from 'new-ui/Search';

import Results from 'new-ui/Results';
import Trial from 'new-ui/Trial';
import Treatment from 'new-ui/Treatment';
import PublicTrial from 'new-ui/Trial/PublicTrial';
import PublicTreatment from 'new-ui/Treatment/PublicTreatment';
import IntakePage from 'new-ui/Pages/Intake';

import Login from 'new-ui/Login';
import ForgotPassword from 'new-ui/Login/forgot-password';
import ResetPassword from 'new-ui/Login/reset-password';

import useForceUpdate from 'use-force-update';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Maintenance from 'new-ui/Components/Maintenance';
import Crash from 'new-ui/Components/Crash';
import Upload from 'new-ui/Pages/Upload';
import FinancePage from 'new-ui/Pages/Finance';
import Patients from 'new-ui/Pages/Patients/Patients';
import FeedPage from 'new-ui/Pages/Feed';
import ArticlesPage from 'new-ui/Pages/Articles';
import ChatButton from 'new-ui/ChatButton';
import FinanceIntake from 'new-ui/Pages/FinanceIntake';
// import configs from 'configs';
import ChatPage from 'new-ui/Pages/Chat';

const pageProviders = [
  { provider: PageHeaderProvider },
];

let loading = false;
// const isProductionEnv = configs.isProduction;
const Dashboard = () => {
  const location = useLocation();
  const [showPreloader, setShowPreloader] = useState(false);
  const [appCrashed, setAppCrashed] = useState(false);
  const [init, setInit] = useState(!!app.inited);
  const history = useHistory();

  app.setShowPreloader = setShowPreloader;
  app.setAppCrashed = setAppCrashed;
  app.isPublic = isPublicRoute();
  app.render = useForceUpdate();
  app.history = useHistory();

  if (!app.inited) app.init(setInit);

  const params = new URLSearchParams(window.location.search);

  if (params.get('signup')) app.isSignup = true;

  const getData = async () => {
    if (loading) return;
    loading = true;
    if (!app.user) {
      if (!showPreloader) setShowPreloader(true);
      await app.setUser();
      if (app.user) setShowPreloader(false);
    }
    app.render();
    await app.update();
    loading = false;
  };

  if ((!app.isPublic && !app.user && getLoggedUser()) || params.get('update')) getData();

  const isTreatmentPage = history.location.pathname.includes('treatment');

  return init ? (
    <div className={`app new-ui app-${window.location.pathname.slice(1)}`}>
      <ScrollToTop />
      <Alert />
      <Preloader isVisible={showPreloader} />
      <Crash isVisible={appCrashed} />
      <div>
        <CombinedProviders providers={pageProviders}>
          <PageHeader isProviderMode={!!app.coniguration?.providerSpecific?.code} />
          <main className={`main-${isTreatmentPage ? 'treatment' : window.location.pathname.slice(1)}`}>
            <Switch>
              <PrivateRoute path={URI[PAGES.RESULTS]} component={Results} exact />
              <PrivateRoute path={URI[PAGES.SUMMARY]} redirect={URI[PAGES.RESULTS]} exact />
              <PrivateRoute path={URI[PAGES.TRIAL]} component={Trial} exact />
              <PrivateRoute path={URI[PAGES.TREATMENT]} component={Treatment} exact />
              <PrivateRoute path={URI[PAGES.INTAKE]} component={IntakePage} exact />
              <PublicRoute path={URI[PAGES.FINANCE]} preventRedirect component={FinancePage} />
              <PrivateRoute path={URI[PAGES.FEED]} component={FeedPage} />
              <PrivateRoute path={URI[PAGES.ARTICLES]} component={ArticlesPage} />
              <PrivateRoute path={URI[PAGES.CHAT]} component={ChatPage} />
              <PublicRoute path={URI[PAGES.FINANCE_INTAKE]} component={FinanceIntake} preventRedirect />
              <PrivateRoute path={URI[PAGES.PATIENTS]} component={Patients} />
              <PublicRoute path={URI[PAGES.FORGOT_PASSWORD]} component={ForgotPassword} exact />
              <PublicRoute path={URI[PAGES.CREATE_PASSWORD]} component={ForgotPassword} exact />
              <PublicRoute path={URI[PAGES.NEW_PASSWORD]} component={ResetPassword} exact />
              <PublicRoute path={URI[PAGES.LOGIN]} component={Login} />
              <PublicRoute path={URI[PAGES.SEARCH]} component={Search} preventRedirect exact />
              <PublicRoute path={URI[PAGES.UPLOAD]} component={Upload} preventRedirect exact />
              <PublicRoute path={URI[PAGES.MAINTENANCE]} component={Maintenance} preventRedirect exact />
              <PublicRoute path={URI[PAGES.PUBLIC_TREATMENT]} preventRedirect component={PublicTreatment} />
              <PublicRoute path={URI[PAGES.PUBLIC_FINANCE]} preventRedirect component={FinancePage} />
              {[URI[PAGES.PUBLIC_TRIAL], URI[PAGES.PUBLIC_TRIAL_WITH_CONDITION]].map(
                (uri) => <PublicRoute key={uri} path={uri} preventRedirect component={PublicTrial} exact />,
              )}
            </Switch>
          </main>
        </CombinedProviders>
        <DashboardPopups />
        <DashboardFooter />
        {
          app?.user?.personal && location.pathname !== '/ai_profile' && location.pathname === '/dashboard/feed' ? (
            <ChatButton popupType={POPUP_TYPES.CHAT_RETURN_USER} text="Speak with Tina" />
          ) : app?.user?.personal && location.pathname !== '/ai_profile' && location.pathname.startsWith('/dashboard/results') ? <ChatButton /> : null
        }
      </div>
    </div>
  ) : null;
};

export default Dashboard;

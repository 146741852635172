import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import { QUESTION_TYPES, QuestionTitle } from '../../index';
import './TreatmentQuestion.css';
import Button from 'new-ui/Components/Button';
import HCPSelect from 'new-ui/Components/HCP/Select';
import { treatmentForIndicationQuestionIds } from 'new-ui/api/data/cancerTypes';
import { randomString } from 'new-ui/functions';
import { THEMES } from 'new-ui/constants';
import InfoNote from 'new-ui/Components/InfoNote';
import classNames from 'classnames';

export const getCleanedTreatments = (treatments)=>{
  return treatments?.filter((treatment) => treatment.drugs_received?.length);
};

const getInitialTreatment = (treatments=[]) => {
  return treatments.filter(item => item.drugs_received === null)
};

const TreatmentQuestion = (props) => {
  const { q, onselect, user } = props;
  const { t } = useTranslation();

  /* eslint-disable-next-line */
  const [showSubquestions, setShowSubquestions] = useState(user?.condition_profile?.treatments?.length > 0);
  const [treatments, setTreatments] = useState(user?.condition_profile?.treatments?.filter(item => item.drugs_received !== null) || []);
  const [isSaveVisible, setSaveVisible] = useState(true);

  const drugsReceived = q.subQuestions.find((sq)=>sq.id === 'drugs_received');

  const treatmentFor = treatmentForIndicationQuestionIds[user.personal.condition];

  // const shouldShowNotReceived = ()=>{
  //   return !treatments[0].drugs_received?.length ||user?.condition_profile[treatmentFor] !== 'no';
  // };

  const scrollToLastItem = () => {
    const container = document.querySelector('.question-renderer');
    var allQuestions = container.querySelectorAll('.treatment-question');
    var lastItem = allQuestions[allQuestions.length - 1];
    if(lastItem) {
      lastItem.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  useEffect(()=>{
    addTreatment();
    // eslint-disable-next-line
  }, []);

  const newTreatment = ()=>{
    return {
      drugs_received: null,
      treatment_admin: null,
      treatment_end_date: null,
      treatment_stop_reason: null,
    };
  };

  const addTreatment = ()=>{
    const _newTreatment = newTreatment();
    treatments.push(_newTreatment);
    const newTreatments = [...treatments];
    setTreatments(newTreatments);

    if(treatments?.length > 1) {
      setTimeout(() => {
        scrollToLastItem();
      }, 0);
    }
  };

  const TreatmentSubQuestion = (subQuestion, treatment, idx, user)=>{
    return (
      <div key={randomString()} className="box-question-treatment-section">
        <div className="box-question-treatment-title">
          {t(subQuestion.title)}
        </div>
        <div className="box-question-treatment-select">
          <HCPSelect
            user={user}
            showAutofill={false}
            options={subQuestion.options?.map((b)=>{
              return {
                ...b,
                label: t(b.label),
              };
            })}
            renderOnSelect={false}
            initValue={
              treatments[idx][subQuestion.id]
            }
            onselect={(newValue)=>{
              treatment[subQuestion.id] = newValue;
              user.condition_profile.treatments = treatments;
              onselect(getCleanedTreatments(treatments), true);
            }}
          />
        </div>
      </div>
    );
  };

  const DISABLED_QUESTIONS = [
    'drugs_received',
  ];

  const getSubQuestions = ()=>{
    return q.subQuestions.filter((sq)=>{
      if (sq.type !== QUESTION_TYPES.SINGLE_OPTION_SELECT) return false;
      if (sq.isShown === false) {
        // DEV-3814 Questions for internal usage should not be shown
        return false;
      }
      if (DISABLED_QUESTIONS.includes(sq.id)) return false;
      return true;
    });
  };

  const TreatmentQuestion = ({treatment, idx})=>{
    return (
        <div className="treatment-question">
          <QuestionTitle q={{
            title: !idx ? t('treatment_question.title') : (treatment.isLast && !treatment.drugs_received?.length) ? t('treatment_question.add_treatment') : `${t('general.other_treatment')} ${idx}`,
            subtitle: !idx ? t('treatment_question.subtitle') : t('treatment_question.treatment_drugs'),
          }} />
          {
            <div className="treatment-question-content">
            <div className="treatment-question-drugs-received">
                <div className="treatment-question-drugs-received-select">
                    <HCPSelect
                        defaultOpen={false}
                        user={user}
                        isMulti
                        useTags
                        renderOnSelect={false}
                        initValue={
                            treatment.drugs_received
                        }
                        options={drugsReceived.options.map((o)=>{
                          return {
                            label: t(o.label),
                            value: o.value,
                          };
                        })}
                        onselect={(v)=>{
                          treatment.drugs_received = v;
                          user.condition_profile.treatments = treatments;
                          setTreatments([...treatments]);
                          onselect(getCleanedTreatments(treatments), true);
                        }}
                    />
                </div>
              {
                treatments[idx]?.drugs_received?.length > 1 ? (
                  <InfoNote info={t('intake.note_description')} />
                ) : null
              }
                {
            !idx && !treatment.drugs_received?.length ? (
                <div className="treatment-question-drugs-received-not-received" onClick={()=>{
                    user.condition_profile[treatmentFor] = 'no';
                    user.condition_profile.treatments = [];
                    onselect(getCleanedTreatments(treatments), true);
                    setTreatments(getInitialTreatment(treatments));
                }}>
                    <div className={classNames("treatment-question-drugs-received-not-received-radio", {
                        selected: user.condition_profile[treatmentFor] === 'no'
                    })}>
                        <div className='received-not-received-radio-circle'></div>
                    </div>
                    <div className="treatment-question-drugs-received-not-received-text">{t('treatment_question.not_received')}</div>
                </div>
                ) : null
                }
            </div>
            <div className="treatment-question-treatment-subquestions">
                {
                treatment.drugs_received?.length
                    ? getSubQuestions().map((sq)=>{
                        return TreatmentSubQuestion(sq, treatment, idx, user);
                    })
                    : null
                }
            </div>
          </div>
          }
        </div>
      );

  };

  return <>
    {
      treatments.map((treatment, idx)=>{
        treatment.isLast = false;
        if(treatments[treatments.length-1] === treatment) treatment.isLast = true;
        return <TreatmentQuestion key={idx} treatment={treatment} idx={idx} />
      })
    }
    <div>
        {
          ((treatments.length > 1 || (treatments.length === 1 && treatments[0]?.drugs_received?.length)) && treatments[treatments.length - 1]?.drugs_received?.length) ? (
            !isSaveVisible ? (
              <Button 
                className='treatment-question-add-treatment' 
                title={t('treatment_question.add_treatment')} 
                theme={THEMES.ORANGE_TRANSPARENT} 
                action={() => {
                  addTreatment();
                  setSaveVisible(true);
                }} 
              />
            ) : (
              <Button 
                className='' 
                title={t('general.save')}
                theme={THEMES.DEFAULT} 
                action={() => {
                  setSaveVisible(false);
                }} 
              />
            )
          ) : null
        }
        <Button className='treatment-question-next' title={t('general.next')} theme={THEMES.ORANGE_FULL} action={()=>{
            onselect(getCleanedTreatments(treatments));
        }} />
    </div>
</>;

};

TreatmentQuestion.propTypes = {
  q: PropTypes.shape({
    title: PropTypes.string,
    subtitle: PropTypes.string,
    options: PropTypes.array,
  }),
  onselect: PropTypes.func,
  value: PropTypes.any,
};

export default TreatmentQuestion; 
export const USER_TYPES = {
  PATIENT: 'patient',
  CAREGIVER: 'caregiver',
  DOCTOR: 'doctor',
  OTHER: 'other',
  PATIENT_NAVIGATOR: 'patient_navigator',
  MEDICAL_TEAM: 'medical_team',
};

export const PLATFORM = {
  HCP: 'HCP',
};

export const USER_SOURCE = {
  HCP: 'HCP',
  PT_UPLOAD_FILE: 'Pt Upload file',
};

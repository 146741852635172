import * as Sentry from '@sentry/browser';
import axios from 'axios';
// import getHeaders from '../functions/api/getHeaders';

window.__network = [];

window.addEventListener('keydown', (event) => {
  if (event.ctrlKey && event.key === 'q') {
    try {
      const jsonString = JSON.stringify(window.__network);
      navigator.clipboard.writeText(jsonString)
        .then(() => {
          console.log('network copied into clipboard');
        })
        .catch((error) => {
          console.error('Failed to copy network to clipboard:', error);
        });
    } catch (e) {
      console.error('failed to copy network', e);
    }
  }
});

const restMethods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

const catchBySentry = (ex) => {
  const sentryData = ex.response
    ? {
      code: ex.response.status,
      message: ex.response.statusText,
      data: ex.response.data,
    }
    : {
      code: ex.status || ex.statusCode || 500,
      message: ex.message || 'Request has failed',
      data: null,
    };

  Sentry.captureException(sentryData);
};

/**
 * Sends request
 *
 * @example
 *```
 * request.post({
 *   url: 'https://mysite.com',   // (required) base URL to which it's needed to send request
 *   headers: {                   // (optional) custom headers
 *     'x-request-id': 'qwerty'
 *   },
 *   data: {                      // (optional) body data; it's not reachable fot GET method
 *     firstName: 'John',
 *     lastName: 'Foo'
 *   },
 *   qs: {                        // (optional) query string parameters
 *     type: 'operators'
 *   },
 *   auth: {                      // (optional) username and password for Basic authorization
 *     username: 'someuser',
 *     password: '12345678'
 *   },
 *   isStream: true,              // (optional) set to "true" if you need to get with response as stream
 *   responseEncoding: 'utf8',    // (optional) response encoding to which response data will be converted
 *   json: true                   // (optional) set to "true" if it's needed to add "Content-Type: application/json" header; it's not reachable fot GET method
 * });
 * ```
 *
 * @param {string} method One from: GET, POST, PUT, PATCH, DELETE
 * @param {Object} params (see above)
 * @returns {Promise<null|any>}
 */
const req = async (method = restMethods.GET, params = {}) => {
  if (!params || typeof params.url !== 'string' || !params.url.length || !Object.values(restMethods).includes(method)) {
    console.error(64, 'Wrong request params');
    return null;
  }

  let headers = {};
  const options = {
    url: params.url.endsWith('/') ? params.url.substring(0, params.url.length - 1) : params.url,
    method,
    timeout: params.timeout ?? 300000,
    signal: params.signal,
  };

  // add query string parameters to URL
  if (params.qs && typeof params.qs === 'object') {
    const queryString = Object.keys(params.qs)
      .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params.qs[key])}`)
      .join('&');

    options.url = `${options.url}?${queryString}`;
  }

  if (params.rawQs && typeof params.rawQs === 'string') {
    options.url = `${options.url}${params.rawQs}`;
  }

  // add headers to request content
  if (params.headers && typeof params.headers === 'object') {
    headers = params.headers;
  }

  // add "application/json" header if it's enabled. Only for non-GET method
  if (params.json === true && method !== restMethods.GET) {
    Object.assign(headers, {
      'Content-Type': 'application/json',
    });
  }

  if (Object.keys(headers).length) {
    Object.assign(options, {
      headers,
    });
  }

  // add body to request content. Only for non-GET method
  if (params.data && typeof params.data === 'object' && method !== restMethods.GET) {
    Object.assign(options, {
      data: params.data,
    });
  }

  // add basic authorization parameters to request content
  if (
    params.auth
    && typeof params.auth === 'object'
    && typeof params.auth.username === 'string'
    && typeof params.auth.password === 'string'
    && params.auth.username.length > 0
    && params.auth.password.length > 0
  ) {
    Object.assign(options, {
      auth: {
        username: params.auth.username.trim(),
        password: params.auth.password.trim(),
      },
    });
  }

  // set postprocessing response body with exact encoding
  if (typeof params.responseEncoding === 'string') {
    options.responseEncoding = params.responseEncoding;
  }

  // set response type as stream to use response with piping
  if (params.isStream === true) {
    options.responseType = 'stream';
  } else if (params.isBlob === true) {
    options.responseType = 'blob';
  }

  let response = null;

  const networkItem = {
    options,
    method,
    params,
  };

  try {
    response = await axios(options);
    networkItem.response = response;
    window.__network.push(networkItem);
  } catch (error) {
    networkItem.error = error;
    window.__network.push(networkItem);
    catchBySentry(error);
    throw error;
  }

  return response && response.hasOwnProperty('data') ? response.data : response;
};

export default {
  get: (params) => req(restMethods.GET, params),
  post: (params) => req(restMethods.POST, params),
  put: (params) => req(restMethods.PUT, params),
  patch: (params) => req(restMethods.PATCH, params),
  del: (params) => req(restMethods.DELETE, params),
};

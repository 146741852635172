import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ReactComponent as SupportIcon } from 'new-ui/assets/icons/support.svg';
import { ReactComponent as TreatmentIcon } from 'new-ui/assets/icons/treatment_icon.svg';
import { ReactComponent as HouseIcon } from 'new-ui/assets/icons/house.svg';
import { ReactComponent as TinaIcon } from 'new-ui/assets/icons/tina.svg';
import { PAGES, QUERY_PARAMS, URI } from 'new-ui/constants';
import app from 'new-ui/app';
import { RESULT_LIST_TABS } from 'new-ui/Results/ResultList';
import { GA_HCP } from 'new-ui/Search/GA';
import { useTranslation } from 'utils/modifiedTranslation';
import { preserveQueryParam } from 'functions/preserveQueryParam';
import configs from 'configs';
import { createUserFinance } from 'new-ui/Pages/Finance/createUserFinance';
import { VERSIONS, TAB_IDS } from './constants';
import styles from './headerTabs.module.css';

const {
  DEFAULT: DEFAULT_VERSION,
  HCP: HCP_VERSION,
} = VERSIONS;

const SUPPORTED_VERSIONS = [DEFAULT_VERSION, HCP_VERSION];

const getIsHcp = () => (
  app.isCurrentPage(PAGES.PUBLIC_FINANCE) || app.isCurrentPage(PAGES.SEARCH) || app.isCurrentPage(PAGES.PATIENTS)
);

const getIsSearchPage = () => (
  app.isCurrentPage(PAGES.PUBLIC_FINANCE) || app.isCurrentPage(PAGES.SEARCH)
);

const GA = {
  category: 'Finance',
  events: {
    clickFinancialTab: 'Clicking on the “Financial support” tab',
    clickTreatmentTab: 'Clicking on the “Treatment options” tab',
    clickSymptomCareTab: 'Clicking on the “Symptom Care” tab',
    clickArticlesTab: 'Clicking on the “Articles” tab',
  },
};

const GA_CHAT = {
  category: 'Chat',
  events: {
    clickSpeakWithTinaTab: 'Clicking on the “Speak with Tina” tab',
  },
};

const GET_IS_TAB_ACTIVE_BY_TAB_ID = {
  [TAB_IDS.FEED]: () => app.isCurrentPage(PAGES.FEED),
  [TAB_IDS.TREATMENT_OPTIONS]: () => (
    ((getIsSearchPage() || getIsHcp()) && app.isCurrentPage(PAGES.SEARCH)) || app.isCurrentPage(PAGES.RESULTS)
  ),
  [TAB_IDS.SUPPORT_PROGRAMS]: () => (
    ((getIsSearchPage() || getIsHcp()) && app.isCurrentPage(PAGES.PUBLIC_FINANCE)) || app.isCurrentPage(PAGES.FINANCE)
  ),
  [TAB_IDS.SPEAK_WITH_TINA]: () => app.isCurrentPage(PAGES.CHAT),
  [TAB_IDS.ARTICLES]: () => app.isCurrentPage(PAGES.ARTICLES),
};

const CLASS_BY_VERSION = {
  [DEFAULT_VERSION]: styles.defaultTabs,
  [HCP_VERSION]: styles.hcpTabs,
};

const gotoTab = (tabName) => {
  if (app.isCurrentPage(PAGES.RESULTS)) {
    app.setTab(tabName);
  } else {
    app.history.push(`${URI[PAGES.RESULTS]}?tab=${tabName}`);
  }
};

const getIsTabActive = (tabId) => (GET_IS_TAB_ACTIVE_BY_TAB_ID[tabId] ?? (() => false))();

export const HeaderTabs = ({
  version, tabIds, isMobileMenuOpen, onMenuItemClick,
}) => {
  const isHcp = getIsHcp();
  const isSearchPage = getIsSearchPage();

  const { t } = useTranslation();

  if (!SUPPORTED_VERSIONS.includes(version)) {
    return null;
  }

  const { personal: { customerRef } = {} } = app.user || {};

  return (
    <div className={cx(styles.root, CLASS_BY_VERSION[version], { [styles.mobileMenuOpen]: isMobileMenuOpen })}>
      {tabIds.includes(TAB_IDS.FEED) && (
        <div
          className={cx(styles.tab, { [styles.activeTab]: getIsTabActive(TAB_IDS.FEED) })}
          onClick={() => {
            app.history.push(URI[PAGES.FEED]);
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickFinancialTab, { name: 'Feed tab clicked' });
            onMenuItemClick();
          }}
        >
          <div className={styles.tabIcon}>
            <HouseIcon />
          </div>
          <div className={styles.tabTitle}>
            {t('general.feed')}
          </div>
        </div>
      )}
      {!customerRef && tabIds.includes(TAB_IDS.TREATMENT_OPTIONS) && (
        <div
          className={cx(styles.tab, { [styles.activeTab]: getIsTabActive(TAB_IDS.TREATMENT_OPTIONS) })}
          onClick={() => {
            if (app.isCurrentPage(PAGES.FINANCE) && !app.user) {
              createUserFinance({
                redirectTo: `${URI[PAGES.RESULTS]}?rematch=1`,
                subtitle: t('support_programs.create_user_subtitle_header'),
              });
              onMenuItemClick();
              return;
            }
            if (isSearchPage || isHcp) {
              app.history.push(preserveQueryParam(URI[PAGES.SEARCH], {
                pick: [QUERY_PARAMS.USER_TYPE],
              }));
              app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.TREATMENTS_TAB_CLICKED);
            } else {
              gotoTab(RESULT_LIST_TABS.TRIALS);
            }
            onMenuItemClick();
          }}
        >
          <div className={styles.tabIcon}>
            <TreatmentIcon />
          </div>
          <div
            className={styles.tabTitle}
            onClick={() => {
              app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickTreatmentTab, { name: 'Treatment options tab' });
            }}
          >
            {t('general.treatment_options')}
          </div>
        </div>
      )}
      {tabIds.includes(TAB_IDS.SUPPORT_PROGRAMS) && (
        <div
          className={cx(styles.tab, { [styles.activeTab]: getIsTabActive(TAB_IDS.SUPPORT_PROGRAMS) })}
          onClick={() => {
            if (isSearchPage || isHcp) {
              app.history.push(preserveQueryParam(URI[PAGES.PUBLIC_FINANCE], {
                pick: [QUERY_PARAMS.USER_TYPE],
              }));
              app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.FINANCE_TAB_CLICKED);
            } else {
              app.history.push(URI[PAGES.FINANCE]);
            }
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickFinancialTab, { name: 'Financial support tab clicked' });
            onMenuItemClick();
          }}
        >
          <div className={styles.tabIcon}>
            <SupportIcon />
          </div>
          <div className={styles.tabTitle}>
            {t('general.support_programs')}
          </div>
        </div>
      )}

      {tabIds.includes(TAB_IDS.SYMPTOM_CARE) && (
        <div
          className={cx(styles.tab)}
          onClick={() => {
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickSymptomCareTab, { name: 'Symptom Care tab clicked' });
            const condition = app.user?.personal?.condition;
            const url = new URL(`${configs.sideEffectsUrl}/search`);
            if (condition) {
              url.searchParams.append('cancer_type', condition);
            }
            url.searchParams.append('user', 'registered');
            window.open(url.toString(), '_blank');
            onMenuItemClick();
          }}
        >
          <div className={styles.tabIcon}>
            <TreatmentIcon />
          </div>
          <div className={styles.tabTitle}>{t('general.symptom_care')}</div>
        </div>
      )}

      {tabIds.includes(TAB_IDS.ARTICLES) && (
        <div
          className={cx(styles.tab, { [styles.activeTab]: getIsTabActive(TAB_IDS.ARTICLES) })}
          onClick={() => {
            app.sendGoogleAnalyticsEvent(GA.category, GA.events.clickArticlesTab, { name: 'Articles tab clicked' });
            app.history.push(URI[PAGES.ARTICLES]);
            onMenuItemClick();
          }}
        >
          <div className={styles.tabTitle}>{t('general.articles')}</div>
        </div>
      )}

      {tabIds.includes(TAB_IDS.SPEAK_WITH_TINA) && (
        <div
          className={cx(styles.tab, styles.tinaTab, { [styles.activeTab]: getIsTabActive(TAB_IDS.SPEAK_WITH_TINA) })}
          onClick={() => {
            app.sendGoogleAnalyticsEvent(GA_CHAT.category, GA_CHAT.events.clickSpeakWithTinaTab, { name: 'speack with tina tab clicked' });
            app.history.push(URI[PAGES.CHAT]);
            onMenuItemClick();
          }}
        >
          <div className={styles.tabIcon}>
            <TinaIcon />
          </div>
          <div className={styles.tabTitle}>
            {t('general.speak_with_tina')}
            <span className={styles.tinaEmoji}>😊</span>
          </div>
        </div>
      )}

    </div>
  );
};

HeaderTabs.propTypes = {
  version: PropTypes.oneOf(SUPPORTED_VERSIONS).isRequired,
  tabIds: PropTypes.arrayOf(PropTypes.oneOf(Object.values(TAB_IDS))).isRequired,
};

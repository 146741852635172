export default {
  CLICK_DOWNLOAD_AND_SHARE_ON_MINISITE: 'click download and share on minisite',
  CLICK_NEXT_ON_A_QUESTION: 'click next on some question',
  DOWNLOAD_PDF_FROM_POPUP: 'download pdf from popup',
  SIGN_UP_SMS: 'sign up sms',
  SIGN_UP_EMAIL: 'sign up email',
  SIGN_UP_GOOGLE: 'sign up google',
  SIGN_UP_FACEBOOK: 'sign up facebook',
  USER_IS_MOVED_TO_THE_NEXT_SECTION: 'user is moved to the next section',
  QUICK_SIGNUP_BUTTON_VIEWED: 'Quick signup button viewed',
  QUICK_SIGNUP_STARTED: 'Quick signup started',
  QUICK_SIGNUP_SUCCEED: 'Signup succeed',
  QUICK_SIGNUP_LOGIN_PAGE_VIEWED: 'Login page viewed',
  QUICK_SIGNUP_LOGIN_SUCCEED: 'Login succeed',
};

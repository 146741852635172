import { allCancerTypes, GENERAL_DISEASE_SUB_TYPE } from 'utils/constants/cancerTypes';
import { SUBTYPE_FIELD_BY_ALL_CONDITIONS } from 'new-ui/constants';

const { GENERAL: GENERAL_DISEASE } = allCancerTypes;
const { [GENERAL_DISEASE]: GENERAL_DISEASE_SUB_TYPE_QUESTION_ID } = SUBTYPE_FIELD_BY_ALL_CONDITIONS;

export const getGeneralDiseaseSubType = (conditionProfile) => {
  const subTypeQuestionValue = conditionProfile[GENERAL_DISEASE_SUB_TYPE_QUESTION_ID];
  return subTypeQuestionValue && Object.values(GENERAL_DISEASE_SUB_TYPE).includes(subTypeQuestionValue)
    ? subTypeQuestionValue
    : null;
};

import React from 'react';
import PropTypes from 'prop-types';

export const AddCaregiverFormWrapper = ({ error, children }) => {
  return (
    <React.Fragment>
      <div className="add-caregiver-form">{children}</div>
      {error ? <div className="add-caregiver-error-handling">{error}</div> : null}
    </React.Fragment>
  );
};

AddCaregiverFormWrapper.propTypes = {
  error: PropTypes.any,
  children: PropTypes.any.isRequired,
};

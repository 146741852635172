import React, {
  useCallback, useEffect, useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
import { push, replace } from 'connected-react-router';
import {
  useDispatch, useSelector, batch, shallowEqual,
} from 'react-redux';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';
import ResultList from 'new-ui/Results/ResultList';
import { START_SIGNUP_URL_SEARCH_PARAM } from 'utils/constants/urlSearchParameters';
import {
  PAGES, ROUTES,
} from 'new-ui/constants';
import { PENDING, PRISTINE } from 'utils/constants/queryStatuses';
import './Search.css';
import { preserveQueryParam } from 'functions/preserveQueryParam';
import { useAsyncAction } from 'utils/hooks/useAsyncAction';
import { useLocationSearchParameters } from 'utils/hooks/useLocationSearchParameters';
import { updateCurrentUser } from 'modules/currentUser/actions';
import { startSignup } from 'modules/hcp/actions/signup';
import { patientSignupPopup } from 'modules/hcp/actions/patientSignup';
import { ensureCurrentUser } from 'modules/hcp/actions/initCurrentUser';
import { biomarkersUploadPopup } from 'modules/hcp/actions/biomarkersUpload';
import { startNewCurrentUser } from 'modules/hcp/actions/newCurrentUserCreate';
import { addPatientPopup } from 'modules/hcp/actions/addPatient';
import { fullProfileUploadPopup } from 'modules/hcp/actions/fullProfileUploadPopup';
import { useHCPUser } from 'modules/hcp/useHCPUser';
import { useHCPAccessControl } from 'modules/hcp/useHCPAccessControl';
import { adjustUserForServer, getIsHCPPatient, isUser } from 'modules/hcp/helpers';
import { saveHCPPatient } from 'modules/hcp/actions/saveHCPPatient';
import ResultsSkeleton from 'new-ui/Results/ResultList/ResultsSkeleton';
import { SearchForm } from 'new-ui/Components/SearchForm/SearchForm';
import {
  useSetHeaderSettings, useUpdatePageHeader, useSetOnLogoClick,
} from 'new-ui/Components/PageHeader/PageHeaderContext';
import { getUserCondition } from 'modules/user/utils';
import { TRIAL_TYPES } from 'utils/constants/trial';
import { TAB_IDS, VERSIONS } from 'new-ui/Components/PageHeader/constants';
import { HCPHeaderRightSlot } from 'components/HCPHeaderRightSlot/HCPHeaderRightSlot';
import { useDelayedAction } from 'utils/hooks/useDelayedAction';
import { hcpApi } from 'modules/hcp/api';
import { GA_HCP } from './GA';
import { SearchUploadNav } from './SearchUploadNav/SearchUploadNav';

const fetchMatches = async (user) => {
  let toFetch = user;
  if (getUserCondition(user)) {
    toFetch = await adjustUserForServer(user);
  }
  return hcpApi.getMatchesByUser(toFetch);
};

const getMatchesParametersId = (params) => JSON.stringify(params);

const SearchTrials = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    currentUser,
  } = useSelector((state) => ({
    currentUser: state.currentUser.data,
  }), shallowEqual);
  const { setHeaderRightSlot } = useUpdatePageHeader();
  const searchFormContainerRef = useRef(null);

  const { pathname } = useLocation();
  const { [START_SIGNUP_URL_SEARCH_PARAM]: startSignupSearchParameter } = useLocationSearchParameters();
  const shouldStartSignup = !!startSignupSearchParameter;

  const {
    isAuthenticated,
    isHCPUser,
    isPatientWithoutId,
  } = useHCPUser();
  useHCPAccessControl();

  const { status: matchesFetchStatus, data: matches } = useAsyncAction({
    action: fetchMatches,
    parameters: currentUser,
    getParametersId: getMatchesParametersId,
    isActive: isUser(currentUser),
    dataKey: 'matches',
  });

  const canPatientBeAdded = isAuthenticated
    && isHCPUser && !getIsHCPPatient(currentUser) && !!getUserCondition(currentUser);
  const indicationChangeEnabled = !isAuthenticated || !getIsHCPPatient(currentUser);

  useSetHeaderSettings({
    version: VERSIONS.HCP,
    tabIds: [TAB_IDS.TREATMENT_OPTIONS, TAB_IDS.SUPPORT_PROGRAMS],
  });

  useSetOnLogoClick(
    useCallback(() => {
      dispatch(startNewCurrentUser({
        popupTitle: t('save_profile.title'),
        redirectPath: ROUTES[PAGES.SEARCH],
      }));
      return false;
    }, [dispatch, t]),
  );

  useEffect(() => () => {
    setHeaderRightSlot(null);
  }, [setHeaderRightSlot]);

  useEffect(() => {
    // it is not clear why it is needed
    delete app.cache.itemData;

    app.intercom.hidden = true;
    const fetch = async () => {
      await app.getSession();

      dispatch(ensureCurrentUser());
    };

    fetch();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isAuthenticated || !shouldStartSignup || !currentUser) {
      return;
    }
    batch(() => {
      dispatch(replace(preserveQueryParam(pathname, { omit: [START_SIGNUP_URL_SEARCH_PARAM] })));
      dispatch(startSignup());
    });
  }, [currentUser, dispatch, isAuthenticated, pathname, shouldStartSignup]);

  useDelayedAction({
    shouldTrigger: isPatientWithoutId,
    delay: 45 * 1000,
    action: () => {
      if (app.popup) {
        return;
      }
      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.PATIENT_SEEN_SIGNUP_BY_TIMEOUT);
      dispatch(patientSignupPopup());
    },
  });

  const handleNewPatient = useCallback(() => {
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NEW_CLICKED);
    dispatch(startNewCurrentUser({ popupTitle: t('save_profile.title') }));
  }, [dispatch, t]);

  const handleStartSignup = useCallback(() => {
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.PATIENT_CLICKED_SAVE);
    dispatch(startSignup());
  }, [dispatch]);

  const goToLogin = useCallback(() => {
    if (isPatientWithoutId) {
      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.PATIENT_LOGIN);
    }

    dispatch(push(`${ROUTES[PAGES.LOGIN]}?redirect=${ROUTES[PAGES.SEARCH]}`));
  }, [dispatch, isPatientWithoutId]);

  const goToPatients = useCallback(() => {
    dispatch(push(ROUTES[PAGES.PATIENTS]));
  }, [dispatch]);

  const handleAddPatient = useCallback(() => {
    dispatch(addPatientPopup());
  }, [dispatch]);

  const setHeader = useCallback(() => {
    setHeaderRightSlot(
      <HCPHeaderRightSlot
        isAuthenticated={isAuthenticated}
        isHCPUser={isHCPUser}
        addPatient={canPatientBeAdded ? handleAddPatient : null}
        newPatient={handleNewPatient}
        startSignup={handleStartSignup}
        goToLogin={goToLogin}
        goToPatients={goToPatients}
      />,
    );
  }, [
    setHeaderRightSlot,
    isAuthenticated,
    isHCPUser,
    canPatientBeAdded,
    handleAddPatient,
    goToLogin,
    goToPatients,
    handleNewPatient,
    handleStartSignup,
  ]);

  useEffect(() => {
    setHeader();
  }, [setHeader]);

  const handleItemClick = (item) => {
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.PATIENT_LEARN_MORE);
    if (isPatientWithoutId) {
      dispatch(patientSignupPopup());
      return;
    }
    const { trialType, nct_number: nctNumber } = item;
    const isTreatment = trialType === TRIAL_TYPES.TREATMENT;

    dispatch(push(
      `${ROUTES[isTreatment ? PAGES.PUBLIC_TREATMENT : PAGES.PUBLIC_TRIAL]}/${nctNumber}`,
    ));
  };

  const handleFullProfileUpload = () => {
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_FILES_CLICKED);
    if (!isAuthenticated) {
      dispatch(startSignup());
      return;
    }

    dispatch(fullProfileUploadPopup());
  };

  const handleBiomarkersUpload = () => {
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_CLICKED);

    if (!isAuthenticated) {
      dispatch(startSignup());
      return;
    }

    dispatch(biomarkersUploadPopup());
  };

  const updateUser = async () => {
    // it is expected that HCPPatient is already stored in currentUser
    try {
      await dispatch(saveHCPPatient());
    } catch (ex) {
      console.error('updateUser ex', ex);
      return;
    }
    app.setQuickNote({
      title: t('hcp.patient_updated_success'),
    });
  };

  return (
    <div className={`search ${currentUser ? 'hcp-user' : ''}`}>
      <SearchUploadNav
        searchFormContainerRef={searchFormContainerRef}
        onFullProfileUpload={handleFullProfileUpload}
        onBiomarkersUpload={handleBiomarkersUpload}
        t={t}
      />
      <div className="grid">
        <div className="results-container">
          {[PENDING, PRISTINE].includes(matchesFetchStatus)
            ? <ResultsSkeleton />
            : (
              matches && currentUser ? (
                <ResultList
                  user={currentUser}
                  getResultsClass={() => (getUserCondition(currentUser) ? '' : 'general-condition')}
                  isSearchPage
                  customNoData={(
                    <div className="hcp-no-data">
                      Looks like there are no matches for this specific search...
                    </div>
                  )}
                  all={matches}
                  action={handleItemClick}
                />
              ) : null
            )}
        </div>
        <div className="avoid-break search-actions">
          <div className="desktop-only">
            {currentUser && (
              <div ref={searchFormContainerRef}>
                <SearchForm
                  // pass {} to trigger new user creation
                  user={currentUser}
                  indicationChangeDisabled={!indicationChangeEnabled}
                  onLoad={(nextUser) => {
                    // preserve dirty status
                    dispatch(updateCurrentUser(nextUser));
                  }}
                  onChange={(nextUser) => {
                    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.FILTER_CHANGED);
                    dispatch(updateCurrentUser(nextUser, true));
                    if (getIsHCPPatient(nextUser)) {
                      updateUser();
                    }
                  }}
                />
              </div>
            )}
            <div className="height-10" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTrials;

export const VERSIONS = {
  DEFAULT: 'default',
  WHITE: 'white',
  HCP: 'hcp',
};

export const TAB_IDS = {
  FEED: 'feed',
  TREATMENT_OPTIONS: 'treatment_options',
  SUPPORT_PROGRAMS: 'support_programs',
  SPEAK_WITH_TINA: 'speak_with_tina',
  SYMPTOM_CARE: 'symptom_care',
  ARTICLES: 'articles',
};

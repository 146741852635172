import mime from 'mime-types';
import { isFileExtensionValid, isFileSizeValid } from '../../../modules/files/utils';

/**
 * @param files {File[]}
 * @param validators {{type: string, extensions: string[], maxQuantity: number, maxSizeMb: number}[]}
 * @return {{ file: File, message: string, args?: {} }[]}
 */
export const validateFiles = (files, validators) => {
  const errors = [];
  const filesSet = new Set();
  const allowedExtensions = validators.flatMap(({ extensions }) => extensions);
  const validatorByMimeType = validators.reduce((map, validator) => {
    for (const extension of validator.extensions) {
      map.set(mime.lookup(extension), validator);
    }
    return map;
  }, new Map());

  const typedCounter = new Map(validators.map((it) => [it, 0]));

  let validator = null;

  const checks = [
    function checkDuplicate(file) {
      if (filesSet.has(file.name)) {
        return { message: 'file_uploader.errors.file.duplicate' };
      }

      filesSet.add(file.name);

      return null;
    },
    function checkMimeType(file) {
      validator = validatorByMimeType.get(file.type) ?? null;

      if (!validator) {
        return {
          message: 'file_uploader.errors.file.invalid_type',
          args: { type: allowedExtensions.join(', ') },
        };
      }

      return null;
    },
    function checkTypedFilesQuantity() {
      const quantity = typedCounter.get(validator) + 1;
      typedCounter.set(validator, quantity);

      if (quantity > validator.maxQuantity) {
        return {
          message: 'file_uploader.errors.to_many_typed_files',
          args: { type: validator.type, quantity: validator.maxQuantity },
        };
      }

      return null;
    },
    function checkSize(file) {
      if (!isFileSizeValid(file.size, validator.maxSizeMb)) {
        return {
          message: 'file_uploader.errors.file.invalid_size',
          args: { type: validator.type, size: `${validator.maxSizeMb} Mb` },
        };
      }

      return null;
    },
    function checkExtension(file) {
      if (!isFileExtensionValid(file.name, validator.extensions)) {
        return {
          message: 'file_uploader.errors.file.invalid_extension',
          args: { extensions: validator.extensions.join(', ') },
        };
      }

      return null;
    },
  ];

  for (const file of files) {
    for (const check of checks) {
      const error = check(file);

      if (error) {
        errors.push({ ...error, file });
        break;
      }
    }
  }

  return errors;
};

export const handleFilesValidation = (files, validators, t, onFileError) => {
  const errors = validateFiles(files, validators);

  if (errors.length) {
    errors.forEach(({ file, message, args }) => {
      file.error = t(message, args);
    });
    onFileError();
  }
};

export const getFileExtensions = (validators) => validators.flatMap(({ extensions }) => extensions);

import { push } from 'connected-react-router';
import { batch } from 'react-redux';
import { checkIsAuthenticated } from 'functions/checkIsAuthenticated';
import { getUserCondition, getUserConditionProfile, getUserWixId } from 'modules/user/utils';
import { PLATFORM, USER_SOURCE, USER_TYPES } from 'utils/constants/user';
import { registerUser } from 'new-ui/functions';
import { getTargetUserProfile } from 'modules/user/api';
import { upsertUserPatient } from 'components/Authentication/actions';
import { resetCurrentUser, updateCurrentUser } from 'modules/currentUser/actions';
import app from 'new-ui/app';
import { getFinanceParams } from 'new-ui/Pages/Finance/functions';
import { PAGES, POPUP_TYPES, ROUTES } from 'new-ui/constants';
import { hcpApi } from '../api';
import { getCaregiverNewPatientEmail } from '../helpers';
import { savePatientPendingDocuments } from './savePatientPendingDocuments';

const GA = {
  category: 'HCP',
  events: {
    error: 'Could not signup',
  },
};

export const addPatientPopup = ({
  redirectPath, preserveCurrentUser, onSuccessAction,
} = {}) => (dispatch, getState) => {
  const {
    profile,
    currentUser: { data: currentUser },
    authentication,
  } = getState();

  if (!checkIsAuthenticated({ authentication, profile }) || !currentUser) {
    // invariant
    return;
  }

  app.setPopup(POPUP_TYPES.ADD_HCP_PATIENT, {
    theme: 'middle',
    indication: {
      value: getUserCondition(currentUser),
      title: '',
      type: null,
    },
    onSuccess: async (user) => {
      const { currentUser: { pendingDocuments } } = getState();

      dispatch(updateCurrentUser(user, false));

      if (pendingDocuments.length) {
        await dispatch(savePatientPendingDocuments());
      }

      app.setPopup(false);
      batch(() => {
        dispatch(upsertUserPatient(user));
        if (!preserveCurrentUser) {
          dispatch(resetCurrentUser({}));
        }
        dispatch(
          onSuccessAction ? onSuccessAction() : push(redirectPath ?? ROUTES[PAGES.PATIENTS]),
        );
      });
    },
    isFirstPatient: !profile?.patients?.length,
  });
};

export const addPatient = ({
  email, name, condition, onError, recaptchaToken, onSuccess,
}) => async (dispatch, getState) => {
  const {
    profile,
    currentUser: { data: currentUser },
    authentication,
  } = getState();

  if (!checkIsAuthenticated({ authentication, profile }) || !currentUser) {
    // invariant
    return;
  }

  app.setShowPreloader(true);

  const finalEmail = email || getCaregiverNewPatientEmail(getUserWixId(profile));
  const conditionProfile = getUserConditionProfile(currentUser);

  let patient;
  try {
    const { success, data: registrationResult } = await registerUser({
      email: finalEmail,
      indication: condition,
      recaptchaToken,
      condition_profile: conditionProfile,
      info: {
        family_name: name,
        email: finalEmail,
        user_type: USER_TYPES.PATIENT,
        platform: PLATFORM.HCP,
        organization: profile.info?.organization,
      },
    });
    if (!success) {
      throw new Error('Something went wrong');
    }
    const { accessToken } = registrationResult;
    patient = await getTargetUserProfile({ accessToken });

    const patientUserWixId = getUserWixId(patient);

    app.intercom.updateUserAttributes({
      email,
      condition,
      user_type: USER_TYPES.PATIENT,
      user_source: USER_SOURCE.HCP,
      shadow_patient: true,
    }, patientUserWixId);

    const addPatientResults = await hcpApi.addPatient(patientUserWixId);
    if (addPatientResults?.success !== true) {
      throw new Error('Something went wrong');
    }
    const patientFinanceParams = await getFinanceParams(patient);
    try {
      await hcpApi.createFinancePatient(patientFinanceParams);
    } catch (e) {
      console.error(e);
    }
  } catch (ex) {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.error, { name: 'caregiver_patient_signup_error' });
    onError(ex);
    return;
  } finally {
    app.setShowPreloader(false);
  }

  if (onSuccess) {
    onSuccess(patient);
  }
};

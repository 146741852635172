export const isFileExtensionValid = (fileName, allowedFileExtensions) => {
  const fileExt = fileName
    ?.split('.')
    .pop()
    .trim()
    .toLowerCase();

  return fileExt && allowedFileExtensions.includes(fileExt);
};

export const isFileSizeValid = (fileSize, maxFileSizeMb) => fileSize / (1024 * 1024) <= maxFileSizeMb;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import app from 'new-ui/app';

import consultIcon from 'new-ui/assets/icons/consult_icon.svg';
import ChatIcon from 'new-ui/assets/icons/chat_icon.svg';

// import { getSponsorsAssetUrl } from 'utils/getSponsorsAssetUrl';
// import demoImg from 'new-ui/assets/images/demo_sponsor_round.jpg';
import { getDrugCommercialName } from 'new-ui/functions/getDrugCommercialName';
import { isCallUsMode } from 'new-ui/functions';
import { TREATMENT_GROUPS } from 'new-ui/constants';
import { openCallUsPopup } from 'new-ui/Popups/CallUs';
import ImgFallback from 'new-ui/assets/icons/empty_item.svg';
import Button from '../Button';
import { PopupButton } from '../Popup';
import MissingCriticalQuestions from '../MissingCritical';
import lealRoleChatIcon from '../../assets/icons/property.svg';

export const ItemButton = (props) => {
  const {
    popupItem,
    listItem,
    action,
    item,
    width,
  } = props;
  const { t } = useTranslation();
  const consultWithUs = () => {
    if (isCallUsMode()) {
      openCallUsPopup();
    } else {
      app.setPopup('consult_with_us');
    }
  };
  return (popupItem ? (
    <PopupButton title={t('trial.learn_more')} action={action} />
  ) : (
    <div className={`actions ${listItem ? 'list-item' : ''}`}>
      {app?.cache?.itemData?.button ? (
        app?.cache?.itemData?.button
      ) : listItem ? (
        <>
          <Button
            className={`learn-more ${!item.isSponsored ? 'not-sponsored' : ''}`}
            title={t('trial.learn_more')}
            action={action}
            width={width}
          />
          {item.isSponsored && app.user ? (
            isCallUsMode() ? <Button icon={ChatIcon} className="consult-button" title={t('call_us.btn.title')} action={consultWithUs} />
              : <Button icon={consultIcon} className="consult-button" title={t('consult_with_us.title')} action={consultWithUs} />
          ) : null}
        </>
      ) : null}
    </div>
  ));
};

ItemButton.propTypes = {
  popupItem: PropTypes.bool,
  listItem: PropTypes.bool,
  action: PropTypes.func,
  item: PropTypes.any,
  $item: PropTypes.any,
  width: PropTypes.any,
};

export const ItemHeader = (props) => {
  const {
    isSearchPage,
    item, isFull, action, title,
  } = props;

  let src = null;

  const { t } = useTranslation();

  try {
    if (item.sponsorIcon) {
      // in order of other img type
      const path = `${item.sponsorIcon.split('.')[0]}.png`;
      src = `/assets/sponsors/logos/${path}`;
    }
  } catch (e) {
    console.error('error', item.sponsorIcon);
  }

  return (
    <div className={`info bottom ${isFull && 'cursor-default'}`} onClick={isFull ? () => { } : action}>
      <div className="description">
        {item.sponsorIcon ? (
          <div className="logo">
            {src ? (
              <div>
                <img
                  src={src}
                  onError={(e)=>{
                    e.target.onerror = null; // Prevents infinite loop if fallback also fails
                    e.target.src = ImgFallback; // Set the fallback URL
                  }}
                  alt={t('sponsor_custom.img.alt')}
                />
              </div>
            ) : null}
            <span>{item.sponsorName}</span>
          </div>
        ) : null}
        {item.trialType === 'drug' ? (
          <div className="treatment-item-title">
            <div>{getDrugCommercialName(item.drug_commercial_name)}</div>
            {item.generic_name ? (
              <div className="drug-commercial-name-container">
                (
                {getDrugCommercialName(item.generic_name)}
                )

              </div>
            ) : null}
          </div>
        ) : (
          <div className={`item-subtitle ${!isFull ? 'short-text' : ''}`}>
            {title ?? item.title}
          </div>
        )}
        <div>
          {
            item.title && item.trialType === 'drug' ? (
              <div id="friendlyTitle" className="dashborad-card-item-freindly-title-margin">
                <div className="dashborad-card-item-freindly-container">
                  <div id="friendlyTitleIcon" className="dashborad-card-item-freindly-title-icon"><img src={lealRoleChatIcon} alt="Leal Role" /></div>
                  {item.title}
                </div>

                <div className="dashborad-card-item-freindly-title-margin" />
              </div>
            ) : null
          }
        </div>
        <div className="group-tags">
          {item?.groups
            ?.map((i, k) => (
              <span key={k}>{i}</span>
            ))}
        </div>
      </div>
    </div>
  );
};

ItemHeader.propTypes = {
  item: PropTypes.any,
  title: PropTypes.string,
  isFull: PropTypes.bool,
  action: PropTypes.func,
  isSearchPage: PropTypes.bool,
};

// for the current implementation it makes no sense to display it without passing the user
export const ItemTags = ({
  item,
  user,
}) => {
  const getItem = (item) => {
    if (item.nct_number ) {
      return app.trials?.find((el) => el.nct_number === item.nct_number) || item;
    }
    return item;
  };

  return (
    <div className="tags">
      {user ? (
        <div className="tags-end">
          <MissingCriticalQuestions
            item={getItem(item)}
            user={user}
          />
        </div>
      ) : null}
    </div>
  );
};

ItemTags.propTypes = {
  item: PropTypes.object.isRequired,
  user: PropTypes.object,
};

import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import debounce from '@material-ui/core/utils/debounce';
import PropTypes from 'prop-types';
import './IndicationChooser.css';
import { useTranslation } from 'utils/modifiedTranslation';
import SearchIcon from 'new-ui/assets/icons/search_text.svg';
import CloseIcon from 'new-ui/assets/icons/close.svg';

const IndicationChooser = (props) => {
  const { value, onChange, getIndications } = props;

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [showDropDown, setShowDropDown] = useState(false);
  const [indications, setIndications] = useState([]);
  const [currentIndications, setCurrentIndications] = useState([]);
  const [currentIndication, setCurrentIndication] = useState(null);
  const [currentValue, setCurrentValue] = useState('');

  const containerRef = useRef(null);

  useEffect(() => {
    const fetch = async () => {
      let indications = await getIndications();

      indications = indications.map(({ title, value, type }) => ({
        title: title.indexOf('disease_name') >= 0 ? t(title) : title,
        value,
        type,
      }));

      const indication = value?.title ? indications.find((it) => it.title === value.title) : null;

      setIndications(indications);
      setCurrentIndication(indication);
      setCurrentValue(indication?.title ?? '');
      setIsLoading(false);
    };

    fetch();
  }, []);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (showDropDown && containerRef.current && !containerRef.current.contains(e.target)) {
        setCurrentValue(currentIndication?.title ?? '');
        setShowDropDown(false);
        onChange(currentIndication ?? null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showDropDown, containerRef, currentIndication]);

  const handleCleanup = () => {
    setCurrentIndications([...indications]);
    setCurrentIndication(null);
    setCurrentValue('');
    setShowDropDown(false);
    onChange(null);
  };

  const handleIndicationSelect = (indication) => {
    setCurrentIndication(indication);
    setCurrentValue(indication?.title ?? '');
    setShowDropDown(false);
    onChange(indication ?? null);
  };

  const debouncedFilter = useCallback(debounce((curValue) => {
    const currentIndications = indications
      .filter((indication) => indication?.title?.toLowerCase().includes(curValue.toLowerCase()));

    setCurrentIndications(currentIndications);
    setShowDropDown(true);
  }, 100),
  [indications]);

  const handleValueChange = (e) => {
    const { value } = e.target;
    setCurrentValue(value);
    debouncedFilter(value);
  };

  return indications?.length ? (
    <div
      className={`indication-chooser-input ${showDropDown ? 'indication-chooser-input-show' : ''}`}
      ref={containerRef}
    >
      <input
        value={currentValue}
        onChange={handleValueChange}
        placeholder="Start typing indication..."
        disabled={isLoading}
      />
      {currentIndication ? (
        <img
          className="indication-chooser-close-icon"
          src={CloseIcon}
          alt="icon"
          onClick={handleCleanup}
        />
      ) : <img className="indication-chooser-list-icon" src={SearchIcon} alt="icon" />}
      <div className="indication-chooser-items">
        {currentIndications.length
          ? currentIndications.map((indication) => (
            <div
              className="indication-chooser-item"
              onClick={() => handleIndicationSelect(indication)}
              key={indication.title}
            >
              {indication?.title ?? ''}
            </div>
          ))
          : <div>{t('indication_chooser.no_results')}</div>}
      </div>
    </div>
  ) : null;
};

IndicationChooser.propTypes = {
  value: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  onChange: PropTypes.func,
  getIndications: PropTypes.func,
};

export default IndicationChooser;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { PLATFORM, USER_SOURCE } from 'utils/constants/user';
import { AddCaregiverHeader } from '../AddCaregiver/AddCaregiverHeader';
import { AddCaregiverFooter } from '../AddCaregiver/AddCaregiverFooter';
import { AddCaregiverWrapper } from '../AddCaregiver/AddCaregiverWrapper';
import useRecaptcha from '../../../utils/hooks/useRecaptcha';
import { AddCaregiverFormWrapper } from '../AddCaregiver/AddCaregiverFormWrapper';
import Form, { FORM_INPUT_TYPES } from '../../Components/Form';
import ArrowCircle from '../../assets/icons/arrow-circle-right.svg';
import isEmailValid from '../../../functions/isEmailValid';
import app from '../../app';
import { isPhoneValid } from '../../../functions/formValidations/isPhoneValid';
import { GA_HCP } from '../../Search/GA';
import { preProcessCondProfile } from '../../../modules/user/utils';
import { signup } from '../../functions';

const getForm = (indication, t) => ({
  data: {
    email: '',
    phone: '',
    role: 'patient',
    indication,
  },
  inputs: {
    email: {
      type: FORM_INPUT_TYPES.INPUT,
      required: true,
      icon: 'email',
      title: t('hcp_signup.form.input.email'),
    },
    phone: {
      type: FORM_INPUT_TYPES.PHONE,
      icon: 'phone',
      title: t('hcp_signup.form.input.phone'),
      placeholder: '',
    },
    indication: {
      required: true,
      title: t('add_patient.indication_input_title'),
      type: FORM_INPUT_TYPES.SEARCH_INDICATION_CHOOSER,
    },
  },
});

const validateForm = (form, t) => {
  if (!isEmailValid(form.data.email)) {
    return t('hcp_signup.form.error.email');
  }

  if (!form.data.role) {
    return t('hcp_signup.form.error.role');
  }

  if (form.data.phone && !isPhoneValid(form.data.phone)) {
    return t('hcp_signup.form.error.phone');
  }

  if (!form.data.indication) {
    return t('hcp_signup.form.error.indication');
  }
};

export const SignupHCPPatient = (props) => {
  const {
    title = 'hcp_signup.patient.title',
    subtitle = 'hcp_signup.patient.subtitle',
    indication,
    profile,
    onSignup,
  } = props;

  const [form, setForm] = useState(null);
  const [formError, setFormError] = useState('');
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const { t } = useTranslation();

  useEffect(() => {
    setForm(getForm(indication, t));
  }, [indication, t]);

  const handleOnSubmit = async () => {
    const validationError = validateForm(form, t);

    if (validationError) {
      return setFormError(validationError);
    }

    app.setShowPreloader(true);

    const {
      indication, email, phone, role,
    } = form.data;

    const conditionProfile = await preProcessCondProfile(
      indication,
      profile?.condition_profile ?? {},
    );

    const params = {
      email,
      indication: indication.value,
      recaptchaToken: await getRecaptchaTokenAsync(),
      condition_profile: conditionProfile,
      info: {
        ...profile?.info ?? {},
        condition: indication.value,
        user_type: role,
        phone,
        email,
        platform: PLATFORM.HCP,
      },
      personal: {
        ...profile?.personal ?? {},
        condition: indication.value,
        providerId: window._provider || '',
        providerCode: window._provider || '',
      },
      onsignup: (user) => {
        app.intercom.updateUserAttributes(
          {
            email,
            phone,
            user_type: user?.info?.user_type,
            user_source: USER_SOURCE.HCP,
            organization: '',
            condition: indication.value,
          },
          user.info.user_wix_id,
        );

        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.PATIENT_SIGNUP_SUCCESS);
        app.setShowPreloader(false);

        onSignup(user);
      },
      onerror: () => {
        setFormError(t('hcp_signup.orm.error.signup'));
        app.setShowPreloader(false);
        app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.PATIENT_SIGNUP_FAILED);
      },
    };

    await signup(params);
  };

  const handleOnChange = () => {
    setFormError('');
  };

  return (
    <AddCaregiverWrapper>
      {recaptcha}
      <AddCaregiverHeader
        title={(
          <div style={{ lineHeight: 'normal' }}>
            {t(title)}
          </div>
        )}
        subtitle={(
          <div style={{ whiteSpace: 'pre-line', textAlign: 'left' }}>
            {t(subtitle)}
          </div>
        )}
      />
      <AddCaregiverFormWrapper error={formError}>
        <Form
          form={form}
          onChange={handleOnChange}
          submit={{
            action: handleOnSubmit,
            icon: ArrowCircle,
            title: t('general.save'),
          }}
        />
      </AddCaregiverFormWrapper>
      <AddCaregiverFooter />
    </AddCaregiverWrapper>
  );
};

SignupHCPPatient.propTypes = {
  title: PropTypes.any,
  subtitle: PropTypes.any,
  indication: PropTypes.shape({
    value: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string,
  }),
  profile: PropTypes.object,
  onSignup: PropTypes.func,
};

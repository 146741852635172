import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import { THEMES } from 'new-ui/constants';
import app from 'new-ui/app';
import ArrowLeft from 'new-ui/assets/icons/arrow_left_2.svg';
import { ReactComponent as ChatIcon } from 'new-ui/assets/icons/chat_bubbles.svg';
import styles from './matchedItemHeaderContent.module.css';

export const MatchedItemHeaderContent = ({ checkEligibility, talkToUs }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.root}>
      <Button
        theme={THEMES.GREY}
        className={styles.backButton}
        titleClassName={styles.backButtonLabel}
        width="fit-content"
        action={() => {
          app.history.goBack();
        }}
        icon={ArrowLeft}
        title={t('general.back_to_results')}
      />
      <div className={styles.rightButtons}>
        <Button
          title={t('general.check_qualification')}
          className={cx(styles.button, styles.checkQualificationButton)}
          titleClassName={styles.buttonLabel}
          action={checkEligibility}
        />
        <Button
          theme={THEMES.BLACK}
          className={styles.button}
          titleClassName={styles.buttonLabel}
          customIcon={<ChatIcon className={styles.buttonIcon} />}
          title={t('general.talk_to_us')}
          action={talkToUs}
        />
      </div>
    </div>
  );
};

MatchedItemHeaderContent.propTypes = {
  checkEligibility: PropTypes.func.isRequired,
  talkToUs: PropTypes.func.isRequired,
};

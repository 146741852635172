import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ELIGIBILITY_STATUSES } from 'components/MatchItemEligibilityCheck/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import styles from './matchItemEligibilityStatus.module.css';

const statusToClassName = (status) => {
  if (!status) {
    return null;
  }
  return styles[`status_${status.toLowerCase()}`];
};

export const MatchItemEligibilityStatus = ({ status }) => {
  const { t } = useTranslation();
  if (!status) {
    return null;
  }
  return (
    <div className={cx(styles.root, statusToClassName(status))}>
      {t(`eligibility.${status.toLowerCase()}`)}
    </div>
  );
};

MatchItemEligibilityStatus.propTypes = {
  status: PropTypes.oneOf(Object.values(ELIGIBILITY_STATUSES)),
};

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import { PopupFooter } from 'new-ui/Components/Popup';
import Button from 'new-ui/Components/Button';
import './AddPatient.css';

export const AddPatientForm = ({
  onSubmit,
  recaptcha,
  error,
  indication,
  name = '',
  email = '',
  emailRequired,
  indicationTitle,
  nameTitle,
  emailTitle,
  buttonTitle,
  title,
  subtitle,
}) => {
  const [form] = useState({
    inputs: {
      indication: {
        required: true,
        title: indicationTitle,
        type: FORM_INPUT_TYPES.INDICATION_CHOOSER,
        hidden: !!indication?.value,
      },
      name: {
        required: true,
        title: nameTitle,
        type: FORM_INPUT_TYPES.INPUT,
      },
      email: {
        title: emailTitle,
        type: FORM_INPUT_TYPES.INPUT,
        required: emailRequired,
      },
    },
    data: {
      indication,
      name,
      email,
    },
  });

  return (
    <div className="add-patient">
      {recaptcha}
      <div className="add-patient-title-holder">
        <div className="add-patient-title">
          {title}
        </div>
        {subtitle ? <div className="add-patient-subtitle">{subtitle}</div> : null}
      </div>
      <Form form={form} />
      {error ? <div className="error">{error}</div> : null}
      <PopupFooter>
        <Button title={buttonTitle} action={() => onSubmit(form?.data)} />
      </PopupFooter>
    </div>
  );
};

AddPatientForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  recaptcha: PropTypes.node.isRequired,
  error: PropTypes.string,
  indication: PropTypes.shape({
    value: PropTypes.string,
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  buttonTitle: PropTypes.string.isRequired,
  indicationTitle: PropTypes.string.isRequired,
  nameTitle: PropTypes.string.isRequired,
  emailTitle: PropTypes.string.isRequired,
  name: PropTypes.string,
  email: PropTypes.string,
  emailRequired: PropTypes.bool,
};

import {
  addDocLogs, createFileProcessingJob, getUserProfile, uploadFiles,
} from 'components/MedicalFiles/api';
import { EXTRACTION_STRATEGY, JOB_STATUS } from 'components/MedicalFiles/constants';
import { poll } from 'components/MedicalFiles/utils';

export const uploadFile = async ({
  files = [],
  getRecaptchaTokenAsync,
  onUpload,
  onError,
  onJobStatusChange,
  extractionStrategy = EXTRACTION_STRATEGY.FULL_PROFILE,
  condition,
  noUserProfileMerge,
}) => {
  try {
    const fileKeys = await uploadFiles(files.filter((it) => !it.error), await getRecaptchaTokenAsync());
    const id = await createFileProcessingJob(
      fileKeys,
      extractionStrategy,
      await getRecaptchaTokenAsync(),
      condition,
    );

    await addDocLogs(
      files.map((it) => ({
        jobId: id, docName: it.name, docStatus: it?.error ? 'error' : 'success', failureReason: it.error,
      })),
    );

    const profile = await poll(
      () => getUserProfile(id, { noUserProfileMerge }),
      (job) => {
        let shouldContinue = true;
        if (onJobStatusChange) {
          shouldContinue = onJobStatusChange(job) ?? true;
        }

        if (!shouldContinue) {
          return false;
        }

        return [JOB_STATUS.IN_PROGRESS, JOB_STATUS.UPDATED].includes(job.status);
      },
      5000,
    );

    if (onUpload) {
      onUpload(profile);
    }
  } catch (ex) {
    if (onError) {
      onError(ex);
    }
  }
};

import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import uploadWhiteIcon from 'new-ui/assets/icons/upload_white.svg';
import nextIcon from 'new-ui/assets/icons/arrow-circle-right.svg';
import uploadIcon from 'new-ui/assets/icons/upload.svg';
import { THEMES } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import useForceUpdate from 'use-force-update';
import app from 'new-ui/app';
import Button from '../Button';
import LinkButton from '../LinkButton';
import './FilesUploader.css';
import { FILE_TYPES_VALIDATORS, UPLOADER_STATES } from './FileUploaderConstants';
import { getFileExtensions, handleFilesValidation } from './FileUploaderUtils';
import { UploaderFile } from './UploaderFile';

const context = {};

const FilesUploader = (props) => {
  const {
    isMulti,
    header,
    footer,
    filerenderer = () => undefined,
    onselect = () => undefined,
    onstatechange = () => undefined,
    onfilesselected = () => undefined,
    onFileError = () => undefined,
    uploader = {},
    openMobileMenu,
    buttonMode = false,
    selectButton,
    isCustomButton,
    GA = {},
    isUploadPopup,
    validators = FILE_TYPES_VALIDATORS,
  } = props;

  const { t } = useTranslation();

  const [state, setState] = useState(UPLOADER_STATES.SELECT);
  const [files, setFiles] = useState([]);

  uploader.setState = setState;
  uploader.setFiles = setFiles;
  uploader.files = files;

  const [showMobileMenu, setShowMobileMenu] = useState(true);

  if (openMobileMenu) {
    openMobileMenu(setShowMobileMenu);
  }

  const update = useForceUpdate();

  const $dragArea = useRef();

  useEffect(() => {
    onstatechange(state);
  }, [state, onstatechange]);

  useEffect(() => {
    window.__currentState = state;
    if (state === UPLOADER_STATES.SELECT) {
      const dropzone = $dragArea.current;

      if (!dropzone) return;

      dropzone.addEventListener('dragover', (event) => {
        event.preventDefault();
        dropzone.classList.add('dragged');
      });
      dropzone.addEventListener('dragleave', (event) => {
        event.preventDefault();
        dropzone.classList.remove('dragged');
      });
      dropzone.addEventListener('drop', (event) => {
        event.preventDefault();
        const droppedFiles = event.dataTransfer.files;
        const uploadedFiles = [];

        for (let i = 0; i < droppedFiles.length; i++) {
          const file = droppedFiles[i];
          uploadedFiles.push(file);
        }

        setFiles(uploadedFiles);
        context.onFilesSelected();
      });
    } else if (state === UPLOADER_STATES.ERROR) {
      setFiles([]);
    }
  }, [state]);

  const onFilesSelected = () => {
    onfilesselected();

    handleFilesValidation(files, validators, t, onFileError);

    if (isMulti) setState(UPLOADER_STATES.MULTI);
    else {
      setFiles(files);
      onselect();
    }
    update();
  };

  context.onFilesSelected = onFilesSelected;

  const onFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      files.push(selectedFile);
      onFilesSelected();
    }
  };

  const photo = ($ev) => {
    $ev.stopPropagation();
    const fileInput = document.getElementById('fileInput');
    fileInput.value = '';
    fileInput.click();
  };

  const cancel = () => {
    setShowMobileMenu(false);
  };

  const select = () => {
    if (isMulti) setState(UPLOADER_STATES.MULTI);
    else upload();
  };

  const isNextValid = () => files.filter((a) => !a.error).length;

  uploader.select = select;

  const upload = () => {
    const fileInput = document.getElementById('fileInput');
    fileInput.value = '';
    fileInput.click();
  };

  const handleRemove = (file) => {
    files.splice(files.indexOf(file), 1);

    files.forEach((it) => {
      it.error = undefined;
    });

    handleFilesValidation(files, validators, t, onFileError);

    setFiles([...files]);
  };

  const render = () => {
    switch (state) {
      default:
      case UPLOADER_STATES.SELECT:
        return (
          <div className="files-uploader-select-state">
            {header}
            <>
              <div className="mobile-files-uploader">
                {isCustomButton ? (
                  <Button
                    width="206px"
                    title="Upload file"
                    action={() => {
                      setShowMobileMenu(true);
                    }}
                  />
                ) : (
                  <Button
                    className="mobile-files-uploader-button"
                    title="Upload File"
                    forwardIcon={uploadWhiteIcon}
                    action={() => {
                      setShowMobileMenu(true);
                    }}
                  />
                )}

                {showMobileMenu ? (
                  <>
                    <div className="mobile-files-uploader-overlay">
                      <div className="mobile-files-uploader-overlay-buttons">
                        <div className="mobile-files-uploader-overlay-button" onClick={photo}>Take a photo</div>
                        <div className="mobile-files-uploader-overlay-button" onClick={upload}>Upload from device</div>
                        <div className="mobile-files-uploader-overlay-button" onClick={cancel}>Cancel</div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              {
                buttonMode ? (
                  <div className="files-uploader-select-buttons">
                    <Button title="Upload File" forwardIcon={uploadWhiteIcon} action={upload} />
                    {selectButton || null}
                  </div>
                ) : (
                  <div className="desktop-files-uploader" ref={$dragArea} onClick={upload}>
                    <div className="icon">
                      <img alt="upload" src={uploadIcon} />
                    </div>
                    {isUploadPopup ? (
                      <div>
                        Drag your file here
                        <br />
                        {' '}
                        Or
                      </div>
                    ) : (
                      <div>
                        Drag Medical Records Here
                      </div>
                    )}

                    <div className="browse">
                      <LinkButton
                        title="Browse"
                        action={() => {
                          if (GA?.category) {
                            app.sendGoogleAnalyticsEvent(GA.category, GA.events.browse, { name: 'Uplad file browse clicked' });
                          }
                        }}
                      />
                    </div>
                  </div>
                )
              }
            </>
            {footer}
          </div>
        );
      case UPLOADER_STATES.MULTI:
        return (
          <div className="files-uploader-multi">
            {header}
            {!isUploadPopup ? (
              <div className="files-uploader-preview-num">
                {files.length}
                {' '}
                {files.length === 1 ? 'file' : 'files'}
              </div>
            ) : null}
            {files.map((file, k) => (
              <React.Fragment key={k}>
                <UploaderFile
                  file={file}
                  state={isUploadPopup && state}
                  isUploadPopup={isUploadPopup}
                  onremove={handleRemove}
                />
              </React.Fragment>
            ))}
            <div className="files-uploader-multi-buttons">
              <Button
                title="Upload More"
                action={() => {
                  if (GA?.category) {
                    app.sendGoogleAnalyticsEvent(GA.category, GA.events.moreUpload, { name: 'Upload more files clicked' });
                  }

                  upload();
                }}
                theme={THEMES.ORANGE}
              />
              {
                isNextValid() ? (
                  <div className="multi-button-holder">
                    <Button
                      title={t('general.next')}
                      action={() => {
                        setFiles(files);
                        setTimeout(() => onselect(), 100);
                      }}
                      forwardIcon={nextIcon}
                    />
                  </div>
                ) : null
              }
            </div>
          </div>
        );
      case UPLOADER_STATES.UPLOADING:
      case UPLOADER_STATES.ANALYZING:
        return (
          <div>
            {header}

          </div>
        );
      case UPLOADER_STATES.SUCCESS:
        return (
          <div className="files-uploader-success">
            {header}
            {files.map((file, k) => (
              <React.Fragment key={k}>
                <UploaderFile file={file} state={state} body={filerenderer(file)} />
              </React.Fragment>
            ))}
            {footer}
          </div>
        );
      case UPLOADER_STATES.ERROR:
        return (
          <div className="files-uploader-error">
            {header}
            {footer}
          </div>
        );
      case UPLOADER_STATES.CUSTOM1:
        return (
          <div className="files-uploader-custom">
            {header}
          </div>
        );
    }
  };

  return (
    <div className={`files-uploader files-uploader-state-${state}`}>
      <input
        id="fileInput"
        accept={getFileExtensions(validators).map((it) => `.${it}`)}
        type="file"
        style={{ display: 'none' }}
        onChange={onFileChange}
      />
      {render()}
    </div>
  );
};

FilesUploader.propTypes = {
  isMulti: PropTypes.bool,
  header: PropTypes.any,
  footer: PropTypes.any,
  filerenderer: PropTypes.any,
  onselect: PropTypes.any,
  onstatechange: PropTypes.any,
  uploader: PropTypes.any,
  onfilesselected: PropTypes.any,
  onFileError: PropTypes.func,
  openMobileMenu: PropTypes.func,
  buttonMode: PropTypes.bool,
  selectButton: PropTypes.any,
  isCustomButton: PropTypes.bool,
  GA: PropTypes.object,
  isUploadPopup: PropTypes.bool,
  validators: PropTypes.arrayOf(PropTypes.object),
};

export default FilesUploader;

import { authenticationService } from 'components/Authentication/api';
import CONSTANTS from 'components/Matches/constants';

function requestMatches() {
  return { type: CONSTANTS.GET_MATCHES_REQUEST };
}

function successMatches(result) {
  return { type: CONSTANTS.GET_MATCHES_SUCCESS, payload: result };
}

function failureMatches(error) {
  return { type: CONSTANTS.GET_MATCHES_FAILURE, payload: error };
}

const getTrialsMatches = (parameters, hidePreloader = false) => async (dispatch) => {
  if (!hidePreloader) {
    dispatch(requestMatches());
  }
  try {
    const result = await authenticationService.getTrialsMatches(parameters);
    dispatch(successMatches(result));
  } catch (ex) {
    console.error('Error occurred, while getting trials matches:', ex);
    dispatch(failureMatches(`Error occurred, while getting trials matches: ${ex.message || ex}`));
  }
};

export const matchesActions = {
  getTrialsMatches,
};

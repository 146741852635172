import React, {
  createContext, useState, useContext, useMemo, useEffect, useCallback, useLayoutEffect
} from 'react';
import PropTypes from 'prop-types';

const PageHeaderContext = createContext(null);

// only serializable props
const DEFAULT_HEADER_SETTINGS = {
  isLogoShown: true,
  isProfileButtonShown: false,
  isNavMenuShown: true,
  version: null,
  isLanguageMenuShown: false,
  isLoginButtonShown: false,
  isBurgerMenuShown: false,
  isUploadButtonShown: false,
  tabIds: [],
};

export const PageHeaderProvider = ({ children, value: initValue = {} }) => {
  const {
    headerSettings: initHeaderSettings = {},
  } = initValue;

  const [headerSettings, setHeaderSettings] = useState(() => ({ ...DEFAULT_HEADER_SETTINGS, ...initHeaderSettings }));
  const [headerContent, setHeaderContent] = useState(null);
  const [headerRightSlot, setHeaderRightSlot] = useState(null);
  const [customLogoScr, setCustomLogoScr] = useState(null);
  const [onLogoClick, setOnLogoClick] = useState();

  const memoizedSetHeaderSettings = useCallback((nextSettings) => setHeaderSettings((currentSettings) => ({
    ...currentSettings,
    ...nextSettings,
  })), [setHeaderSettings]);

  const value = useMemo(() => ({
    headerSettings,
    headerContent,
    headerRightSlot,
    customLogoScr,
    onLogoClick,
    setHeaderContent,
    setOnLogoClick,
    setCustomLogoScr,
    setHeaderRightSlot,
    setHeaderSettings: memoizedSetHeaderSettings,
  }), [headerSettings, headerContent, headerRightSlot, customLogoScr, onLogoClick, memoizedSetHeaderSettings]);

  return (
    <PageHeaderContext.Provider value={value}>
      {children}
    </PageHeaderContext.Provider>
  );
};

PageHeaderProvider.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.shape({
    headerSettings: PropTypes.shape({
      isLogoShown: PropTypes.bool,
      isProfileButtonShown: PropTypes.bool,
      isNavMenuShown: PropTypes.bool,
      version: PropTypes.string,
      isLanguageMenuShown: PropTypes.bool,
      isLoginButtonShown: PropTypes.bool,
      isBurgerMenuShown: PropTypes.bool,
      isUploadButtonShown: PropTypes.bool,
      tabIds: PropTypes.array,
    }),
  }),
};

export const usePageHeader = () => useContext(PageHeaderContext);

export const useUpdatePageHeader = () => {
  const {
    setHeaderContent, setOnLogoClick, setHeaderSettings, setHeaderRightSlot,
  } = useContext(PageHeaderContext) ?? {};
  return {
    setHeaderContent, setOnLogoClick, setHeaderSettings, setHeaderRightSlot,
  };
};

export const useSetOnLogoClick = (onLogoClick) => {
  const { setOnLogoClick } = useUpdatePageHeader();

  useEffect(() => {
    // should set a function in order onLogoClick is not be called
    setOnLogoClick(() => onLogoClick);
    return () => {
      setOnLogoClick(null);
    };
  }, [setOnLogoClick, onLogoClick]);
};

export const useSetHeaderSettings = (settings) => {
  const { setHeaderSettings } = useUpdatePageHeader();
  const settingsHash = JSON.stringify(settings);

  useLayoutEffect(() => {
    setHeaderSettings(settings);
    return () => {
      // reset to default values
      const defaultSettings = Object.fromEntries(
        Object.keys(settings).map((key) => [key, DEFAULT_HEADER_SETTINGS[key]]),
      );
      setHeaderSettings(defaultSettings);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsHash, setHeaderSettings]);
};

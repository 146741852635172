import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import { TAB_IDS } from 'new-ui/Components/PageHeader/constants';
// import ChatPopup from 'new-ui/Popups/Chat';
import './chat.css';
import ChatNewUserPopup from 'new-ui/Popups/ChatNewUser';
import { useSetHeaderSettings } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { isNewlyDiagnosedUser } from 'modules/user/utils';

const ChatPage = () => {
  // const { t } = useTranslation();
  const [init, setInit] = useState(null);

  useSetHeaderSettings({
    isBurgerMenuShown: true,
    isUploadButtonShown: true,
    tabIds: [
      TAB_IDS.FEED,
      TAB_IDS.TREATMENT_OPTIONS,
      TAB_IDS.SUPPORT_PROGRAMS,
      TAB_IDS.SYMPTOM_CARE,
      ...(isNewlyDiagnosedUser(app?.user) ? [TAB_IDS.ARTICLES] : []),
      TAB_IDS.SPEAK_WITH_TINA,
    ],
  });

  useEffect(() => {
    const fetch = async () => {
      if (!app.user) {
        app.setShowPreloader(true);
        app.user = await app.getUser();
        app.setShowPreloader(false);
        setInit(true);
      } else {
        setInit(true);
      }
    };

    fetch();
  }, []);
  return (
    init ? (
      <>
        <div className="full-screen-chat">
          <div className="full-screen-chat-width"><ChatNewUserPopup isFullMode /></div>
        </div>
      </>
    ) : null

  );
};

export default ChatPage;

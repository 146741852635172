export const MATCH_FLOW_LOCAL_KEY = 'match_flow';

export const MATCH_FLOW = {
  BASIC: 'basic',
  LATEST: 'latest'
};

export default {
  GET_MATCHES_REQUEST: 'GET_MATCHES_REQUEST',
  GET_MATCHES_SUCCESS: 'GET_MATCHES_SUCCESS',
  GET_MATCHES_FAILURE: 'GET_MATCHES_FAILURE',
  CLEAR_MATCHES: 'CLEAR_MATCHES',
  OPTIMIZE_SORTER_DIRECTION: {
    ASCENDING: 'ASC',
    DESCENDING: 'DESC'
  },
};

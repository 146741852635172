import React from 'react';
import HCPSelect from 'new-ui/Components/HCP/Select';
import { useTranslation } from 'utils/modifiedTranslation';
import { questionPropTypes } from './questionPropTypes';

export const MockQuestion = ({ q, user }) => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="hcp-input-title">{t(q.title)}</div>
      <div>
        <HCPSelect
          user={user}
          disabled
          placeholder={t(q.placeholder)}
          showAutofill={false}
          options={[]}
          initValue={null}
        />
      </div>
    </div>
  );
};

MockQuestion.propTypes = questionPropTypes;

/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Overlay from 'new-ui/Components/Overlay';
import { USER_SOURCE } from 'utils/constants/user';
import { useTranslation } from 'utils/modifiedTranslation';
import { allCancerTypes } from 'utils/constants/cancerTypes';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';
import Preloader from 'new-ui/Components/Preloader';
import { getInitialConditionProfileByIndication, signup } from 'new-ui/functions';
import { getIndications } from 'new-ui/api';
import ArrowCirlce from 'new-ui/assets/icons/arrow-circle-right.svg';
import useRecaptcha from 'utils/hooks/useRecaptcha';
// import { STORAGE_SEARCH_BIOMARKERS, STORAGE_SEARCH_INDICATION } from 'new-ui/Search/SearchParameters';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import app from 'new-ui/app';
import {
  INTERCOM_EVENTS, PAGES, URI,
} from 'new-ui/constants';
import lealHealthIcon from 'new-ui/assets/icons/leal_logo_2.svg';
import ChatUploadExplainPopup from 'new-ui/Popups/ChatUploadExplain';
import academicCancer from 'new-ui/assets/icons/academic_cancer_logo.svg';
import IsoImage from '../../../../components/Authentication/components/Signup/assets/image/iso.svg';
import HipaaImage from '../../../../components/Authentication/components/Signup/assets/image/hipaa.svg';
import FreeImage from '../../../../components/Authentication/components/Signup/assets/image/free.svg';
import { authenticationActions } from '../../../../components/Authentication/actions';
import './styles.css';

export const HaveAnAccount = ()=>{
  return (
    <div
      className="have-an-account"
      onClick={()=>{
        window.location = URI[PAGES.LOGIN];
      }}
    >
      <span>
        {window.t('add_caregiver.have_an_account')}
        {' '}
        <b>{window.t('general.login')}</b>
      </span>
    </div>
  );
};

let aIndication = 'breast_2';
export const EmailSignupForm = ({
  showForm, setShowForm, biomarkers, user, setNewUser,
}) => {
  const [conditionParam, setConditionParam] = useState(null);
  const params = app.getParams();
  const [formData, setFormData] = useState({ email: '', indication: '', phone: '' });
  const [indicationError, setIndicationError] = useState(false);
  const [signupError, setSignupError] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const history = useHistory();
  const { t } = useTranslation();
  const [formDefinition, setFormDefinition] = useState(null);

  const GA = {
    category: 'Singup',
    events: {
      pageview: 'Upload file landing page viewed',
      browse: 'Clicking on browse',
      dragFile: 'Drag a file',
      sendRecord: 'Clicking on Send record by mail',
      answerManually: 'Clickin on Answer manually',
      emailToLater: 'Upload file clicking continue later',
      emailToLaterForm: 'Continue later form viewed',
      emailToLaterPopup: 'Continue later final popup viewed',
      moreUpload: 'Upload more clicked',
      next: 'Clicking on “Next”',
      startVideo: 'Upload file video started',
      endVideo: 'Upload file video finished',
      fileSuccessfully: 'Upload file extracted successfully',
      fileFailed: 'Upload file extracted failed',
      reviewing: 'When the revireing page is presented',
      confirmaed: 'When confirmaed',
      success: 'signup_success',
      error: 'signup_error',
    },
  };

  useEffect(() => {
    const condition = params.get('condition');
    const subCondition = params.get('sub_condition');

    if (condition) {
      setConditionParam(condition);
    }
    if (subCondition) {
      switch (subCondition) {
        case 'pancreas':
          setConditionParam('pancreatic');
          break;
        case 'testicular_cancer':
          setConditionParam('testicular');
          break;
        default:
          break;
      }
    }
  }, [params]);


  const handleForm = async () => {
    const { email, indication, phone } = formData;
    setFormLoading(true);
    setSignupError(false);

    if (!indication) {
      setFormLoading(false);
      setIndicationError(true);
      return;
    }

    try {
      const condition_profile = await getInitialConditionProfileByIndication(indication, {
        biomarkers,
      });

      await signup({
        email,
        indication,
        phone,
        recaptchaToken: await getRecaptchaTokenAsync(),
        condition_profile,
        onsignup: async (newUser) => {
          user = newUser;
          setNewUser(newUser);
          app.user = user;
          app.intercom.updateUserAttributes(
            {
              email: formData.email,
              condition: formData.indication,
              phone: formData.phone,
              user_source: USER_SOURCE.PT_UPLOAD_FILE,
            },
            newUser.info.user_wix_id,
          );

          app.intercom.sendEvent(INTERCOM_EVENTS.UPLOAD_PAGE.USER_SIGNUP);
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.success, { name: 'user_signup_success' });
          await app.store.dispatch(authenticationActions.updateUser(newUser, true));
          setFormLoading(false);
          setShowForm(false);
          history.push('/ai_profile');
        },
        onerror: (e) => {
          console.error('user signup error', e);
          setFormLoading(false);
          setSignupError(true);
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.error, { name: 'user_signup_error' });
        },
      });
    } catch (error) {
      console.error('An error occurred during signup:', error);
      setFormLoading(false);
      setSignupError(true);
    }
  };

  useEffect(() => {
    const fetchIndications = async () => {
      try {
        const indications = await getIndications();
        const modifiedIndications = [
          ...indications.filter(({ value }) => value !== allCancerTypes.GENERAL),
          {
            title: t('questionnaire.questions.general.type_of_ca.option.pancreas'),
            value: 'pancreatic',
          },
          {
            title: t('questionnaire.questions.general.type_of_ca.option.testicular_cancer'),
            value: 'testicular',
          },
        ];
        setFormDefinition({
          inputs: {
            email: {
              type: FORM_INPUT_TYPES.INPUT,
              required: true,
              icon: 'email',
              placeholder: t('general.email'),
              title: t('general.email'),
            },
            indication: {
              title: t('upload.add_cancer_type'),
              placeholder: t('upload.add_cancer_type'),
              required: true,
              type: FORM_INPUT_TYPES.SELECT,
              autocomplete: true,
              options: modifiedIndications.map((i) => ({
                title: i.title.indexOf('disease_name') !== -1 ? `${t(i.title)}` : `${t(i.title)}`,
                value: (i.value),
              })),
            },
            phone: {
              type: FORM_INPUT_TYPES.PHONE,
              icon: 'phone',
              title: `${t('upload.registration_phone_text')}`,
              placeholder: '651465',
              className: 'registration-phone',
            },
          },
          data: {
            email: '',
            indication: '',
            phone: '',
          },
        });
      } catch (error) {
        console.error('Error fetching indications:', error);
      }
    };

    fetchIndications();
  }, []);

  return (
    !showForm && (
      <>
        <div className="form-margin-top">
          <img
            alt="arrow button"
            src={lealHealthIcon}
            style={{
            }}
          />
        </div>
        <div>
          <Overlay className="ngs-overlay" onclose={() => history.push('/ai_profile')} hideCloseButton showChat>
            <div className="ngs-form-page">
              {recaptcha}
              <div className="center-headtitle">
                <div className="email-signup-center-headtitle-div" />
                <div className="form-margin-top">{window.t('email_signup.title')}</div>
                <div className="small-height" />
                <div className="signup-page-secoundry-title">{window.t('email_signup.subtitle')}</div>
              </div>
              {formDefinition && (
                <div className="ngs-form">
                  <Form
                    form={{
                      ...formDefinition,
                      data: {
                        ...formData,
                        indication: conditionParam || formData.indication,
                      },
                    }}
                    onChange={(data) => {
                      aIndication = data.indication;
                      setFormData({ ...data, indication: aIndication });
                    }}
                  />
                </div>
              )}
              {indicationError && <div className="ngs-form-error">{window.t('email_signup.error_select_indication')}</div>}
              {signupError && <div className="ngs-form-error">Oops, Registration error. Please contact support</div>}
              <div className="ngs-footer ngs-form-footer">
                {formLoading && (
                  <div className="ngs-form-preloader">
                    <Preloader inline isVisible />
                  </div>
                )}
                <Button disabled={formLoading} title="Go to your treatment options" forwardIcon={ArrowCirlce} action={handleForm} />
                <div className="ngs-privacy">
                  By clicking "Save" I agree to the
                  <a className="signup-page-link-color" href="https://www.leal.health/terms"> Terms </a>
                  and
                  <a className="signup-page-link-color" href="https://www.leal.health/privacy-policy"> Privacy Policy </a>
                  .
                  <br />
                  This site is protected by reCAPTCHA and the
                  <a className="signup-page-link-color" href="https://policies.google.com/privacy"> Google Privacy Policy </a>
                  and
                  <a className="signup-page-link-color" href="https://policies.google.com/terms"> Term of Service </a>
                  apply.
                </div>
              </div>
              <div className="upload-page-start-section-bottom">
                <img src={academicCancer} alt="free" />
                <div className="large-width-gap" />
                <img src={FreeImage} alt="free" />
                <img src={HipaaImage} alt="hipaa" />
                <img src={IsoImage} alt="iso" />
              </div>
            </div>
            <br />
            <div />
            <div className="singup-chat">
              <div className="ai-chat-upload-box singup-chat" id="singup-chat">
                <div className="ai-chat-upload">
                  <ChatUploadExplainPopup isFullMode />
                </div>
              </div>
            </div>
            <HaveAnAccount />
          </Overlay>
        </div>

      </>
    )
  );
};

export default EmailSignupForm;

import React from 'react';
import './style.css';
import app from 'new-ui/app';
import { PAGES, URI } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import Checkmark from '../Checkmark';
import WidgetBtn from '../Button';
import AssistanceButton from '../AssistanceButton';
import { FEED_GA_CATEGORY, FEED_GA_EVENTS, FEED_GA } from '../constants';
import { isUserFromProvider } from 'new-ui/functions';

const getOptionsCount = (treatments, trials) => {
  let count = trials?.length ?? 0;
  if (!isUserFromProvider()) {
    count += treatments?.length ?? 0;
  };
  return count;
};

const TreatmentOptions = ({ treatments, trials }) => {
  const { t } = useTranslation();

  const onBtnClick = () => {
    app.sendGoogleAnalyticsEvent(FEED_GA_CATEGORY, FEED_GA_EVENTS.WIDGET_INTERACTED, {
      widget: 'treatments',
    });
    app.sendGoogleAnalyticsEvent(FEED_GA_CATEGORY, FEED_GA.events.treatmentsInteracted, {
      widget: 'treatment options',
    });
    app.history.push(`${URI[PAGES.RESULTS]}?tab=${'TRIALS'}`);
  };

  const onNewClick = () => {
    app.history.push(`${URI[PAGES.RESULTS]}?sort=${'recent'}`);
  };

  const options = [
    {
      id: 1,
      key: 'items.approved_drugs',
      label: 'Approved drugs',
      count: treatments?.length,
      isVisible: !isUserFromProvider()
    },
    {
      id: 1,
      key: 'items.clinical_trial',
      label: 'Clinical trial',
      count: trials?.length,
      new: trials?.filter((el) => el.isNew).length,
      isVisible: true
    },
  ];

  const visibleOptions = options.filter(option => option.isVisible);

  return (
    <div className="treatment-options">
      <div className="options-header">
        <div className="options-count">
          <div className="count">{getOptionsCount(treatments, trials)}</div>
          <span className="divider">/</span>
          <div className="label">{t('summary_widget.sub_title')}</div>
        </div>
      </div>
      <div className="options-body">
        <h2 className="title">{t('summary_widget.title')}</h2>
        <h3 className="sub-title">
          {t('summary_widget.description')}
          {/* & <span className='highlight'>your preference</span> */}
        </h3>
        <ul className="options-list">
          {visibleOptions.map((option, key) => (
            <li className="options-item" key={key}>
              <Checkmark />
              <div className="label">{t(`summary_widget.${option.key}`)}</div>
              <div className="count">{option.count}</div>
              {!!option.new && (
                <div className="new">
                  /{' '}
                  <span onClick={onNewClick}>
                    {option.new}
                    {' '}
                    NEW
                  </span>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      <div className="options-footer">
        <WidgetBtn title={t('summary_widget.actions.my_options.button')} onClick={onBtnClick} />
        <AssistanceButton title={t('summary_widget.actions.assistance.button')} onClick={()=>{ app.cache.assistTrigBy = 'treatmentOptions'; }} />
      </div>
    </div>
  );
};

export default TreatmentOptions;

import configs from 'configs';
import { authHeaders } from 'functions/authHeader';
import request from 'functions/api/request';

const apiUrl = configs.beApiUrl;

export const getTotalArticles = async (condition) => {
  let response = null;

  const requestOptions = {
    url: `${apiUrl}/blogPostReference/find?diseaseCode=${condition}`,
    headers: await authHeaders(),
    json: true,
  };

  try {
    response = await request.get(requestOptions);
  } catch (ex) {
    console.error('Error occurred, while getting articles', ex);
    throw ex;
  }

  return response?.data;
};

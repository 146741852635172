import queryString from 'query-string';

export const assembleRoutePath = ({ pathname, searchParameters = {}, hash }) => {
  let path = pathname;
  if (Object.keys(searchParameters).length) {
    path += `?${queryString.stringify(searchParameters, { skipNull: true })}`;
  }
  if (hash) {
    path += hash.startsWith('#') ? hash : `#${hash}`;
  }
  return path;
};

import { useState, useEffect, useRef } from 'react';

// value must be boolean
// timeout must be stable throughout rendering
export const useMinDurationTrue = ({ value, timeout }) => {
  const [canBeFalse, setCanBeFalse] = useState(false);
  const timeRef = useRef(null);

  useEffect(() => {
    if (!value || timeRef.current) {
      return;
    }
    timeRef.current = setTimeout(() => {
      timeRef.current = null;
      setCanBeFalse(true);
    }, timeout);
    setCanBeFalse(false);
  }, [value, timeout]);

  useEffect(() => () => {
    if (timeRef.current) {
      clearTimeout(timeRef.current);
    }
  }, []);

  return value || !canBeFalse;
};

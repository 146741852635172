import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import { GA_HCP } from 'new-ui/Search/GA';
import { addCurrentUserPendingDocuments, updateCurrentUser } from 'modules/currentUser/actions';
import {
  getUserCondition, getUserConditionProfile,
} from 'modules/user/utils';
import { getGeneralDiseaseSubType } from 'modules/user/conditionProfileHelpers';
import { allCancerTypes } from 'utils/constants/cancerTypes';
import { getIsHCPPatient, mergeExtractedProfile } from '../helpers';
import { savePatientPendingDocuments } from './savePatientPendingDocuments';
import { saveHCPPatient } from './saveHCPPatient';

const { GENERAL: GENERAL_DISEASE } = allCancerTypes;

export const fullProfileUploadPopup = () => (dispatch, getState) => {
  const { currentUser: { data: currentUser } } = getState();

  const condition = getUserCondition(currentUser);
  const generalDiseaseSubType = condition === GENERAL_DISEASE
    ? getGeneralDiseaseSubType(getUserConditionProfile(currentUser))
    : null;

  const handleUploadedFiles = (files, { jobId, source }) => {
    if (!files.length) {
      return;
    }

    dispatch(addCurrentUserPendingDocuments([{ files, metadata: { jobId, source } }]));

    const { currentUser: { data: actualCurrentUser } } = getState();

    if (!getIsHCPPatient(actualCurrentUser)) {
      return;
    }

    dispatch(savePatientPendingDocuments())
      .catch((ex) => {
        console.error('savePatientPendingDocuments ex', ex);
      });
  };

  app.setPopup(POPUP_TYPES.CURRENT_USER_UPLOAD, {
    theme: 'middle',
    preventOverlayClose: true,
    onUploadedFilesChange: handleUploadedFiles,
    condition,
    generalDiseaseSubType,
    onSuccess: async (nextUser) => {
      const { currentUser: { data: actualCurrentUser } } = getState();

      app.setShowPreloader(true);
      let mergedUser;
      try {
        mergedUser = await mergeExtractedProfile({ currentProfile: actualCurrentUser, extractedProfile: nextUser });
        // eslint-disable-next-line no-empty
      } catch {}

      app.setShowPreloader(false);

      if (!mergedUser) {
        return;
      }

      // we don't use replaceCurrentUser so as to preserve pendingDocuments
      // if a patient (or hcp patient) is not registered pendingDocuments are not saved on the backend
      dispatch(updateCurrentUser(mergedUser, true));

      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.UPLOAD_SUCCESS);

      if (!getIsHCPPatient(mergedUser)) {
        return;
      }

      // don't block popup hiding
      dispatch(saveHCPPatient())
        .catch((ex) => {
          console.error('fullProfileUploadPopup saveHCPPatient ex', ex);
        });
    },
  });
};

import { SORT_ORDER } from './constants';

const sortResultItems = (items, sortOrder) => {
  if (sortOrder === SORT_ORDER.RECENT) {
    return [...items].sort(({ isNew: aIsNew }, { isNew: bIsNew }) => (
      (bIsNew ? 1 : 0) - (aIsNew ? 1 : 0)
    ));
  }
  return items;
};

const combineResultItems = ({ trials, treatments }) => {
  const sponsored = [...trials, ...treatments].filter((item) => item.isSponsored);
  const filteredTrials = trials.filter((item) => !item.isSponsored);
  const filteredTreatments = treatments.filter((item) => !item.isSponsored);
  const combined = [...sponsored];

  let trialIndex = 0;
  let treatmentIndex = 0;

  while (trialIndex < filteredTrials.length || treatmentIndex < filteredTreatments.length) {
    for (let i = 0; i < 2 && trialIndex < filteredTrials.length; i += 1) {
      combined.push(filteredTrials[trialIndex]);
      trialIndex += 1;
    }

    for (let i = 0; i < 2 && treatmentIndex < filteredTreatments.length; i += 1) {
      combined.push(filteredTreatments[treatmentIndex]);
      treatmentIndex += 1;
    }
  }
  return combined;
};

export const prepareResultItems = ({ trials, treatments, sortOrder }) => {
  if (sortOrder) {
    return sortResultItems([...trials, ...treatments], sortOrder);
  }
  return combineResultItems({ trials, treatments });
};

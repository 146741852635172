import { MATCH_FLOW } from 'components/Matches/constants';
import { MatchFlow } from 'domains/Matches/MatchFlow';

const BaseStorageShowOperations = {
  setShow(value) {
    localStorage.setItem(this.storageKey, value);
  },
};

const UpdateConditionalProfileDialog = {
  storageKey: 'show_update_medical_condition_dialog',
  ...BaseStorageShowOperations,
};

export const LatestMatchesFlow = {
  onLogin() {
    MatchFlow.setMatchFlow(MATCH_FLOW.LATEST);
    UpdateConditionalProfileDialog.setShow(true);
  },
  onUpdateMedicalCondition() {
    MatchFlow.setMatchFlow(MATCH_FLOW.BASIC);
    UpdateConditionalProfileDialog.setShow(false);
  },
  onQuestionnairePass() {
    MatchFlow.setMatchFlow(MATCH_FLOW.BASIC);
    UpdateConditionalProfileDialog.setShow(false);
  },
};

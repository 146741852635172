export default {
  maxWidth320: { maxWidth: 320 },
  minWidth321: { minWidth: 321 },
  maxWidth480: { maxWidth: 480 },
  minWidth481: { minWidth: 481 },
  maxWidth560: { maxWidth: 560 },
  minWidth561: { minWidth: 561 },
  maxWidth768: { maxWidth: 768 },
  minWidth769: { minWidth: 769 },
  maxWidth800: { maxWidth: 800 },
  maxWidth960: { maxWidth: 960 },
  minWidth961: { minWidth: 961 },
  maxWidth992: { maxWidth: 992 },
  minWidth993: { minWidth: 993 },
  maxWidth1200: { maxWidth: 1200 },
  minWidth1201: { minWidth: 1201 },
  maxWidth1921: { maxWidth: 1921 },
  minWidth1922: { minWidth: 1922 }
};

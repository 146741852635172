import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as reduxFormReducer } from 'redux-form';
import { sponsoredTrialTemplate } from 'modules/sponsoredTrialTemplate/reducer';
import { appConfiguration } from 'components/AppConfiguration/reducer';
import { provider } from 'components/Provider/reducer';
import { backdrop } from 'modules/backdrop/reducer';
import { currentUser } from 'modules/currentUser/reducer';
import { geoLocation } from './geoLocation';
import {
  authentication,
  profile,
  userGet,
  userCreate,
  userUpdate,
  superUser,
} from '../../components/Authentication/reducers';
import { serverStatus } from './serverStatus';
import { matches } from '../../components/Matches/reducer';
import { main } from './main';
import { partner } from './partner';
import { internal } from './internal';
import { history } from '../../functions/history';
import { treatmentFinder } from '../../components/TreatmentFinder/reducer';
import { redirectTo } from '../../components/AppRoutes/reducer';
import { dialog } from '../../components/Dialogs/reducer';
import { questionnaire } from '../../components/Questionnaire/reducer';
import { callToAction } from './callToAction';
import { appSettings } from './appSettings';

export default combineReducers({
  appSettings,
  appConfiguration,
  provider,
  authentication,
  callToAction,
  dialog,
  form: reduxFormReducer,
  geo_location: geoLocation,
  main,
  matches,
  questionnaire,
  treatmentFinder,
  partner,
  internal,
  profile,
  redirectTo,
  router: connectRouter(history),
  serverStatus,
  superUser,
  userGet,
  userCreate,
  userUpdate,
  sponsoredTrialTemplate,
  backdrop,
  currentUser,
});

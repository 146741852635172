/* eslint-disable no-empty-pattern */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable no-trailing-spaces */
/* eslint-disable semi */
import React, { useState, useRef } from 'react';
import './style.css';
import { useTranslation } from 'utils/modifiedTranslation';
import EventTypes from './EventTypes';
import EventList from './EventList';
import StatusCards from './StatusCards';
import app from 'new-ui/app';

export const COMPONENT = {
  STATUS_CARDS: 'StatusCards',
  EVENT_LIST: 'eventList',
  EVENT_TYPES: 'EventTypes'
};

export const GA = {
  category: 'Feed',
  events: {
    eventTypeClicked: 'Widget Reminder Event type clicked',
    eventAddClicked: 'Widget Reminder Event Add clicked',
    scheduleAddClicked: 'Widget Reminder Add schedule clicked'
  },
};

const EventsWidget = () => {
  const eventsWidgetRef = useRef(null);

  const scrollToEventsWidget = () => {
    if (eventsWidgetRef.current) {
      eventsWidgetRef.current.scrollIntoView();
    }
  };

  const { t } = useTranslation();

  const [activeComponent, setActiveComponent] = useState(COMPONENT.EVENT_TYPES);
  const [selectedType, setSelectedType] = useState('');

  const sendGAEvent = (type) => {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.eventTypeClicked, {
      type: type,
    });
  };

  const renderComponent = () => {
    switch (activeComponent) {
      case COMPONENT.STATUS_CARDS:
        return <StatusCards 
          onClickBack={() => {
            setActiveComponent(COMPONENT.EVENT_LIST)
          }} 
        />;
      case COMPONENT.EVENT_LIST:
        return <EventList 
          onSelectType={onEventTypeClick} 
          selectedType={selectedType} 
        />;
      case COMPONENT.EVENT_TYPES:
        return <EventTypes 
          onSelectType={(type) => {
            setActiveComponent(COMPONENT.EVENT_LIST);
            setSelectedType(type);
            sendGAEvent(type);
          }}
        />;
      default:
        return <EventTypes 
          onSelectType={(type) => {
            setActiveComponent(COMPONENT.EVENT_LIST);
            setSelectedType(type);
            sendGAEvent(type);
          }}
        />;
    }
  };

  const headerContent = {
    EventTypes: {
      h2: 'events_widget.title',
      h3: 'events_widget.sub_title',
    },
    eventList: {
      h2: 'events_widget.title',
      h3: 'events_widget.sub_title',
    },
    StatusCards: {
      h2: 'events_widget.status.title',
      h3: 'events_widget.status.sub_title',
    },
  };

  const onEventTypeClick = (eventName) => {
    setActiveComponent(eventName);
    scrollToEventsWidget()
  };

  return (
    <div className="events-widget" ref={eventsWidgetRef}>
      <div className="options-header">
        <h2 className="title">{t(headerContent[activeComponent].h2)}</h2>
        <h3 className="sub-title">{t(headerContent[activeComponent].h3)}</h3>
      </div>
      <div className="options-body">
        {renderComponent()}
      </div>
    </div>
  );
};
export default EventsWidget;

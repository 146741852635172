import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import { WidgetSectionDelimiter } from './WidgetSectionDelimiter/WidgetSectionDelimiter';
import { CustomSurveyWidget } from './CustomSurveyWidget/CustomSurveyWidget';
import EventsWidget from './EventsWidget';

export const CustomerRefUserWidgets = () => {
  const { t } = useTranslation();
  return (
    <>
      <WidgetSectionDelimiter text={t('feed.sections.treatment.title')} />
      <EventsWidget />
      <br />
      <CustomSurveyWidget />
    </>
  );
};

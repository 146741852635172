import React, { useState, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { push } from 'connected-react-router'; 
import app from 'new-ui/app';
import {
  ROUTES, PAGES, THEMES, INTERCOM_EVENTS, POPUP_TYPES,
  URI,
  INDICATIONS,
} from 'new-ui/constants';
import { getGeneralDiseaseSubType } from 'modules/user/conditionProfileHelpers';
import preserveQueryParam from 'functions/preserveQueryParam';
import ArrowCirlce from 'new-ui/assets/icons/arrow-circle-right.svg';
import Logo from 'new-ui/assets/icons/leal_logo_2.svg';
import greenArrow from 'new-ui/assets/icons/green_arrow.svg';
import academicCancer from 'new-ui/assets/icons/academic_cancer_logo.svg';
import closeIcon from 'new-ui/assets/icons/close.svg';
import aiDiscovery from 'new-ui/assets/icons/ai_discovery.svg';
import uploadDocument from 'new-ui/assets/icons/upload_document.svg';
import ThumbsIcon from 'new-ui/assets/icons/thumb_up_with_start.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { UPLOADER_STATES } from 'new-ui/Components/FilesUploader/FileUploaderConstants';
import { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';
import { getSearchApi } from 'modules/hcp/api';
import {
  getBiomarkerTitle,
  getDrugName,
  getLoggedUser,
  questionsNormalizer,
} from 'new-ui/functions';
import {
  getIndications,
} from 'new-ui/api';
import InfoIcon from 'new-ui/assets/icons/info.svg';
import { deduplicateList } from 'functions/deduplicateList';
import { authenticationActions } from 'components/Authentication/actions';
import {
  STORAGE_SEARCH_BIOMARKERS,
  STORAGE_SEARCH_INDICATION,
} from 'new-ui/Search/SearchParameters';
import './Upload.css';
import Intake from 'new-ui/Components/Intake';
import { UPLOAD_INTAKE_QUESTIONS } from 'new-ui/Components/Intake/questions';
import { addUserDocuments } from 'modules/userDocuments/api';
import moment from 'moment/moment';
import { QUESTION_TYPES } from 'new-ui/Components/QuestionRenderer';
import { useTranslation } from 'utils/modifiedTranslation';
import ChatButton from 'new-ui/ChatButton';
import { getQstack } from 'new-ui/Components/Intake/functions';
import InfinityLoader from 'new-ui/Components/InfinityLoader';
import ChatUploadExplainPopup from 'new-ui/Popups/ChatUploadExplain';
import { useSetHeaderSettings } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { VERSIONS } from 'new-ui/Components/PageHeader/constants';
import mediaQueriesConstants from 'utils/constants/mediaQueries';
import { allCancerTypes } from 'utils/constants/cancerTypes';
import { useDispatch } from 'react-redux';
import { getUserConditionProfile } from 'modules/user/utils';
import Animate from 'new-ui/Components/Animate/animate';
import getLatestDate from 'functions/getLatestDate';
import FilesUploader from 'new-ui/Components/FilesUploader';
import { JOB_STATUS } from 'components/MedicalFiles/constants';
import { getErrorMessages } from 'components/MedicalFiles/utils';
import { uploadFile } from './api';
import FreeImage from './assets/free.svg';
import HipaaImage from './assets/hipaa.svg';
import IsoImage from './assets/iso.svg';
import LogoFooter from './assets/logo-footer.svg';
import RecordsEmail from './RecordsEmail';
import ReceivingFile from './ReceivingFile';
import PartnersAndPubications from './Components/PartnersAndPubications/PartnersAndPubications';
import { Slider } from './Components/Slider/Slider';
import ResultsSkeleton from './Skeleton/AnalysSkeleton';
import SendEmailAnalyzing from './SendEmailAnalyzing';
import ErrorMessage from './ErrorMessage/ErrorMessage';
import EmailSignupForm from './EmailSignUp/EmailSignUp';
import { geOnJobStatusChange } from './uploadHelpers';

const GA = {
  category: 'Upload',
  events: {
    pageview: 'Upload file landing page viewed',
    browse: 'Clicking on browse',
    dragFile: 'Drag a file',
    sendRecord: 'Clicking on Send record by mail',
    answerManually: 'Clickin on Answer manually',
    emailToLater: 'Upload file clicking continue later',
    emailToLaterForm: 'Continue later form viewed',
    emailToLaterPopup: 'Continue later final popup viewed',
    moreUpload: 'Upload more clicked',
    next: 'Clicking on “Next”',
    startVideo: 'Upload file video started',
    endVideo: 'Upload file video finished',
    fileSuccessfully: 'Upload file extracted successfully',
    fileFailed: 'Upload file extracted failed',
    reviewing: 'When the revireing page is presented',
    confirmaed: 'When confirmaed',
    success: 'signup_success',
    error: 'signup_error',
  },
};

let form;
let indications;
const uploader = {};

let indication;

export const UPLOAD_TYPE = {
  RECORDS: 'RECORDS',
  RECEIVING: 'RECEIVING',
  ANSWER: 'ANSWER',
  UPLOADING: 'UPLOADING',
  ANALYZING: 'ANALYZING',
};

const { GENERAL: GENERAL_DISEASE } = allCancerTypes;

const Upload = () => {
  let setMobileMenu = null;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [uploaderState, setUploaderState] = useState(UPLOADER_STATES.SELECT);
  const [showForm, setShowForm] = useState(true);
  const [showSignUpPage, setShowSignUpPage] = useState(false);
  const [showMedicalProileInfo, setShowMedicalProileInfo] = useState(false);
  const [showLaterUpdaeFilesInfo, setLaterUpdaeFilesInfo] = useState(false);
  const [showOnlyUploder, setShowOnlyUploder] = useState(false);
  const [showAnswerButton, setShowAnswerButton] = useState(true);
  const [newUser, setNewUser] = useState(user);
  const [hideOrElement, setHideOrElemet] = useState(false);
  const [userUpdateComplete, setUserUpdateComplete] = useState(false);
  const [biomarkers, setBiomarkers] = useState([]);
  const [profile, setProfile] = useState({});
  const [localStorageUpdated, setLocalStorageUpdated] = useState(false);
  const [drugsRecevedFromJob, setDrugsRecevedFromJob] = useState(null);
  const [videoIsPlaying, setVideoIsPlaying] = useState(true);
  const [job, setJob] = useState(null);
  const [user, setUser] = useState(app?.user ?? null);
  const [formData] = useState({
    email: '',
    phone: '',
  });
  const [results, setResults] = useState(null);
  const [uploadType, setUploadType] = useState(isSendEmail() ? UPLOAD_TYPE.RECORDS : null);
  const [finishUploading, setIsFinishUploading] = useState(false);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const [errorMessages, setErrorMessages] = useState([]);

  const history = useHistory();

  const isWidthLess800 = useMediaQuery(mediaQueriesConstants.maxWidth800);

  const onJobStatusChange = geOnJobStatusChange({ dispatch, keepQstack: true });

  useSetHeaderSettings({
    isLanguageMenuShown: true,
    isLoginButtonShown: true,
    version: VERSIONS.WHITE,
    isLogoShown: !isWidthLess800,
  });

  useEffect(() => {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.pageview, {
      name: 'Upload file landing page viewed',
    });
  }, []);

  useEffect(() => {
    showSkip();
  }, [finishUploading]);

  useEffect(() => {
    const checkForUser = () => {
      if (app.user) {
        setUser(app.user);
      } else {
        setTimeout(checkForUser, 2000); // Check again after 2 seconds
      }
    };
    checkForUser(); // Initial check
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      app.user = await app.getUser();
    };
    if (getLoggedUser() && !app.user) {
      fetchUser();
    }
    if (app?.user?.personal) {
      history.push('/?newprofile=true');
    }
    return () => {
      document.body.classList.remove('upload-page');
    };
  }, [history]);

  // init
  useEffect(() => {
    localStorage.removeItem(STORAGE_SEARCH_BIOMARKERS);
    localStorage.removeItem(STORAGE_SEARCH_INDICATION);
    document.body.classList.add('upload-page');

    const init = async () => {
      indications = await getIndications();
      form = {
        inputs: {
          email: {
            type: FORM_INPUT_TYPES.INPUT,
            required: true,
            icon: 'email',
            title: t('upload.registration_email_text'),
            placeholder: '',
            className: 'registration-email',
          },
          phone: {
            type: FORM_INPUT_TYPES.PHONE,
            icon: 'phone',
            title: uploadType === UPLOAD_TYPE.RECORDS ? t('upload.registration_phone_touch_text') : t('upload.registration_phone_text'),
            placeholder: '651465',
            className: 'registration-phone',
          },
        },
        data: {
          email: '',
          phone: '',
        },
      };
    };

    init();
  }, [t, uploadType]);

  useEffect(() => {
    Array.from(document.querySelectorAll('.intercom-button')).forEach((a) => {
      a.classList[(
        uploaderState === UPLOADER_STATES.SELECT
        || uploaderState === UPLOADER_STATES.SUCCESS
        || uploaderState === UPLOADER_STATES.ERROR
      ) ? 'remove' : 'add']('hidden');
    });
  }, [uploaderState]);

  useEffect(() => {
    const updateUser = async () => {
      const qstack = deduplicateList([
        ...(newUser?.condition_profile?.qstack ?? []),
        ...(profile?.condition_profile?.qstack ?? []),
      ]);

      newUser.info = {
        ...newUser.info,
        ...profile?.info ?? {},
      };

      newUser.personal = {
        ...newUser.personal,
        ...profile?.personal ?? {},
      };

      newUser.condition_profile = {
        ...newUser.condition_profile,
        ...profile?.condition_profile ?? {},
        qstack,
      };

      await app.store.dispatch(authenticationActions.updateUser(newUser, true));
      setUserUpdateComplete(true);
    };

    const updateIntercomUserAttributes = () => {
      app.intercom.updateUserAttributes({
        file_types: deduplicateList(job?.files?.map(({ type }) => type) ?? []),
        last_document_date: getLatestDate(job?.files?.map(({ documentDate }) => documentDate) ?? []),
        last_upload_date: moment().format('YYYY-MM-DD'),
      }, newUser?.personal?.user_wix_id);
    };

    if (newUser && Object.keys(profile).length) {
      updateUser();
      updateIntercomUserAttributes();
    }
    // eslint-disable-next-line
  }, [newUser, profile]);

  useEffect(() => {
    app.intercom.boot({ user: newUser });
  }, [newUser]);

  useEffect(() => {
    const updateUserDocuments = async () => {
      await addUserDocuments({
        jobId: job.id,
        profileId: app.user.personal.profile_id,
        files: job.files,
        source: 'upload',
      });
    };

    if (!!job && !!app.user && !!uploader?.files?.length && !!app.user.personal.profile_id && uploaderState === UPLOADER_STATES.CUSTOM1) {
      updateUserDocuments();
    }
    // eslint-disable-next-line
  }, [job, uploaderState]);

  const back = () => {
    uploader.setFiles([...uploader.files.filter((a) => !a.error)]);
    uploader.setState(UPLOADER_STATES.MULTI);
  };

  const gotoNextStep = () => {
    if (finishUploading) {
      uploader.setState(UPLOADER_STATES.CUSTOM1);
    } else {
      uploader.setState(UPLOADER_STATES.ANALYZING);
    }
  };

  const showSkip = () => {
    const $skip = document.querySelector('.animate-skip');
    if ($skip) $skip.classList.add('visible');
    const $loader = document.querySelector('.animate-loader');
    if ($loader) $loader.classList.add('visibility-hidden');
  };

  const header = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return null;
      case UPLOADER_STATES.MULTI:
        return (
          <>
            <div className="files-uploader-preview-title">{t('upload.upload_file_text')}</div>
          </>
        );
      case UPLOADER_STATES.UPLOADING:
        return (
          <Animate
            onDone={() => {
              setVideoIsPlaying(false);
              gotoNextStep();
            }}
            showForm={false}
            onSkip={gotoNextStep}
            GA={GA}
          />
        );
      case UPLOADER_STATES.ANALYZING:
        return (
          <>
            <div className="upload-page-title">{t('upload.analyzed_text')}</div>
            <ResultsSkeleton
              finishUploading={finishUploading}
              onDone={() => {
                if (finishUploading) {
                  if (videoIsPlaying) {
                    showSkip();
                  } else {
                    uploader.setState(UPLOADER_STATES.CUSTOM1);
                  }
                }
              }}
            />
          </>
        );
      case UPLOADER_STATES.ERROR:
        return (
          <>
            <div className="upload-page-error">
              {t('upload.error_message')}
            </div>
          </>
        );
      case UPLOADER_STATES.SUCCESS:
        return <div className="top-title">Generating your biomarkers report</div>;
      case UPLOADER_STATES.CUSTOM1:
        // here app.user should be avail. after success signup

        const condition = app.user?.personal?.condition;
        // const condition = UPLOAD_MOCK.indication; // TODO REMOVE
        // app.user = UPLOAD_MOCK.profile;

        const questions = questionsNormalizer(UPLOAD_INTAKE_QUESTIONS, condition);
        app.user.personal.condition = condition;

        // condition specific sub-questions:
        switch (condition) {
          case INDICATIONS.LUNG:
            questions.push('braf_mutation', 'egft_mut', 'kras_mut', 'erbb2_her2_mut', 'met_mut');
            break;
          case INDICATIONS.CRC_2:
          case INDICATIONS.CRC:
            questions.push('kras_mut');
            break;
          case INDICATIONS.MELANOMA:
          case INDICATIONS.MELANOMA_2:
            questions.push('braf_mutation');
            break;
          default:
            break;
        }

        app.sendGoogleAnalyticsEvent(GA.category, GA.events.reviewing, { name: 'Upload file attrubutes reviewing viewed' });

        const DRUGS_RECEIVED_QID = 'drugs_received';

        questions.unshift(DRUGS_RECEIVED_QID);

        const DashboardButton = () => (
          <>
            <div className="upload-intake-parent-button">
              <Button
                forwardIcon={ArrowCirlce}
                action={async () => {
                  app.setShowPreloader(true);
                  const questions = await app.getQuestions(app.user.personal.condition, true);
                  const qstack = await getQstack(app.user, questions);
                  app.user.condition_profile.qstack = qstack;
                  await app.updateUser();
                  app.setShowPreloader(false);
                  app.sendGoogleAnalyticsEvent(GA.category, GA.events.confirmaed, { name: 'Upload file attrubutes confirmed' });
                  window.location = `${ROUTES[PAGES.RESULTS]}?rematch=1&signup=1&source=upload`;
                }}
                title={t('upload.confirm_and_see_btn_text')}
              />
            </div>
          </>
        );
        return (
          <div>
            <div className="top-title">{t('upload.review_medical_title')}</div>
            <div className="upload-intake-block">
              <div className="upload-intake-parent">
                <Intake
                  customQuestions={questions}
                  showOnlyAnswered
                  expandQuestions={[DRUGS_RECEIVED_QID]}
                  appendQuestions={[
                    {
                      id: DRUGS_RECEIVED_QID,
                      section: 'MY_CANCER',
                      type: QUESTION_TYPES.DRUGS_RECEIVED,
                      valueRenderer: () => {
                        if (!drugsRecevedFromJob || !drugsRecevedFromJob?.length) return 'No drugs found';
                        return drugsRecevedFromJob.map((drug) => window.t(getDrugName(drug))).join(', ');
                      },
                    },
                  ]}
                  flatMode
                  onUpdate={async (user) => {
                    app.updateUser(user, true);
                  }}
                  user={app.user}
                />
                <DashboardButton />
              </div>
              <div className="add-absulot"><ChatButton popupType={POPUP_TYPES.CHAT_UPLOAD} text="Understand your profile" /></div>
            </div>

          </div>
        );
    }
  };

  const errorFooter = () => (
    <div>
      <Button title={t('upload.upload_another_file')} action={back} />
      <div className="height-10" />
      <Button
        theme={THEMES.ORANGE}
        title={t('upload.answer_btn_text')}
        action={() => {
          history.push('/?newprofile=true');
        }}
      />
    </div>
  );

  const successFooter = () => (
    <div>
      <div className="upload-page-found">
        {results !== null ? (
          <div>
            <div className="upload-page-analyze-found-info">
              <img src={InfoIcon} alt="info" />
            </div>
            There are
            {' '}
            <b>{results}</b>
            {' '}
            targeted therapy treatments for you based on your biomarkers
          </div>
        ) : 'Searching for results...'}
      </div>
      {userUpdateComplete ? <Button title="Show my treatment options" action={gotoSearch} /> : null}
    </div>
  );

  const isErrored = (files = uploader.files) => {
    const erroredFiles = files.filter((a) => a.error);
    return erroredFiles.length === files.length;
  };

  const footer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return null;
      case UPLOADER_STATES.UPLOADING:
        return null;
      case UPLOADER_STATES.ERROR:
        return (
          <div className="ngs-upload-error">
            {errorMessages?.map((it, idx) => (!idx ? <h3>{it}</h3> : <p>{it}</p>))}
          </div>
        );
      case UPLOADER_STATES.SUCCESS:
        return isErrored() ? errorFooter() : successFooter();
      case UPLOADER_STATES.CUSTOM1:
        return null;
      case UPLOADER_STATES.ANALYZING:
        return null;
    }
  };

  const filerenderer = (file) => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
      case UPLOADER_STATES.UPLOADING:
      case UPLOADER_STATES.ERROR:
        return null;
      case UPLOADER_STATES.SUCCESS:
        return file.biomarkers && file.biomarkers.length ? (
          <>
            <div>
              in this document we identified the following biomarkers:
            </div>
            <div className="upload-page-files-biomarkers">
              {
                file.biomarkers.map((biomarker, key) => (
                  <React.Fragment key={key}>
                    <div className="upload-analyze-files-biomarker">
                      <div className="upload-analyze-files-biomarker-name">{getBiomarkerTitle(biomarker)}</div>
                    </div>
                  </React.Fragment>
                ))
              }
            </div>
          </>
        ) : null;
    }
  };

  const fetchResults = async (biomarkers) => {
    let userId;
    try {
      userId = JSON.parse(localStorage.getItem('user')).user_wix_id;
    } catch (e) {
    }
    const r = await getSearchApi({
      biomarkers: biomarkers.length ? biomarkers : ['none'],
      condition: indication || null,
      country: null,
      distance: null,
      diseaseSubType: null,
      diseaseType: null,
      diseaseStatus: null,
      // isDefault: true,
      lang: 'en',
      patientStage: null,
      patientStageMet: null,
      patientStageMibc: null,
      patientStageNmibc: null,
      chromosomalAlterions: null,
      receivedAllogenicTransplant: null,
      receivedTreatment: null,
      numTreatmentLines: null,
      userId,
    });
    setResults((r?.trials?.result?.length || 0) + (r?.trials?.treatments?.length || 0));
  };

  const uploadError = () => {
    uploader.setState(UPLOADER_STATES.ERROR);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.fileFailed, { name: 'Upload file extracted failed' });
  };

  const upload = async () => {
    const files = [...uploader.files];
    uploader.setState(UPLOADER_STATES.UPLOADING);

    const condition = app?.user?.personal?.condition;

    uploadFile({
      files,
      getRecaptchaTokenAsync,
      onJobStatusChange,
      condition: condition === GENERAL_DISEASE ? getGeneralDiseaseSubType(getUserConditionProfile(app?.user)) : condition,
      onUpload: async (r) => {
        if (r?.status === JOB_STATUS.ERROR || r?.status === JOB_STATUS.FILE_ERROR) {
          uploadError(r.error);
          setErrorMessages(getErrorMessages(r, t));
          app.intercom.sendEvent(INTERCOM_EVENTS.MEDICAL_FILES.ERROR);
          return;
        }

        app.intercom.sendEvent(INTERCOM_EVENTS.MEDICAL_FILES.SUCCEED, {
          filesQuantity: r?.files?.length ?? 0,
        });

        const profile = r?.profile ?? {};
        const conditionProfile = profile?.condition_profile ?? {};
        const biomarkers = conditionProfile?.biomarkers ?? [];
        const drugs = conditionProfile?.drugs_received;

        if (r?.indication) {
          setIsFinishUploading(true);
        }

        setDrugsRecevedFromJob(drugs);
        setJob(r);
        setBiomarkers(biomarkers);
        setProfile(profile);
        fetchResults(biomarkers);
        uploader.setFiles(files);
        // uploader.setState(UPLOADER_STATES.SUCCESS);
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.fileSuccessfully, { name: 'Upload file extracted successfully' });
      },
      onError: () => {
        uploader.setState(UPLOADER_STATES.ERROR);
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.fileFailed, { name: 'Upload file extracted failed' });
        app.intercom.sendEvent(INTERCOM_EVENTS.MEDICAL_FILES.ERROR);
      },
    });
  };

  useEffect(() => {
    const handleLocalStorageUpdate = () => {
      setLocalStorageUpdated((prevState) => !prevState);
    };
    window.addEventListener('localStorageUpdated', handleLocalStorageUpdate);
    return () => {
      window.removeEventListener('localStorageUpdated', handleLocalStorageUpdate);
    };
  }, []);

  useEffect(() => {
  }, [showSignUpPage]);

  const gotoSearch = async () => {
    if (app.isIntakeFlow(indication)) {
      history.push(`${ROUTES[PAGES.RESULTS]}?signup=1&rematch=1`);
      return;
    }
    localStorage.setItem(STORAGE_SEARCH_INDICATION, formData.indication || null);
    localStorage.setItem(STORAGE_SEARCH_BIOMARKERS, JSON.stringify(biomarkers));
    history.push(`${ROUTES[PAGES.SEARCH]}?source=ngs`);
  };

  const handleUploadType = (uploadType) => {
    switch (uploadType) {
      default:
        return null;
      case UPLOAD_TYPE.RECORDS:
        return <RecordsEmail setShowForm={setShowForm} setUploadType={setUploadType} />;
      case UPLOAD_TYPE.ANALYZING:
        return <SendEmailAnalyzing />;
      case UPLOAD_TYPE.RECEIVING:
        return <ReceivingFile setUploadType={setUploadType} />;
      case UPLOAD_TYPE.ANSWER:
        return history.push('/?newprofile=true');
      case UPLOAD_TYPE.UPLOADING:
        return (
          <>
            <div className="animate-section">
              <Animate onSkip={() => {
              }}
              />
            </div>
          </>
        );
    }
  };

  const medicalProileInfoJsxStrings = [
    {
      title: t('upload.medical_profile_info_title_1'),
      content: t('upload.medical_profile_info_content_1'),
    },
    {
      title: t('upload.medical_profile_info_title_2'),
      content: t('upload.medical_profile_info_content_2'),
    },
    {
      title: t('upload.medical_profile_info_title_3'),
      content: t('upload.medical_profile_info_content_3'),
    },
    {
      title: t('upload.medical_profile_info_title_4'),
      content: t('upload.medical_profile_info_content_4'),
    },
    {
      title: t('upload.medical_profile_info_title_5'),
      content: t('upload.medical_profile_info_content_5'),
    },
  ];

  const medicalProileInfoJsx = (
    <>

      <div className="medical-profile-info-box">
        <div id="medicalProileInfoJsx" className="medical-profile-info-main">
          <img
            alt="arrow button"
            src={closeIcon}
            onClick={() => {
              setShowMedicalProileInfo(false);
              setLaterUpdaeFilesInfo(false);
              app.sendGoogleAnalyticsEvent(GA.category, GA.events.dragFile, { name: 'Upload file find medical files popup closed' });
            }}
            style={{}}
          />
          <div className="medical-profile-info-main-header">
            <img
              alt="arrow button"
              src={ThumbsIcon}
              style={{}}
            />
            <div style={{ height: '20px' }} />
            <h2 className="medical-profile-info-text"><b>{t('upload.where_find_medical_files')}</b></h2>
          </div>
          <div />
          <div className="medical-profile-info-text">{t('upload.you_can_find_your_medical_files')}</div>
          <>
            <ul className="medical-profile-info-list">
              {medicalProileInfoJsxStrings.map((e) => (
                <li key={e.title} className="medical-profile-info-item">
                  <div className="medical-profile-info-text">
                    <b>{` ${e.title} `}</b>
                    {e.content}
                  </div>
                  <br />
                </li>
              ))}
            </ul>
          </>
          <div className="medical-profile-info-lower-text">
            <div className="medical-profile-info-foot-text">
              <div />
              <img
                alt="arrow button"
                src={Logo}
                style={{}}
              />

              <div className="medical-profile-info-text-with-margin">
                {`${t('upload.leal_fight_with_you')}`}
              </div>
            </div>
          </div>
          <div className="medical-profile-info-gap" />
          <div className="medical-profile-info-btn-div">
            <Button
              title={t('upload.start_answer_by_myself_btn')}
              className="medical-profile-info-button"
              action={() => {
                setLaterUpdaeFilesInfo(true);
                window.location.href = '/intake';
              }}
            />
          </div>
        </div>
      </div>
    </>
  );

  const laterUpdaeFilesInfoJsx = (
    <>
      <div className="medical-profile-info-box">
        <div id="medicalProileInfoJsx" className="later-upload-file">
          <img
            alt="arrow button"
            src={closeIcon}
            onClick={() => {
              setShowMedicalProileInfo(false);
              setLaterUpdaeFilesInfo(false);
            }}
            style={{}}
          />
          <div className="medical-profile-info-main-header">
            <img
              alt="arrow button"
              src={ThumbsIcon}
              style={{}}
            />
            <div style={{ height: '20px' }} />
            <h2 className="medical-profile-info-text"><b>{t('upload.where_find_medical_files')}</b></h2>
            <div style={{ height: '28px' }} />
          </div>
          <div />
          <div className="medical-profile-info-text">{t('upload.will_send_you_email')}</div>
          <div style={{ height: '28px' }} />
          <div className="medical-profile-info-text"><b>{t('upload.mother_teresa_quate')}</b></div>
          <div className="quote">{t('upload.mother_teresa')}</div>
          <div style={{ height: '20px' }} />
          <div className="medical-profile-info-lower-text">
            <div className="medical-profile-info-foot-text">
              <div />
              <img
                alt="arrow button"
                src={Logo}
                style={{}}
              />
              <div className="medical-profile-info-text-with-margin">
                <div className="medical-profile-info-space-from-logo" />
                {`${t('upload.leal_fight_with_you')}`}
              </div>
            </div>
          </div>
          <div style={{ height: '30px' }} />
          <div className="medical-profile-info-btn-div">
            <Button
              title={t('upload.start_answer_by_myself_btn')}
              className="later-upload-button"
              action={() => {
                window.location.href = '/intake';
              }}
            />
          </div>
        </div>
      </div>
    </>
  );

  const fileUploaderJsx = (
    <>
      {recaptcha}
      {
        showMedicalProileInfo ? <>{medicalProileInfoJsx}</>
          : showLaterUpdaeFilesInfo ? <>{laterUpdaeFilesInfoJsx}</>
            : (
              <div id="fileUploaderJsx" className={`${!showOnlyUploder ? 'file-uploader-main' : ''}`}>
                {
                  showOnlyUploder ? null
                    : (
                      <div id="main-div">
                        <div className="small_height" />
                        <h2 className="file-uploader-main-header">Lets build your medical profile</h2>
                        <div className="small_height" />
                        <div className="file-uploader-secoundry-header">Select a file from your devise storage (e.g., PDF, JPG, PNG).</div>
                        <div className="upload-page-gapper" />
                        <div className="upload-page-main-div">
                          <div className="flex">
                            <img src={aiDiscovery} alt="free" />
                            <div className="small-width-gap" />
                            AI assistant profile building
                          </div>
                          <div className="small_height" />
                        </div>

                      </div>
                    )
                }
                <div className={`${!showOnlyUploder ? 'file-uploader-main-upload-section' : ''}`}>
                  <div>
                    <div className={uploaderState !== UPLOADER_STATES.SELECT ? 'file-uploader-block' : ''}>
                      <FilesUploader
                        buttonMode
                        isMulti
                        GA={GA}
                        openMobileMenu={(setState) => {
                          setMobileMenu = setState;
                        }}
                        onstatechange={setUploaderState}
                        header={header()}
                        footer={footer()}
                        filerenderer={filerenderer}
                        uploader={uploader}
                        fileTypesError={t('upload.file_type_error_message')}
                        onfilesselected={() => {
                          app.sendGoogleAnalyticsEvent(GA.category, GA.events.dragFile, { name: 'Uplad file dragged' });
                          setShowOnlyUploder(true); // Show the video animation area
                          setHideOrElemet(true);
                        }}
                        onselect={() => {
                          app.sendGoogleAnalyticsEvent(GA.category, GA.events.next, { name: 'Upload file done' });
                          setShowOnlyUploder(true); // Show the video animation area
                          setShowAnswerButton(false);
                          setHideOrElemet(true);
                          upload();
                        }}
                        onFileError={() => {
                          setShowAnswerButton(true);
                          app.intercom.sendEvent(INTERCOM_EVENTS.NGS.WRONG_FORMAT);
                        }}
                      />
                      {
                        showAnswerButton ? (
                          <>
                            <div>
                              <div className="small_height" />
                              {
                                !hideOrElement && (
                                  <div className="flex">
                                    Or
                                    <div className="small-width-gap" />
                                    <button
                                      type="button"
                                      className="or-answer-btn"
                                      onClick={() => {
                                        const targetPath = '/';
                                        const preservedPath = preserveQueryParam(
                                          targetPath,
                                          {
                                            extra: [['newprofile', 'true'], ['visit_ai', 'true']],
                                          },
                                        );
                                        dispatch(push(preservedPath));
                                      }}
                                    >
                                      Answer questions
                                    </button>
                                  </div>
                                )
                              }

                              {
                                user
                                  ? (
                                    <div className="ai-chat-upload-box">
                                      <div className="ai-chat-upload">
                                        <ChatUploadExplainPopup />
                                      </div>
                                    </div>
                                  )
                                  : <div className="center-loader"><InfinityLoader /></div>
                              }
                            </div>
                          </>
                        ) : null
                      }
                    </div>
                  </div>
                </div>
              </div>
            )
      }
    </>
  );

  return (
    <>
      {
        (showSignUpPage) ? <EmailSignupForm setShowForm={setShowSignUpPage} user={user} setNewUser={setNewUser} />
          : (newUser || localStorage.getItem('user')) ? (
            <>
              <div className="file-uploader-main-entry">{fileUploaderJsx}</div>
            </>
          ) : (
            <div>
              {showForm ? (
                <>
                  <div
                    id="fileUploader"
                    className={`upload-page ${uploaderState === UPLOADER_STATES.UPLOADING && 'none'}`}
                  >
                    <div className="upload-page-title-main" dangerouslySetInnerHTML={{ __html: t('upload.title_text') }} />
                    <div className="upload-page-start-low-title">{t('upload.start_text')}</div>
                    {/* <Steps /> */}
                    <div className="upload-page-gapper" />
                    <div className="upload-button-section-main-box">
                      <div className="upload-button-section-main-title">{t('upload_page.privacy')}</div>
                      <div className="upload-button-section-buttons-row">
                        <div className="upload-button-section-buttons-div">
                          <button
                            className="upload-answer-button"
                            onClick={() => {
                              app.sendGoogleAnalyticsEvent(GA.category, GA.events.answerManually, { name: 'Answer manually clicked' });
                              setUploadType(UPLOAD_TYPE.ANSWER);
                              window.location = preserveQueryParam(
                                '/',
                                {
                                  extra: [['newprofile', 'true'] , ['visit_ai', 'true']],
                                },
                              );
                            }}
                          >
                            Answer questions
                          </button>
                          <div className="small-width-gap" />
                          <div className="btn-height-gap" />
                          <button
                            className="upload-medical-button"
                            onClick={() => {
                              if (localStorage.getItem('user')) {
                                window.location.href = '/?newprofile=true';
                              }
                              setShowSignUpPage(true);

                              app.sendGoogleAnalyticsEvent(GA.category, GA.events.answerManually, { name: 'Answer manually clicked' });
                              setUploadType(UPLOAD_TYPE.ANSWER);
                            }}
                          >
                            <img src={uploadDocument} alt="free" />
                            Upload medical files
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="upload-page-gapper" />
                    <div className="upload-how-it-works-section">
                      <div className="upload-how-it-works-section-title">{t('upload_page.how_it_works_title')}</div>
                      <div className="small_height" />
                      <div className="upload-how-it-works-section-body">
                        <div>
                          <div>
                            {t('upload_page.how_it_works_title_1')}
                            <div className="upload-how-it-works-section-body-text">
                              {t('upload_page.how_it_works_content_1')}
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="arrow_icon_rotation">
                            <img
                              alt="arrow button"
                              src={greenArrow}
                              style={{}}
                            />
                          </div>
                        </div>
                        <div>
                          <div>
                            {t('upload_page.how_it_works_title_2')}
                            <div className="upload-how-it-works-section-body-text">
                              {t('upload_page.how_it_works_content_2')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="upload-page-gapper" />
                    <div className="upload-page-start-section-bottom">
                      <img src={academicCancer} alt="free" />
                      <div className="large-width-gap" />
                      <img src={FreeImage} alt="free" />
                      <img src={HipaaImage} alt="hipaa" />
                      <img src={IsoImage} alt="iso" />
                    </div>
                    <div className="upload-page-gapper" />
                    <div className="upload-stats-section">
                      <div className="upload-how-it-works-section-body">
                        <div className="upload-stats-section-numbers">
                          <div>
                            <span>{t('upload_page.clinital_trial_number')}</span>
                            <div>
                              {t('upload_page.clinital_trial_title')}
                            </div>
                          </div>
                        </div>
                        <div className="upload-stats-section-numbers">
                          <div>
                            <span>{t('upload_page.soc_number')}</span>
                            <div>
                              {t('upload_page.soc_title')}
                            </div>
                          </div>
                        </div>
                        <div className="upload-stats-section-numbers">
                          <div>
                            <span>{t('upload_page.next_treatment_number')}</span>
                            <div>
                              {t('upload_page.next_treatment_title')}
                            </div>
                          </div>
                        </div>
                      </div>
                      <ChatButton
                        id="chatUploadExplain"
                        popupType={POPUP_TYPES.CHAT_UPLOAD_EXPLAIN}
                        text="Need help?"
                        isVisible
                      />
                    </div>
                    {/* <Statistics /> */}
                    <PartnersAndPubications />
                    <Slider />
                    {/* <AboutLeal /> */}
                    <div className="upload-footer-buttons">
                      <div className="upload-footer-buttons-middle">
                        <div
                          className="upload-footer-button answer-questions"
                          onClick={() => {
                            const $b = document.querySelector('.upload-answer-button');
                            if ($b) {
                              $b.click();
                            }
                          }}
                        >
                          {t('ai_profile.answer_questions')}
                        </div>
                        <div
                          className="upload-footer-button upload-button"
                          onClick={() => {
                            const $b = document.querySelector('.upload-medical-button');
                            if ($b) {
                              $b.click();
                            }
                          }}
                        >
                          <img src={uploadDocument} alt="" />
                          {t('ai_profile.upload_button')}
                        </div>
                      </div>
                    </div>
                    <div className="upload-footer">
                      <img src={LogoFooter} alt="logo-footer" />
                      <div>{t('upload.by_leal_health_text')}</div>
                    </div>

                  </div>

                </>
              ) : handleUploadType(uploadType)}
            </div>
          )
      }
    </>
  );
};

export default Upload;

export const isSendEmail = () => {
  const params = new URLSearchParams(window.location.search);
  return params.get('action') === 'email';
};

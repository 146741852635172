import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { uploadNGS } from 'new-ui/NGS/api';
import { addUserDocuments } from 'modules/userDocuments/api';
import { deduplicateList } from 'functions/deduplicateList';
import { getUserConditionProfile, getUserProfileId } from 'modules/user/utils';
import { NGSForm } from './NGSForm';

export const NGSPopup = ({
  onSuccess,
  indication,
}) => {
  const {
    profile,
  } = useSelector((state) => ({
    profile: state.profile,
  }), shallowEqual);

  const profileId = getUserProfileId(profile);

  const uploadFiles = ({
    files,
    getRecaptchaTokenAsync,
    onUpload,
    onError,
  }) => {
    uploadNGS({
      files,
      getRecaptchaTokenAsync,
      onUpload,
      onError,
      indication,
    });
  };

  const getNextBiomarkers = (extractedBiomarkers) => (
    deduplicateList([
      ...(getUserConditionProfile(profile)?.biomarkers ?? []),
      ...(extractedBiomarkers ?? []),
    ])
  );

  const handleUploadedFiles = async (files, { jobId }) => {
    if (!profileId) {
      return;
    }
    try {
      await addUserDocuments({
        jobId,
        files,
        source: 'ngs',
        profileId,
      });
    } catch (ex) {
      console.error('addUserDocuments ex', ex);
    }
  };

  return (
    <NGSForm
      title="Extracting your biomarkers"
      generatingText="Generating your biomarkers report"
      uploadText="Upload your NGS files"
      uploadFiles={uploadFiles}
      getNextBiomarkers={getNextBiomarkers}
      onSuccess={onSuccess}
      onUploadedFilesChange={handleUploadedFiles}
    />
  );
};

NGSPopup.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  indication: PropTypes.string,
};

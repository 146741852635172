export const PFIZER = 'pfizer';
export const LEUKEMIARF = 'leukemiarf';
export const CIGNA = 'cigna';

export const DISEASES_BY_PROVIDER = {
  [LEUKEMIARF]: [
    'aml',
    'cll',
    'mds',
    'cml',
  ],
};

export const OTHER_CANCER_FOR_PROVIDERS = [
  CIGNA,
];

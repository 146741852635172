export function ensurePromise(fn) {
  return function (...args) {
    try {
      const result = fn(...args);
      return result instanceof Promise ? result : Promise.resolve(result);
    } catch (error) {
      return Promise.reject(error);
    }
  };
}

import React, { useEffect, useState } from 'react';
import './UploadPopup.css';
import app from 'new-ui/app';
import FilesUploader from 'new-ui/Components/FilesUploader';
import { UPLOADER_STATES } from 'new-ui/Components/FilesUploader/FileUploaderConstants';
import Button from 'new-ui/Components/Button';
import Vimeo from '@u-wave/react-vimeo';
import PropTypes from 'prop-types';
import { useTranslation } from 'utils/modifiedTranslation';
import { JOB_STATUS } from 'components/MedicalFiles/constants';
import ArrowCirlce from 'new-ui/assets/icons/arrow-circle-right.svg';
import { getDrugName, questionsNormalizer, waitFor } from 'new-ui/functions';
import { UPLOAD_INTAKE_QUESTIONS } from 'new-ui/Components/Intake/questions';
import Intake from 'new-ui/Components/Intake';
import { QUESTION_TYPES } from 'new-ui/Components/QuestionRenderer';
import ChatButton from 'new-ui/ChatButton';
import {
  INDICATIONS, PAGES, POPUP_TYPES, THEMES,
} from 'new-ui/constants';
import { render } from 'react-dom';
import NextIcon from 'new-ui/assets/icons/next.svg';
import InfinityLoader from 'new-ui/Components/InfinityLoader';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { getUserCondition } from 'modules/user/utils';
import { getErrorMessages } from 'components/MedicalFiles/utils';
import { mock_lung_extraction } from './mock';

const GA = {
  category: 'Upload',
  events: {
    pageview: 'Upload file landing page viewed',
    browse: 'Clicking on browse',
    dragFile: 'Drag a file',
    sendRecord: 'Clicking on Send record by mail',
    answerManually: 'Clickin on Answer manually',
    emailToLater: 'Upload file clicking continue later',
    emailToLaterForm: 'Continue later form viewed',
    emailToLaterPopup: 'Continue later final popup viewed',
    moreUpload: 'Upload more clicked',
    next: 'Clicking on “Next”',
    startVideo: 'Upload file video started',
    endVideo: 'Upload file video finished',
    fileSuccessfully: 'Upload file extracted successfully',
    fileFailed: 'Upload file extracted failed',
    reviewing: 'When the revireing page is presented',
    confirmaed: 'When confirmaed',
    success: 'signup_success',
    error: 'signup_error',
  },
};

const uploader = {};

let user;
let results;

const getVimeoId = () => {
  if (app.isCurrentPage(PAGES.SEARCH)) return UPLOAD_VIMEO_IDS[PAGES.SEARCH];
  return UPLOAD_VIMEO_IDS[PAGES.UPLOAD];
};

export const UPLOAD_VIMEO_IDS = {
  [PAGES.UPLOAD]: '944032597',
  [PAGES.SEARCH]: '995786509',
};

export const UploadForm = ({
  onSuccess,
  onBeforeIntake,
  condition,
  onUploadedFilesChange,
  uploadFiles,
}) => {
  const { t } = useTranslation();

  const [uploaderState, setUploaderState] = useState(UPLOADER_STATES.SELECT);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const [errorMessages, setErrorMessages] = useState([]);
  const [finishUploading, setIsFinishUploading] = useState(false);
  const [drugsRecevedFromJob, setDrugsRecevedFromJob] = useState(null);
  const [isPlaying, setIsPlaying] = useState(true);

  const AiAnimate = ({ onSkip, onEnd }) => (
    <div className="upload-preloader">
      <div className="upload-title">
        {t('ai_profile.analyzing_your_files')}
      </div>
      <div className="upload-preloader-bar upload-popup-preloader-bar">
        <InfinityLoader />
      </div>
      <div>
        <Vimeo
          video={getVimeoId()}
          autoplay
          onEnd={onEnd}
          onPlay={() => app.sendGoogleAnalyticsEvent(GA.category, GA.events.startVideo, { name: 'Upload file video started' })}
          style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        />
      </div>
      {onSkip ? (
        <div className="skip">
          <Button theme={THEMES.LIGHT} forwardIcon={NextIcon} title={t('general.skip')} action={onSkip} />
        </div>
      ) : null}
    </div>
  );

  const showSkip = () => {
    const $skip = document.querySelector('.upload-preloader .skip');
    if ($skip) {
      $skip.classList.add('visible');
    }
    const $prog = document.querySelector('.upload-preloader .upload-preloader-bar');
    if ($prog) {
      $prog.classList.add('visibility-hidden');
    }
  };

  useEffect(() => {
    if (finishUploading) {
      if (isPlaying) {
        showSkip();
      } else uploader.setState(UPLOADER_STATES.CUSTOM1);
    }
  }, [finishUploading, isPlaying]);

  useEffect(() => {
    if (window.__upload_debug) {
      user = mock_lung_extraction;
      setUploaderState(UPLOADER_STATES.CUSTOM1);
    }
  }, []);

  const gotoNextStep = () => {
    uploader.setState(UPLOADER_STATES.CUSTOM1);
  };

  const header = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return <div className="upload-popup-title">Got a medical record?</div>;
      case UPLOADER_STATES.MULTI:
        return <div className="upload-popup-title">Is that all?</div>;
      case UPLOADER_STATES.UPLOADING:
        return (
          <div className="animate">
            {AiAnimate({
              onSkip: gotoNextStep,
              onEnd: () => {
                setIsPlaying(false);
                if (finishUploading) gotoNextStep();
              },
            })}
          </div>
        );
      case UPLOADER_STATES.ERROR:
        return (
          <div className="ngs-upload-error">
            {errorMessages?.map((it, idx) => (!idx ? <h3>{it}</h3> : <p>{it}</p>))}
          </div>
        );
      case UPLOADER_STATES.ANALYZING:
        return null;
      case UPLOADER_STATES.SUCCESS:
        return null;
      case UPLOADER_STATES.CUSTOM1:
        const fetch = async () => {
          window.__uploadUserBefore = JSON.parse(JSON.stringify(user));

          const extractedCondition = getUserCondition(user);

          if (condition && condition !== extractedCondition) {
            const $el = document.querySelector('.files-uploader');
            render(
              (
                <>
                  <div className="file-uploader-title">
                    Cannot extract
                    <span role="img" aria-label="smily">😅</span>
                  </div>
                  <div className="file-uploader-text">
                    The indication found in the extracted file was not the same as the one in the current profile
                  </div>
                  <Button
                    title="Close"
                    action={() => {
                      app.setPopup(false);
                    }}
                  />
                </>
              ),
              $el,
            );
            return;
          }

          const questions = questionsNormalizer(UPLOAD_INTAKE_QUESTIONS, extractedCondition);
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.reviewing, { name: 'Upload file attrubutes reviewing viewed' });

          switch (extractedCondition) {
            case INDICATIONS.LUNG:
              questions.push('braf_mutation', 'egft_mut', 'kras_mut', 'erbb2_her2_mut', 'met_mut');
              break;
            case INDICATIONS.CRC_2:
            case INDICATIONS.CRC:
              questions.push('kras_mut');
              break;
            case INDICATIONS.MELANOMA:
            case INDICATIONS.MELANOMA_2:
              questions.push('braf_mutation');
              break;
            default:
              break;
          }

          const DRUGS_RECEIVED_QID = 'drugs_received';

          questions.unshift(DRUGS_RECEIVED_QID);

          if (onBeforeIntake) await onBeforeIntake(questions, results, user);

          const DashboardButton = () => (
            <>
              <div className="upload-intake-parent-button">
                <Button
                  forwardIcon={ArrowCirlce}
                  action={async () => {
                    app.sendGoogleAnalyticsEvent(GA.category, GA.events.confirmaed, { name: 'Upload file attrubutes confirmed' });
                    if (onSuccess) await onSuccess(user, questions);
                    app.setPopup(false);
                  }}
                  title={t('upload.confirm_and_see_btn_text')}
                />
              </div>
            </>
          );
          let appendQuestions = [
            {
              id: DRUGS_RECEIVED_QID,
              section: 'MY_CANCER',
              type: QUESTION_TYPES.DRUGS_RECEIVED,
              valueRenderer: () => {
                if (!drugsRecevedFromJob || !drugsRecevedFromJob?.length) return 'No drugs found';
                return drugsRecevedFromJob.map((drug) => window.t(getDrugName(drug))).join(', ');
              },
            },
          ];
          let expandQuestions = [DRUGS_RECEIVED_QID];
          if (!user.condition_profile.drugs_received) {
            appendQuestions = undefined;
            expandQuestions = undefined;
          }
          const $div = (
            <div>
              <div className="upload-popup-title">{t('upload.popup_title')}</div>
              <div className="upload-intake-block">
                <div className="upload-intake-parent">
                  <Intake
                    id="upload"
                    showOnlyAnswered
                    customQuestions={questions}
                    expandQuestions={expandQuestions}
                    appendQuestions={appendQuestions}
                    flatMode
                    onUpdate={(user) => {
                      onSuccess(user);
                    }}
                    user={user}
                  />
                  <ChatButton popupType={POPUP_TYPES.CHAT_UPLOAD} text="Understand your profile" />
                  <DashboardButton />
                </div>
              </div>
            </div>
          );
          waitFor(() => document.querySelector('.ai-upload-intake'), () => {
            render($div, document.querySelector('.ai-upload-intake'));
          }, () => {});
        };

        fetch();
        return <div className="ai-upload-intake" />;
    }
  };

  const back = () => {
    uploader.setFiles([...uploader.files.filter((a) => !a.error)]);
    uploader.setState(UPLOADER_STATES.MULTI);
  };

  const errorFooter = () => (
    <div>
      <Button title="Upload another file" action={back} />
    </div>
  );

  const isErrored = (files = uploader.files) => {
    const erroredFiles = files.filter((a) => a.error);
    return erroredFiles.length === files.length;
  };

  const footer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return null;
      case UPLOADER_STATES.UPLOADING:
        return null;
      case UPLOADER_STATES.ERROR:
        return errorFooter();
      case UPLOADER_STATES.SUCCESS:
        return isErrored() ? errorFooter() : null;
    }
  };

  const filerenderer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
      case UPLOADER_STATES.UPLOADING:
      case UPLOADER_STATES.ERROR:
        return null;
      case UPLOADER_STATES.SUCCESS:
        return null;
    }
  };

  const uploadError = () => {
    uploader.setState(UPLOADER_STATES.ERROR);
  };

  const upload = async () => {
    const files = [...uploader.files];
    uploader.setState(UPLOADER_STATES.UPLOADING);

    uploadFiles({
      files: [...uploader.files],
      getRecaptchaTokenAsync,
      onUpload: async (r) => {
        if (r?.status === JOB_STATUS.COMPLETED) {
          // todo document indication is not same as user indication error
          user = r.profile;
          results = r;
        }
        if (r?.status === JOB_STATUS.ERROR || r?.status === JOB_STATUS.FILE_ERROR) {
          uploadError();
          setErrorMessages(getErrorMessages(r, t));
          return;
        }

        const { profile = {}, id: jobId, files: uploadedFiles } = r;
        const conditionProfile = profile?.condition_profile ?? {};
        const drugs = conditionProfile?.drugs_received;

        setDrugsRecevedFromJob(drugs);
        setIsFinishUploading(true);
        user = profile;
        uploader.setFiles(files);
        if (isPlaying) {
          showSkip();
        } else uploader.setState(UPLOADER_STATES.CUSTOM1);

        onUploadedFilesChange(
          // uploadedFiles.filter(({ error }) => !error)
          uploadedFiles,
          { jobId },
        );
      },
      onError: () => {
        uploader.setState(UPLOADER_STATES.ERROR);
      },
    });
  };

  return (
    <div className="upload-popup">
      {recaptcha}
      <FilesUploader
        isUploadPopup
        isMulti
        onstatechange={(state) => {
          const $img = document.querySelector('.popup-theme-middle .popup-header img');
          if ($img) {
            $img.classList.remove('hidden');
            if (state === UPLOADER_STATES.UPLOADING) {
              $img.classList.add('hidden');
            }
          }

          setUploaderState(state);
        }}
        header={header()}
        footer={footer()}
        filerenderer={filerenderer}
        uploader={uploader}
        onfilesselected={() => {
        }}
        onselect={() => upload()}
      />
    </div>
  );
};

UploadForm.propTypes = {
  onSuccess: PropTypes.func,
  condition: PropTypes.any,
  onBeforeIntake: PropTypes.func,
  onUploadedFilesChange: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

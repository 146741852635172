/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'connected-react-router';
import app from 'new-ui/app';
import { useTranslation } from 'utils/modifiedTranslation';
import { hcpApi } from 'modules/hcp/api';
import './Finance.css';
import {
  DEFAULT_INDICATION, PAGES, ROUTES, URI,
} from 'new-ui/constants';
import { createEmptyUser } from 'new-ui/functions';
import { useHCPUser } from 'modules/hcp/useHCPUser';
import { startSignup } from 'modules/hcp/actions/signup';
import { ensureCurrentUser } from 'modules/hcp/actions/initCurrentUser';
import {
  useSetHeaderSettings,
  useSetOnLogoClick,
  useUpdatePageHeader,
} from 'new-ui/Components/PageHeader/PageHeaderContext';
import { TAB_IDS, VERSIONS } from 'new-ui/Components/PageHeader/constants';
import Preloader from 'new-ui/Components/Preloader';
import { startNewCurrentUser } from 'modules/hcp/actions/newCurrentUserCreate';
import { addPatientPopup } from 'modules/hcp/actions/addPatient';
import { getIsHCPPatient } from 'modules/hcp/helpers';
import { getUserCondition, isNewlyDiagnosedUser } from 'modules/user/utils';
import { HCPHeaderRightSlot } from 'components/HCPHeaderRightSlot/HCPHeaderRightSlot';
import { getFinanceParams } from './functions';
import { getEmptyFinanceProfile } from '../FinanceIntake';
import {
  arrangeCategories,
  clearCategoriesFromLocalStorage,
  getCategoriesFromLocalStorage,
  getCategoryLabels,
  saveCategoriesToLocalStorage,
  useRouteChecker,
} from './utils';
import WithOnboarding from './WithOnboarding';
import WithoutOnboarding from './WithoutOnboarding';
import {
  createFinanceUser,
  getFinanceCategorties,
  getFinanceMatch,
  getFinanceProfile,
  getFinancePrograms,
  setFinanceUser,
} from './api';
import { AuthButtonHeaderRightSlot } from './HeaderRightSlots/AuthButtonHeaderRightSlot';
import { createUserFinance } from './createUserFinance';

const GA = {
  category: 'Finance',
  events: {
    unselectCategory: 'Unselect category',
    selectCategory: 'Select category',
    clickEligibility: 'Check eligibility clicked',
    videoStarted: 'Video started',
    checkEligibility: 'Check eligibility in category clicked',
    seeAllCategory: 'See all in category clicked',
    seeDetails: 'See details clicked',
    presentedResults: 'When results are presented',
    missingInfo: 'missing info clicked',
  },
};

const resetLocalStorageItem = (itemName) => {
  const currentVersion = '1.0.0';
  const versionKey = 'app_version';
  const previousVersion = localStorage.getItem(versionKey);

  if (previousVersion !== currentVersion) {
    localStorage.removeItem(itemName);
    localStorage.setItem(versionKey, currentVersion);
  }
};

const goToLogin = () => {
  app.history.push(`${ROUTES[PAGES.LOGIN]}?redirect=${URI[PAGES.FINANCE]}`);
};

const FinancePage = () => {
  resetLocalStorageItem('categoryOptions');
  const { setHeaderRightSlot } = useUpdatePageHeader();
  const dispatch = useDispatch();
  const { isDashboardFinance, isPublicFinance } = useRouteChecker();
  const [programs, setPrograms] = useState(null);
  const [matchedPrograms, setMatchedPrograms] = useState(null);
  const [filteredPrograms, setFilteredPrograms] = useState(null);
  const [categories, setCategories] = useState(null);
  const [metaData, setMetaData] = useState({});
  const [financeProfile, setFinanceProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const {
    currentUser,
  } = useSelector((state) => ({
    currentUser: state.currentUser.data,
  }));

  const {
    isAuthenticated,
    isHCPUser,
    isPatientWithoutId,
    isHCPUserWithoutId,
  } = useHCPUser();

  useSetHeaderSettings({
    version: isPublicFinance ? VERSIONS.HCP : VERSIONS.DEFAULT,
    isBurgerMenuShown: true,
    isUploadButtonShown: true,
    tabIds: [
      TAB_IDS.TREATMENT_OPTIONS,
      TAB_IDS.SUPPORT_PROGRAMS,
      ...(isPublicFinance
        ? []
        : [
          TAB_IDS.FEED,
          ...(isAuthenticated ? [TAB_IDS.SYMPTOM_CARE] : []),
          ...(isNewlyDiagnosedUser(app?.user) ? [TAB_IDS.ARTICLES] : []),
          TAB_IDS.SPEAK_WITH_TINA]
      )],
  });

  const isHCP = isPublicFinance && (isHCPUser || isPatientWithoutId || isHCPUserWithoutId);

  useSetOnLogoClick(
    // eslint-disable-next-line consistent-return
    useCallback(() => {
      if (isHCPUser || isHCPUserWithoutId) {
        dispatch(push(ROUTES[PAGES.SEARCH]));
        return false;
      }
      if (isPatientWithoutId) {
        createUserFinance({
          redirectTo: `${URI[PAGES.RESULTS]}?rematch=1`,
          subtitle: t('support_programs.create_user_subtitle_logo'),
        });
        return false;
      }
    }, [dispatch, isHCPUser, isHCPUserWithoutId, isPatientWithoutId, t]),
  );

  const canPatientBeAdded = isAuthenticated
    && isHCPUser && !getIsHCPPatient(currentUser) && !!getUserCondition(currentUser);

  const handleStartSignup = useCallback(() => {
    dispatch(startSignup());
  }, [dispatch]);

  const handleNewPatient = useCallback(() => {
    dispatch(push(ROUTES[PAGES.SEARCH]));
    dispatch(startNewCurrentUser({ popupTitle: t('save_profile.title') }));
  }, [dispatch, t]);

  const handleAddPatient = useCallback(() => {
    dispatch(addPatientPopup());
  }, [dispatch]);

  const goToPatients = useCallback(() => {
    dispatch(push(ROUTES[PAGES.PATIENTS]));
  }, [dispatch]);

  useEffect(() => {
    if (isPublicFinance) {
      setHeaderRightSlot(
        <HCPHeaderRightSlot
          isAuthenticated={isAuthenticated}
          isHCPUser={isHCPUser}
          addPatient={canPatientBeAdded ? handleAddPatient : null}
          newPatient={handleNewPatient}
          startSignup={handleStartSignup}
          goToLogin={goToLogin}
          goToPatients={goToPatients}
        />,
      );
    } else if (!isAuthenticated) {
      setHeaderRightSlot(
        <AuthButtonHeaderRightSlot goToLogin={goToLogin} />,
      );
    }
    return () => {
      setHeaderRightSlot(null);
    };
  }, [
    canPatientBeAdded,
    goToPatients,
    handleAddPatient,
    handleNewPatient,
    handleStartSignup,
    isAuthenticated,
    isHCPUser,
    isPublicFinance,
    setHeaderRightSlot,
  ]);

  useEffect(() => {
    setIsLoading(true);
    const fetch = async () => {
      await app.getSession();

      if (isHCP) {
        dispatch(ensureCurrentUser());
      }

      const aidTypes = [];

      let financeProfile = null;

      if (!isPublicFinance) {
        try {
          financeProfile = await getFinanceProfile();
        } catch (err) {

        }
      }

      if (financeProfile) {
        if (financeProfile.aidTypes) {
          const existingAidTypes = financeProfile.aidTypes.split(',');
          aidTypes.push(...existingAidTypes);
        }
        const matchedPrograms = await getFinanceMatch(getFinanceParams(app.user, financeProfile, aidTypes));
        setMatchedPrograms(matchedPrograms);
        clearCategoriesFromLocalStorage();
      }

      let programs = [];
      let categories = [];
      if (!isPublicFinance && app.user) {
        if (localStorage.getItem('finance_params')) {
          programs = await hcpApi.financeMatch(JSON.parse(localStorage.getItem('finance_params')));
        } else {
          programs = await getFinancePrograms();
        }
        categories = arrangeCategories(await getFinanceCategorties(), programs, aidTypes);
      } else {
        programs = [];
        let _financeProfile = getEmptyFinanceProfile();

        if (localStorage.getItem('finance_params')) {
          // localStorage.setItem('finance_params', JSON.stringify(financeParams));
          // localStorage.setItem('finance_user', JSON.stringify(user));
          const financeParams = JSON.parse(localStorage.getItem('finance_params'));
          try {
            programs = await hcpApi.financeMatch(financeParams);
            setFilteredPrograms(programs);
            clearCategoriesFromLocalStorage();
          } catch (e) {
            console.log('cannot get programs for params', financeParams);
          }
        } else if (app.user) {
          try {
            _financeProfile = await getFinanceProfile();
          } catch (e) {
            console.log('finance user does not exist. creating a new one');
            try {
              const selectedCategories = getCategoryLabels();
              await createFinanceUser(getFinanceParams(app.user, {}, selectedCategories));
            } catch (e) {
              console.log('finance user cannot be created');
            }
          }
          const _programs = await hcpApi.financeMatch(getFinanceParams(app.user, _financeProfile));
          programs = _programs;
          setFilteredPrograms(programs);
        } else {
          const user = await createEmptyUser(DEFAULT_INDICATION, undefined, {
            user_type: 'patient',
          });
          const _programs = await hcpApi.financeMatch(getFinanceParams(user, _financeProfile));
          programs = _programs;
          setFilteredPrograms(programs);
        }

        categories = arrangeCategories([
          'Co-Pay',
          'Mental Health Support',
          'Pet Care Support',
          'Meal & Food Support',
          'Support Group',
          'Financial Support',
          'Lodging',
          'Medical Expenses',
          'Transportation',
          'Travel Expenses',
          'Caregiver Support',
          'Legal Assistance',
          'Medical Support',
          'Child Care Support',

          'Wellbeing Support',
          'Caregiver Support Group',
          'Patient Support Group',
          'Caregivers Wellbeing Support',
          'Family Support Group',
          'Families and Friends Support Group',
          'Patient Wellbeing Support',
        ], [], []);
      }

      if (!financeProfile && !getCategoriesFromLocalStorage()) {
        saveCategoriesToLocalStorage(categories);
      }
      setMetaData((prevMetaData) => ({
        ...prevMetaData,
        program_count: programs?.length,
      }));

      setPrograms(programs);
      setCategories(categories);
      setFinanceProfile(financeProfile);
      setIsLoading(false);

      if (app.getParams().get('create')) {
        createUserFinance({
          redirectTo: URI[PAGES.FINANCE],
          subtitle: t('support_programs.create_user_subtitle_register'),
        });
      }
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const onCategorySelect = useCallback(async (categories) => {
    const aidTypes = categories.map((category) => category.label);
    if (financeProfile) {
      const matchedPrograms = await getFinanceMatch(getFinanceParams(app.user, financeProfile, aidTypes));
      const params = getFinanceParams(app.user, financeProfile, aidTypes);
      delete params.userWixId;
      delete params.email;
      await setFinanceUser({ ...params });
      setMatchedPrograms(matchedPrograms);
    }
  }, [financeProfile]);

  const onCategorySelectWithoutAuth = useCallback((categories) => {
    saveCategoriesToLocalStorage(categories);
  }, []);

  const onSeeAllClick = async (option) => {
    const filteredPrograms = programs.filter((program) => program.category === option.label);
    setFilteredPrograms(filteredPrograms);
    setMetaData((prevMetaData) => ({
      ...prevMetaData,
      selected_category: option,
    }));
  };

  const onBackClick = () => {
    setFilteredPrograms(null);
  };

  return (
    <div className="finance-page">
      <div className="finance-page-header">
        {isLoading ? <Preloader isVisible /> : (
          financeProfile
            ? (
              <WithOnboarding
                categories={categories}
                matchedPrograms={matchedPrograms}
                onCategorySelect={onCategorySelect}
                meta={metaData}
                GA={GA}
                isPublicFinance={isPublicFinance}
                isHCP={isHCP}
              />
            )
            : (
              <WithoutOnboarding
                categories={categories}
                filteredPrograms={filteredPrograms}
                meta={metaData}
                onSeeAllClick={onSeeAllClick}
                onBackClick={onBackClick}
                onCategorySelect={onCategorySelectWithoutAuth}
                GA={GA}
                isPublicFinance={isPublicFinance}
                isHCP={isHCP}
              />
            )
        )}
      </div>
    </div>
  );
};

export default FinancePage;

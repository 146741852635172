import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './style.css';
import { Button } from '@material-ui/core';
import { assembleRoutePath } from 'functions/assembleRoutePath';
import app from 'new-ui/app';
import { PAGES, ROUTES, URI } from 'new-ui/constants';
import { useHCPUser } from 'modules/hcp/useHCPUser';
import { useTranslation } from 'utils/modifiedTranslation';
import configs from 'configs';
import EligibleBtn from './EligibleBtn';
import MissingEligibilityBtn from './MissingEligibleBtn';
import ImageWrapper from './image-wrapper';
import { categoriesMap } from '../utils';
import { isProgramEligible } from '../functions';
import { createUserFinance } from '../createUserFinance';

const onClickDetails = (program) => {
  window.open(program.linkToApply, '_blank');
};

const SupportPrograms = ({ programs, GA, isHCP }) => {
  useEffect(() => {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.presentedResults, { name: 'Financial results viewed' });
  });

  const { t } = useTranslation();
  const history = useHistory();

  const {
    isPatientWithoutId,
  } = useHCPUser();

  const openSignupForm = (action) => () => {
    if (app.isCurrentPage(PAGES.PUBLIC_FINANCE) && isPatientWithoutId) {
      return createUserFinance({
        redirectTo: URI[PAGES.FINANCE],
        subtitle: t('support_programs.create_user_subtitle_eligibility'),
      });
    }

    action();
  };

  const handleMissingData = openSignupForm(() => {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.missingInfo, { name: 'missing info clicked' });

    history.push(assembleRoutePath({
      pathname: ROUTES[PAGES.FINANCE_INTAKE],
      searchParameters: { hcp: isHCP ? '1' : null },
    }));
  });

  const handleSeeDetails = (program) => openSignupForm(() => {
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.seeDetails, {
      name: 'See details clicked',
      metaDate: program.category,
    });
    onClickDetails(program);
  });

  return (
    <div className="support-programs">
      <div className="programs-header">
        <h2 className="title">Available Patient Support Programs</h2>
        <div className="count">
          {programs.length}
          {' '}
          {t('general.programs')}
        </div>
      </div>
      <ul className="program-list">
        <li>
          <div className="heading-program-wrapper">
            <span className="program-title">Programs</span>
          </div>
          <div />
        </li>
        {programs.map((program, index) => (
          <li className="program-item" key={index}>
            <div className="program-info-wrapper">
              <div className="program-info">
                <div className="img-cont">
                  <ImageWrapper
                    imgSrc={`${configs.financeApiClientUrl}/fam-logos/${program.logoName}`}
                    defaultImgSrc={`${configs.financeApiClientUrl}/fam-logos/default.png`}
                  />
                </div>
                <span className="program-title">{program.programName}</span>
              </div>

              <div className="category">
                <img alt="icon" src={categoriesMap[program.category]?.icon} />
                <span>{program.category}</span>
              </div>
            </div>
            <div className="actions">
              {isProgramEligible(program) ? <EligibleBtn /> : <MissingEligibilityBtn onClick={handleMissingData} />}
              <Button onClick={handleSeeDetails(program)} className="details-button">
                <span className="label">{t('general.see_details')}</span>
              </Button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

SupportPrograms.propTypes = {
  isHCP: PropTypes.bool.isRequired,
};

export default SupportPrograms;

import LOCAL_STORAGE_KEYS from 'utils/constants/localStorageKeys';
import { getQuestionnaireRoute } from 'functions/getQuestionnaireRoute';
import { ROUTE_NAME, ROUTE_PATH } from '../AppRoutes/constants';

export const getDefaultUserCountry = () => {
  if (typeof navigator.languages !== 'undefined' && navigator.languages.length > 1) {
    return navigator.languages[0].slice(-2).toLowerCase() || null;
  }

  return navigator.language.slice(-2).toLowerCase() || null;
};

export const validate = (values) => {
  const errors = {};
  const requiredFields = ['email'];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Required';
    }
  });

  if (
    values.email
    // source: https://html.spec.whatwg.org/multipage/input.html#email-state-(type=email)
    // eslint-disable-next-line
    && /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
      values.email,
    )
  ) {
    errors.email = 'Invalid email address';
  }

  return errors;
};

export const isAuthTokenPresent = () => (
  localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN) && localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
);

export const createQuestionnaireRedirectURL = (indication) => {
  const [fistQuestion] = indication;

  if (fistQuestion?.id) {
    return getQuestionnaireRoute({ questionId: fistQuestion.id });
  }

  return ROUTE_PATH[ROUTE_NAME.LOGIN];
};

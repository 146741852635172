import { INDICATIONS as allCancerTypes } from 'new-ui/constants';
import configs from '../../../configs';

const { GENERAL: GENERAL_CONDITION } = allCancerTypes;

export const CancerFieldsByIndication = {
  [allCancerTypes.MELANOMA_2]: {
    diseaseType: 'melanoma_subtype',
    patientStage: 'current_stage',
  },
  [allCancerTypes.MDS]: {
    diseaseType: 'type_of_mds',
    riskGroup: 'risk_group',
  },
  [allCancerTypes.MPN]: {
    diseaseType: 'type_of_mpn',
    riskGroup: 'risk_group',
  },
  [allCancerTypes.UTERINE]: {
    diseaseType: 'type_of_uterine_cancer',
    patientStage: 'current_stage',
    type_of_carcinoma: 'type_of_carcinoma',
    type_of_sarcoma: 'type_of_sarcoma',
  },
  [allCancerTypes.BRAIN]: {
    diseaseType: 'brain_cancer_type',
  },
  [allCancerTypes.MM]: {
    diseaseType: 'type_of_mm',
  },
  [allCancerTypes.LUNG]: {
    diseaseType: 'type_of_lung_cancer',
    patientStage: 'current_stage',
  },
  [allCancerTypes.BREAST_2]: {
    diseaseType: 'type_of_bc',
    patientStage: 'patient_stage',
    molecular_subtype: 'molecular_subtype',
  },
  [allCancerTypes.NHL]: {
    diseaseType: 'type_of_lymphoma',
    patientStage: 'current_stage',
    type_of_tcell_lymphoma: 'type_of_tcell_lymphoma',
    type_of_bcell_lymphoma: 'type_of_bcell_lymphoma',
    type_of_aids_related_lymphoma: 'type_of_aids_related_lymphoma',
  },
  [allCancerTypes.CRC_2]: {
    diseaseType: 'type_of_crc',
    patientStage: 'current_stage',
    type_of_familial_cancer: 'type_of_familial_cancer',
  },
  [allCancerTypes.PROSTATE]: {
    diseaseType: 'type_of_pc',
    patientStage: 'current_stage',
    patientSubStage: 'sub_stage',
  },
  [allCancerTypes.AML]: {
    diseaseType: 'type_of_aml',
    riskGroup: 'risk_group',
    riskGroupApl: 'risk_group_apl',
  },
  [allCancerTypes.HNSCC]: {
    diseaseType: 'type_of_hnscc',
    patientStage: 'current_stage',
  },
  [allCancerTypes.BLADDER_2]: {
    diseaseType: 'type_of_bladder_cancer',
    patientStageNmibc: 'patient_stage_nmibc',
    patientStageMibc: 'patient_stage_mibc',
    patientStageMet: 'patient_stage_met',
  },
  [allCancerTypes.CLL]: {
    diseaseType: 'type_of_disease',
    patientStage: 'disease_stage',
    riskGroupIpi: 'risk_group_ipi',
  },
  [allCancerTypes.GI_TRACT]: {
    diseaseType: 'type_of_gi',
    patientStage: 'current_stage',
  },
  [allCancerTypes.OVARIAN]: {
    diseaseType: 'type_of_ovarian_cancer',
    patientStage: 'current_stage',
    type_of_ovarian_cancer: 'type_of_ovarian_cancer',
  },
  [allCancerTypes.HCC]: {
    diseaseType: 'type_of_liver',
    patientStage: 'current_stage',
  },
  [allCancerTypes.MESO]: {
    patientStage: 'patient_stage',
  },
  [allCancerTypes.RCC]: {
    patientStage: 'patient_stage',
  },
};

window.__CancerFieldsByIndication = CancerFieldsByIndication;

export const cancerTypes = (() => {
  const supportedCancers = {
    AML: 'aml',
    BLADDER_2: 'bladder_2',
    BRAIN: 'brain',
    BREAST_2: 'breast_2',
    CLL: 'cll',
    CRC_2: 'crc_2',
    GI_TRACT: 'gi_tract',
    HCC: 'hcc',
    HNSCC: 'hnscc',
    LUNG: 'lung',
    MDS: 'mds',
    MELANOMA_2: 'melanoma_2',
    MM: 'mm',
    MPN: 'mpn',
    NHL: 'nhl',
    OVARIAN: 'ovarian',
    PROSTATE: 'prostate',
    UTERINE: 'uterine',
  };

  const excludeOnProd = [''];

  return Object.entries(supportedCancers).reduce((acc, cur) => {
    const [key, cancerType] = cur;

    if (configs.isProduction && excludeOnProd.includes(cancerType)) {
      return acc;
    }

    acc[key] = cancerType;
    return acc;
  }, {});
})();

export const diseaseNameTranslationKeyByCancerTypes = Object.values(cancerTypes).reduce((translateKeys, cancerType) => {
  translateKeys[cancerType] = `disease_name.cancer_types.${cancerType}`;
  return translateKeys;
}, {});

export const supportedCancerTypes = Object.keys(diseaseNameTranslationKeyByCancerTypes).map((key) => ({
  title: diseaseNameTranslationKeyByCancerTypes[key],
  value: key,
}));

export const treatmentForIndicationQuestionIds = {
  [allCancerTypes.AML]: 'treatment_for_aml',
  [allCancerTypes.BLADDER]: 'treatment_for_bladder_cancer',
  [allCancerTypes.BLADDER_2]: 'treatment_for_bladder_cancer',
  [allCancerTypes.MELANOMA_2]: 'treatment_for_melanoma',
  [allCancerTypes.CRC]: 'treatment_for_crc_cancer',
  [allCancerTypes.CRC_2]: 'treatment_for_crc_cancer',
  [allCancerTypes.MDS]: 'treatment_for_mds',
  [allCancerTypes.MPN]: 'treatment_for_mpn',
  [allCancerTypes.UTERINE]: 'treatment_for_uterine_cancer',
  [allCancerTypes.BRAIN]: 'treatment_for_brain_cancer',
  [allCancerTypes.MM]: 'treatment_for_mm',
  [allCancerTypes.LUNG]: 'treatment_for_lung_cancer',
  [allCancerTypes.BREAST_2]: 'treatment_for_breast_cancer',
  [allCancerTypes.NHL]: 'treatment_for_lymphoma',
  [allCancerTypes.PROSTATE]: 'treatment_for_prostate_cancer',
  [allCancerTypes.HNSCC]: 'treatment_for_hnscc',
  [allCancerTypes.CLL]: 'treatment_for_cll',
  [allCancerTypes.GI_TRACT]: 'treatment_for_gi_tract',
  [allCancerTypes.OVARIAN]: 'treatment_for_ovarian_cancer',
  [allCancerTypes.HCC]: 'treatment_for_liver',
  [allCancerTypes.RCC]: 'treatment_for_kidney_cancer',
  [allCancerTypes.MESO]: 'treatment_for_mesothelioma_cancer',
  [GENERAL_CONDITION]: 'treatment_for',
  [allCancerTypes.GENERAL]: 'treatment_for_cancer',
  [allCancerTypes.CML]: 'treatment_for_cml',

};

export const procedureForIndicationQuestionIds = {
  bladder_2: 'had_surgery_for_bladder_cancer',
  breast_2: 'had_procedure_for_breast_cancer',
  crc_2: 'had_procedure_for_crc_cancer',
  gi_tract: 'had_procedure_for_gi_cancer',
  hcc: 'had_procedure',
  hnscc: 'had_procedure',
  lung: 'had_procedure_for_lung_cancer',
  nhl: 'had_procedure_for_lymphoma',
  ovarian: 'had_procedure_for_ovarian_cancer',
  prostate: 'had_procedure_for_prostate_cancer',
  uterine: 'had_procedure_for_uterine_cancer',
  rcc_kidney: 'had_procedure_for_kidney_cancer',
  mesothelioma: 'had_procedure_for_mesothelioma_cancer',
  general: 'had_procedure_for_gi_cancer',
};

export const subTypeQuestionByType = {
  [allCancerTypes.BREAST_2]: ['molecular_subtype', 'other_type_bc'],
  [allCancerTypes.UTERINE]: ['type_of_carcinoma', 'type_of_sarcoma'],
  [allCancerTypes.CRC_2]: ['type_of_familial_cancer'],
  [allCancerTypes.GI_TRACT]: ['type_of_esophageal_gej'],
  [allCancerTypes.LUNG]: ['type_of_nsclc'],
  [allCancerTypes.MELANOMA_2]: ['melanoma_subtype', 'melanoma_subtype_mucosal'],
  [allCancerTypes.MPN]: ['type_of_mf', 'type_of_cmml'],
  [allCancerTypes.NHL]: ['type_of_marginal_cell_lymphoma', 'type_of_dlbcl', 'type_of_tcell_lymphoma', 'type_of_bcell_lymphoma', 'type_of_aids_related_lymphoma'],
  [allCancerTypes.OVARIAN]: ['type_of_non_epithelial_disease'],
  [allCancerTypes.MESO]: ['histological_type_of_mesothelioma'],
  [allCancerTypes.GENERAL]: ['type_of_pancreatic'],
};

export const getFieldNameByIndication = (indication, fieldName) => {
  const field = CancerFieldsByIndication[indication];
  return field[fieldName] || fieldName;
};

window.__getFieldNameByIndication = getFieldNameByIndication;

export const getStageFieldByIndication = (indication) => CancerFieldsByIndication[indication]?.patientStage;

window.__getStageFieldByIndication = getFieldNameByIndication;

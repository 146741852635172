import { ROUTE_NAME, ROUTE_PATH } from 'components/AppRoutes/constants';
import { formRoutePath } from './formRoutePath';
import { assembleRoutePath } from './assembleRoutePath';

export function getQuestionnaireRoute({ questionId, additionalParams = {} }) {
  return assembleRoutePath({
    pathname: formRoutePath(ROUTE_PATH[ROUTE_NAME.QUESTIONNAIRE], { questionId }),
    searchParameters: additionalParams,
  });
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import FallbackImage from 'new-ui/assets/icons/fallback_image.svg';

export const LazyImage = ({ src, alt }) => {
  const [errored, setErrored] = useState(false);

  return (
    <>
      {errored ? (
        <img
          src={FallbackImage}
          alt={alt}
          style={{ objectFit: 'initial', width: '26px' }}
        />
      ) : (
        <LazyLoadImage
          src={src}
          alt={alt}
          onError={() => setErrored(true)}
        />
      )}
    </>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

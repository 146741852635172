import { push } from 'connected-react-router';
import { checkIsAuthenticated } from 'functions/checkIsAuthenticated';
import app from 'new-ui/app';
import { replaceCurrentUser } from 'modules/currentUser/actions';
import { getUserCondition } from 'modules/user/utils';
import { PAGES, POPUP_TYPES, ROUTES } from 'new-ui/constants';
import { getIsHCPUser } from '../helpers';
import { addPatientPopup } from './addPatient';

export const hcpUserSignupPopup = ({
  userType, onPatientSuccessAction, onHCPUserSuccessAction, preserveCurrentUser = true,
} = {}) => (dispatch, getState) => {
  const {
    profile,
    currentUser: { data: currentUser },
    authentication,
  } = getState();

  if (checkIsAuthenticated({ authentication, profile })) {
    // invariant
    console.error('patientSignup profile');
    return;
  }

  if (!currentUser) {
    // invariant
    console.error('patientSignup !currentUser');
    return;
  }

  app.setPopup(POPUP_TYPES.ADD_CAREGIVER, {
    theme: 'middle',
    role: userType,
    indication: getUserCondition(currentUser) ? getUserCondition(currentUser) : undefined,
    onSignup: async (nextUser) => {
      app.setShowPreloader(true);
      app.user = nextUser;

      if (!preserveCurrentUser) {
        dispatch(replaceCurrentUser(nextUser));
      }

      app.setShowPreloader(false);
      app.setPopup(false);

      if (getIsHCPUser(nextUser)) {
        dispatch(
          onHCPUserSuccessAction ? onHCPUserSuccessAction() : addPatientPopup(),
        );
      } else {
        // patient
        dispatch(
          onPatientSuccessAction ? onPatientSuccessAction() : push(ROUTES[PAGES.INTAKE]),
        );
      }
    },
  });
};

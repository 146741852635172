import { USER_TYPES } from 'utils/constants/user';
import {
  getUserConditionProfile, getUserCondition, getUserEmail, getUserType, updateUserQstack, getUserWixId, mergeUser,
} from 'modules/user/utils';
import { addUserDocuments } from 'modules/userDocuments/api';
import { mergeUserProfiles } from 'components/MedicalFiles/api.js';
import { INDICATIONS, SUBTYPE_FIELD_BY_ALL_CONDITIONS } from 'new-ui/constants';
import { createEmptyUser, randomString } from 'new-ui/functions';
import app from 'new-ui/app';
import { getQstack } from 'new-ui/Components/Intake/functions';
import { hcpApi } from './api';

const { GENERAL: GENERAL_CONDITION } = INDICATIONS;

const NONE = 'none';

export const isUser = (user) => !!(user && Object.keys(user).length);

export const getIsHCPUserType = (userType) => (
  Object.values(USER_TYPES).filter((type) => type !== USER_TYPES.PATIENT).includes(userType)
);

export const getIsHCPUser = (user) => {
  if (!user) {
    return false;
  }
  const userType = getUserType(user);
  if (!userType) {
    // due to a lot of profiles registered outside hcp do not have user type
    return false;
  }
  return getIsHCPUserType(userType);
};

export const getIsPatientUser = (user) => {
  if (!user) {
    return false;
  }
  return !getIsHCPUser(user);
};

export const getCaregiverNewPatientEmail = (caregiverUserWixId) => (
  `patient_of_${caregiverUserWixId}_${randomString()}@leal.health`
);

export const adjustUser = (user) => {
  if (!user) {
    // invariant
    return user;
  }

  // it is important to clone user as Intake mutate input user object
  return mergeUser(
    user,
    {
      condition_profile: {
        ...(
          !Array.isArray(user.condition_profile?.biomarkers) || !user.condition_profile.biomarkers.length
            ? { biomarkers: [NONE] }
            : {}
        ),
      },
    },
  );
};

export const adjustGeneralConditionUser = (generalConditionUser) => {
  const nextUser = {
    ...generalConditionUser,
    personal: {
      ...generalConditionUser.personal,
      condition: GENERAL_CONDITION,
    },
    info: {
      ...generalConditionUser.info,
      condition: GENERAL_CONDITION,
    },
  };
  return adjustUser(nextUser);
};

export const createIndicationEmptyUser = async (
  indication,
  {
    biomarkers = [NONE],
    country = null,
    subType,
    otherQuestionId,
    otherQuestion,
  } = {},
) => {
  let emptyUser = await createEmptyUser(
    indication,
    { biomarkers },
    { country },
  );

  emptyUser = adjustUser(emptyUser);

  if (indication === INDICATIONS.GENERAL) {
    adjustGeneralConditionUser(emptyUser);
  }

  if (subType) {
    emptyUser.condition_profile[SUBTYPE_FIELD_BY_ALL_CONDITIONS[indication]] = subType;
  }

  if (otherQuestionId && otherQuestion) {
    emptyUser.condition_profile[otherQuestionId] = otherQuestion;
  }

  return emptyUser;
};

// empty user; for case no indication
export const createMockUser = async ({ userType } = {}) => {
  const emptyUser = await createEmptyUser(INDICATIONS.GENERAL);
  emptyUser.personal.condition = null;
  emptyUser.info.condition = null;
  if (userType) {
    emptyUser.info.user_type = userType;
  }
  emptyUser.condition_profile.biomarkers = [NONE];
  return emptyUser;
};

// simplified check
export const getIsMockUser = (user) => {
  if (getUserCondition(user)) {
    return false;
  }
  const { biomarkers, qstack, ...rest } = getUserConditionProfile(user) ?? {};
  if (Object.values(rest).some((i) => i && (!Array.isArray(i) || i.length))) {
    return false;
  }
  if ((biomarkers ?? []).some((biomarker) => biomarker !== NONE)) {
    return false;
  }

  return true;
};

// user is supposed to be in hcp user patients list
export const getIsHCPPatient = (user) => !!(getUserWixId(user) && getUserEmail(user) && getUserCondition(user));

// does not work for comparing condition profile received from the backend and condition profile from intake form
const compareConditionProfiles = (profile1, profile2, { ignoreQstack = true } = {}) => {
  const adjustConditionProfile = ({
    biomarkers, treatments, qstack, ...rest
  }) => ({
    ...Object.fromEntries(Object.entries(rest).sort(([key1], [key2]) => key1.localeCompare(key2))),
    qstack: ignoreQstack ? [] : qstack,
    biomarkers: (biomarkers ?? []).filter((biomarker) => biomarker !== NONE).sort(),
    treatments: (treatments ?? [])
      .filter(({ drugs_received: drugsReceived }) => (
        Array.isArray(drugsReceived) && drugsReceived.length
      ))
      .sort(({ drugs_received: drugs1 }, { drugs_received: drugs2 }) => {
        const stringifyDrugs = (drugs) => drugs.map((i) => i ?? '_').sort().join(', ');
        return stringifyDrugs(drugs1).localeCompare(stringifyDrugs(drugs2));
      }),
  });
  return JSON.stringify(adjustConditionProfile(profile1)) === JSON.stringify(adjustConditionProfile(profile2));
};

// does not work for comparing condition profile received from the backend and condition profile from intake form
export const compareUsers = (user1, user2) => {
  if ((user1 ?? null) === (user2 ?? null)) {
    return true;
  }
  if (!user1 || !user2) {
    return false;
  }

  const { condition_profile: user1ConditionProfile = {}, ...restUser1 } = user1;
  const { condition_profile: user2ConditionProfile = {}, ...restUser2 } = user2;

  if (!compareConditionProfiles(user1ConditionProfile, user2ConditionProfile)) {
    return false;
  }
  return JSON.stringify(restUser1) === JSON.stringify(restUser2);
};

export const adjustUserForServer = async (user) => {
  const condition = getUserCondition(user);

  if (!condition) {
    throw new Error('condition is empty');
  }

  let adjustedUser = user;
  // not sure if it is needed
  const { info: { country } = {} } = user;
  if (country?.name) {
    adjustedUser = mergeUser(
      user, {
        info: {
          country: {
            ...country,
            label: country.name,
          },
        },
      },
    );
  }

  const questions = await app.getQuestions(condition, true);
  return updateUserQstack(adjustedUser, getQstack(adjustedUser, questions));
};

export const prepareAndSaveHCPPatient = async (user, { shouldUpdateQstack = true } = {}) => {
  if (!getIsHCPPatient(user)) {
    throw new Error('user is not a patient');
  }
  let toUpdate = user;
  if (shouldUpdateQstack) {
    toUpdate = await adjustUserForServer(user);
  }
  return hcpApi.updateUser(toUpdate);
};

// it is intended to use for not existing patient (not stored on the backend)
// for existing patient the backend is responsible for merging
export const mergeExtractedProfile = async ({ currentProfile, extractedProfile }) => {
  const condition = getUserCondition(currentProfile);
  const extractedCondition = getUserCondition(extractedProfile);

  let mergedUser = extractedProfile;
  if (condition && condition === extractedCondition) {
    const preparedCurrentProfile = await adjustUserForServer(currentProfile);
    mergedUser = await mergeUserProfiles({ oldProfile: preparedCurrentProfile, newProfile: extractedProfile });
  } else if (extractedCondition && condition !== extractedCondition) {
    // condition mismatch;
    // it is likely condition is empty (MockUser case)
    const { condition_profile: { biomarkers } = {}, info: { country } = {} } = currentProfile;
    const emptyUser = await createIndicationEmptyUser(
      extractedCondition,
      {
        biomarkers,
        country,
      },
    );

    const preparedEmptyUser = await adjustUserForServer(emptyUser);
    mergedUser = await mergeUserProfiles({ oldProfile: preparedEmptyUser, newProfile: extractedProfile });
  }
  return mergedUser;
};

export const saveUserDocuments = async (profileId, documents) => {
  const results = await Promise.all(documents.map(async ({ files, metadata: { jobId, source } }) => {
    try {
      await addUserDocuments({
        jobId,
        files,
        source,
        profileId,
      });
      return true;
    } catch (ex) {
      console.error('saveUserDocuments ex', ex);
      return false;
    }
  }));
  if (!results.every((i) => i)) {
    throw new Error('adding user documents error');
  }
};

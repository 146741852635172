import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import LocationInput from 'new-ui/Components/Form/LocationInput';
import { questionPropTypes } from './questionPropTypes';

export const BoxLocationQuestion = ({ user, onselect }) => {
  const { t } = useTranslation();
  return (
    <div className="box-question-location">
      <div className="hcp-input-title">{t('general.treatment_location')}</div>
      <div>
        <LocationInput
          value={user?.info?.country}
          onChange={(country) => {
            // eslint-disable-next-line no-param-reassign
            user.info.country = country;
            onselect(country);
          }}
        />
      </div>
    </div>
  );
};

BoxLocationQuestion.propTypes = questionPropTypes;

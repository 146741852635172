import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import './SearchForm.css';
import app from 'new-ui/app';
import Intake from 'new-ui/Components/Intake';
import HCPSelect from 'new-ui/Components/HCP/Select';
import { useTranslation } from 'utils/modifiedTranslation';
import { useObjectRef } from 'utils/hooks/useObjectRef';
import { useIsMounted } from 'utils/hooks/useIsMounted';
import { QUESTION_TYPES } from 'new-ui/Components/QuestionRenderer';
import SearchIcon from 'new-ui/assets/icons/search_text.svg';
import UserIcon from 'new-ui/assets/icons/user3.svg';
import { getSearchParamsApi } from 'new-ui/api';
import { getUserCondition } from 'modules/user/utils';
import {
  adjustUser, createIndicationEmptyUser, createMockUser, isUser, compareUsers,
} from 'modules/hcp/helpers';
import Preloader from '../Preloader';
import { BoxTreatmentsQuestion } from './BoxTreatments';
import { HCP_MOCK, LOCATION_QUESTION_TYPE } from './constants';
import {
  getIntakeCustomData, getIntakeQuestions,
} from './dataProcessing';
import { BoxSelectQuestion } from './components/BoxSelectQuestion';
import { BoxBiomarkersQuestion } from './components/BoxBiomarkersQuestion';
import { BoxLocationQuestion } from './components/BoxLocationQuestion';
import { BoxNumberQuestion } from './components/BoxNumberQuestion';
import { MockQuestion } from './components/MockQuestion';

let searchParamsData;

const QUESTION_RENDERERS = {
  [QUESTION_TYPES.SINGLE_OPTION_SELECT]: BoxSelectQuestion,
  [QUESTION_TYPES.MULTIPLE_OPTION_SELECT]: BoxSelectQuestion,
  [QUESTION_TYPES.NUMBER_INPUT]: BoxNumberQuestion,
  [QUESTION_TYPES.TREATMENT_HISTORY]: BoxTreatmentsQuestion,
  [QUESTION_TYPES.COMPOSITE]: BoxTreatmentsQuestion,
  [QUESTION_TYPES.BIOMARKERS]: BoxBiomarkersQuestion,
  [QUESTION_TYPES.HCP_TREATMENTS]: BoxTreatmentsQuestion,
  [LOCATION_QUESTION_TYPE]: BoxLocationQuestion,
  [HCP_MOCK]: MockQuestion,
};

const getIndicationSelectorInitData = (targetUser) => ({
  initValue: targetUser?.personal?.condition || null,
  initLabel: targetUser?.personal?.condition_name || null,
});

export const SearchForm = ({
  user: userProp, // can be {} to trigger new user creation
  indicationChangeDisabled,
  onChange,
  onLoad,
  onBeforeChange,
  onBeforeRender,
  onAccordionRender,
}) => {
  const onLoadRef = useRef(onLoad);
  onLoadRef.current = onLoad;

  const isMounted = useIsMounted();

  const [intakeData, setIntakeData] = useState(null);
  const [count, setCount] = useState(0);
  const { t } = useTranslation();

  const { user } = intakeData ?? {};

  const userRef = useObjectRef(user);

  useEffect(() => {
    userRef.current = user ?? null;
  }, [user, userRef]);

  useEffect(() => {
    const { current: currentUser } = userRef;
    if (currentUser && getUserCondition(currentUser) !== getUserCondition(userProp)) {
      // condition mismatch
      setIntakeData(null);
    }

    if (compareUsers(currentUser, userProp)) {
      return;
    }

    // propUser can be {} to trigger new user creation
    let nextUser = isUser(userProp) ? adjustUser(userProp) : null;
    const fetch = async () => {
      searchParamsData = searchParamsData || app?.cache?.searchParamsData || await getSearchParamsApi();
      app.cache.searchParamsData = searchParamsData;

      if (!nextUser) {
        nextUser = await createMockUser();
      }

      const intakeQuestions = await getIntakeQuestions(getUserCondition(nextUser));

      if (!isMounted()) {
        return;
      }

      setIntakeData({
        user: nextUser,
        allQuestions: intakeQuestions,
      });
      setCount((prevCount) => prevCount + 1);

      // it is crucial to call onLoad only after calling setIntakeData
      if (onLoadRef.current) {
        onLoadRef.current(nextUser);
      }
    };
    fetch();
  }, [isMounted, userProp, userRef]);

  const handleIndicationChange = (condition, label) => {
    if (indicationChangeDisabled) {
      return;
    }

    if (user && getUserCondition(user) !== condition) {
      // condition mismatch
      setIntakeData(null);
    }

    if (onBeforeChange) {
      onBeforeChange();
    }

    const fetch = async () => {
      if (!condition) {
        const nextUser = await createMockUser();
        const intakeQuestions = await getIntakeQuestions();
        setIntakeData({
          user: nextUser,
          allQuestions: intakeQuestions,
        });
        onChange(nextUser);
        return;
      }

      const labelData = searchParamsData.indicationLabelsData.find((l) => l.label === label);

      const { user: { condition_profile: { biomarkers } = {}, info: { country } = {} } = {} } = intakeData;

      const nextUser = await createIndicationEmptyUser(
        condition,
        {
          biomarkers,
          country,
          subType: labelData?.autoSelectType,
          otherQuestionId: labelData.subType,
          otherQuestion: labelData.autoSelectSubType,
        },
      );

      nextUser.personal.condition_name = label;

      const intakeQuestions = await getIntakeQuestions(getUserCondition(nextUser));

      setIntakeData({
        user: nextUser,
        allQuestions: intakeQuestions,
      });
      onChange(nextUser);
    };

    fetch();
  };

  const { info: { family_name: familyName } = {} } = user ?? {};

  const key = `${getUserCondition(user) ?? null}_${count}`;
  const indicationSelectorKey = Object.values(getIndicationSelectorInitData(user)).join('_');

  return (
    <div className="hcp-form">
      {familyName && (
        <div className="family_name">
          <img src={UserIcon} alt="icon" />
          {familyName}
        </div>
      )}
      {!!searchParamsData?.indicationLabelsData?.length && (
        <div>
          <div className="indication-selector">
            <div className="hcp-input-title">
              {t('general.condition')}
            </div>
            <HCPSelect
              key={indicationSelectorKey}
              disabled={!intakeData || indicationChangeDisabled}
              options={searchParamsData.indicationLabelsData}
              className="hcp-select-indication"
              icon={SearchIcon}
              placeholder={t('hcp.indication_input_title')}
              hideOnSelect
              {...getIndicationSelectorInitData(user)}
              onselect={handleIndicationChange}
            />
          </div>
          {!!intakeData && (
            <Intake
              flatMode
              preventNotSure
              expandAll
              id="search"
              key={key}
              onAccordionRender={onAccordionRender}
              user={user}
              onBeforeRender={onBeforeRender}
              customData={getIntakeCustomData(intakeData)}
              customOnSelect={() => {
                onChange({ ...user });
              }}
              customRenderers={QUESTION_RENDERERS}
            />
          )}
        </div>
      )}
      {(!searchParamsData?.indicationLabelsData?.length || !intakeData) && (
        <div className="intake-preloader">
          <Preloader inline isVisible />
        </div>
      )}
    </div>
  );
};

SearchForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  user: PropTypes.object,
  onLoad: PropTypes.func,
  onBeforeChange: PropTypes.func,
  onBeforeRender: PropTypes.func,
  onAccordionRender: PropTypes.func,
  indicationChangeDisabled: PropTypes.bool,
};

import { INTAKE_BIOMARKERS_QUESTION } from 'new-ui/Components/Intake/questions';
import { INDICATIONS } from 'new-ui/constants';
import { isEmptyObject } from 'new-ui/functions';

export const HCP_MOCK = 'HCP_MOCK';

const HCP_TYPE_QUESTION = {
  id: 'hcp_type',
  type: HCP_MOCK,
  title: 'general.type',
  isQuestionAnswered: () => true,
  getQuestionValue: () => '',
  setQuestionValue: () => {
  },
};

const HCP_SUBTYPE_QUESTION = {
  id: 'hcp_subtype',
  type: HCP_MOCK,
  title: 'general.subtype',
  isQuestionAnswered: () => true,
  getQuestionValue: () => '',
  setQuestionValue: () => {
  },
};

const HCP_STAGE_QUESTION = {
  id: 'hcp_stage',
  type: HCP_MOCK,
  title: 'general.stage',
  isQuestionAnswered: () => true,
  getQuestionValue: () => '',
  setQuestionValue: () => {
  },
};

// for case no indication
export const MOCK_QUESTIONS = [
  INTAKE_BIOMARKERS_QUESTION,
  HCP_TYPE_QUESTION,
  HCP_SUBTYPE_QUESTION,
  HCP_STAGE_QUESTION,
];

export const LOCATION_QUESTION_TYPE = 'LOCATION';

export const LOCATION_QUESTION = {
  id: 'location',
  type: LOCATION_QUESTION_TYPE,
  intakeType: 'about',
  intakeTitle: 'Location preferences',
  isQuestionAnswered: (user) => !isEmptyObject(user?.info?.country),
  getQuestionValue: (user) => (
    `${user?.info?.country?.label}${user?.personal?.travel_limit ? `, ${window.t(`questionnaire.questions.global.travel_limit.options.${user?.personal?.travel_limit}`)}` : ''}`
  ),
  setQuestionValue: () => {
  },
};

export const HCP_QUESTIONS = {
  [INDICATIONS.BREAST_2]: [
    'disease_status',
    'molecular_subtype',
    'type_of_bc',
    'her2_levels',
    'patient_stage',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_breast_cancer',
    'received_fam_trastuzumab',
    'treatments',
    'biomarkers',
  ],
  [INDICATIONS.LUNG]: [
    'disease_status',
    'type_of_lung_cancer',
    'type_of_nsclc',
    'current_stage',
    'has_biopsy',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'biomarkers',
    'braf_mutation',
    'egft_mut',
    'kras_mut',
    'erbb2_her2_mut',
    'met_mut',
    'pd_l1_carrier_status',
    'pd_l1_expression',
    'disease_resectable',
    'treatment_for_lung_cancer',
  ],
  [INDICATIONS.CRC_2]: [
    'disease_status',
    'current_stage',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_crc_cancer',
    'treatments',
    'biomarkers',
    'kras_mut',
    'msi',
    'kras_mut',
    'type_of_crc',
    'sidedness',
  ],
  [INDICATIONS.PROSTATE]: [
    'disease_status',
    'crpc',
    'current_stage',
    'type_of_pc',
    'gleason_score',
    'psa_level',
    'had_procedure_for_prostate_cancer',
    'treatment_for_prostate_cancer',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'biomarkers',
  ],
  [INDICATIONS.MELANOMA_2]: [
    'disease_status',
    'melanoma_subtype',
    'current_stage',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_melanoma',
    'treatments',
    'biomarkers',
    'braf_mutation',
  ],
  [INDICATIONS.BLADDER_2]: [
    'disease_status',
    'patient_phase',
    'patient_grade',
    'type_of_bladder_cancer',
    'had_surgery_for_bladder_cancer',
    'treatment_for_bladder_cancer',
    'treatments',
    'biomarkers',
    'patient_stage_nmibc',
  ],
  [INDICATIONS.RCC]: [
    'treatment_for_advanced_metastatic_disease',
    'disease_status',
    'type_of_cc',
    'type_of_rcc',
    'patient_stage',
    'had_procedure_for_kidney_cancer',
    'treatment_for_kidney_cancer',
    'treatments',
    'biomarkers',
    'hereditary_rcc',
  ],
  [INDICATIONS.GI_TRACT]: [
    'disease_status',
    'type_of_gi',
    'type_of_esophageal_gej',
    'current_stage',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_gi_tract',
    'treatments',
    'biomarkers',
  ],
  [INDICATIONS.MESO]: [
    'disease_status',
    'type_of_mesothelioma_cancer',
    'histological_type_of_mesothelioma',
    'patient_stage',
    'disease_location',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_mesothelioma_cancer',
    'treatments',
    'biomarkers',
  ],
  [INDICATIONS.OVARIAN]: [
    'disease_status',
    'type_of_ovarian_cancer',
    'current_stage',
    'treatment_for_ovarian_cancer',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'biomarkers',
    'msi',
    'platinum_response',
    'had_procedure_for_ovarian_cancer',
    'procedures',
  ],
  [INDICATIONS.UTERINE]: [
    'disease_status',
    'type_of_uterine_cancer',
    'current_stage',
    'treatment_for_uterine_cancer',
    'treatments',
    'had_procedure_for_uterine_cancer',
    'biomarkers',
    'msi',
    'patient_stage',
    'type_of_sarcoma',
  ],
  [INDICATIONS.HNSCC]: [
    'disease_status',
    'type_of_hnscc',
    'current_stage',
    'treatment_for_hnscc',
    'treatment_for_advanced_metastatic_disease',
    'treatments',
    'had_procedure',
    'biomarkers',
  ],
  [INDICATIONS.BRAIN]: [
    'disease_status',
    'brain_cancer_type',
    'brain_radiation',
    'treatment_for_brain_cancer',
    'treatments',
    'biomarkers',
    'tumor_surgery',
  ],
  [INDICATIONS.MDS]: [
    'disease_status',
    'risk_group',
    'intermediate_risk_subgroup',
    'percentage_of_bm_blasts',
    'treatment_for_mds',
    'treatment_nontransplant_candidate',
    'received_allogenic_transplant',
    'allogenic_transplant_date',
    'allogenic_transplant_outcome',
    'treatment_after_transplant_relapse',
    'treatment_after_transplant_relapse_outcome',
    'biomarkers',
    'type_of_mds',
  ],
  [INDICATIONS.MPN]: [
    'disease_status',
    'type_of_mpn',
    'risk_group',
    'intermediate_risk_subgroup',
    'splenomegaly',
    'platelet_count',
    'treatment_for_mpn',
    'treatments',
    'received_allogenic_transplant',
    'biomarkers',
  ],
  [INDICATIONS.MM]: [
    'disease_status',
    'has_mprotein',
    'has_serum_flc',
    'chromosomal_alterions',
    'received_transplant',
    'treatment_for_mm',
    'treatments',
    'type_of_mm',
  ],
  [INDICATIONS.NHL]: [
    'disease_status',
    'type_of_lymphoma',
    'type_of_bcell_lymphoma',
    'type_of_marginal_cell_lymphoma',
    'type_of_aids_related_lymphoma',
    'current_stage',
    'disease_location',
    'current_stage_follicular_lymphoma',
    'treatment_for_lymphoma',
    'treatments',
    'procedure_candidate',
    'had_procedure_for_lymphoma',
    'procedures',
    'procedures_received',
    'biomarkers',
    'type_of_tcell_lymphoma',
  ],
  [INDICATIONS.CLL]: [
    'disease_status',
    'type_of_disease',
    'cll_characteristics',
    'platelet_count',
    'lymphocyte_count',
    'treatment_for_cll',
    'treatments',
    'received_transplant',
    'biomarkers',
    'disease_stage',
    'risk_group_ipi',
  ],
  [INDICATIONS.AML]: [
    'type_of_aml',
    'disease_status',
    'risk_group',
    'received_transplant',
    'treatment_for_aml',
    'treatments',
    'biomarkers',
  ],
  [INDICATIONS.HCC]: [
    'disease_status',
    'type_of_liver',
    'current_stage',
    'number_lesions',
    'hcc_related_health_problems',
    'treatment_for_advanced_metastatic_disease',
    'treatment_for_liver',
    'treatments',
    'biomarkers',
  ],
  [INDICATIONS.CML]: [
    'disease_status',
    'cml_phase',
    'chronic_phase',
    'risk_group',
    'percentage_of_bm_blasts',
    'biomarkers',
    'treatment_for_cml',
    'treatments',
  ],
};
Object.values(HCP_QUESTIONS).forEach((questions) => {
  questions.push('ecog', 'location');
});
Object.assign(
  HCP_QUESTIONS,
  {
    [INDICATIONS.GENERAL]: [
      'disease_status',
      'type_of_pancreatic',
      'patient_stage',
      'treatment_for_advanced_metastatic_disease',
      'treatment_for_cancer',
      'treatments',
      'biomarkers',
    ],
  },
);

import React, { useEffect, useState } from 'react';
import './NGS.css';
import app from 'new-ui/app';
import { getBiomarkerTitle } from 'new-ui/functions';
import { uploadNGS } from 'new-ui/NGS/api';
import FilesUploader from 'new-ui/Components/FilesUploader';
import { UPLOADER_STATES } from 'new-ui/Components/FilesUploader/FileUploaderConstants';
import Button from 'new-ui/Components/Button';
import Lottie from 'lottie-react';
import LottieAnimation from 'new-ui/assets/lottie/NGS.json';
import { INTERCOM_EVENTS } from 'new-ui/constants';
import PropTypes from 'prop-types';
import { addUserDocuments } from '../../../modules/userDocuments/api';
import useRecaptcha from '../../../utils/hooks/useRecaptcha';

const GA = {
  category: 'NGS_POPUP',
  events: {
    pageview: 'page_view',
    button: 'button_click',
    general: 'general',
    form: 'form_filled',
  },
};

const uploader = {};

const NGS = (props) => {
  const {
    onSuccess,
    saveButtonText = 'Apply biomarkers and search',
    selectHeader,
    selectFooter,
    selectButton,
    buttonMode = false,
    isSingle = false,
    controller = {},
    successFooterRenderer,
  } = props;
  const [job, setJob] = useState(null);
  const [uploaderState, setUploaderState] = useState(UPLOADER_STATES.SELECT);
  const [biomarkers, setBiomarkers] = useState([]);
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();

  useEffect(() => {
    const updateUserDocuments = async () => {
      await addUserDocuments({
        jobId: job.id,
        profileId: app.user.personal.profile_id,
        files: uploader.files.filter(({ error }) => !error),
        source: 'ngs',
      });
    };

    if (!!job && !!app.user && !!uploader?.files?.length && uploaderState === UPLOADER_STATES.SUCCESS) {
      updateUserDocuments();
    }
  }, [job, uploaderState]);

  controller.uploader = uploader;

  const header = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return selectHeader || null;
      case UPLOADER_STATES.MULTI:
        return <div className="files-uploader-preview-title">Upload your NGS files</div>;
      case UPLOADER_STATES.UPLOADING:
        return (
          <>
            <div className="ngs-title">Extracting your biomarkers</div>
            <div className="ngs-analyze">
              <Lottie animationData={LottieAnimation} loop />
            </div>
            <div className="height-10" />
          </>
        );
      case UPLOADER_STATES.ERROR:
        return (
          <div className="ngs-upload-error">
            Something went wrong with uploading your NGS files, please try again or contact support
          </div>
        );
      case UPLOADER_STATES.SUCCESS:
        return <div className="ngs-title">Generating your biomarkers report</div>;
    }
  };

  const back = () => {
    uploader.setFiles([...uploader.files.filter((a) => !a.error)]);
    uploader.setState(UPLOADER_STATES.MULTI);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, {
      name: 'back_to_file_select_due_to_error',
    });
  };

  const errorFooter = () => (
    <div>
      <Button title="Upload another file" action={back} />
    </div>
  );

  const successFooter = () => {
    if (successFooterRenderer) return successFooterRenderer(biomarkers);
    return (
      <div className="success-footer">
        <Button
          title={saveButtonText}
          action={() => {
            onSuccess(biomarkers);
          }}
        />
      </div>
    );
  };

  const isErrored = (files = uploader.files) => {
    const erroredFiles = files.filter((a) => a.error);
    return erroredFiles.length === files.length;
  };

  const footer = () => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
        return selectFooter || null;
      case UPLOADER_STATES.UPLOADING:
        return null;
      case UPLOADER_STATES.ERROR:
        return errorFooter();
      case UPLOADER_STATES.SUCCESS:
        return isErrored() ? errorFooter() : successFooter();
    }
  };

  const filerenderer = (file) => {
    switch (uploaderState) {
      default:
      case UPLOADER_STATES.SELECT:
      case UPLOADER_STATES.UPLOADING:
      case UPLOADER_STATES.ERROR:
        return null;
      case UPLOADER_STATES.SUCCESS:
        return file.biomarkers && file.biomarkers.length ? (
          <>
            <div className="ngs-analyze-subtitle">
              in this document we identified the following biomarkers:
            </div>
            <div className="ngs-analyze-files-biomarkers">
              {
                file.biomarkers.map((biomarker, key) => (
                  <React.Fragment key={key}>
                    <div className="ngs-analyze-files-biomarker">
                      <div className="ngs-analyze-files-biomarker-name">{getBiomarkerTitle(biomarker)}</div>
                    </div>
                  </React.Fragment>
                ))
              }
            </div>
          </>
        ) : null;
    }
  };

  const uploadError = () => {
    uploader.setState(UPLOADER_STATES.ERROR);
    app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'upload_error' });
  };

  const upload = async () => {
    const files = [...uploader.files.filter(({ error }) => !error)];
    uploader.setState(UPLOADER_STATES.UPLOADING);

    uploadNGS({
      files: [...uploader.files],
      getRecaptchaTokenAsync,
      onUpload: (r) => {
        if (r?.status === 'error') {
          uploadError();
          return;
        }

        const uploadedFiles = r?.files;
        const conditionProfile = r?.profile?.condition_profile ?? {};
        const biomarkers = conditionProfile?.biomarkers ?? [];

        files.forEach((file) => {
          const f = uploadedFiles.find((r) => r.name === file.name);

          if (!f) {
            return;
          }

          file.storageName = f?.storageName ?? '';
          file.biomarkers = f?.profile?.condition_profile?.biomarkers ?? [];

          if (f?.error) {
            file.error = f.error;
            app.intercom.sendEvent(INTERCOM_EVENTS.NGS_POPUP.FILE_NOT_READABLE);
          } else if (!file.biomarkers || !file.biomarkers.length) {
            file.error = "The NGS file doesn't contain any biomarkers";
            app.intercom.sendEvent(INTERCOM_EVENTS.NGS_POPUP.NO_BIOMARKERS);
          }
        });

        setJob(r);
        setBiomarkers(biomarkers);
        uploader.setFiles(files);
        uploader.setState(UPLOADER_STATES.SUCCESS);
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'upload_success' });
      },
      onError: uploadError,
    });
  };

  return (
    <>
      <FilesUploader
        buttonMode={buttonMode}
        selectButton={selectButton}
        isMulti={!isSingle}
        onstatechange={setUploaderState}
        header={header()}
        footer={footer()}
        filerenderer={filerenderer}
        uploader={uploader}
        onfilesselected={() => {
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.general, { name: 'file_selected' });
        }}
        onselect={() => {
          app.sendGoogleAnalyticsEvent(GA.category, GA.events.button, { name: 'files_selection_next_button' });
          return upload();
        }}
        onFileError={() => {
          app.intercom.sendEvent(INTERCOM_EVENTS.NGS.WRONG_FORMAT);
        }}
      />
      {recaptcha}
    </>
  );
};

NGS.propTypes = {
  onSuccess: PropTypes.func,
  selectHeader: PropTypes.any,
  selectFooter: PropTypes.any,
  saveButtonText: PropTypes.string,
  buttonMode: PropTypes.bool,
  isSingle: PropTypes.bool,
  selectButton: PropTypes.any,
  controller: PropTypes.any,
  successFooterRenderer: PropTypes.func,
};

export default NGS;

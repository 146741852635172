import React from 'react';
import { useTranslation } from 'utils/modifiedTranslation';
import HCPSelect from 'new-ui/Components/HCP/Select';
import app from 'new-ui/app';
import { getQstack } from 'new-ui/Components/Intake/functions';
import { INDICATIONS } from 'new-ui/constants';
import { CheckEligibility } from './CheckEligibility';
import { questionPropTypes } from './questionPropTypes';

const { GENERAL: GENERAL_CONDITION } = INDICATIONS;

export const BoxBiomarkersQuestion = ({ onselect, user }) => {
  const { t } = useTranslation();

  const options = app.biomarkers.map(({ name, code }) => ({
    title: t(name),
    value: code,
  }));

  return (
    <div className="box-question-biomarkers">
      <div className="hcp-input-title">
        {t('general.biomarkers')}
      </div>
      <CheckEligibility />
      <div>
        <HCPSelect
          user={user}
          showAutofill
          renderOnSelect={false}
          useTags
          isMulti
          onselect={(v) => {
            const fetch = async () => {
              // eslint-disable-next-line no-param-reassign
              user.condition_profile.biomarkers = v || [];
              if (user.personal.condition && user.personal.condition !== GENERAL_CONDITION) {
                const questions = await app.getQuestions(user.personal.condition, true);
                // eslint-disable-next-line no-param-reassign
                user.condition_profile.qstack = getQstack(user, questions);
              }
              onselect(user.condition_profile.biomarkers);
              if (window._accordion) {
                window._accordion.render();
              }
            };
            fetch();
          }}
          options={options}
          initValue={user.condition_profile.biomarkers || ['none']}
        />
      </div>
    </div>
  );
};

BoxBiomarkersQuestion.propTypes = questionPropTypes;

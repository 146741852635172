export const LOADING_MAX_PERCENT = 95;

export const FILE_TYPES_VALIDATORS = [
  {
    type: 'images',
    extensions: ['jpg', 'jpeg', 'png'],
    maxQuantity: 20,
    maxSizeMb: 10,
  },
  {
    type: 'documents',
    extensions: ['pdf'],
    maxQuantity: 5,
    maxSizeMb: 20,
  },
];

export const UPLOADER_STATES = {
  SELECT: 'SELECT',
  MULTI: 'MULTI',
  UPLOADING: 'UPLOADING',
  SUCCESS: 'SUCCESS',
  CUSTOM1: 'CUSTOM1',
  ANALYZING: 'ANALYZING',
  ERROR: 'ERROR',
};

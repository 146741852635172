/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable arrow-parens */
/* eslint-disable react/button-has-type */
/* eslint-disable-next-line jsx-a11y/alt-text */
/* eslint-disable-line(mode) */
/* eslint-disable-next-line */

import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import GenericChat from '../../Components/GenericChat';
import { formmatedTrials, updateChatScoreUsingThumb, socTreatmentsCategoriesFormatter } from '../Chat/functions';
// eslint-disable-next-line import/no-named-as-default
import ActionPanel from '../Chat/ActionPanel/ActionPanel';
import NextScanChat from '../Chat/Forms/NextScan';
import { formmatedlData, getDaysSinceRegistration, handleSendMessageByReturnUser } from './functions';

let userChatMode = '-1';
let socTreatments = [];
let newSessionFromUi = true;

const setUserChatMode = mode => {
  userChatMode = mode;
};
const setSocTreatments = treatments => {
  socTreatments = treatments;
};

const setNewSessionFromUi = newSessionFromUiState => {
  newSessionFromUi = newSessionFromUiState;
};

const ChatReturnUserPopup = () => {
  const [init, setInit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [chatHistory, setChatHistory] = useState(app.chatHistoryReturnUser || []);
  const [showChatNextScan, setShowChatNextScan] = useState(false);
  const [payload, setPayload] = useState({ ...app.user });
  const [showSpinnerOnly, setShowSpinnerOnly] = useState(newSessionFromUi);
  const [socTreatmentsCategories, setSocTreatmentsCategories] = useState([]);
  const [treatmentCategories, setTreatmentCategories] = useState([]);
  const userTrialList = formmatedTrials(app?.trials ?? []);
  const userHaveSponseredTrialList = app.trials?.some(trial => {
    return trial?.isSponsored;
  });
  const userSponsoredTrials = app.trials?.filter(trial => {
    return trial?.isSponsored;
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setSocTreatments(socTreatmentsCategoriesFormatter(app.treatments));
    getDaysSinceRegistration(app?.user?.personal?.first_registered_at);
    setPayload(
      formmatedlData(
        payload,
        userTrialList,
        userHaveSponseredTrialList,
        userSponsoredTrials,
        socTreatmentsCategories,
        socTreatments,
        false
      )
    );
    if (app.treatmentsCategories) {
      setSocTreatmentsCategories(
        app.treatmentsCategories
          .filter(e => !e.treatment_category_key.includes('supportive_care'))
          .map(e => e.treatment_category_key)
      );
    } else {
      setSocTreatmentsCategories([]);
    }
    if (app.trials) {
      const tempTreatmentCategories = [];
      app.trials.forEach(item => {
        item.groups.forEach(group => {
          if (!tempTreatmentCategories.includes(group)) {
            tempTreatmentCategories.push(group);
          }
        });
        setTreatmentCategories(tempTreatmentCategories);
      });
    } else {
      setTreatmentCategories([]);
    }

    setChatHistory(app.chatHistoryReturnUser || []);

    if (!chatHistory.length) {
      setChatHistory(chatHistory => [
        ...chatHistory,
      ]);
      setPayload({ ...payload });
    }
    // Call handleSendMessageByReturnUser if newSessionFromUi is true
    if (newSessionFromUi) {
      const sendMessage = async () => {
        await handleSendMessageByReturnUser('Hello', payload, setChatHistory, chatHistory, true);
        setNewSessionFromUi(false);
        setShowSpinnerOnly(false);
      };

      sendMessage(); // Call the async function
    }
    setInit(true);
  }, []);

  useEffect(() => {
    const $chatPopup = document.querySelector('.chat-popup');
    if ($chatPopup) {
      $chatPopup.scrollTop = $chatPopup.scrollHeight;
    }
    app.chatHistoryReturnUser = chatHistory;
  }, [chatHistory]);

  const handleSendMessageClick = async (inputMessage, payload, dontIncludeInHistory) => {
    await handleSendMessageByReturnUser(inputMessage, payload, setChatHistory, chatHistory, dontIncludeInHistory);
  };

  const NextScanContainer = (
    <div>
      {showChatNextScan ? (
        <div>
          <NextScanChat setShowChatNextScan={setShowChatNextScan} setChatHistory={setChatHistory} />
        </div>
      ) : null}
    </div>
  );

  const ActionPanelContainer = (
    <ActionPanel
      loading={loading}
      setUserChatMode={setUserChatMode}
      setChatHistory={setChatHistory}
      userHaveSponseredTrialList={userHaveSponseredTrialList}
      setShowChatNextScan={setShowChatNextScan}
    />
  );

  return init ? (
    newSessionFromUi ? (
      <GenericChat
        handleSendMessageClick={handleSendMessageClick}
        updateChatScoreUsingThumb={updateChatScoreUsingThumb}
        actionPanel={<>{ActionPanelContainer}</>}
        chatHistory={chatHistory}
        chatBodyComponent={<>{NextScanContainer}</>}
        payload={payload}
        showSpinnerOnly
        type="returnUser"
      />
    ) : (
      <GenericChat
        handleSendMessageClick={handleSendMessageClick}
        updateChatScoreUsingThumb={updateChatScoreUsingThumb}
        actionPanel={<>{ActionPanelContainer}</>}
        chatHistory={chatHistory}
        chatBodyComponent={<>{NextScanContainer}</>}
        payload={payload}
        type="returnUser"
      />
    )
  ) : (
    <GenericChat
      handleSendMessageClick={handleSendMessageClick}
      updateChatScoreUsingThumb={updateChatScoreUsingThumb}
      actionPanel={<>{ActionPanelContainer}</>}
      chatHistory={chatHistory}
      chatBodyComponent={<>{NextScanContainer}</>}
      payload={payload}
      type="returnUser"
    />
  );
};

export default ChatReturnUserPopup;

import {
  useState, useEffect, useMemo, useRef,
} from 'react';
import {
  PENDING, PRISTINE, FAILURE, SUCCESS,
} from '../constants/queryStatuses';
import { useIsMounted } from './useIsMounted';

const getParametersIdDefault = (params) => params;

// dataKey can be used to force action re-run; in most cases should be stable; can be empty
export const useAsyncAction = ({
  action,
  parameters,
  dataKey,
  initState = null,
  // to determine if the parameters have changed
  getParametersId = getParametersIdDefault,
  isActive,
}) => {
  const [state, setState] = useState({ status: PRISTINE, data: initState });
  const isMounted = useIsMounted();
  const abortControllerRef = useRef(null);
  const requestCounterRef = useRef(0);

  const parametersString = useMemo(() => (
    getParametersId(parameters)
  ), [parameters, getParametersId]);

  useEffect(() => {
    if (!isActive) {
      return () => {};
    }

    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }

    const abortController = new AbortController();
    abortControllerRef.current = abortController;
    const { signal } = abortController;

    requestCounterRef.current += 1;
    const currentRequestNumber = requestCounterRef.current;

    setState({ data: null, status: PENDING });

    action(parameters, { signal })
      .then((result) => {
        if (!isMounted() || currentRequestNumber !== requestCounterRef.current) {
          return;
        }
        setState({ data: result, status: SUCCESS });
      })
      .catch((ex) => {
        if (!isMounted() || currentRequestNumber !== requestCounterRef.current) {
          return;
        }
        console.error(`useAsyncAction ${dataKey} ex:`, ex);
        setState({ data: null, status: FAILURE });
      });

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
    // parameters must not trigger effect re-run
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, parametersString, dataKey, isActive, isMounted]);

  return state;
};

import { SUBTYPE_FIELD_BY_ALL_CONDITIONS, INDICATIONS } from 'new-ui/constants';
import { deduplicateList } from 'functions/deduplicateList';
import { indicationChooser } from 'components/Questionnaire/functions';
import { getInitialConditionProfileByIndication } from 'new-ui/functions';

const { GENERAL: GENERAL_CONDITION } = INDICATIONS;

/**
 * @param indication {{ value: string, type: string }}
 * @return {{diseaseSubTypeField: string, diseaseSubTypeValue: string} | {}}
 */
export const getIndicationParams = (indication) => {
  if (!indication.type) {
    return {};
  }

  return {
    diseaseSubTypeField: SUBTYPE_FIELD_BY_ALL_CONDITIONS[indication.value],
    diseaseSubTypeValue: indication.type,
  };
};

/**
 * @param indication {{ value: string, type: string }}
 * @param conditionProfile {{ qstack: Array.<string> }}
 */
export const removeNonIndicationAttrs = async (indication, conditionProfile) => {
  const profile = {
    qstack: [],
  };

  const questions = await indicationChooser(indication.value, getIndicationParams(indication));

  for (const question of questions) {
    profile[question.id] = question.id in conditionProfile ? conditionProfile[question.id] : null;
    profile.qstack.push(question.id);
  }

  return profile;
};

/**
 * @param indication {{ value: string, type: string }}
 * @param conditionProfile {Object}
 * @return {{}}
 */
export const mixGeneralRelatedAttrs = (indication, conditionProfile) => {
  const { value, type } = indication;

  if (value !== GENERAL_CONDITION) {
    return conditionProfile;
  }

  const attributeName = SUBTYPE_FIELD_BY_ALL_CONDITIONS[indication.value];

  conditionProfile[attributeName] = type ?? null;
  conditionProfile.qstack = [...conditionProfile.qstack, attributeName];

  return conditionProfile;
};

/**
 * Removes non-indication-specific attributes and mixes in general indication subtype into the cond profile.
 * @param indication {{ value: string, type: string }}
 * @param conditionProfile {{}}
 * @return {Promise<{}>}
 */
export const preProcessCondProfile = async (indication, conditionProfile) => {
  let profile = await getInitialConditionProfileByIndication(
    indication.value,
    conditionProfile,
  );

  profile = await removeNonIndicationAttrs(indication, profile);

  if (indication.value !== GENERAL_CONDITION) {
    return profile;
  }

  return mixGeneralRelatedAttrs(indication, profile);
};

// qstack should be updated (if necessary) separately
export const mergeUser = (
  user,
  toUpdate,
  {
    mergeBiomarkers,
    mergePatients,
    ignoreEmptyField,
  } = {},
) => {
  const pickNotEmpty = (target) => Object.fromEntries(
    Object.entries(target).filter(([, value]) => (
      value && (!Array.isArray(value) || value.length)
    )),
  );
  const pickToUpdate = (target) => (ignoreEmptyField ? pickNotEmpty(target) : target);

  const nextUser = {
    ...user,
    personal: {
      ...(user.personal ?? {}),
      ...pickToUpdate((toUpdate.personal ?? {})),
    },
    info: {
      ...(user.info ?? {}),
      ...pickToUpdate((toUpdate.info ?? {})),
    },
    condition_profile: {
      ...(user.condition_profile ?? {}),
      ...pickToUpdate((toUpdate.condition_profile ?? {})),
    },
  };

  if (toUpdate?.patients) {
    if (mergePatients) {
      nextUser.patients = [...(nextUser.patients ?? []), ...toUpdate.patients];
    } else {
      nextUser.patients = toUpdate.patients;
    }
  }

  if (toUpdate?.condition_profile?.biomarkers) {
    if (mergeBiomarkers) {
      nextUser.condition_profile.biomarkers = deduplicateList([
        ...(user.condition_profile.biomarkers ?? []),
        ...(toUpdate.condition_profile.biomarkers ?? []),
      ]);
    } else {
      nextUser.condition_profile.biomarkers = toUpdate.condition_profile.biomarkers;
    }
    if (nextUser.condition_profile.biomarkers.length > 1) {
      nextUser.condition_profile.biomarkers = nextUser.condition_profile.biomarkers.filter(
        (biomarker) => biomarker !== 'none',
      );
    }
  }

  return nextUser;
};

export const updateUserQstack = (user, qstack) => mergeUser(user, { condition_profile: { qstack } });

export const getUserCondition = (user) => user?.personal?.condition;

export const getUserConditionProfile = (user) => user?.condition_profile;
export const getUserWixId = (user) => user?.personal?.user_wix_id;
export const getUserProfileId = (user) => user?.personal?.profile_id;

// a user who can add patient to his patient pull
export const getUserEmail = (user) => user?.info?.email;
export const getUserType = (user) => user?.info?.user_type;

export const isNewlyDiagnosedUser = (user) => {
  const DISEASE = 'new_disease';
  const conditionProfile = getUserConditionProfile(user);
  const diseaseStatus = conditionProfile?.disease_status;

  if (Array.isArray(diseaseStatus)) {
    return diseaseStatus.includes(DISEASE);
  } if (typeof diseaseStatus === 'string') {
    return diseaseStatus === DISEASE;
  }

  return false;
};

const LABELS = {
  'Biopsy & Pathology': {
    title: 'Biopsy & Pathology',
    key: 'biopsy_and_pathology',
  },
  'CT Scan': {
    title: 'CT Scan',
    key: 'ct_scan',
  },
  'PET Scan': {
    title: 'PET Scan',
    key: 'pet_scan',
  },
  'MRI Scan': {
    title: 'MRI Scan',
    key: 'mri_scan',
  },
  'Biomarker Testing': {
    title: 'Biomarker Testing',
    key: 'biomarker_testing',
  },
  'NGS Results': {
    title: 'NGS Results',
    key: 'ngs_results',
  },
  'Oncologist appointment': {
    title: 'Oncologist appointment',
    key: 'oncologist_appointment',
  },
};

const getLabelKey = (label) => LABELS[label]?.key;

export const arrangeArticles = (articles) => articles.map((article) => {
  const { tags = [] } = article;
  return {
    ...article,
    tag: tags.join(),
    key: getLabelKey(tags.join()),
  };
});

export const getArticles = (articles, offset = 0, limit = 400) => { // Temp increase from 4 to 400 for loading all articles
  const data = articles.slice(offset, offset + limit);
  const totalItems = articles.length;
  const hasMore = offset + limit < totalItems;

  return {
    data,
    totalItems,
    hasMore,
  };
};

export const sortArticlesByLabels = (articles, checkedLabels) => {
  if (checkedLabels.length === 0) {
    return [...articles].sort((a, b) => a.id - b.id);
  }

  return [...articles].sort((a, b) => {
    const aIndex = checkedLabels.indexOf(a.key);
    const bIndex = checkedLabels.indexOf(b.key);

    if (aIndex === -1 && bIndex === -1) {
      return a.id - b.id;
    }
    if (aIndex === -1) return 1;
    if (bIndex === -1) return -1;

    return aIndex - bIndex;
  });
};

export const getLabels = () => Object.values(LABELS);

import { batch } from 'react-redux';
import { push } from 'connected-react-router';
import { replaceCurrentUser } from 'modules/currentUser/actions';
import { currentUserLossWarningPopup } from './currentUserLossWarningPopup';
import { saveCurrentUserAndContinue } from './saveCurrentUserAndContinue';

const getContinueWithoutSavingAction = ({ redirectPath }) => () => (dispatch) => {
  batch(() => {
    dispatch(replaceCurrentUser({}));
    if (redirectPath) {
      dispatch(push(redirectPath));
    }
  });
};

export const startNewCurrentUser = ({ popupTitle, redirectPath }) => (dispatch) => {
  const continueAction = getContinueWithoutSavingAction({ redirectPath });
  dispatch(currentUserLossWarningPopup({
    popupTitle,
    continueWithoutSavingAction: continueAction,
    saveAndContinueAction: () => (actualDispatch) => {
      actualDispatch(saveCurrentUserAndContinue({
        continueAction,
      }));
    },
  }));
};

import React, { useState, useEffect } from 'react';
import './Trial.css';

import { useSelector } from 'react-redux';

import TrialItem from 'new-ui/Trial/Item';
import ClosestTrial from 'new-ui/Trial/Closest';
import TrialDetails from 'new-ui/Trial/Details';
import BackButton from 'new-ui/Components/BackButton';
import { useSetHeaderSettings } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { VERSIONS } from 'new-ui/Components/PageHeader/constants';
import { useMatchedItemEligibilityCheck } from 'components/MatchItemEligibilityCheck/useMatchedItemEligibilityCheck';
import { MatchItemEligibilityStatus } from 'components/MatchItemEligibilityStatus/MatchItemEligibilityStatus';
import { fetchSponsoredTrialTemplateData } from 'modules/sponsoredTrialTemplate/api';
import { getUserCondition } from 'modules/user/utils';
import { getTrialByNCT } from 'new-ui/api';
import { TRIAL_TYPES } from 'utils/constants/trial';
import { useParams } from 'react-router-dom';
import app from 'new-ui/app';
import WhatsNext from './WhatsNext';

export const GA_HCP = {
  category: 'HCP',
  events: {
    TRIAL_VIEWED: 'hcp trial viewed',
  },
};

const PublicTrial = () => {
  const { nctNumber } = useParams();
  const [sponsoredTemplate, setSponsoredTemplate] = useState({});
  const [trial, setTrial] = useState(null);

  const {
    lang,
    currentUser,
  } = useSelector((state) => ({
    lang: state?.main?.lang,
    currentUser: state.currentUser.data,
  }));

  const user = currentUser ?? app.user;

  useSetHeaderSettings({
    version: VERSIONS.HCP,
  });

  app.trial = trial; // debugging purposes

  useEffect(() => {
    if (!trial) {
      return;
    }
    app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.TRIAL_VIEWED);
  }, [trial]);

  const eligibilityStatus = useMatchedItemEligibilityCheck({ trialType: TRIAL_TYPES.TRIAL, nctNumber });

  const condition = getUserCondition(user);
  const userCountry = user?.info?.country?.location;

  useEffect(() => {
    const fetch = async () => {
      const trial_ = await getTrialByNCT({
        nctNumber,
        usersCountry: userCountry,
        conditions: {
          cancerType: condition,
        },
      });
      setTrial(trial_);
    };
    if (!trial) fetch();
  }, [condition, nctNumber, trial, user, userCountry]);

  useEffect(() => {
    const getSponsoredTemplate = async () => {
      const facilityName = trial?.closest_facility?.facility_name;
      const conditions = {
        isMobile: false,
        isForPDF: false,
        lang,
        country: user?.info?.country?.name || '',
        countryStates: user?.info?.country?.state ? [user?.info?.country?.state] : undefined,
        facilityNames: facilityName ? [facilityName] : undefined,
        cancerType: user?.personal?.condition || '',
        nctNumbers: [trial.nct_number],
      };
      const r = await fetchSponsoredTrialTemplateData({
        nctId: trial.nct,
        conditions,
        nctNumber: nctNumber?.toUpperCase(),
        indication: user?.personal?.condition || '',
      });

      app._lastFetchedSponsored = r;

      if (r?.result?.length) {
        const template = {};
        r.result.forEach((item) => {
          if (String(item.blockName).startsWith('WhatNext')) {
            template.WhatsNext = item.content;
          }
          if (String(item.blockName).startsWith('HeaderTitle')) {
            template.HeaderTitle = item.content[0];
          } else {
            template[item.blockName] = item.content;
          }
        });
        setSponsoredTemplate(template);
      }
    };

    if (trial?.isSponsored) getSponsoredTemplate();
  }, [lang, trial, nctNumber]);

  const renderActions = (type) => (
    <div>
      { type === 'mobile' ? (
        <div className="mobile-only">
          <div className="height-10" />
        </div>
      ) : null}
      <div className={`${type === 'mobile' ? 'mobile-only' : 'desktop-only'} `}>
        {/* { renderActionWidget(trial) } */}
        { sponsoredTemplate.WhatsNext ? <WhatsNext content={sponsoredTemplate.WhatsNext} /> : null}
        <div className="height-20" />
        {trial?.closest_facility?.facility_id ? <ClosestTrial trial={trial} /> : null}
      </div>
    </div>
  );

  return (
    trial ? (
      <div className="trial">
        {/* <div className="back-button-holder"><BackButton action={() => { window.location = ROUTES[PAGES.SEARCH]; }} /></div> */}
        <div className="back-button-holder"><BackButton /></div>
        {
          eligibilityStatus ? <MatchItemEligibilityStatus status={eligibilityStatus} /> : null
        }
        <div className="grid">
          <div className="widget">
            <TrialItem
              isFull
              item={trial}
              title={sponsoredTemplate?.HeaderTitle ?? null}
              logoSrc={sponsoredTemplate?.Logo}
              user={user}
            />
            {renderActions('mobile')}
            <div className="height-20" />
            <TrialDetails trial={trial} content={sponsoredTemplate.Description ? sponsoredTemplate.Description : null} />
          </div>
          {renderActions('desktop')}
        </div>
      </div>
    ) : null
  );
};

export default PublicTrial;

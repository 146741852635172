import React from 'react';
import NotificationIcon from 'new-ui/assets/icons/notification.svg';
import { useTranslation } from 'utils/modifiedTranslation';

export const CheckEligibility = () => {
  const { t } = useTranslation();
  return (
    <div className="intake-question-eligibility">
      <img src={NotificationIcon} alt="" />
      {' '}
      {t('general.missing_eligibility_critiria')}
    </div>
  );
};

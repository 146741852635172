import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { ReactComponent as LoginIcon } from 'new-ui/assets/icons/login-button.svg';
import { ReactComponent as UserTickIcon } from 'new-ui/assets/icons/user_tick.svg';
import { ReactComponent as SearchIcon } from 'new-ui/assets/icons/search-zoom-in.svg';
import { ReactComponent as PatientsIcon } from 'new-ui/assets/icons/patients.svg';
import { ReactComponent as UserIcon } from 'new-ui/assets/icons/user2.svg';
import { THEMES } from 'new-ui/constants';
import { useTranslation } from 'utils/modifiedTranslation';
import Button from 'new-ui/Components/Button';
import ProfileButton from 'new-ui/Components/ProfileButton';
import styles from './hcpHeaderRightSlot.module.css';

export const HCPHeaderRightSlot = ({
  addPatient,
  goToLogin,
  goToPatients,
  startSignup,
  isAuthenticated,
  isHCPUser,
  newPatient,
}) => {
  const { t } = useTranslation();

  // should be no more than 2
  let buttons = [];
  if (isHCPUser) {
    // authenticated HCP User
    if (addPatient) {
      buttons.push(
        <Button
          key="addPatient"
          theme={THEMES.BLACK}
          title={t('search.save')}
          action={addPatient}
          customIcon={<UserTickIcon className={styles.buttonIcon} />}
          className={styles.button}
          titleClassName={styles.buttonLabel}
        />,
      );
    } else {
      buttons.push(
        <Button
          key="new"
          theme={THEMES.BLACK}
          title={t('general.new')}
          action={newPatient}
          customIcon={<SearchIcon className={styles.buttonIcon} />}
          className={styles.button}
          titleClassName={styles.buttonLabel}
        />,
      );
    }
    buttons.push(
      <Button
        key="patients"
        theme={THEMES.GREY}
        title={t('general.patients')}
        action={goToPatients}
        customIcon={<PatientsIcon className={styles.buttonIcon} />}
        className={styles.button}
        titleClassName={styles.buttonLabel}
      />,
    );
  } else if (!isAuthenticated) {
    buttons = [
      <Button
        key="startSignup"
        theme={THEMES.BLACK}
        title={t('search.save')}
        action={startSignup}
        customIcon={<UserTickIcon className={styles.buttonIcon} />}
        className={styles.button}
        titleClassName={styles.buttonLabel}
      />,
    ];
  }

  return (
    <div className={styles.root}>
      {buttons}

      {
        isAuthenticated ? (
          <ProfileButton />
        ) : (
          <>
            <Button
              className={cx(styles.button, 'mobile-only')}
              titleClassName={styles.buttonLabel}
              theme={THEMES.GREY}
              title={t('general.login')}
              action={goToLogin}
              customIcon={<LoginIcon className={styles.buttonIcon} />}
            />
            <Button
              className={cx(styles.button, 'desktop-only')}
              titleClassName={styles.buttonLabel}
              theme={THEMES.GREY}
              title={t('general.login')}
              action={goToLogin}
              customIcon={<UserIcon className={styles.buttonIcon} />}
            />
          </>
        )
      }
    </div>
  );
};

HCPHeaderRightSlot.propTypes = {
  addPatient: PropTypes.func,
  goToPatients: PropTypes.func.isRequired,
  goToLogin: PropTypes.func.isRequired,
  newPatient: PropTypes.func.isRequired,
  startSignup: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.bool.isRequired,
  isHCPUser: PropTypes.bool.isRequired,
};

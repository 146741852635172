import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import './style.css';
import { PAGES, ROUTES, INDICATIONS } from 'new-ui/constants';
import { push } from 'connected-react-router';
import { createIndicationEmptyUser } from 'modules/hcp/helpers';
import { setPatient } from 'modules/hcp/actions/setPatient';
import { useTranslation } from 'utils/modifiedTranslation';
import NewButton from '../NewButton';
import IndicationChoose from '../IndicationChoose';

const { GENERAL: GENERAL_CONDITION } = INDICATIONS;
const CONDITIONS = Object.values(INDICATIONS);

const PatientsFooter = ({ indications }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [indication, setIndication] = useState(null);
  const onSearchIndication = (value) => {
    setIndication(value);
  };
  const onButtonClick = () => {
    if (indication !== GENERAL_CONDITION && CONDITIONS.includes(indication)) {
      createIndicationEmptyUser(indication)
        .then((user) => {
          dispatch(setPatient({
            user,
            redirectPath: ROUTES[PAGES.SEARCH],
            popupTitle: t('save_profile.title'),
          }));
        });
    } else {
      dispatch(push(ROUTES[PAGES.SEARCH]));
    }
  };

  return (
    <div className="patients-footer">
      <span className="description">Start a new search:</span>
      <IndicationChoose onChange={onSearchIndication} options={indications} />
      <NewButton onClick={onButtonClick} />
    </div>
  );
};

export default PatientsFooter;

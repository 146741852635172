/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable max-len */
/* eslint-disable prefer-template */
/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
/* eslint-disable object-curly-newline */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable arrow-parens */
/* eslint-disable react/button-has-type */
/* eslint-disable-next-line jsx-a11y/alt-text */
/* eslint-disable-line(mode) */
/* eslint-disable-next-line */

import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import app from 'new-ui/app';
import { CHAT_ROLES } from 'new-ui/constants';
import { formatTextToUseNewLineBeforeNumbers } from './functions';
import sendMessageIcon from '../../assets/icons/send_message.svg';
import lealRoleChatIcon from '../../assets/icons/leal_role_chat.svg';
import userRoleChatIcon from '../../assets/icons/user_role_chat.svg';
import thumbDownIcon from '../../assets/icons/thumb_down.svg';
import thumbUpIcon from '../../assets/icons/thumb_up.svg';
import { ChatLoader } from './ChatLoader/ChatLoader';
import './index.css';

const GenericChat = ({
  chatBodyComponent,
  updateChatScoreUsingThumb,
  handleSendMessageClick,
  actionPanel,
  chatHistory,
  payload,
  loader,
  showSpinnerOnly, // Add the new prop
  isFullMode = { isFullMode: false },
  type
}) => {
  const [inputMessage, setInputMessage] = useState('');
  const [init, setInit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showThumbs, setShowThumbs] = useState(true);
  const bottomRef = useRef(null);
  const [payload2] = useState(payload);
  const userWixId = app?.user?.info?.user_wix_id ?? 'unknown';
  const location = useLocation();

  useEffect(() => {
    setInit(true);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (app?.coniguration?.data?.clearChatHistory) {
      app.coniguration.data.clearChatHistory = false;
    }
    const $chatPopup = document.querySelector('.chat-popup');
    if ($chatPopup) {
      $chatPopup.scrollTop = $chatPopup.scrollHeight;
    }

    if (type === 'returnUser') {
      app.chatHistoryReturnUser = chatHistory;
    } else {
    app.chatHistory = chatHistory;
    }
    // eslint-disable-next-line
  }, [chatHistory]);

  useEffect(() => {
    if (bottomRef.current) {
      window.scrollTo({
        top: bottomRef.current.offsetTop,
        behavior: 'smooth'
      });
    }
  }, [chatHistory]);

  const handleSendMessage = async () => {
    if (inputMessage.trim()) {
      setLoading(true);
      if (payload2) {
        await handleSendMessageClick(inputMessage, payload2);
      } else {
        await handleSendMessageClick(inputMessage);
      }
      setInputMessage('');
      setLoading(false);
    }
  };

  return init ? (
    <div className="chat-popup">
      {showSpinnerOnly ? (
        <div className="chat-popup-sidebar-spinner">
          <ChatLoader />
        </div>
      ) : (
        <>
          {(actionPanel && !isFullMode.isFullMode) ? (actionPanel) : null}
          <div className="chat-actions-panel-divider" />
        </>
      )}
      <div id="conversation" className="chat-popup-conversation">
        <>
          {chatHistory?.map((message, index) => {
            return (
              <div key={index} className={`chat-message ${message?.role}`}>
                <div className="chat-message-content">
                  <div className="chat-role">
                    {message?.role !== CHAT_ROLES.ME ? (
                      <img src={lealRoleChatIcon} alt="Leal Role" />
                    ) : (
                      <img src={userRoleChatIcon} alt="User Role" />
                    )}
                  </div>
                  <div className="chat-message-text">
                    <b>{message?.role}</b>:
                    <span
                      dangerouslySetInnerHTML={{
                        __html: formatTextToUseNewLineBeforeNumbers(message.content),
                      }}
                    />
                    <br />
                    {message?.daynamicContent ? <>{message.daynamicContent}</> : null}
                  </div>
                </div>
                {index === chatHistory.length - 1 && index > 0 && chatHistory[index].role !== 'Me' && showThumbs ? (
                  <div className="chat-message-thumb">
                    <span className="thumb-icon">
                      <img
                        src={thumbUpIcon}
                        alt="thumb Up"
                        onClick={() => updateChatScoreUsingThumb(userWixId, 1, setShowThumbs)}
                      />
                    </span>
                    <span className="thumb-icon">
                      <img
                        src={thumbDownIcon}
                        alt="thumb Down"
                        onClick={() => updateChatScoreUsingThumb(userWixId, -1, setShowThumbs)}
                      />
                    </span>
                  </div>
                ) : null}
              </div>
            );
          })}
        </>
        {(loading || loader) && <ChatLoader />}
      </div>
      {chatBodyComponent}
      <div className={`chat-input ${(!isFullMode.isFullMode) ? 'add-absulot' : ''}`}>
      {
            !isFullMode.isFullMode ? <></> : <div style={{ height: '250px' }} />
          }
        {/* {isFullMode.isFullMode && !location.pathname.startsWith('/dashboard/results') && <div className="full-screen-height" />} */}
        <div
          className="submit-wrapper"
        >
        <textarea
          disabled={loading}
          value={inputMessage}
          onChange={e => setInputMessage(e.target.value)}
          onKeyPress={e => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage(inputMessage);
            }
          }}
          className="chat-input-textarea"
          maxLength={1500}
          minLength={2}
          rows={1}
          placeholder="Type your message..."
        />
        <button
          onClick={handleSendMessage}
          disabled={loading}
          className="chat-send-button"
        >
          <img src={sendMessageIcon} alt="Send Message" />
        </button>
        </div>
          <div className="chat-send-to-support">
            {`BETA. Have feedback? `}
            <a href="mailto:support@leal.health?subject=My%20Feedback%20on%20Leal%20Health%20Chat&body=Hi%20Leal%20Health%2C%0A%0AMy%20feedback%20about%20the%20Leal%20Health%20chat%20is%20as%20follows%3A%0A%0A%0APlease%20add%20a%20screenshot.">
              Contact Support.
            </a>
          </div>
      </div>
      <div id="bottomRef" ref={bottomRef} />
    </div>
  ) : null;
};

export default GenericChat;

/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import Intake from 'new-ui/Components/Intake';

import { INTERCOM_EVENTS, POPUP_TYPES } from 'new-ui/constants';

import { PopupTitle, PopupFooter } from 'new-ui/Components/Popup';
import { getUserConditionProfile, getUserCondition } from 'modules/user/utils';
import { useTranslation } from 'utils/modifiedTranslation';
import { questionToURL } from 'new-ui/functions/missingQuestionsTransformer';

import { SECONDARY_THEME } from 'new-ui/Components/Intake';
import app from 'new-ui/app';

import PropTypes from 'prop-types';
import inContextEvents, {
  GA_CATEGORY_NAME,
} from 'new-ui/Components/GoogleAnalytics/inContextEvents';

import './MissingCriticalInformation.css';
import { filterQuestionsByCondition } from 'new-ui/functions';

const MissingCriticalInformation = (props) => {
  const { trial, source } = props;
  let { questions } = props;

  if (!questions) {
    questions = trial
      ? trial.missed_questions
      : [
        ...new Set(app.trials.reduce((a, b) => a.concat(b.missed_questions), [])),
        ...new Set(app.treatments ? app.treatments.reduce((a, b) => a.concat(b.missed_questions), []) : null),
      ];
  }
  questions = [...new Set(questions)];
  const [filteredQuestions, setFilteredQuestions] = useState(questions);
  useEffect(() => {
    const filterQuestions = async () => {
      const filtered = await filterQuestionsByCondition({
        questionsIds: questions,
        condition: getUserCondition(app?.user),
        conditionProfile: getUserConditionProfile(app?.user),
      });
      setFilteredQuestions(filtered);
    };
    filterQuestions();
    // eslint-disable-next-line
  }, []);
  questions = filteredQuestions;
  const originalTrialsIds = [...app.trials].map((t) => t._id);
  const { t } = useTranslation();
  const { eventName, getMetaData } = inContextEvents.popupInContextDisplayed;
  app.sendGoogleAnalyticsEvent(
    GA_CATEGORY_NAME,
    eventName,
    getMetaData(filteredQuestions.length),
  );

  app.onpopupclose = () => {
    app.intercom.sendEvent(INTERCOM_EVENTS.MISSING_CRITICAL_INFORMATION.POPUP_SUCCESS, { source });
    // setTimeout(() => {
    //   app.intercom.show();
    // }, 500);
  };

  const onDone = async (questionDone, index) => {
    app.setShowPreloader(true);
    await app.update({ rematch: true });
    app.setShowPreloader(false);
    app.renderPage();
    if (questionDone) {
      questions.splice(questions.indexOf(questionDone), 1);
      if (!questions.length) {
        if (source) app.intercom.sendEvent(INTERCOM_EVENTS.MISSING_CRITICAL_INFORMATION.POPUP_SUCCESS, { source });
        return app.setPopup(false);
      }
      app.setPopup(POPUP_TYPES.MISSING_CRITICAL_INFORMATION, { questions, source });
      return;
    }
    app.setPopup(false);

    const updatedTrial = null;
    if (trial) app.trials.find((tr) => tr._id === trial._id);
    let newTrials = 0;

    app.trials.forEach((_trial) => {
      if (!originalTrialsIds.includes(_trial._id)) newTrials += 1;
    });

    let alertMessage = '';

    if (updatedTrial) {
      if (!updatedTrial.missed_questions.length) {
        alertMessage = t('missing_critical.toast_message_ready_for_review');
        const { eventName, getMetaData } = inContextEvents.profileUpdate;
        app.sendGoogleAnalyticsEvent(
          GA_CATEGORY_NAME,
          eventName,
          getMetaData(index, alertMessage),
        );
        app.alert(alertMessage);
        return;
      }
      alertMessage = t('missing_critical.toast_message_answered_partly');
    } else if (!updatedTrial && newTrials) {
      if (newTrials === 1) {
        alertMessage = t('missing_critical.toast_message_not_relevant_new_singular');
      } else {
        alertMessage = t('missing_critical.toast_message_not_relevant_new_plural', {
          number: newTrials,
        });
      }
    } else if (!updatedTrial && app.trials.length) {
      if (app.trials.length === 1) {
        alertMessage = t('missing_critical.toast_message_not_relevant_old_singular');
      } else {
        alertMessage = t('missing_critical.toast_message_not_relevant_old_plural', {
          number: app.trials.length,
        });
      }
    } else if (!updatedTrial && !app.trials.length) {
      alertMessage = t('missing_critical.toast_message_no_trials');
    }
    const { eventName, getMetaData } = inContextEvents.profileUpdate;
    app.sendGoogleAnalyticsEvent(
      GA_CATEGORY_NAME,
      eventName,
      getMetaData(index, alertMessage),
    );
    app.alert(alertMessage);
  };

  const updateConfiguration = () => {
    app.setConfiguration({
      lastMissingCriticalUpdate: new Date().toISOString(),
    });
  };

  const processQuestion = (qid = 0) => {
    const question = questions[qid];
    if (!question) {
      if (source) app.intercom.sendEvent(INTERCOM_EVENTS.MISSING_CRITICAL_INFORMATION.POPUP_SUCCESS, { source });
      updateConfiguration();
      onDone();
      return;
    }
    app.setPopup(false);
    app.editQuestion(questionToURL(question), async () => {
      app.userSignupInteracted = true;
      // if (source) app.intercom.sendEvent(INTERCOM_EVENTS.MISSING_CRITICAL_INFORMATION.POPUP_SUCCESS, { source });
      updateConfiguration();
      processQuestion(qid + 1);
    });
  };

  return (
    <div className="missing-critical-information-popup">
      <PopupTitle title={t('missing_critical.title')} />
      <Intake
        theme={SECONDARY_THEME}
        user={app.user}
        customQuestions={questions}
        onUpdate={async (user) => {
          app.updateUser(user, true);
          await app.update({ rematch: true });
          app.setPopup(false);
        }}
      />
    </div>
  );
};

MissingCriticalInformation.propTypes = {
  trial: PropTypes.object,
  questions: PropTypes.array,
  source: PropTypes.string,
};

export default MissingCriticalInformation;

import { addCurrentUserPendingDocuments, updateCurrentUser } from 'modules/currentUser/actions';
import { mergeUser, getUserCondition } from 'modules/user/utils';
import app from 'new-ui/app';
import { POPUP_TYPES } from 'new-ui/constants';
import { GA_HCP } from 'new-ui/Search/GA';
import { getIsHCPPatient } from '../helpers';
import { savePatientPendingDocuments } from './savePatientPendingDocuments';
import { saveHCPPatient } from './saveHCPPatient';

export const biomarkersUploadPopup = () => (dispatch, getState) => {
  const { currentUser: { data: currentUser } } = getState();

  const handleUploadedFiles = (files, { jobId, source }) => {
    if (!files.length) {
      return;
    }

    dispatch(addCurrentUserPendingDocuments([{ files, metadata: { jobId, source } }]));

    const { currentUser: { data: actualCurrentUser } } = getState();

    if (!getIsHCPPatient(actualCurrentUser)) {
      return;
    }

    dispatch(savePatientPendingDocuments())
      .catch((ex) => {
        console.error('savePatientPendingDocuments ex', ex);
      });
  };

  app.setPopup(POPUP_TYPES.CURRENT_USER_NGS, {
    theme: 'middle',
    preventOverlayClose: true,
    onUploadedFilesChange: handleUploadedFiles,
    condition: getUserCondition(currentUser),
    onSuccess: async (biomarkers) => {
      app.sendGoogleAnalyticsEvent(GA_HCP.category, GA_HCP.events.NGS_SUCCESS, {
        biomarkers,
      });

      const { currentUser: { data: actualCurrentUser } } = getState();

      if (!actualCurrentUser) {
        // invariant
        return;
      }

      const nextUser = mergeUser(
        actualCurrentUser,
        { condition_profile: { biomarkers } },
        { mergeBiomarkers: true },
      );
      dispatch(updateCurrentUser(nextUser, true));

      if (getIsHCPPatient(nextUser)) {
        dispatch(saveHCPPatient()).catch((ex) => {
          console.error('biomarkersUploadPopup saveHCPPatient ex', ex);
        });
      }

      app.setPopup(false);
    },
  });
};

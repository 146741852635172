import queryString from 'query-string';
import { checkIsAuthenticated } from 'functions/checkIsAuthenticated';
import { USER_TYPE_URL_SEARCH_PARAM } from 'utils/constants/urlSearchParameters';
import { USER_TYPES } from 'utils/constants/user';
import { getIsHCPUserType } from '../helpers';
import { hcpUserSignupPopup } from './hcpUserSignup';
import { patientSignupPopup } from './patientSignup';

export const startSignup = ({
  userType,
  onHCPUserSuccessAction,
  onPatientSuccessAction,
} = {}) => (dispatch, getState) => {
  const {
    profile,
    currentUser: { data: currentUser },
    authentication,
    main: { urlParameters: initUrlParameters },
  } = getState();

  if (checkIsAuthenticated({ authentication, profile })) {
    // invariant
    console.error('patientSignup profile');
    return;
  }

  if (!currentUser) {
    // invariant
    console.error('patientSignup !currentUser');
    return;
  }

  let finalUserType = userType;
  if (!finalUserType) {
    const { query: { [USER_TYPE_URL_SEARCH_PARAM]: userTypeSearchParameter } } = queryString.parseUrl(initUrlParameters);
    if (Object.values(USER_TYPES).includes(userTypeSearchParameter)) {
      finalUserType = userTypeSearchParameter;
    }
  }

  if (!finalUserType || getIsHCPUserType(finalUserType)) {
    dispatch(hcpUserSignupPopup({ userType: finalUserType, onPatientSuccessAction, onHCPUserSuccessAction }));
    return;
  }
  dispatch(patientSignupPopup({ onSuccessAction: onPatientSuccessAction }));
};

export const getFormattedDate = () => {
  const today = new Date();
  const day = String(today.getDate()).padStart(2, '0');
  const month = String(today.getMonth() + 1).padStart(2, '0'); // months are 0-indexed
  const year = today.getFullYear();

  const formattedToday = `${day}-${month}-${year}`;

  return formattedToday;
};

import { useSelector } from 'react-redux';
import useAuth from 'utils/hooks/useAuth';
import { getUserType } from 'modules/user/utils';
import { USER_TYPE_URL_SEARCH_PARAM } from 'utils/constants/urlSearchParameters';
import { useInitUrlParameters } from 'utils/hooks/useInitUrlParameters';
import { USER_TYPES } from 'utils/constants/user';

const USER_TYPE_VALUES = Object.values(USER_TYPES);

export const useHCPUserType = () => {
  const isAuthenticated = useAuth();
  const profile = useSelector((state) => state.profile);

  const { query: { [USER_TYPE_URL_SEARCH_PARAM]: userTypeSearchParameter } } = useInitUrlParameters();

  if (isAuthenticated) {
    return getUserType(profile);
  }

  return USER_TYPE_VALUES.includes(userTypeSearchParameter) ? userTypeSearchParameter : null;
};

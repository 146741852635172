const CancerType = {
  MELANOMA: 'melanoma',
  MELANOMA_2: 'melanoma_2',
  CRC: 'crc',
  BC: 'breast_cancer',
  MDS: 'mds',
  MPN: 'mpn',
  BLADDER: 'bladder',
  BLADDER_2: 'bladder_2',
  UTERINE: 'uterine',
  BRAIN: 'brain',
  MM: 'mm',
  LUNG: 'lung',
  BREAST_2: 'breast_2',
  NHL: 'nhl',
  CRC_2: 'crc_2',
  PROSTATE: 'prostate',
  AML: 'aml',
  HNSCC: 'hnscc',
  CLL: 'cll',
  GI_TRACT: 'gi_tract',
  OVARIAN: 'ovarian',
  HCC: 'hcc',
  RCC: 'rcc_kidney',
  MESO: 'mesothelioma',
  GENERAL: 'general',
  CML: 'cml',
};

export default CancerType;

import { hasGenderQuestion, isEmptyObject } from 'new-ui/functions';

export const INTAKE_SECTIONS = {
  ABOUT_YOUR_CANCER: 'cancer',
  ABOUT_YOUR_TREATMENT: 'treatment',
  ABOUT_YOUR_HEALTH: 'health',
  ABOUT_YOU: 'about',
};

export const INTAKE_QUESTIONS = {
  [INTAKE_SECTIONS.ABOUT_YOUR_CANCER]: {
    breast_2: ['disease_status', 'molecular_subtype', 'her2_levels', 'patient_stage'],
    lung: ['disease_status', 'type_of_lung_cancer', 'type_of_nsclc', 'current_stage', 'has_biopsy'],
    crc_2: ['disease_status', 'current_stage'],
    prostate: ['disease_status', 'crpc', 'current_stage', 'gleason_score', 'psa_level'],
    melanoma_2: ['disease_status', 'melanoma_subtype', 'current_stage'],
    bladder_2: ['disease_status', 'patient_phase', 'patient_grade'],
    rcc_kidney: ['disease_status', 'type_of_rcc', 'patient_stage'],
    gi_tract: ['disease_status', 'type_of_gi', 'type_of_esophageal_gej', 'current_stage'],
    mesothelioma: ['disease_status', 'type_of_mesothelioma_cancer', 'histological_type_of_mesothelioma', 'patient_stage', 'disease_location'],
    general: ['disease_status', 'type_of_pancreatic', 'patient_stage'],
    cml: ['disease_status', 'cml_phase'],
    ovarian: ['disease_status', 'type_of_ovarian_cancer', 'current_stage', 'had_procedure_for_ovarian_cancer', 'procedures'],
    uterine: ['disease_status', 'type_of_uterine_cancer', 'current_stage'],
    hnscc: ['disease_status', 'type_of_hnscc', 'current_stage'],
    brain: ['disease_status', 'brain_cancer_type', 'brain_radiation'],
    mds: ['disease_status', 'risk_group', 'intermediate_risk_subgroup', 'percentage_of_bm_blasts'],
    mpn: ['disease_status', 'type_of_mpn', 'risk_group', 'intermediate_risk_subgroup', 'splenomegaly', 'platelet_count'],
    mm: ['disease_status', 'has_mprotein', 'has_serum_flc', 'chromosomal_alterions'],
    nhl: ['disease_status', 'type_of_lymphoma', 'type_of_bcell_lymphoma', 'type_of_marginal_cell_lymphoma', 'type_of_aids_related_lymphoma', 'current_stage', 'disease_location', 'current_stage_follicular_lymphoma'],
    cll: ['disease_status', 'type_of_disease', 'cll_characteristics', 'platelet_count', 'lymphocyte_count'],
    aml: ['disease_status', 'risk_group', 'received_transplant'],
    hcc: ['disease_status', 'type_of_liver', 'current_stage', 'number_lesions', 'hcc_related_health_problems'],
  },
  [INTAKE_SECTIONS.ABOUT_YOUR_TREATMENT]: {
    breast_2: ['treatment_for_advanced_metastatic_disease', 'treatment_for_breast_cancer', 'received_fam_trastuzumab', 'treatments'],
    lung: ['treatment_for_advanced_metastatic_disease', 'treatments'],
    crc_2: ['treatment_for_advanced_metastatic_disease', 'treatment_for_crc_cancer', 'treatments'],
    prostate: ['had_procedure_for_prostate_cancer', 'procedures', 'treatment_for_prostate_cancer', 'treatment_for_advanced_metastatic_disease', 'treatments'],
    melanoma_2: ['treatment_for_advanced_metastatic_disease', 'treatment_for_melanoma', 'treatments'],
    bladder_2: ['had_surgery_for_bladder_cancer', 'procedures', 'treatment_for_bladder_cancer', 'treatments'],
    rcc_kidney: ['had_procedure_for_kidney_cancer', 'treatment_for_kidney_cancer', 'treatment_for_advanced_metastatic_disease', 'treatments'],
    gi_tract: ['treatment_for_advanced_metastatic_disease', 'treatment_for_gi_tract', 'treatments'],
    mesothelioma: ['treatment_for_advanced_metastatic_disease', 'treatment_for_mesothelioma_cancer', 'treatments'],
    general: ['treatment_for_advanced_metastatic_disease', 'treatment_for_cancer', 'treatments'],
    cml: ['treatment_for_cml', 'treatments', 'received_transplant'],
    ovarian: ['treatment_for_ovarian_cancer', 'treatment_for_advanced_metastatic_disease', 'treatments'],
    uterine: ['treatment_for_uterine_cancer', 'treatments', 'had_procedure_for_uterine_cancer', 'procedures'],
    hnscc: ['treatment_for_hnscc', 'treatment_for_advanced_metastatic_disease', 'treatments', 'had_procedure', 'procedures'],
    brain: ['treatment_for_brain_cancer', 'treatments'],
    mds: ['treatment_for_mds', 'treatment_nontransplant_candidate', 'received_allogenic_transplant', 'allogenic_transplant_date', 'allogenic_transplant_outcome', 'treatment_after_transplant_relapse', 'treatment_after_transplant_relapse_outcome'],
    mpn: ['treatment_for_mpn', 'treatments', 'received_allogenic_transplant'],
    mm: ['received_transplant', 'treatment_for_mm', 'treatments'],
    nhl: [
      'treatment_for_lymphoma',
      'treatments',
      'procedure_candidate',
      'had_procedure_for_lymphoma',
      'procedures_received',
      'procedures',
    ],
    cll: ['treatment_for_cll', 'treatments', 'received_transplant'],
    aml: ['treatment_for_aml', 'treatments'],
    hcc: ['treatment_for_advanced_metastatic_disease', 'treatment_for_liver', 'treatments'],
  },
  [INTAKE_SECTIONS.ABOUT_YOUR_HEALTH]: {
    breast_2: ['ngs', 'biomarkers'],
    lung: ['ngs', 'biomarkers', 'braf_mutation', 'egft_mut', 'erbb2_her2_mut', 'met_mut', 'kras_mut', 'pd_l1_carrier_status', 'pd_l1_expression'],
    crc_2: ['ngs', 'biomarkers', 'msi', 'kras_mut'],
    prostate: ['ngs', 'biomarkers'],
    melanoma_2: ['ngs', 'biomarkers', 'braf_mutation'],
    bladder_2: ['ngs', 'biomarkers'],
    rcc_kidney: ['ngs', 'biomarkers'],
    gi_tract: ['ngs', 'biomarkers'],
    mesothelioma: ['ngs', 'biomarkers'],
    general: ['ngs', 'biomarkers', 'kras_mut'],
    cml: ['ngs', 'biomarkers', 'bcr_abl_variants', 'bcr_abl_mutations'],
    ovarian: ['ngs', 'biomarkers', 'msi'],
    uterine: ['ngs', 'biomarkers', 'msi'],
    hnscc: ['ngs', 'biomarkers'],
    brain: ['ngs', 'biomarkers'],
    mds: ['ngs', 'biomarkers'],
    mpn: ['ngs', 'biomarkers'],
    mm: [],
    nhl: ['ngs', 'biomarkers'],
    cll: ['ngs', 'biomarkers'],
    aml: ['ngs', 'biomarkers'],
    hcc: ['ngs', 'biomarkers'],
  },
  [INTAKE_SECTIONS.ABOUT_YOU]: {
    all: ['about', 'name', 'ecog', 'initial_diagnosis_date', 'other_medical_condition', 'location', 'insurances'],
  },
};

window._INTAKE_QUESTIONS = INTAKE_QUESTIONS;

export const INTAKE_ABOUT_QUESTION = {
  id: 'about',
  type: 'INTAKE_ABOUT_YOU',
  intakeType: INTAKE_SECTIONS.ABOUT_YOU,
  intakeTitle: 'intake.about_title',
  isQuestionAnswered: (user) => {
    if (!user?.info?.user_type) return false;
    if (!user?.personal?.dob) return false;
    if (hasGenderQuestion() && !user?.personal?.gender) return false;
    if (!user?.condition_profile.race?.length) return false;
    return true;
  },
  getQuestionValue: (user) => `${hasGenderQuestion() && user?.personal?.gender}, ${new Date(user.personal.dob).getFullYear()}, ${user.condition_profile.race.join(', ')}`,
  setQuestionValue: (user) => {
    // value on the user is set on the renderer
  },
};

export const INTAKE_LOCATION_QUESTION = {
  id: 'location',
  type: 'INTAKE_LOCATION',
  intakeType: INTAKE_SECTIONS.ABOUT_YOU,
  intakeTitle: 'Location preferences',
  isQuestionAnswered: (user) => !isEmptyObject(user?.info?.country),
  getQuestionValue: (user) => `${user?.info?.country?.label}${user?.personal?.travel_limit ? `, ${window.t(`questionnaire.questions.global.travel_limit.options.${user?.personal?.travel_limit}`)}` : ''}`,
  setQuestionValue: () => {
    // value on the user is set on the renderer
  },
};

export const INTAKE_BIOMARKERS_QUESTION = {
  id: 'biomarkers',
  type: 'BIOMARKERS',
  intakeType: 'general',
  intakeTitle: 'Location preferences',
  isQuestionAnswered: (user) => !isEmptyObject(user?.info?.country),
  getQuestionValue: (user) => `${user?.info?.country?.label}${user?.personal?.travel_limit ? `, ${window.t(`questionnaire.questions.global.travel_limit.options.${user?.personal?.travel_limit}`)}` : ''}`,
  setQuestionValue: () => {
    // value on the user is set on the renderer
  },
};

export const INTAKE_NAME_QUESTION = {
  id: 'name',
  type: 'TEXT_INPUT',
  intakeType: INTAKE_SECTIONS.ABOUT_YOU,
  intakeTitle: 'questionnaire.questions.global.full_name.title',
  title: 'questionnaire.questions.global.full_name.title',
  // showTitle: true,
  isQuestionAnswered: (user) => user?.info?.family_name,
  getQuestionValue: (user) => user?.info?.family_name,
  setQuestionValue: (newValue, user) => {
    user.info.family_name = newValue;
    // value on the user is set on the renderer
  },
};

export const CUSTOM_INTAKE_QUESTIONS = [
  INTAKE_ABOUT_QUESTION,
  INTAKE_NAME_QUESTION,
  INTAKE_LOCATION_QUESTION,
];

export const UPLOAD_INTAKE_QUESTIONS = [
  'type',
  'subtype',
  'biomarkers',
  'disease_status',
  'disease_location',
  'disease_stage',
  'received_treatment',
  'treatment_for_advanced_metastatic_disease',
  'treatments',
];

import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import app from 'new-ui/app';
import { useSetHeaderSettings } from 'new-ui/Components/PageHeader/PageHeaderContext';
import { TAB_IDS } from 'new-ui/Components/PageHeader/constants';
import { isNewlyDiagnosedUser } from 'modules/user/utils';
import FlipMove from 'react-flip-move';
import Iframe from 'new-ui/Components/Iframe';
import { useTranslation } from 'utils/modifiedTranslation';
import { replace } from 'connected-react-router';
import styles from './articles.module.css';
import { Label, Card } from './Components';
import { ARTICLES_GA } from './constants';
import { useFetchArticles } from './useFetchArticles';
import { sortArticlesByLabels } from './utils';

const openIframe = (url, dispatch) => {
  const iframe = new Iframe();
  const updatedUrl = `${url}${url.includes('?') ? '&' : '?'}iframe=true`;
  iframe.show(updatedUrl);
  dispatch(replace(`${window.location.pathname}?iframe=${updatedUrl}`));
};

const onClickCard = (article, dispatch) => {
  openIframe(article.sourceUrl, dispatch);

  app.sendGoogleAnalyticsEvent(ARTICLES_GA.category, ARTICLES_GA.events.articleCardClick, {
    article_title: article.title,
    article_link: article.sourceUrl,
    tag_label: article.tag,
    duration: article.durationMinutes,
    urgency_level: article.urgency,
  });
};

const ArticlesPage = () => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const footerRef = useRef(null);

  const user = useSelector((state) => (
    state.authentication
    && state.authentication.loggedIn
    && state.authentication.user) || null);

  const {
    articles,
    labels,
    hasMore,
    loadMoreArticles,
    isLoaded,
  } = useFetchArticles(user);

  const [checkedLabels, setCheckedLabels] = useState([]);

  const loadMoreArticlesHandler = () => {
    loadMoreArticles();
    app.sendGoogleAnalyticsEvent(ARTICLES_GA.category, ARTICLES_GA.events.seeMoreClick);
  };

  const sortedArticles = useMemo(() => sortArticlesByLabels(articles, checkedLabels), [
    articles,
    checkedLabels,
  ]);

  useEffect(() => {
    if (articles.length && footerRef.current) {
      footerRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }, [articles.length]);

  const handleLabelChange = (key) => {
    setCheckedLabels((prev) => {
      if (prev.includes(key)) {
        return [];
      }
      return [key];
    });

    const selectedLabel = labels.find((el) => el.key === key);
    app.sendGoogleAnalyticsEvent(
      ARTICLES_GA.category,
      ARTICLES_GA.events.filterLabelClick,
      { selected_label: selectedLabel.title },
    );
  };

  useSetHeaderSettings({
    isProfileButtonShown: true,
    isBurgerMenuShown: true,
    isUploadButtonShown: true,
    tabIds: [
      TAB_IDS.FEED,
      TAB_IDS.TREATMENT_OPTIONS,
      TAB_IDS.SUPPORT_PROGRAMS,
      TAB_IDS.SYMPTOM_CARE,
      ...(isNewlyDiagnosedUser(app?.user) ? [TAB_IDS.ARTICLES] : []),
      TAB_IDS.SPEAK_WITH_TINA,
    ],
  });

  if (!isLoaded) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.filterWrapper}>
        {labels.map((label) => (
          <Label
            selectedItems={checkedLabels}
            key={label.key}
            item={label}
            onChange={() => handleLabelChange(label.key)}
          />
        ))}
      </div>
      <div className={styles.contentWrapper}>
        <FlipMove
          className={styles.flipMove}
          duration={500}
          easing="ease-out"
        >
          {sortedArticles.map((article) => (
            <Card
              selectedTags={checkedLabels}
              key={article.id}
              item={article}
              onClick={() => onClickCard(article, dispatch)}
            />
          ))}
        </FlipMove>
      </div>

      <div className={styles.footer} ref={footerRef}>
        {hasMore && (
          <button
            className={styles.moreButton}
            type="button"
            onClick={loadMoreArticlesHandler}
          >
            {t('articles.button.see_more')}
          </button>
        )}
      </div>
    </div>
  );
};

export default ArticlesPage;

import app from 'new-ui/app';
import { JOB_STATUS } from 'components/MedicalFiles/constants';
import { setUserData } from 'components/Authentication/actions';

export const geOnJobStatusChange = ({ keepQstack, dispatch }) => (job) => {
  let responseData;

  const { status } = job;
  if (status === JOB_STATUS.UPDATED) {
    if (job.indication) {
      responseData = {
        personal: {
          ...app.user?.personal,
          ...job?.profile.personal,
          condition: job?.indication,
        },
        info: {
          ...app.user?.info,
          ...job?.profile?.info,
          condition: job?.indication,
        },
        condition_profile: {
          ...app.user?.condition_profile,
          ...job?.profile?.condition_profile,
        },
      };
    }
  }
  if (status === JOB_STATUS.COMPLETED || status === JOB_STATUS.COMPLETED_WITH_ALERT) {
    responseData = {
      personal: {
        ...app.user?.personal,
        ...job?.profile?.personal,
        condition: job?.indication,
      },
      info: {
        ...job?.profile.info,
        ...app.user?.info,
        condition: job?.indication,
      },
      condition_profile: {
        ...app.user?.condition_profile,
        ...job?.profile?.condition_profile,
      },
    };
  }

  if (!responseData) {
    return;
  }

  if (keepQstack && responseData?.condition_profile?.qstack && app?.user?.condition_profile?.qstack) {
    responseData.condition_profile.qstack = app.user.condition_profile.qstack;
  }

  dispatch(setUserData(responseData));
  app.user = responseData;
};

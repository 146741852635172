import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'utils/modifiedTranslation';
import { PAGES, ROUTES } from 'new-ui/constants';
import ProfileButton from 'new-ui/Components/ProfileButton';
import SearchIcon from 'new-ui/assets/icons/search-zoom-in.svg';
import { startNewCurrentUser } from 'modules/hcp/actions/newCurrentUserCreate';
import styles from './headerRightSlot.module.css';

export const HeaderRightSlot = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const handleNewClick = () => {
    dispatch(startNewCurrentUser({
      popupTitle: t('save_profile.title'),
      redirectPath: ROUTES[PAGES.SEARCH],
    }));
  };

  return (
    <div className={styles.root}>
      <button
        type="button"
        onClick={handleNewClick}
        className={styles.newButton}
      >
        <img src={SearchIcon} alt="icon" />
        <span className={styles.newButtonLabel}>
          New
        </span>
      </button>
      <div className={styles.profileButtonWrapper}>
        <ProfileButton />
      </div>
    </div>
  );
};
